import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The private note for the person */
    privateNote: PropTypes.string,

    /** The name of the person */
    name: PropTypes.string.isRequired,

    /** A callback function to trigger when the private note is changed */
    onPrivateNoteChange: PropTypes.func.isRequired,
};
const defaultProps = {
    privateNote: '',
};

class PrivateNote extends React.Component {
    constructor(props) {
        super(props);
        this.updatePrivateNote = this.updatePrivateNote.bind(this);
        this.state = {
            privateNote: props.privateNote || '',
        };
    }

    /**
     * Update the note in our state and also trigger our prop callback
     *
     * @param {SyntheticEvent} e
     */
    updatePrivateNote(e) {
        this.props.onPrivateNoteChange(e.target.value);
        this.setState({
            privateNote: e.target.value,
        });
    }

    render() {
        return (
            <textarea
                className="form-control flex-grow-1 matching-sidebar-color"
                rows="6"
                placeholder={`My private notes on ${this.props.name}...`}
                value={this.state.privateNote || ''}
                onChange={this.updatePrivateNote}
            />
        );
    }
}

PrivateNote.propTypes = propTypes;
PrivateNote.defaultProps = defaultProps;
PrivateNote.displayName = 'PrivateNote';

export default PrivateNote;
