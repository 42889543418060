import PropTypes from 'prop-types';
import propTypesIndividual from './propTypesIndividual';

export const chronosTopProjectProps = PropTypes.shape({
    /** An array of the user's last 6 months' top projects */
    topProjects: PropTypes.arrayOf(PropTypes.string),

    /** Any error that may have occurred while trying to get the top data from the API */
    error: PropTypes.string,
});

export const chronosHoursWorkedProps = PropTypes.shape({
    /** An integer showing the amount of hours a user has worked since last comp review */
    hoursWorked: PropTypes.number,
});

export const propTypesTotalChatsReceived = PropTypes.shape({
    /** The total count of chats received for this user */
    total: PropTypes.number,

    /** The count of chats received for this user for each tier */
    firstResponder: PropTypes.number,
    firstResponderPlus: PropTypes.number,
    triage: PropTypes.number,
    supportal: PropTypes.number,
    launch: PropTypes.number,
    operations: PropTypes.number,
    c4b: PropTypes.number,
    manifesto: PropTypes.number,
    retention: PropTypes.number,
    sfoBar: PropTypes.number,
    pdxBar: PropTypes.number,
    sfoLounge: PropTypes.number,
    pdxLounge: PropTypes.number,
    researchTeam: PropTypes.number,
});

export const propTypesTotalQAChatsHandled = PropTypes.shape({
    /** The total count of QA chats handled by this user */
    total: PropTypes.number,
});

export const conciergeTotalCustomerChatsReceivedProps = PropTypes.shape({
    /** An array of the user's total chats received in the last 6 months */
    totalCustomerChatsReceived: propTypesTotalChatsReceived,

    /** Any error that may have occurred while trying to get the top data from the API */
    error: PropTypes.string,
});

export const conciergeTotalQAChatsHandledProps = PropTypes.shape({
    /** An array of the user's total QA chats handled in the last 6 months */
    totalQAChatsHandled: propTypesTotalQAChatsHandled,

    /** Any error that may have occurred while trying to get the top data from the API */
    error: PropTypes.string,
});

export const propTypesContributions = PropTypes.shape({
    /** The github username for this user. */
    username: PropTypes.string,

    /** The count of the github pull requests for this user. */
    pullRequests: PropTypes.number,

    /** The count of the github issues opened by this user. */
    openedIssues: PropTypes.number,

    /** The count of the closed github issues assigned to this user. */
    closedIssues: PropTypes.number,

    /** The count of the github reviews for this user. */
    reviews: PropTypes.number,

    /** The count of the github commits for this user. */
    commits: PropTypes.number,

    /** Contributions by all Upwork contributor's that were reviewed by the user */
    openSource: PropTypes.number,

    /** The count of the github contributions for this user. */
    total: PropTypes.number,
});

export const githubContributionProps = PropTypes.objectOf(PropTypes.shape({
    /** The contributions of a user */
    contributions: propTypesContributions,

    /** If there's an error, the message would be here */
    error: PropTypes.string,

    /** Today's date */
    date: PropTypes.string,
}));

export const figmaContributionProps = PropTypes.shape({
    /** The contributions of a user */
    figmaContributions: PropTypes.number,

    /** If there's an error, the message would be here */
    error: PropTypes.string,
});

export const stackoverflowReputationProps = PropTypes.shape({
    /** The Stackoverflow reputation */
    stackoverflowReputation: PropTypes.number,

    /** If there's an error, the message would be here */
    error: PropTypes.string,
});

export const githubCommentsProps = PropTypes.shape({
    /** The number of GitHub comments */
    githubComments: PropTypes.number,

    /** If there's an error, the message would be here */
    error: PropTypes.string,
});

export default PropTypes.shape({
    /** The props for the first person in the pair */
    A: propTypesIndividual,

    /** The props for the second person in the pair */
    B: propTypesIndividual,
});
