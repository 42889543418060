import React from 'react';
import _ from 'underscore';
import {chronosTopProjectProps} from './propTypesPair';

const propTypes = {
    /** Top projects worked on by this person during the last six months */
    chronosTopProjects: chronosTopProjectProps,
};

const defaultProps = {
    chronosTopProjects: {},
};

class Projects extends React.Component {
    constructor(props) {
        super(props);

        this.toggleProjectExpanded = this.toggleProjectExpanded.bind(this);

        this.state = {
            indexesExpanded: [],
        };
    }

    /**
     * Toggles the project description to either show it completely or only display up to a few words
     *
     * @param {Number} index
     */
    toggleProjectExpanded(index) {
        this.setState((prevState) => {
            const currentIndexExpanded = prevState.indexesExpanded;
            const position = currentIndexExpanded.indexOf(index);
            if (position > -1) {
                currentIndexExpanded.splice(position, 1);
            } else {
                currentIndexExpanded.push(index);
            }

            return {indexesExpanded: currentIndexExpanded};
        });
    }

    render() {
        return (
            <div>
                <div className="text-secondary-color">Recent Top Projects</div>
                {this.props.chronosTopProjects.error ? (
                    <p>No top projects found</p>
                ) : (
                    <ul className="list-top-projects">
                        {_.map(_.values(this.props.chronosTopProjects.topProjects).reverse(), (topProject, index) => (
                            <li key={index}>
                                <span
                                    className={`${_.contains(this.state.indexesExpanded, index) ? '' : 'text-truncate'}`}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{__html: topProject}}
                                    role="presentation"
                                    aria-label="link"
                                    onClick={() => this.toggleProjectExpanded(index)}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        );
    }
}

Projects.propTypes = propTypes;
Projects.defaultProps = defaultProps;
Projects.displayName = 'Projects';

export default Projects;
