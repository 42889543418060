import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** Stuff that is displayed inside the alert */
    children: PropTypes.node,

    /** Function is called when the dismiss button is clicked */
    onDismiss: PropTypes.func,

    /** Whether or not the Alert should be shown */
    shouldShow: PropTypes.bool,

    /** The type of visual alert style the alert will have */
    type: PropTypes.oneOf(['success', 'danger', 'warning']),
};
const defaultProps = {
    children: null,
    onDismiss: () => {},
    shouldShow: false,
    type: 'success',
};
const Alert = (props) => {
    if (!props.shouldShow) {
        return null;
    }

    return (
        <div className={`alert alert-${props.type} mt-4`}>
            {props.children}
            <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={props.onDismiss}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
};
Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
