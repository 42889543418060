import React from 'react';
import PropTypes from 'prop-types';
import {getPair} from '../libs/actions/Pair';
import MatchRow from './MatchRow';
import ProfilePair from '../pages/pair/ProfilePair';

const propTypes = {
    matches: PropTypes.arrayOf(
        PropTypes.shape({
            /** The person who was on the left of the match */
            A: PropTypes.shape({
                /** The email address of the person */
                email: PropTypes.string.isRequired,

                /** The name of the person */
                name: PropTypes.string.isRequired,
            }).isRequired,

            /** Same as A */
            B: PropTypes.shape({
                email: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired,

            /** The email address of the person which was ranked higher */
            higher: PropTypes.string.isRequired,

            /** Flag that shows if the match was auto generated or not */
            isAutoMatch: PropTypes.bool,
        }),
    ),

    // A flag to disable the UI
    disabled: PropTypes.bool,

    // A function to call when the results of a row are switched
    onSwitch: PropTypes.func.isRequired,
};

const defaultProps = {
    disabled: false,
    matches: [],
};


class MatchRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {pair: null};
    }

    getButtons(row) {
        const newHigher = row.higher === row.A.email
            ? row.B.email
            : row.A.email;
        const buttons = {
            Switch: () => {
                if (this.isProfilePairOpen(row)) {
                    // Open the profile in the opposite order. We call this.openProfilePair
                    // with the pairs in the new order instead of just flipping them in the state
                    // because this way we reload the private/feedback notes.
                    this.openProfilePair(
                        newHigher === row.A.email ? row.A.email : row.B.email,
                        newHigher === row.A.email ? row.B.email : row.A.email,
                    );
                }
                this.props.onSwitch(row.A.email, row.B.email, newHigher);
            },
        };
        if (this.isProfilePairOpen(row)) {
            buttons['Hide Profiles'] = () => this.setState({pair: null});
        } else {
            // ProfilePair display A to the left and B to the right, meanwhile, MatchRow displays
            // to the left the profile that has the email equal to row.higher. Lets make sure
            // that ProfilePair shows the profile with email matching with row.higher to the left.
            buttons['View Profiles'] = () => this.openProfilePair(
                row.higher === row.A.email ? row.A.email : row.B.email,
                row.higher === row.A.email ? row.B.email : row.A.email,
            );
        }
        return buttons;
    }

    isProfilePairOpen(row) {
        if (!this.state.pair) {
            return false;
        }

        // The pair may be swapped, check both ways
        return (this.state.pair.A.email === row.A.email && this.state.pair.B.email === row.B.email)
            || (this.state.pair.B.email === row.A.email && this.state.pair.A.email === row.B.email);
    }

    openProfilePair(emailA, emailB) {
        this.setState({pair: {loading: true, A: {email: emailA}, B: {email: emailB}}});
        getPair(emailA, emailB).then((pairData) => {
            // The server may respond with the pairs in a different order
            this.setState({
                pair: {
                    A: pairData.A.email === emailA ? pairData.A : pairData.B,
                    B: pairData.A.email === emailA ? pairData.B : pairData.A,
                },
            });
        });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-4 text-center">
                        <strong>Higher</strong>
                    </div>
                    <div className="col-4" />
                    <div className="col-4 text-center js_name-lower">
                        <strong>Lower</strong>
                    </div>
                </div>

                <div className="striped-rows">
                    {this.props.matches && this.props.matches.map(row => (
                        <React.Fragment key={`${row.A.email}_${row.B.email}`}>
                            <MatchRow
                                A={row.A}
                                B={row.B}
                                higher={row.higher}
                                buttons={this.getButtons(row)}
                                disabled={this.props.disabled}
                                isAutoMatch={row.isAutoMatch}
                            />
                            {this.isProfilePairOpen(row) && (
                                <div className="mt-2">
                                    {this.state.pair.loading ? 'Loading!' : <ProfilePair pair={this.state.pair} shouldShowSelectButton={false} /> }
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </>
        );
    }
}

MatchRows.propTypes = propTypes;
MatchRows.defaultProps = defaultProps;
MatchRows.displayName = 'MatchRows';

export default MatchRows;
