import _ from 'underscore';

const growthTrackTeamUrls = {
    // expensifier track
    '#veteran': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-expensifier-track#h.p_Qh2bxEcKRakW',
    '#storied': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-expensifier-track#h.p_4LkJikU4RbgM',
    '#oldguard': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-expensifier-track#h.p_y6uQzdWZRbri',

    // ambassador track
    '#evangelist': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-ambassador-track#h.p_RU9l6-6NRqqK',
    '#speaker': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-ambassador-track#h.p_LWjUJlkeRp4x',
    '#ambassador': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-ambassador-track#h.p_hIvHS183RqS4',
    '#thoughtleader': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-ambassador-track#h.p_rl6X4_WCRqfH',

    // generalist track
    '#graduate': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_Xc_7hcFjSAhI',

    // use the same one for project manager and specialist until rename is complete
    '#specialist': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_ushY17SFSArv',
    '#project-manager': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_ushY17SFSArv',

    // use the same one for product manager and serial_specialist until rename is complete
    '#serial_specialist': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_gXs4w01pSBHe',
    '#product-manager': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_gXs4w01pSBHe',
    '#generalist': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-generalist-track#h.p_MrvcFTcGSBTO',

    // people development track
    '#mentor': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-people-development-track#h.p_O5uL8egMSXUA',
    '#leader': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-people-development-track#h.p_PJBqOGrUSXew',
    '#senior-leader': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-people-development-track#h.p_qq3_uIA5SXp9',
    '#director': 'https://sites.google.com/a/expensify.com/manifesto/working-at-expensify/growth-recognition/tracks/the-people-development-track#h.p_S3V2VGeGSX1J',

    '#insider': 'https://stackoverflow.com/c/expensify/questions/11813/',
};


const growthTracksTeams = _.keys(growthTrackTeamUrls);

const growthTracks = {
    Expensifier: ['#veteran', '#storied', '#oldguard'],
    Ambassador: ['#evangelist', '#speaker', '#ambassador', '#thoughtleader'],
    Generalist: ['#graduate', '#project-manager', '#product-manager', '#generalist'],
    'People development': ['#mentor', '#leader', '#senior-leader', '#director'],
};

const teamToRecognitionTrack = _.reduce(growthTracks, (memo, teams, growthTrack) => {
    // eslint-disable-next-line no-param-reassign
    _.forEach(teams, team => memo[team] = growthTrack);
    return memo;
}, {});

/**
 * Returns the list of teams from `teamsA` not found in `teamsB` considering the growth and recognition tracks's team hierarchy.
 * If `teamsA` contains '#graduate' and `teamsB` contains '#product-managers', '#graduate' won't be included in the return.
 *
 * @param {Array<string>} teamsA
 * @param {Array<string>} teamsB
 * @returns {Array<string>}
 */

function getUniqueOrHigherTeams(teamsA, teamsB) {
    return _.filter(teamsA, (teamA) => {
        if (teamsB.includes(teamA)) {
            return false;
        }
        if (teamA in teamToRecognitionTrack) {
            // This is a team from G/R tracks
            const teamAGrowthTrack = teamToRecognitionTrack[teamA];
            const teamAPositionInGrowthTrack = growthTracks[teamAGrowthTrack].indexOf(teamA);

            // Try to find a team in teamB that belongs to the same G/R track but it is more advanced. If we find it,
            // teamA is rejected.
            if (_.some(teamsB, teamB => growthTracks[teamAGrowthTrack].indexOf(teamB) > teamAPositionInGrowthTrack)) {
                return false;
            }
        }

        // The teamA was not in teamsB, and we also didn't find a higher team on the same G/R track
        return true;
    });
}

export {
    getUniqueOrHigherTeams,
    growthTracksTeams,
    growthTrackTeamUrls,
    teamToRecognitionTrack,
};
