import Onyx from 'react-native-onyx';
import Str from 'expensify-common/lib/str';
import ONYXKEYS from '../../ONYXKEYS';

let currentRedirectTo;
Onyx.connect({
    key: ONYXKEYS.APP_REDIRECT_TO,
    callback: val => currentRedirectTo = val,
});
let darkThemeEnabled;
Onyx.connect({
    key: ONYXKEYS.APP_DARK_THEME_ENABLED,
    callback: val => darkThemeEnabled = val,
});

/**
 * Redirect the app to a new page by updating the state in Onyx
 *
 * @param {String} url
 */
function redirect(url) {
    const formattedURL = Str.normalizeUrl(url);
    Onyx.set(ONYXKEYS.APP_REDIRECT_TO, formattedURL);
}

/**
 * Keep the current route match stored in Onyx so other libs can access it
 * Also reset the app_redirect_to in Onyx so that if we go back to the current url the state will update
 *
 * @param {Object} match
 * @param {String} match.url
 */
function recordCurrentRoute({match}) {
    Onyx.merge(ONYXKEYS.CURRENT_URL, match.url);
    if (match.url === currentRedirectTo) {
        Onyx.set(ONYXKEYS.APP_REDIRECT_TO, null);
    }
}

/**
 * Function to toggle the UI theme
 */
function toggleTheme() {
    Onyx.merge(ONYXKEYS.APP_DARK_THEME_ENABLED, !darkThemeEnabled);
}

export {
    recordCurrentRoute,
    redirect,
    toggleTheme,
};
