import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import getComponentDisplayName from '../libs/getComponentDisplayName';
import ONYXKEYS from '../ONYXKEYS';

const permissionPropTypes = {
    /** From Onyx: data about the permissions the current user has */
    permissions: PropTypes.shape({
        /** Indicates if the user is able to access the compensation data */
        canAccessCompensation: PropTypes.bool,
    }),
};

export default function (WrappedComponent) {
    const propTypes = {
        /** The HOC takes an optional ref as a prop and passes it as a ref to the wrapped component.
          * That way, if a ref is passed to a component wrapped in the HOC, the ref is a reference to the wrapped component, not the HOC. */
        forwardedRef: PropTypes.func,

        ...permissionPropTypes,
    };

    const defaultProps = {
        forwardedRef: () => {},

        ...permissionPropTypes,
    };

    const WithCompensationAccessPermission = (props) => {
        // Don't render anything if this information cannot be accessed
        if (!props.permissions.canAccessCompensation) {
            return null;
        }

        return (
            <WrappedComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                ref={props.forwardedRef}
            />
        );
    };

    WithCompensationAccessPermission.displayName = `withCompensationAccessPermission(${getComponentDisplayName(WrappedComponent)})`;
    WithCompensationAccessPermission.propTypes = propTypes;
    WithCompensationAccessPermission.defaultProps = defaultProps;
    const withCompensationAccessPermission = React.forwardRef((props, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WithCompensationAccessPermission {...props} forwardedRef={ref} />
    ));

    return withOnyx({
        permissions: {
            key: ONYXKEYS.PERMISSIONS,
        },
    })(withCompensationAccessPermission);
}

export {
    permissionPropTypes,
};
