import Onyx from 'react-native-onyx';
import lodashGet from 'lodash/get';
import _ from 'underscore';
import * as API_Comp from '../APIComp';
import API_Expensify from '../APIExpensify';
import ONYXKEYS from '../../ONYXKEYS';
import {redirect} from './App';
import ROUTES from '../../ROUTES';
import * as Metric from './Metric';

let allMatchResults;
Onyx.connect({
    key: ONYXKEYS.ALL_MATCH_RESULTS,
    callback: val => allMatchResults = val,
});

let matchResultsForEmail;
Onyx.connect({
    key: ONYXKEYS.MATCH_RESULTS_FOR_EMAIL,
    callback: val => matchResultsForEmail = val,
});

let autoMatches;
Onyx.connect({
    key: ONYXKEYS.AUTO_MATCHES,
    callback: val => autoMatches = val,
});

let session;
Onyx.connect({
    key: ONYXKEYS.SESSION,
    callback: val => session = val,
});

let githubContributions = {};
Onyx.connect({
    key: ONYXKEYS.GITHUB_CONTRIBUTIONS,
    callback: val => githubContributions = val,
});

let figmaContributions = {};
Onyx.connect({
    key: ONYXKEYS.FIGMA_CONTRIBUTIONS,
    callback: val => figmaContributions = val,
});

let chronosTopProjects = {};
Onyx.connect({
    key: ONYXKEYS.CHRONOS_TOP_PROJECTS,
    callback: val => chronosTopProjects = val,
});

let chronosHoursWorked = {};
Onyx.connect({
    key: ONYXKEYS.CHRONOS_HOURS_WORKED,
    callback: val => chronosHoursWorked = val,
});

let conciergeTotalCustomerChatsReceived = {};
Onyx.connect({
    key: ONYXKEYS.CONCIERGE_TOTAL_CUSTOMER_CHATS_RECEIVED,
    callback: val => conciergeTotalCustomerChatsReceived = val,
});

let conciergeTotalQAChatsHandled = {};
Onyx.connect({
    key: ONYXKEYS.CONCIERGE_TOTAL_QA_CHATS_HANDLED,
    callback: val => conciergeTotalQAChatsHandled = val,
});

let slackUsername = {};
Onyx.connect({
    key: ONYXKEYS.SLACK_USERNAME,
    callback: val => slackUsername = val,
});

let stackoverflowReputation = {};
Onyx.connect({
    key: ONYXKEYS.STACKOVERFLOW_REPUTATION,
    callback: val => stackoverflowReputation = val,
});

let githubComments = {};
Onyx.connect({
    key: ONYXKEYS.GITHUB_COMMENTS,
    callback: val => githubComments = val,
});

/**
 * Get the results of all the matches done by the current person
 */
function getAllMatchResults() {
    Onyx.merge(ONYXKEYS.APP, {loading: true});

    API_Comp.MatchResults_GetAll()
        .then((response) => {
            Onyx.merge(ONYXKEYS.APP, {loading: false});

            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.ALL_MATCH_RESULTS, response.data);
            }
        });
}

/**
 * Get all the match results for a single email
 *
 * @param {String} email
 */
function getAllMatchResultsForEmail(email) {
    Onyx.merge(ONYXKEYS.APP, {loading: true});

    API_Comp.MatchResults_GetForEmail({email})
        .then((response) => {
            Onyx.merge(ONYXKEYS.APP, {loading: false});

            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.MATCH_RESULTS_FOR_EMAIL, response.data);
            }
        });
}

/**
 * Get the matches that were autoCompleted for a pair of people
 *
 * @param {String} higherEmail
 * @param {String} lowerEmail
 */
function getAutoMatches(higherEmail, lowerEmail) {
    Onyx.merge(ONYXKEYS.APP, {loading: true});

    API_Comp.AutoMatches_Get({higherEmail, lowerEmail})
        .then((response) => {
            Onyx.merge(ONYXKEYS.APP, {loading: false});

            if (response.jsonCode === 200) {
                // If there were not autoMatches returned, then redirect back to the pairs page
                const matchesLostCount = lodashGet(response, 'data.matches.matchesLostByHigherEmail', []).length;
                const matchesWonCount = lodashGet(response, 'data.matches.matchesWonByLowerEmail', []).length;
                if (!matchesLostCount && !matchesWonCount) {
                    redirect(ROUTES.PAIR);
                    return;
                }

                Onyx.set(ONYXKEYS.AUTO_MATCHES, response.data);
            }
        });
}

/**
 * Checks if the data that was cached has expired after one day
 * @param {String} date
 * @returns {bool}
 */
function hasCachedDataExpired(date) {
    const ONE_DAY_MS = 1000 * 60 * 60 * 24;
    return new Date() - Date.parse(date) > ONE_DAY_MS;
}

/**
 * Fetch extra data and save it in the Onyx store
 * @param {String} email
 * @param {Object} params
 * @param {String} params.apiAttr
 * @param {String} params.attr
 * @param {String} params.onyxAttr
 * @param {String} params.onyxKey
 * @param {function} params.method
 */
function fetchAndSaveMetricData(email, params) {
    if (!_.has(params.attr, email)
        || _.isUndefined(params.attr[params.apiAttr])
        || _.has(params.attr[email], 'error')
        || hasCachedDataExpired(params.attr[email].date)) {
        const response = params.method({email, months: 6});
        const isFromLocalStore = _.isObject(response) && !_.isFunction(response.then);

        Promise.resolve(response).then((result) => {
            if (result.jsonCode === 200 || isFromLocalStore) {
                const newObj = {date: Date().toString()};
                if (params.apiAttr === null) {
                    newObj[params.onyxAttr] = result;
                } else {
                    newObj[params.onyxAttr] = result[params.apiAttr];
                }
                Onyx.merge(params.onyxKey, {
                    [email]: newObj,
                });
            } else {
                Onyx.merge(params.onyxKey, {
                    [email]: {
                        error: 'unavailable',
                        date: Date().toString(),
                    },
                });
            }
        });
    }
}

/**
 * Fetch the Github, Top projects, Concierge KPIs and other extra data
 * and set it into the Onyx store
 * @param {String} email
 */
function getAllMetricDataForEmail(email) {
    fetchAndSaveMetricData(email, {
        apiAttr: null,
        attr: githubContributions,
        method: Metric.getTotalGithubContributions,
        onyxAttr: 'contributions',
        onyxKey: ONYXKEYS.GITHUB_CONTRIBUTIONS,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'figmaContributions',
        attr: figmaContributions,
        method: Metric.getFigmaContributions,
        onyxAttr: 'figmaContributions',
        onyxKey: ONYXKEYS.FIGMA_CONTRIBUTIONS,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'topProjects',
        attr: chronosTopProjects,
        method: Metric.getChronosTopProjects,
        onyxAttr: 'topProjects',
        onyxKey: ONYXKEYS.CHRONOS_TOP_PROJECTS,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'hoursWorked',
        attr: chronosHoursWorked,
        method: Metric.getChronosHoursWorked,
        onyxAttr: 'hoursWorked',
        onyxKey: ONYXKEYS.CHRONOS_HOURS_WORKED,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'totalCustomerChatsReceived',
        attr: conciergeTotalCustomerChatsReceived,
        method: Metric.getConciergeKPIs,
        onyxAttr: 'totalCustomerChatsReceived',
        onyxKey: ONYXKEYS.CONCIERGE_TOTAL_CUSTOMER_CHATS_RECEIVED,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'totalQAChatsHandled',
        attr: conciergeTotalQAChatsHandled,
        method: Metric.getConciergeKPIs,
        onyxAttr: 'totalQAChatsHandled',
        onyxKey: ONYXKEYS.CONCIERGE_TOTAL_QA_CHATS_HANDLED,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'name',
        attr: slackUsername,
        method: API_Expensify.GetSlackUsernames,
        onyxAttr: 'username',
        onyxKey: ONYXKEYS.SLACK_USERNAME,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'reputation',
        attr: stackoverflowReputation,
        method: Metric.getStackoverflowReputation,
        onyxAttr: 'stackoverflowReputation',
        onyxKey: ONYXKEYS.STACKOVERFLOW_REPUTATION,
    });
    fetchAndSaveMetricData(email, {
        apiAttr: 'githubComments',
        attr: githubComments,
        method: Metric.getGithubComments,
        onyxAttr: 'githubComments',
        onyxKey: ONYXKEYS.GITHUB_COMMENTS,
    });
}

/**
 * Get a pair of people to display as a match
 */
function getRandomPair() {
    Onyx.merge(ONYXKEYS.APP, {loading: true});

    API_Comp.Pair_GetRandom()
        .then((response) => {
            Onyx.merge(ONYXKEYS.APP, {loading: false});

            if (response.jsonCode === 200) {
                const {totalMatches, matchesPerformed} = response.data;
                Onyx.merge(ONYXKEYS.APP, {totalMatches, matchesPerformed});

                if (!response.data.A || !response.data.B) {
                    return;
                }
                Onyx.set(ONYXKEYS.PAIR, response.data);
                getAllMetricDataForEmail(response.data.A.email);
                getAllMetricDataForEmail(response.data.B.email);
            }
        });
}

function getPair(emailA, emailB) {
    return API_Comp.Pair_Get(emailA, emailB)
        .then(response => response.data);
}

/**
 * Save the note for a specific person
 *
 * @param {String} email
 * @param {String} note
 * @param {String} currentPairKey A or B
 */
function saveNote(email, note, currentPairKey = '') {
    // The first time a note is added for a person, the note is not added in the pair onyx key
    // This causes an issue when you revert to a previous match since it would not have the updated note
    if (currentPairKey) {
        Onyx.merge(ONYXKEYS.PAIR, {
            [currentPairKey]: {
                note,
            },
        });
    }
    API_Comp.Note_Save({email, note})
        .then((response) => {
            if (response.jsonCode === 200 && allMatchResults) {
                // Use a reducer to update the note of the person
                const newAllMatchResults = allMatchResults.map((matchResult) => {
                    if (matchResult.email === email) {
                        return {
                            ...matchResult,
                            note,
                        };
                    }
                    return matchResult;
                });
                Onyx.set(ONYXKEYS.ALL_MATCH_RESULTS, newAllMatchResults);
            }
        });
}

/**
 * Save the private note for a specific person
 *
 * @param {String} email
 * @param {String} privateNote
 * @param {String} currentPairKey A or B
 */
function savePrivateNote(email, privateNote, currentPairKey = '') {
    // The first time a note is added for a person, the note is not added in the pair onyx key
    // This causes an issue when you revert to a previous match since it would not have the updated note
    if (currentPairKey) {
        Onyx.merge(ONYXKEYS.PAIR, {
            [currentPairKey]: {
                privateNote,
            },
        });
    }

    API_Comp.PrivateNote_Save({email, privateNote})
        .then((response) => {
            if (response.jsonCode === 200 && allMatchResults) {
                // Use a reducer to update the privateNote of the person
                const newAllMatchResults = allMatchResults.map((matchResult) => {
                    if (matchResult.email === email) {
                        return {
                            ...matchResult,
                            privateNote,
                        };
                    }
                    return matchResult;
                });
                Onyx.set(ONYXKEYS.ALL_MATCH_RESULTS, newAllMatchResults);
            }
        });
}

/**
 * Record the result of two people being matched against each other
 *
 * @param {Object} userA
 * @param {Object} userB
 * @param {String} higherEmail
 */
function selectPair(userA, userB, higherEmail) {
    Onyx.merge(ONYXKEYS.APP, {loading: true});

    // Store the match we just made in PREVIOUS_PAIR in case we want to undo / go back
    Onyx.set(ONYXKEYS.PREVIOUS_PAIR, {
        A: userA,
        B: userB,
    });

    API_Comp.Pair_SaveResult({
        emailA: userA.email,
        emailB: userB.email,
        higherEmail,
    }).then((response) => {
        Onyx.merge(ONYXKEYS.APP, {loading: false});

        if (response.jsonCode === 200) {
            Onyx.set(ONYXKEYS.PAIR, {});

            // Redirect to the page which will show any autoMatches
            const lowerEmail = higherEmail === userA.email ? userB.email : userA.email;
            redirect(ROUTES.getAutoMatchesRoute(higherEmail, lowerEmail));
        }
    });
}

/**
 * Switches the current pair to the pair stored in previousPair
 *
 * @param {Object} previousPairA
 * @param {Object} previousPairB
 * @param {Number} matchesPerformed
 */
function goToPreviousPair(previousPairA, previousPairB, matchesPerformed) {
    Onyx.set(ONYXKEYS.PAIR, {
        A: previousPairA,
        B: previousPairB,
    });

    Onyx.merge(ONYXKEYS.APP, {matchesPerformed});

    if (!autoMatches || !autoMatches.matches) {
        return;
    }

    // Since the previous pair might have generated auto matches we want to revert those as well
    Onyx.merge(ONYXKEYS.PREVIOUS_PAIR, {
        loading: true,
    });
    const combinedAutoMatches = [
        ...autoMatches.matches.matchesLostByHigherEmail,
        ...autoMatches.matches.matchesWonByLowerEmail,
    ];

    // We only want to revert matches that the user has not manually updated
    const autoMatchedPairs = _.where(combinedAutoMatches, {isAutoMatch: true});

    API_Comp.Pair_ResetMatch({
        createdBy: session.email,
        matches: JSON.stringify(autoMatchedPairs),
    }).then((response) => {
        if (response.jsonCode !== 200) {
            console.debug('Unable to reset match', response);
        }
    }).finally(() => {
        // If we redirect the user too early, they have the chance of doing the pairing
        // before the auto matches get reverted leading to missed auto matches for the new winner
        Onyx.merge(ONYXKEYS.PREVIOUS_PAIR, {
            loading: false,
        });
        redirect(ROUTES.PAIR);
    });
}

/**
 * Switch the result of a pair of people
 *
 * @param {String} emailA
 * @param {String} emailB
 * @param {String} higherEmail
 */
function switchPair(emailA, emailB, higherEmail) {
    Onyx.merge(ONYXKEYS.APP, {isUIDisabled: true});

    API_Comp.Pair_SaveResult({
        emailA,
        emailB,
        higherEmail,
    }).then((response) => {
        Onyx.merge(ONYXKEYS.APP, {isUIDisabled: false});

        if (response.jsonCode === 200) {
            // Use a reducer to modify the pair in Onyx to swap who is higher
            const newAutoMatches = {
                ...autoMatches,
                matches: {
                    ...autoMatches.matches,
                    matchesWonByLowerEmail: autoMatches.matches.matchesWonByLowerEmail.map((match) => {
                        if (match.A.email === emailA && match.B.email === emailB) {
                            return {
                                ...match,
                                higher: higherEmail,
                                isAutoMatch: false,
                            };
                        }
                        return match;
                    }),
                    matchesLostByHigherEmail: autoMatches.matches.matchesLostByHigherEmail.map((match) => {
                        if (match.A.email === emailA && match.B.email === emailB) {
                            return {
                                ...match,
                                higher: higherEmail,
                                isAutoMatch: false,
                            };
                        }
                        return match;
                    }),
                },
            };
            Onyx.set(ONYXKEYS.AUTO_MATCHES, newAutoMatches);
        }
    });
}

/**
 * Switch the result of a pair of people
 *
 * @param {String} emailA
 * @param {String} emailB
 * @param {String} higherEmail
 */
function switchMatchResultsForEmailPair(emailA, emailB, higherEmail) {
    Onyx.merge(ONYXKEYS.APP, {isUIDisabled: true});

    API_Comp.Pair_SaveResult({
        emailA,
        emailB,
        higherEmail,
    }).then((response) => {
        Onyx.merge(ONYXKEYS.APP, {isUIDisabled: false});

        if (response.jsonCode === 200) {
            // Use a reducer to modify the pair in Onyx to swap who is higher
            const newMatchResultsForEmail = matchResultsForEmail.map((match) => {
                if (match.A.email === emailA && match.B.email === emailB) {
                    return {
                        ...match,
                        higher: higherEmail,
                        isAutoMatch: false,
                    };
                }
                return match;
            });
            Onyx.set(ONYXKEYS.MATCH_RESULTS_FOR_EMAIL, newMatchResultsForEmail);
        }
    });
}

export {
    getAllMatchResults,
    getAllMatchResultsForEmail,
    getAllMetricDataForEmail,
    getAutoMatches,
    getRandomPair,
    getPair,
    saveNote,
    savePrivateNote,
    selectPair,
    goToPreviousPair,
    switchPair,
    switchMatchResultsForEmailPair,
};
