import * as APIUtils from './APIUtils';
import CONFIG from '../CONFIG';

/**
 * @param {Object} parameters
 * @param {String} parameters.authToken
 * @param {String} parameters.partnerName
 * @param {String} parameters.partnerPassword
 * @param {String} parameters.partnerUserID
 * @param {String} parameters.partnerUserSecret
 * @returns {Promise}
 */
function CreateLogin(parameters) {
    const commandName = 'CreateLogin';
    APIUtils.requireParameters([
        'authToken',
        'partnerName',
        'partnerPassword',
        'partnerUserID',
        'partnerUserSecret',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @param {Number} parameters.months
 * @returns {Promise}
 */
function GetTotalGithubContributions(parameters) {
    const commandName = 'Github_GetTotalContributions';
    APIUtils.requireParameters([
        'email',
        'months',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function GetFigmaContributions(parameters) {
    const commandName = 'Figma_GetContributions';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.emailList
 * @returns {Promise}
 */
function GetConciergeKPIs(parameters) {
    const commandName = 'ChatBot_GetConciergeKPIs';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

function GetChronosTopProjects(parameters) {
    const commandName = 'Chronos_GetTopProjects';
    APIUtils.requireParameters([
        'email',
        'months',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function GetChronosHoursWorked(parameters) {
    const commandName = 'Chronos_GetHoursWorked';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function GetSlackUsernames(parameters) {
    const commandName = 'Slack_GetUsername';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function GetStackoverflowReputation(parameters) {
    const commandName = 'StackOverflow_GetReputation';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function GetGithubComments(parameters) {
    const commandName = 'Github_GetComments';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.partnerUserID
 * @param {String} parameters.partnerName
 * @param {String} parameters.partnerPassword
 * @param {String} parameters.doNotRetry
 * @returns {Promise}
 */
function DeleteLogin(parameters) {
    const commandName = 'DeleteLogin';
    APIUtils.requireParameters(['partnerUserID', 'partnerName', 'partnerPassword', 'doNotRetry'],
        parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.message
 * @param {Object} parameters.parameters
 * @param {String} [parameters.email]
 * @returns {Promise}
 */
function Log(parameters) {
    const commandName = 'Log';
    APIUtils.requireParameters(['logPacket', 'parameters'], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

/**
 * @param {String} email
 * @returns {Promise}
 */
function BeginSignIn(email) {
    const commandName = 'BeginSignIn';
    return APIUtils.request(commandName, {email}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_EXPENSIFY);
}

export default {
    Authenticate: APIUtils.Authenticate,
    BeginSignIn,
    CreateLogin,
    DeleteLogin,
    GetTotalGithubContributions,
    GetFigmaContributions,
    GetChronosTopProjects,
    GetChronosHoursWorked,
    GetSlackUsernames,
    GetStackoverflowReputation,
    GetGithubComments,
    GetConciergeKPIs,
    Log,
};
