import _ from 'underscore';
import React from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import ONYXKEYS from '../ONYXKEYS';

const propTypes = {
    /** A value to set the field to */
    value: PropTypes.string,

    /** A callback method that is triggered when an email is selected */
    onSelect: PropTypes.func.isRequired,

    /** From Onyx: all the emails of employees (past and present) */
    emails: PropTypes.arrayOf(PropTypes.string),

    /** A default option to show in the select input */
    defaultOption: PropTypes.string,
};
const defaultProps = {
    value: '',
    emails: [],
    defaultOption: null,
};

const EmailSelector = props => (
    <div className="form-group">
        <select
            className="form-control"
            value={props.value}
            onChange={e => props.onSelect(e.target.value)}
        >
            {props.defaultOption && (
                <option>{props.defaultOption}</option>
            )}
            {_.map(props.emails, email => <option key={email} value={email}>{email}</option>)}
        </select>
    </div>
);

EmailSelector.propTypes = propTypes;
EmailSelector.defaultProps = defaultProps;
EmailSelector.displayName = 'EmailSelector';

export default withOnyx({
    emails: {
        key: ONYXKEYS.EMAILS,
    },
})(EmailSelector);
