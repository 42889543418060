import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {propTypesTotalChatsReceived} from './propTypesPair';

const propTypes = {
    /** The count of the person's totalChats in the past 6 months */
    totalChats: propTypesTotalChatsReceived,

    /** Classes to be added to the title */
    titleClasses: PropTypes.string,

    /** Classes to be added to the value */
    valueClasses: PropTypes.string,

    /** The keys that need to be bold */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** The keys to show */
    keysToShow: PropTypes.arrayOf(PropTypes.string),

    /** The total label to display */
    totalLabel: PropTypes.string,
};

const defaultProps = {
    totalChats: {},
    titleClasses: '',
    valueClasses: '',
    winningKeys: [],
    keysToShow: [],
    totalLabel: '',
};

const KPIData = ({
    totalChats, titleClasses, valueClasses, winningKeys, keysToShow, totalLabel,
}) => (
    <>
        {_.contains(keysToShow, 'firstResponder') && (
            <div className="row mb-1">
                <div className={titleClasses}>First Responder</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'firstResponder') ? 'font-weight-bold' : ''}`}>{totalChats.firstResponder || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'firstResponderPlus') && (
            <div className="row mb-1">
                <div className={titleClasses}>First Responder Plus</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'firstResponderPlus') ? 'font-weight-bold' : ''}`}>{totalChats.firstResponderPlus || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'triage') && (
            <div className="row mb-1">
                <div className={titleClasses}>Triage</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'triage') ? 'font-weight-bold' : ''}`}>{totalChats.triage || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'supportal') && (
            <div className="row mb-1">
                <div className={titleClasses}>Coach</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'supportal') ? 'font-weight-bold' : ''}`}>{totalChats.supportal || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'launch') && (
            <div className="row mb-1">
                <div className={titleClasses}>Launch</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'launch') ? 'font-weight-bold' : ''}`}>{totalChats.launch || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'operations') && (
            <div className="row mb-1">
                <div className={titleClasses}>Operations</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'operations') ? 'font-weight-bold' : ''}`}>{totalChats.operations || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'c4b') && (
            <div className="row mb-1">
                <div className={titleClasses}>C4B</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'c4b') ? 'font-weight-bold' : ''}`}>{totalChats.c4b || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'manifesto') && (
            <div className="row mb-1">
                <div className={titleClasses}>Manifesto</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'manifesto') ? 'font-weight-bold' : ''}`}>{totalChats.manifesto || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'retention') && (
            <div className="row mb-1">
                <div className={titleClasses}>Retention</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'retention') ? 'font-weight-bold' : ''}`}>{totalChats.retention || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'sfoBar') && (
            <div className="row mb-1">
                <div className={titleClasses}>SFO Bar</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'sfoBar') ? 'font-weight-bold' : ''}`}>{totalChats.sfoBar || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'pdxBar') && (
            <div className="row mb-1">
                <div className={titleClasses}>PDX Bar</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'pdxBar') ? 'font-weight-bold' : ''}`}>{totalChats.pdxBar || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'sfoLounge') && (
            <div className="row mb-1">
                <div className={titleClasses}>SFO Lounge</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'sfoLounge') ? 'font-weight-bold' : ''}`}>{totalChats.sfoLounge || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'pdxLounge') && (
            <div className="row mb-1">
                <div className={titleClasses}>PDX Lounge</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'pdxLounge') ? 'font-weight-bold' : ''}`}>{totalChats.pdxLounge || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'researchTeam') && (
            <div className="row mb-1">
                <div className={titleClasses}>Research</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'researchTeam') ? 'font-weight-bold' : ''}`}>{totalChats.researchTeam || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, '') && (
            <div className="row mb-1">
                <div className={titleClasses}>Tier Not Recorded</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, '') ? 'font-weight-bold' : ''}`}>{totalChats[''] || 0}</div>
            </div>
        )}
        {_.contains(keysToShow, 'total') && (
            <div className="row mb-1">
                {totalLabel === 'Concierge Chats Total' ? (
                    <>
                        <div className={`${titleClasses} border-top`}>{totalLabel}</div>
                        <div className={`${valueClasses} ${_.contains(winningKeys, 'total') ? 'font-weight-bold' : ''} border-top`}>{totalChats.total || 0}</div>
                    </>
                ) : (
                    <>
                        <div className={`${titleClasses}`}>{totalLabel}</div>
                        <div className={`${valueClasses} ${_.contains(winningKeys, 'total') ? 'font-weight-bold' : ''}`}>{totalChats.total || 0}</div>
                    </>
                )}
            </div>
        )}
    </>
);

KPIData.propTypes = propTypes;
KPIData.defaultProps = defaultProps;
KPIData.displayName = 'KPIData';

export default KPIData;
