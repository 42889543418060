import PropTypes from 'prop-types';

export default PropTypes.shape({
    /** The full name of the person */
    name: PropTypes.string.isRequired,

    /** Any notes that have been written about the person */
    note: PropTypes.string,

    /** Any private notes that have been written about the person */
    privateNote: PropTypes.string,

    /** The email address of the person */
    email: PropTypes.string.isRequired,

    /** A description of the work the person has been doing */
    description: PropTypes.string,

    /** The teams this person is a member of, not including teams they joined or left since the last comp review */
    teams: PropTypes.arrayOf(PropTypes.string),

    /** The teams this person left since the last comp review */
    oldTeams: PropTypes.arrayOf(PropTypes.string),

    /** The teams this person joined since the last comp review */
    newTeams: PropTypes.arrayOf(PropTypes.string),

    /** The slackID for this person */
    slackID: PropTypes.string.isRequired,

    /** Slack numbers for this person */
    slackNumbers: PropTypes.shape({
        /** Number of work slack channels this user participated in */
        channels: PropTypes.number,

        /** Number of work messages this user sent in slack */
        messages: PropTypes.number,
    }),
});
