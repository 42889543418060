import React from 'react';
import PropTypes from 'prop-types';
import Onyx, {withOnyx} from 'react-native-onyx';
import withWindowDimensions, {windowDimensionsPropTypes} from './withWindowDimensions';
import {toggleTheme} from '../libs/actions/App';
import ThemeSwitcherIcon from '../../images/theme-switcher-icon.svg';
import compose from '../libs/compose';
import ONYXKEYS from '../ONYXKEYS';

const propTypes = {
    /** The primary title */
    titleOne: PropTypes.string,

    /** The second title of the current page */
    titleTwo: PropTypes.string,

    /** The third title of the current page */
    titleThree: PropTypes.string,

    /** Subtitle to appear alongside/below main title, if any */
    subtitle: PropTypes.string,

    /** Bool to indicate if showing switch theme icon is needed */
    shouldShowChangeThemesIcon: PropTypes.bool,

    ...windowDimensionsPropTypes,
};
const defaultProps = {
    titleOne: 'Comp Review',
    titleTwo: null,
    titleThree: null,
    subtitle: '',
    shouldShowChangeThemesIcon: false,
};

const PageHeader = (props) => {
    const title = (
        <>
            <h1>
                {props.titleOne}
                {props.titleTwo && (
                    <>
                        <i className="expensicons expensicons-caret-right" />
                        <span className="sub-title">{props.titleTwo}</span>
                    </>
                )}
                {props.titleThree && (
                    <>
                        <i className="expensicons expensicons-caret-right" />
                        <span className="sub-title">{props.titleThree}</span>
                    </>
                )}
            </h1>
            {props.subtitle && (
                <div className="text-center text-secondary-color center-header">
                    {props.subtitle}
                </div>
            )}
        </>
    );
    return (
        <div className="page-header d-flex justify-content-between">
            {
                props.isSmallScreenWidth
                    ? (
                        <div className="d-flex flex-column align-items-start">
                            {title}
                        </div>
                    )
                    : title
            }
            <div className="sidepane-logo d-flex">
                {props.session.authToken && !props.isSmallScreenWidth && (
                    <button
                        type="button"
                        className="btn btn-danger mr-2"
                        onClick={Onyx.clear}
                    >
                        Log Out
                    </button>
                )}
                {props.shouldShowChangeThemesIcon && (
                    <div className="icon">
                        <ThemeSwitcherIcon height={28} width={28} onClick={toggleTheme} />
                    </div>
                )}
            </div>
        </div>
    );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;
PageHeader.displayName = 'PageHeader';

export default compose(
    withWindowDimensions,
    withOnyx({
        session: {
            key: ONYXKEYS.SESSION,
        },
    }),
)(PageHeader);
