import React from 'react';
import {withOnyx} from 'react-native-onyx';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import appPropTypes from '../../propTypesApp';
import ONYXKEYS from '../../ONYXKEYS';
import PropTypesPair, {chronosTopProjectProps, githubContributionProps} from './propTypesPair';
import IndividualSummary from './IndividualSummary';
import ComparisonTable from './comparison/ComparisonTable';
import NotesSection from './NotesSection';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../components/withWindowDimensions';
import compose from '../../libs/compose';
import Rankings from './Rankings';
import {getUniqueOrHigherTeams} from '../../libs/growthAndRecognition';

const propTypes = {
    /** Data about the state of the entire application */
    app: appPropTypes,

    /** Data about the pair of people that need to be enacted upon */
    pair: PropTypesPair.isRequired,

    /** Function to execute when a person is selected */
    onSelectPerson: PropTypes.func,

    /** Contains the last six months' worth of top projects for each user (by email address) stored in Chronos. */
    chronosTopProjects: chronosTopProjectProps,

    /** Contains the Slack username for each user */
    slackUsername: PropTypes.objectOf(PropTypes.shape({
        /** The Slack username */
        username: PropTypes.string,

        /** Today's date */
        date: PropTypes.string,
    })),

    /** Contains the github contribution information for each user (by email address) that we've already looked up. */
    githubContributions: githubContributionProps,

    /** Should the select button be shown alongside the user name in the individual details section? */
    shouldShowSelectButton: PropTypes.bool,

    ...windowDimensionsPropTypes,
};

const defaultProps = {
    app: {},
    chronosTopProjects: {},
    slackUsername: {},
    githubContributions: {},
    shouldShowSelectButton: true,
    onSelectPerson: () => {},
};

class Pair extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowHeightsA: [],
            rowHeightsB: [],
            metricKeyLabelPair: [null, null],
        };

        this.selectMetric = this.selectMetric.bind(this);

        this.rankingsRef = React.createRef();
    }

    /**
     * Calculate the maximum row heights from both IndividualDetails views – A and B.
     * These become the minimum row heights for both IndividualDetails views, ensuring that they line up side-by-side.
     *
     * @returns {Array<Number>}
     */
    getMinRowHeights() {
        const minRowHeights = [];
        for (let i = 0; i < this.state.rowHeightsA.length; i++) {
            minRowHeights[i] = Math.max(this.state.rowHeightsA[i], this.state.rowHeightsB[i]);
        }
        return minRowHeights;
    }

    selectMetric(metricKeyLabelPair) {
        this.setState({metricKeyLabelPair});
        this.rankingsRef.current.scrollIntoView({behavior: 'smooth'});
    }

    render() {
        if (this.props.app.loading) {
            return (
                <div className="d-flex justify-content-center align-items-center h-50">
                    <div className="spinner large-spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        const allTeamsA = [...this.props.pair.A.teams, ...this.props.pair.A.newTeams];
        const allTeamsB = [...this.props.pair.B.teams, ...this.props.pair.B.newTeams];
        return (
            <>
                <div className="row text-center">
                    <div className="col-12">
                        <h3 className="mb-3 mb-md-5">Who do you think should be paid more?</h3>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-6">
                            <IndividualSummary
                                individual={this.props.pair.A}
                                slackUsername={lodashGet(this.props.slackUsername, [this.props.pair.A.email, 'username'], '')}
                                uniqueOrHigherTeams={getUniqueOrHigherTeams(allTeamsA, allTeamsB)}
                                githubContributions={lodashGet(this.props.githubContributions, [this.props.pair.A.email, 'contributions'])}
                                chronosTopProjects={lodashGet(this.props.chronosTopProjects, [this.props.pair.A.email])}
                                onSelect={() => this.props.onSelectPerson('A')}
                                shouldShowSelectButton={this.props.shouldShowSelectButton && !this.props.isSmallScreenWidth}
                                minRowHeights={this.props.isSmallScreenWidth ? undefined : this.getMinRowHeights()}
                                updateRowHeights={this.props.isSmallScreenWidth ? undefined : rowHeightsA => this.setState({rowHeightsA})}
                            />
                            {this.props.isSmallScreenWidth && <NotesSection personA={this.props.pair.A} />}
                        </div>
                        <div className="col col-md-6">
                            <IndividualSummary
                                individual={this.props.pair.B}
                                slackUsername={lodashGet(this.props.slackUsername, [this.props.pair.B.email, 'username'], '')}
                                uniqueOrHigherTeams={getUniqueOrHigherTeams(allTeamsB, allTeamsA)}
                                githubContributions={lodashGet(this.props.githubContributions, [this.props.pair.B.email, 'contributions'])}
                                chronosTopProjects={lodashGet(this.props.chronosTopProjects, [this.props.pair.B.email])}
                                onSelect={() => this.props.onSelectPerson('B')}
                                shouldShowSelectButton={this.props.shouldShowSelectButton && !this.props.isSmallScreenWidth}
                                minRowHeights={this.props.isSmallScreenWidth ? undefined : this.getMinRowHeights()}
                                updateRowHeights={this.props.isSmallScreenWidth ? undefined : rowHeightsB => this.setState({rowHeightsB})}
                            />
                            {this.props.isSmallScreenWidth && <NotesSection personA={this.props.pair.B} />}
                        </div>
                    </div>

                    <ComparisonTable pair={this.props.pair} selectMetric={this.selectMetric} />

                    <Rankings metricKeyLabelPair={this.state.metricKeyLabelPair} personA={this.props.pair.A} personB={this.props.pair.B} ref={this.rankingsRef} />

                    {!this.props.isSmallScreenWidth && (
                        <NotesSection
                            personA={this.props.pair.A}
                            personB={this.props.pair.B}
                        />
                    )}
                </div>
            </>
        );
    }
}

Pair.propTypes = propTypes;
Pair.defaultProps = defaultProps;

export default compose(
    withWindowDimensions,
    withOnyx({
        app: {key: ONYXKEYS.APP},
        slackUsername: {key: ONYXKEYS.SLACK_USERNAME},
        chronosTopProjects: {key: ONYXKEYS.CHRONOS_TOP_PROJECTS},
        githubContributions: {key: ONYXKEYS.GITHUB_CONTRIBUTIONS},
    }),
)(Pair);
