import React from 'react';
import lodashGet from 'lodash/get';
import _ from 'underscore';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../components/withWindowDimensions';
import {growthTracksTeams, growthTrackTeamUrls} from '../../libs/growthAndRecognition';
import teamUrls from '../../libs/regularTeamUrls';

const propTypes = {
    /** Team names for this person e.g. ['infra', 'keyholders', 'ring3'] */
    teams: PropTypes.arrayOf(PropTypes.string),

    /** Old team names for this person e.g. ['infra', 'keyholders', 'ring3'] */
    oldTeams: PropTypes.arrayOf(PropTypes.string),

    /** New team names for this person e.g. ['infra', 'keyholders', 'ring3'] */
    newTeams: PropTypes.arrayOf(PropTypes.string),

    /** teams that are not in both profiles considering G/R teams hierarchy */
    uniqueOrHigherTeams: PropTypes.arrayOf(PropTypes.string),

    ...windowDimensionsPropTypes,
};

const defaultProps = {
    teams: [],
    oldTeams: [],
    newTeams: [],
    uniqueOrHigherTeams: [],
};

const Teams = (props) => {
    // Growth teams
    const growthTeams = _.filter(props.teams, team => team.charAt(0) === '#');
    const growthNewTeams = _.filter(props.newTeams, team => team.charAt(0) === '#');
    const growthOldTeams = _.filter(props.oldTeams, team => team.charAt(0) === '#');
    const allGrowthTeams = growthTeams
        .concat(growthNewTeams, growthOldTeams)
        .sort((team1, team2) => growthTracksTeams.indexOf(team1) - growthTracksTeams.indexOf(team2));

    // Regular teams
    const regularTeams = _.filter(props.teams, team => team.charAt(0) !== '#');
    const regularNewTeams = _.filter(props.newTeams, team => team.charAt(0) !== '#');
    const regularOldTeams = _.filter(props.oldTeams, team => team.charAt(0) !== '#');
    const allRegularTeams = regularTeams.concat(regularNewTeams, regularOldTeams).sort();

    return (
        <div>
            <div className="text-secondary-color">G&R</div>
            <p className={props.isSmallScreenWidth ? 'mb-1' : ''}>
                {
                    _.map(allGrowthTeams, team => (
                        <a
                            key={team}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cn({
                                tag: true,
                                unique: _.contains(props.uniqueOrHigherTeams, team),
                                new: _.contains(growthNewTeams, team),
                                old: _.contains(growthOldTeams, team),
                            })}
                            href={lodashGet(growthTrackTeamUrls, team, '')}
                        >
                            {
                                _.contains(growthOldTeams, team)
                                    ? <strike>{team}</strike>
                                    : team
                            }
                        </a>
                    ))
                }
            </p>

            <div className="text-secondary-color">Teams</div>
            <p>
                {
                    _.map(allRegularTeams, team => (
                        <a
                            key={team}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cn({
                                tag: true,
                                unique: _.contains(props.uniqueOrHigherTeams, team),
                                new: _.contains(regularNewTeams, team),
                                old: _.contains(regularOldTeams, team),
                            })}
                            href={lodashGet(teamUrls, team, `https://stackoverflow.com/c/expensify/search?q=%5Bteam-${encodeURIComponent(team)}%5D+%5Bchore%5D+is%3Aquestion&searchOn=1`)}
                        >
                            {
                                _.contains(regularOldTeams, team)
                                    ? <strike>{team}</strike>
                                    : team
                            }
                        </a>
                    ))
                }
            </p>
        </div>
    );
};

Teams.propTypes = propTypes;
Teams.defaultProps = defaultProps;
Teams.displayName = 'Teams';

export default withWindowDimensions(Teams);
