import Onyx from 'react-native-onyx';
import * as API_Comp from '../APIComp';
import * as Pair from './Pair';
import ONYXKEYS from '../../ONYXKEYS';

/**
 * @param {String} email
 */
function getProfile(email) {
    Onyx.merge(ONYXKEYS.PROFILE, {
        loading: true,
    });
    API_Comp.Profile_Get({email: email ?? ''})
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.merge(ONYXKEYS.PROFILE, {
                    [email]: response.data,
                });
                Pair.getAllMetricDataForEmail(response.data.email);
                return;
            }
            if (response.jsonCode === 500) {
                Onyx.merge(ONYXKEYS.PROFILE, {
                    [email]: {
                        error: response.data.error,
                    },
                });
            }
        })
        .finally(() => Onyx.merge(ONYXKEYS.PROFILE, {
            [email]: {
                loading: false,
            },
        }));
}

export default getProfile;
