import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import CONST from '../CONST';

const propTypes = {
    /** The ID of the form field */
    id: PropTypes.string.isRequired,

    /** A callback function that is triggered when the value changes */
    onChange: PropTypes.func.isRequired,

    /** The value to set for this controlled component */
    value: PropTypes.string.isRequired,

    /** Whether or not this field is required for the form to be submitted */
    required: PropTypes.bool,
};
const defaultProps = {
    required: false,
};

const CurrencySelector = ({
    id, onChange, value, required,
}) => (
    <select
        id={id}
        className="form-control"
        onChange={onChange}
        value={value}
        required={required}
    >
        {_.map(CONST.CURRENCIES, CURRENCY => <option value={CURRENCY} key={CURRENCY}>{CURRENCY}</option>)}
    </select>
);

CurrencySelector.propTypes = propTypes;
CurrencySelector.defaultProps = defaultProps;
CurrencySelector.displayName = 'CurrencySelector';

export default CurrencySelector;
