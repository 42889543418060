import _ from 'underscore';
import lodashGet from 'lodash/get';
import React, {Component} from 'react';
import PropTypesIndividual from './propTypesIndividual';
import {saveNote, savePrivateNote} from '../../libs/actions/Pair';
import PrivateNote from './PrivateNote';
import FeedbackNote from './FeedbackNote';

const propTypes = {
    /** Data for person A */
    personA: PropTypesIndividual.isRequired,

    /** Data for person B */
    personB: PropTypesIndividual,
};

const defaultProps = {
    personB: null,
};

class NotesSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noteA: lodashGet(props.personA, 'note'),
            noteB: lodashGet(props.personB, 'note'),
            privateNoteA: lodashGet(props.personA, 'privateNote'),
            privateNoteB: lodashGet(props.personB, 'privateNote'),
        };

        this.updateFeedbackNote = this.updateFeedbackNote.bind(this);
        this.updatePrivateNote = this.updatePrivateNote.bind(this);
        this.persistPrivateNoteA = _.debounce(privateNote => savePrivateNote(props.personA.email, privateNote, 'A'), 300);
        this.persistPrivateNoteB = _.debounce(privateNote => savePrivateNote(props.personB.email, privateNote, 'B'), 300);
        this.persistNoteA = _.debounce(note => saveNote(props.personA.email, note, 'A'), 300);
        this.persistNoteB = _.debounce(note => saveNote(props.personB.email, note, 'B'), 300);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {};

        if (nextProps.personA.email !== prevState.prevPersonAEmail) {
            newState.noteA = lodashGet(nextProps.personA, 'note');
            newState.privateNoteA = lodashGet(nextProps.personA, 'privateNote');
            newState.prevPersonAEmail = nextProps.personA.email;
        }

        if (lodashGet(nextProps.personB, 'email') !== prevState.prevPersonBEmail) {
            newState.noteB = lodashGet(nextProps.personB, 'note');
            newState.privateNoteB = lodashGet(nextProps.personB, 'privateNote');
            newState.prevPersonBEmail = lodashGet(nextProps.personB, 'email');
        }

        return Object.keys(newState).length ? newState : null;
    }

    /**
     * Update the note for one of the pairs
     *
     * @param {String} person
     * @param {String} note
     */
    updateFeedbackNote(person, note) {
        if (person === 'A') {
            this.setState({noteA: note});
            this.persistNoteA(note);
        } else {
            this.setState({noteB: note});
            this.persistNoteB(note);
        }
    }

    /**
     * Update the private note for one of the pairs
     *
     * @param {String} person
     * @param {String} note
     */
    updatePrivateNote(person, note) {
        if (person === 'A') {
            this.setState({privateNoteA: note});
            this.persistPrivateNoteA(note);
        } else {
            this.setState({privateNoteB: note});
            this.persistPrivateNoteB(note);
        }
    }

    render() {
        const noteA = (
            <FeedbackNote
                feedbackNote={this.state.noteA || this.props.personA.note}
                name={this.props.personA.name}
                onFeedbackNoteChange={note => this.updateFeedbackNote('A', note)}
            />
        );
        const privateNoteA = (
            <PrivateNote
                privateNote={this.state.privateNoteA || this.props.personA.privateNote}
                name={this.props.personA.name}
                onPrivateNoteChange={note => this.updatePrivateNote('A', note)}
            />
        );

        if (!this.props.personB) {
            return (
                <>
                    <div className="row my-2">
                        <div className="col">
                            {privateNoteA}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            {noteA}
                        </div>
                    </div>
                </>
            );
        }

        const noteB = (
            <FeedbackNote
                feedbackNote={this.state.noteB || this.props.personB.note}
                name={this.props.personB.name}
                onFeedbackNoteChange={note => this.updateFeedbackNote('B', note)}
            />
        );
        const privateNoteB = (
            <PrivateNote
                privateNote={this.state.privateNoteB || this.props.personB.privateNote}
                name={this.props.personB.name}
                onPrivateNoteChange={note => this.updatePrivateNote('B', note)}
            />
        );
        return (
            <>
                <div className="row mt-4">
                    <div className="col col-md-6">
                        {privateNoteA}
                    </div>
                    <div className="col col-md-6">
                        {privateNoteB}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col col-md-6">
                        {noteA}
                    </div>
                    <div className="col col-md-6">
                        {noteB}
                    </div>
                </div>
            </>
        );
    }
}

NotesSection.propTypes = propTypes;
NotesSection.defaultProps = defaultProps;

export default NotesSection;
