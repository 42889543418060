import React from 'react';
import {Switch, Route} from 'react-router-dom';

import ROUTES from '../ROUTES';
import InstructionsPage from './InstructionsPage';
import ProfilePage from './ProfilePage';
import PairPage from './pair/PairPage';
import AutoMatchesPage from './automatches/AutoMatchesPage';
import MatchesPage from './MatchesPage';
import DonePage from './done/DonePage';
import CompensationEditPage from './compensation/CompensationEditPage';
import CompensationNewPage from './compensation/CompensationNewPage';
import CompensationOverview from './compensation/CompensationOverviewPage';
import CompensationUploadPage from './compensation/CompensationConfirmUploadPage';
import ManageMatchesOverviewPage from './manageMatches/ManageMatchesOverviewPage';
import ErrorBanner from './ErrorBanner';

export default () => (
    <>
        <ErrorBanner />
        <Switch>
            <Route path={[ROUTES.HOME, ROUTES.INSTRUCTIONS]} component={InstructionsPage} />
            <Route path={ROUTES.PROFILE} component={ProfilePage} />
            <Route path={ROUTES.MANAGEMATCHES_OVERVIEW} component={ManageMatchesOverviewPage} exact />
            <Route path={ROUTES.COMPENSATION_OVERVIEW} component={CompensationOverview} exact />
            <Route path={ROUTES.COMPENSATION_CONFIRM_UPLOAD} component={CompensationUploadPage} exact />
            <Route path={ROUTES.COMPENSATION_NEW} component={CompensationNewPage} exact />
            <Route path={ROUTES.COMPENSATION_EDIT} component={CompensationEditPage} exact />
            <Route path={ROUTES.COMPENSATION_EDIT_EMAIL} component={CompensationEditPage} exact />
            <Route path={ROUTES.PAIR} component={PairPage} />
            <Route path={ROUTES.AUTOMATCHES} component={AutoMatchesPage} />
            <Route path={ROUTES.MATCHES} component={MatchesPage} />
            <Route path={ROUTES.DONE} component={DonePage} />
        </Switch>
    </>
);
