import React, {useState, useEffect} from 'react';
import {withOnyx} from 'react-native-onyx';
import _ from 'underscore';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import ManageMatchesDetailedProgressRow from './ManageMatchesDetailedProgressRow';
import {getManageMatchesOverviewDetails} from '../../libs/actions/ManageMatches';
import Spinner from '../../components/Spinner';

const propTypes = {
    ...overviewPropTypes,
};
const defaultProps = {
    ...overviewDefaultProps,
};

const ManageMatchesDetailedProgress = (props) => {
    const [shouldShowDetails, setShouldShowDetails] = useState(false);

    // Get the overview details when this is mounted
    useEffect(getManageMatchesOverviewDetails, []);

    const progress = props.overview.totalMatches
        ? Math.round((props.overview.completedMatches / props.overview.totalMatches) * 10000) / 100
        : 0;

    const sortedMentorDetails = _.chain(props.overview.dataByEmail)
        .filter(row => row.isMentor)
        .sortBy('completedMatches')
        .value();
    const sortedNonMentorDetails = _.chain(props.overview.dataByEmail)
        .filter(row => !row.isMentor)
        .sortBy('completedMatches')
        .value();
    return (
        <>
            <p className="lead">
                {`Progress: ${progress}% of ${new Intl.NumberFormat('en-US').format(props.overview.totalMatches)} matches`}
            </p>
            <div className="progress mb-4">
                <div
                    className="progress-bar bg-success"
                    aria-label={`${progress}%`}
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: `${progress}%`}}
                />
            </div>
            <p>
                {!shouldShowDetails && (
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                            setShouldShowDetails(true);

                            // Fetch the details when we want to see them
                            getManageMatchesOverviewDetails();
                        }}
                    >
                        Show details
                    </button>
                )}
                {shouldShowDetails && (
                    <button className="btn btn-success" type="button" onClick={() => setShouldShowDetails(false)}>
                        Hide details
                    </button>
                )}
            </p>
            {shouldShowDetails && (
                <div className="card card-body">
                    <Spinner shouldShow={props.overview.isLoadingDetails} />
                    <h2>Mentors</h2>
                    {_.map(sortedMentorDetails.reverse(), details => (
                        <ManageMatchesDetailedProgressRow
                            key={details.email}
                            email={details.email}
                            totalMatches={details.totalMatches}
                            completedMatches={details.completedMatches}
                        />
                    ))}
                    <h2>Non-Mentors</h2>
                    {_.map(sortedNonMentorDetails.reverse(), details => (
                        <ManageMatchesDetailedProgressRow
                            key={details.email}
                            email={details.email}
                            totalMatches={details.totalMatches}
                            completedMatches={details.completedMatches}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

ManageMatchesDetailedProgress.propTypes = propTypes;
ManageMatchesDetailedProgress.defaultProps = defaultProps;

export default compose(
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
    }),
)(ManageMatchesDetailedProgress);
