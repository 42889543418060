import React, {useState} from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import {dismissGenerateAllError, dismissGenerateAllSuccess, generateAllMatches} from '../../libs/actions/ManageMatches';
import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';

const propTypes = {
    ...overviewPropTypes,

    /** Data about generating all the matches */
    generateAll: PropTypes.shape({
        /** If the API call is currently happening */
        loading: PropTypes.bool,

        /** A success message from the API */
        success: PropTypes.string,

        /** An error message from the API */
        error: PropTypes.string,

        /** An array of emails of the people participating in comp review */
        participants: PropTypes.arrayOf(PropTypes.string),

        /** How many participants have had matches generated for them */
        participantsCompleted: PropTypes.number,
    }),
};
const defaultProps = {
    ...overviewDefaultProps,
    generateAll: {
        loading: false,
        success: null,
        error: null,
        participants: [],
        participantsCompleted: 0,
    },
};

const ManageMatchesStartComp = (props) => {
    const [shouldShowConfirm, showConfirm] = useState(false);

    return (
        <div className="card mt-4">
            <div className="card-header">
                Step 6: Generate matches
            </div>
            <div className="card-body">
                {shouldShowConfirm ? (
                    <div className="my-4">
                        <p>
                            <strong>Are you sure you want to start the comp review process by generating a full set of matches?</strong>
                        </p>

                        <button
                            type="button"
                            className="btn btn-success mr-4"
                            onClick={() => {
                                generateAllMatches();
                                showConfirm(false);
                            }}
                        >
                            Yes, let&apos;s begin!
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => showConfirm(false)}
                        >
                            No, cancel
                        </button>
                    </div>
                ) : (
                    <>
                        Do this
                        <strong> two days </strong>
                        before the panel begins doing comp review so that everything can be tested.
                        <br />
                        <br />
                        <button
                            type="button"
                            className="btn btn-primary mr-4"
                            disabled={props.generateAll.loading || props.overview.totalMatches !== 0}
                            onClick={() => showConfirm(true)}
                        >
                            Generate matches
                        </button>
                        <Spinner
                            shouldShow={props.generateAll.loading}
                            // eslint-disable-next-line max-len
                            optionalText={`This might take a while. Don't refresh the page. Finished ${props.generateAll.participantsCompleted} out of ${props.generateAll.participants.length + 1} employees`}
                        />
                    </>
                )}

                <Alert
                    shouldShow={Boolean(props.generateAll.error)}
                    onDismiss={dismissGenerateAllError}
                    type="danger"
                >
                    <strong>Error:</strong>
                    {` ${props.generateAll.error}`}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.generateAll.success)}
                    onDismiss={dismissGenerateAllSuccess}
                >
                    {props.generateAll.success}
                </Alert>
            </div>
        </div>
    );
};

ManageMatchesStartComp.propTypes = propTypes;
ManageMatchesStartComp.defaultProps = defaultProps;

export default compose(
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
        generateAll: {
            key: ONYXKEYS.MANAGEMATCHES.GENERATE_ALL,
        },
    }),
)(ManageMatchesStartComp);
