import React, {useState} from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The start date of the previous comp period (when people started voting) */
    previousPeriodStartDate: PropTypes.string,

    /** The start date of the current comp period (when people will start to vote) */
    currentPeriodStartDate: PropTypes.string,
};
const defaultProps = {
    previousPeriodStartDate: '',
    currentPeriodStartDate: '',
};
const ManageMatchesCollectMetrics = ({previousPeriodStartDate, currentPeriodStartDate}) => {
    const [shouldShowInstructions, setShouldShowInstructions] = useState(false);
    return (
        <div className="card">
            <div className="card-header">
                Step 2: Collect all the metrics for the comp tool
            </div>
            <div className="card-body">
                This should be done three times:
                <ol>
                    <li>
                        <strong>Three weeks </strong>
                        before comp review begins so the data can be checked for errors.
                    </li>
                    <li>
                        <strong>Two weeks </strong>
                        before comp review begins so the data is up-to-date on profiles.
                    </li>
                    <li>
                        <strong>One day </strong>
                        before comp review begins so that the data is up-to-date.
                    </li>
                </ol>
                {shouldShowInstructions ? (
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => setShouldShowInstructions(false)}
                            type="button"
                        >
                            Hide instructions
                        </button>
                        <ol className="mt-4">
                            <li>
                                Create the following
                                {' '}
                                <a
                                    href="https://github.com/Expensify/Web-Expensify/blob/main/script/bwm/GatherConciergeKPI.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    bedrock job
                                </a>
                                {' '}
                                to gather the Concierge KPI data:
                                <br />
                                <code>
                                    <pre>
                                        {`
CreateJob
name: www-prod/GatherConciergeKPI
data: {"startDate": "${previousPeriodStartDate || 'unknown'}","endDate": "${currentPeriodStartDate || 'unknown'}"}
                                        `}
                                    </pre>
                                </code>
                                where &quot;endDate&quot; is the first day of comp review (when it starts), and &quot;startDate&quot; is the first day of the previous comp review
                            </li>
                            <li className="mt-4">
                                Populate Figma and Hours Worked data by following the instructions
                                {' '}
                                <a
                                    href="https://stackoverflowteams.com/c/expensify/questions/13578"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                                {' '}
                                and
                                {' '}
                                <a
                                    href="https://stackoverflowteams.com/c/expensify/questions/17016/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    here
                                </a>
                                {' '}
                                respectively.
                            </li>
                            <li className="mt-4">
                                Fetch all the metric data and store it into
                                <code> src/METRICDATA.js </code>
                                by SSHing into prod and running
                                <code> sudo sudo -u www-data php -d display_errors=1 scripts/getMetrics.php </code>
                                and merging/deploying a PR with this file. You can let the script run in the background while you do the rest of the steps, it should take about an hour.
                            </li>
                            <li className="mt-4">
                                Follow the Slack allocations process (
                                <a
                                    href="https://github.com/Expensify/Expensify/issues/254762"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    example
                                </a>
                                ) to collect Slack data starting from the first day of the previous comp period and ending on the first day of this comp period. Then update the
                                {' '}
                                <code>SLACK_NUMBERS</code>
                                {' '}
                                in
                                {' '}
                                <code>lib/CompReviewStore.php</code>
                                {' '}
                                with the resulting data. Be sure to store the array values as numbers and not strings so they are sorted properly.
                            </li>
                            <li className="mt-4">
                                Add Ambassador Event Days - this should be done by the Senior Leader
                                {' '}
                                <a
                                    href="https://github.com/Expensify/Expensify/issues?q=is%3Aissue+is%3Aopen+%22Upload+Ambassador+Event+Days+to+Comp%22+in%3Atitle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    assigned to the chore
                                </a>
                            </li>
                        </ol>
                    </>
                ) : (
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => setShouldShowInstructions(true)}
                            type="button"
                        >
                            Show instructions
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

ManageMatchesCollectMetrics.propTypes = propTypes;
ManageMatchesCollectMetrics.defaultProps = defaultProps;

export default ManageMatchesCollectMetrics;
