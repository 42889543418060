import React from 'react';
import PropTypes from 'prop-types';
import withCompensationAccessPermission from '../../components/withCompensationAccessPermission';
import {redirect} from '../../libs/actions/App';
import ROUTES from '../../ROUTES';
import CompensationNavBar from '../../components/CompensationNavBar';

const propTypes = {
    /** Comes from React Router */
    /* eslint-disable-next-line react/forbid-prop-types */
    match: PropTypes.any.isRequired,
};

class CompensationNewPage extends React.Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        this.state = {
            email: '',
            error: '',
        };
    }

    submit(e) {
        e.preventDefault();

        this.setState({error: ''}, () => {
            if (!this.state.email) {
                return this.setState({error: 'You must enter an email address'});
            }

            if (this.state.email.indexOf('@expensify.com') === -1) {
                return this.setState({error: 'You must enter a full expensify.com email address'});
            }

            redirect(ROUTES.getCompensationEditEmailRoute(this.state.email.trim()));
        });
    }

    render() {
        return (
            <>
                <CompensationNavBar email={this.props.match.params.email} />

                <div className="mt-4">
                    <h3>New Employee Email:</h3>
                    <form onSubmit={this.submit}>
                        <div className="row">
                            <div className="col-3 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="test@expensify.com"
                                    value={this.state.email}
                                    onChange={e => this.setState({email: e.target.value})}
                                />
                            </div>
                        </div>

                        {this.state.error !== '' && (
                            <div className="alert alert-danger mt-2">
                                {this.state.error}
                            </div>
                        )}

                        <div className="row">
                            <div className="col-3">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                >
                                    Add New Employee
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}


CompensationNewPage.displayName = 'CompensationNewPage';
CompensationNewPage.propTypes = propTypes;

export default withCompensationAccessPermission(CompensationNewPage);
