import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {redirect} from '../../libs/actions/App';
import ROUTES from '../../ROUTES';

const propTypes = {
    /** The email address of the person */
    email: PropTypes.string.isRequired,

    /** The person's name */
    name: PropTypes.string.isRequired,

    /** Any notes that have been written for the user */
    note: PropTypes.string,

    /** The win percentage of this user */
    winPercent: PropTypes.number,

    /** A callback function to trigger when we want to save our notes */
    onSaveNote: PropTypes.func.isRequired,

    /** A flag for if the UI is disabled or not */
    disabled: PropTypes.bool,
};

const defaultProps = {
    note: '',
    winPercent: 0,
    disabled: false,
};

class DoneRow extends React.Component {
    constructor(props) {
        super(props);

        this.startEditingNote = this.startEditingNote.bind(this);
        this.saveNote = this.saveNote.bind(this);
        this.updateNote = this.updateNote.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);

        this.state = {
            isEditingNote: false,
            note: this.props.note,
        };
    }

    /**
     * Show the form for editing the note
     */
    startEditingNote() {
        this.setState({
            isEditingNote: true,
        });
    }

    /**
     * Save the note to the API
     */
    saveNote() {
        this.props.onSaveNote(this.state.note);

        this.setState({
            isEditingNote: false,
            note: '',
        });
    }

    /**
     * Update the value of the note in our state
     *
     * @param {SyntheticEvent} e
     */
    updateNote(e) {
        this.setState({
            note: e.target.value,
        });
    }

    /**
     * Hide the form for editing the note
     */
    cancelEditing() {
        this.setState({
            isEditingNote: false,
        });
    }

    render() {
        return (
            <div className="row" key={this.props.email}>
                <div className="col-sm-2 py-2">
                    <Link to={ROUTES.getProfileRoute(this.props.email)} target="_blank">
                        <strong>{this.props.name}</strong>
                    </Link>
                    <span className="badge badge-secondary ml-2">
                        {Math.round(this.props.winPercent * 100) / 100}
                        %
                    </span>
                </div>

                <div className="col-sm-10 py-2">
                    <button
                        onClick={() => redirect(ROUTES.getMatchesRoute(this.props.email))}
                        className="btn btn-dark btn-sm mr-2"
                        type="button"
                    >
                        Review Matches
                    </button>

                    {!this.state.isEditingNote && (
                        <button
                            className="btn btn-dark btn-sm mr-2"
                            onClick={this.startEditingNote}
                            disabled={this.props.disabled}
                            type="button"
                        >
                            Edit Feedback
                        </button>
                    )}

                    {this.props.note && !this.state.isEditingNote && (
                        <div className="p-2">
                            <em>{this.props.note}</em>
                        </div>
                    )}

                    {this.state.isEditingNote && (
                        <div className="p-2">
                            <textarea
                                className="form-control my-2"
                                rows="4"
                                value={this.state.note || this.props.note}
                                onChange={this.updateNote}
                                disabled={this.props.disabled}
                                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                                autoFocus
                            />
                            <button
                                disabled={this.props.disabled}
                                className="btn btn-primary btn-sm"
                                onClick={this.saveNote}
                                type="button"
                            >
                                Save Feedback
                            </button>
                            <button
                                disabled={this.props.disabled}
                                className="btn btn-dark btn-sm"
                                onClick={this.cancelEditing}
                                type="button"
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

DoneRow.propTypes = propTypes;
DoneRow.defaultProps = defaultProps;

export default DoneRow;
