import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';

import PageHeader from '../../components/PageHeader';
import AutoMatchesGroup from './AutoMatchesGroup';
import KeyUp from '../../components/KeyUp';
import appPropTypes from '../../propTypesApp';
import PropTypesPair from '../pair/propTypesPair';
import ONYXKEYS from '../../ONYXKEYS';
import {getAutoMatches, switchPair, goToPreviousPair} from '../../libs/actions/Pair';
import {redirect} from '../../libs/actions/App';
import ROUTES from '../../ROUTES';

const propTypes = {
    /** Data about the state of the entire application */
    app: appPropTypes,

    /** The data about all the matches that were auto-completed as a result of the previous pair */
    autoMatches: PropTypes.shape({
        /** The full name of the person that was previously chosen as the lower person */
        lowerName: PropTypes.string,

        /** The full name of the person that was previously chosen as the higher person */
        higherName: PropTypes.string,

        /** The data about the matches specifically */
        matches: PropTypes.shape({
            /** All the matches that were lost by the higher email */
            matchesLostByHigherEmail: PropTypes.arrayOf(PropTypes.object).isRequired,

            /** All the matches that were won by the lower email */
            matchesWonByLowerEmail: PropTypes.arrayOf(PropTypes.object).isRequired,
        }),
    }),

    /** Data about the previous pair of people that were recently ranked */
    previousPair: PropTypesPair,

    /** Comes from React Router */
    /* eslint-disable-next-line */
    match: PropTypes.any.isRequired,
};

const defaultProps = {
    app: {},
    autoMatches: {},
    previousPair: null,
};

class AutoMatchesPage extends React.Component {
    constructor(props) {
        super(props);

        this.processKeyPress = this.processKeyPress.bind(this);
    }

    componentDidMount() {
        getAutoMatches(this.props.match.params.higherEmail, this.props.match.params.lowerEmail);
    }

    /**
     * When the enter key is pressed, take them back to reviewing matches
     *
     * @param {KeyboardEvent} e
     */
    processKeyPress(e) {
        // Only process keys done on the body so that the enter key can be used in the notes field with
        // no conflict
        if (e.target.nodeName === 'BODY' && e.key === 'Enter') {
            redirect(ROUTES.PAIR);
        }
    }

    /**
     * Switch the result of a single pair
     *
     * @param {String} emailA
     * @param {String} emailB
     * @param {String} higherEmail
     */
    switchPair(emailA, emailB, higherEmail) {
        switchPair(emailA, emailB, higherEmail);
    }

    render() {
        const {app, previousPair} = this.props;
        const {lowerName, higherName, matches} = this.props.autoMatches;
        if (this.props.app.loading || !matches) {
            return (
                <>
                    <PageHeader titleTwo="Auto Matches" />
                    <div className="text-center">Loading...</div>
                </>
            );
        }

        const {matchesLostByHigherEmail, matchesWonByLowerEmail} = matches;

        return (
            <>
                <PageHeader titleTwo="Auto Matches" />

                <KeyUp callback={this.processKeyPress} />

                <div className="container">
                    <p className="text-center">
                        These matches were auto completed. You can change any outcome below
                    </p>

                    {matchesLostByHigherEmail && (
                        <AutoMatchesGroup
                            title={lowerName}
                            autoMatches={matchesLostByHigherEmail}
                            onSwitchPair={this.switchPair}
                            disabled={this.props.app.isUIDisabled}
                        >
                            These are the auto-matches as a result of
                            {' '}
                            <strong>{lowerName}</strong>
                            {' '}
                            being lower than
                            {' '}
                            <strong>{higherName}</strong>
                        </AutoMatchesGroup>
                    )}

                    {matchesWonByLowerEmail && (
                        <AutoMatchesGroup
                            title={higherName}
                            autoMatches={matchesWonByLowerEmail}
                            onSwitchPair={this.switchPair}
                            disabled={this.props.app.isUIDisabled}
                        >
                            These are the auto-matches as a result of
                            {' '}
                            <strong>{higherName}</strong>
                            {' '}
                            being higher than
                            {' '}
                            <strong>{lowerName}</strong>
                        </AutoMatchesGroup>
                    )}


                    <p className="text-center mt-5">
                        Hint: use the enter key to continue:
                        {' '}
                        <code>[enter]</code>
                    </p>
                    <p className="text-center">
                        <button
                            className="btn btn-primary"
                            onClick={() => redirect(ROUTES.PAIR)}
                            disabled={app.isUIDisabled || previousPair.loading}
                            type="button"
                        >
                            Do More Matches
                        </button>
                    </p>
                    <p className="text-center">
                        <button
                            onClick={() => goToPreviousPair(
                                previousPair.A,
                                previousPair.B,
                                app.matchesPerformed - 1,
                            )}
                            disabled={previousPair.loading}
                            className="btn btn-warning"
                            type="button"
                        >
                                    Go to previous match (Will revert auto-matches except manually updated ones)
                        </button>
                    </p>
                </div>
            </>
        );
    }
}

AutoMatchesPage.propTypes = propTypes;
AutoMatchesPage.defaultProps = defaultProps;

export default withOnyx({
    app: {key: ONYXKEYS.APP},
    autoMatches: {key: ONYXKEYS.AUTO_MATCHES},
    previousPair: {key: ONYXKEYS.PREVIOUS_PAIR},
})(AutoMatchesPage);
