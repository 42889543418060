import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import ROUTES from '../ROUTES';
import PageHeader from './PageHeader';

const propTypes = {
    /** Email of the user whose compensation is being edited (optional) */
    email: PropTypes.string,
};

const defaultProps = {
    email: '',
};

const CompensationNavBar = ({email}) => (
    <>
        <Route path={ROUTES.COMPENSATION_OVERVIEW}>
            <PageHeader titleOne="Compensation" titleTwo="Overview" />
        </Route>
        <Route path={ROUTES.COMPENSATION_CONFIRM_UPLOAD}>
            <PageHeader titleOne="Compensation" titleTwo="Confirm Raises to Upload" />
        </Route>
        <Route path={ROUTES.COMPENSATION_NEW}>
            <PageHeader titleOne="Compensation" titleTwo="New" />
        </Route>
        <Route path={ROUTES.COMPENSATION_EDIT} exact>
            <PageHeader titleOne="Compensation" titleTwo="Edit" />
        </Route>
        <Route path={ROUTES.COMPENSATION_EDIT_EMAIL} exact>
            <PageHeader titleOne="Compensation" titleTwo="Edit" titleThree={email} />
        </Route>
        <>
            <NavLink to={ROUTES.COMPENSATION_OVERVIEW} className="btn btn-primary" activeClassName="nav-active">Compensation Overview</NavLink>
            <NavLink to={ROUTES.COMPENSATION_EDIT} className="btn btn-primary ml-2" activeClassName="nav-active">Edit Compensation</NavLink>
            <NavLink to={ROUTES.COMPENSATION_NEW} className="btn btn-primary ml-2" activeClassName="nav-active">Add new employee</NavLink>
        </>
    </>
);

CompensationNavBar.displayName = 'CompensationNavBar';
CompensationNavBar.propTypes = propTypes;
CompensationNavBar.defaultProps = defaultProps;

export default CompensationNavBar;
