import _ from 'underscore';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import getComponentDisplayName from '../libs/getComponentDisplayName';

// Taken from mobile breakpoint in Web-Expensify bootstrap plugin:
// https://github.com/Expensify/Web-Expensify/blob/4c925fa0f28188a3a517952f076ebd74ef93b56b/site/sass/_mixins.scss#L4
const MOBILE_RESPONSIVE_BREAKPOINT = 899;

const windowDimensionsPropTypes = {
    // Width of the window
    windowWidth: PropTypes.number.isRequired,

    // Height of the window
    windowHeight: PropTypes.number.isRequired,

    // Is the window width narrow, like on a mobile device?
    isSmallScreenWidth: PropTypes.bool.isRequired,
};

export default function (WrappedComponent) {
    const propTypes = {
        forwardedRef: PropTypes.func,
    };

    const defaultProps = {
        forwardedRef: () => {},
    };

    class WithWindowDimensions extends Component {
        constructor(props) {
            super(props);

            this.onDimensionChange = _.debounce(this.onDimensionChange.bind(this), 100);

            const isSmallScreenWidth = window.innerWidth <= MOBILE_RESPONSIVE_BREAKPOINT;

            this.dimensionsEventListener = null;

            this.state = {
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                isSmallScreenWidth,
            };
        }

        componentDidMount() {
            window.addEventListener('resize', this.onDimensionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.onDimensionChange);
            if (!this.dimensionsEventListener) {
                return;
            }
            this.dimensionsEventListener.remove();
        }

        onDimensionChange() {
            const isSmallScreenWidth = window.innerWidth <= MOBILE_RESPONSIVE_BREAKPOINT;
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                isSmallScreenWidth,
            });
        }

        render() {
            // eslint-disable-next-line react/destructuring-assignment
            const {forwardedRef, ...rest} = this.props;
            return (
                <WrappedComponent
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                    ref={forwardedRef}
                    windowHeight={this.state.windowHeight}
                    windowWidth={this.state.windowWidth}
                    isSmallScreenWidth={this.state.isSmallScreenWidth}
                />
            );
        }
    }

    WithWindowDimensions.propTypes = propTypes;
    WithWindowDimensions.defaultProps = defaultProps;
    WithWindowDimensions.displayName = `withWindowDimensions(${getComponentDisplayName(WrappedComponent)})`;
    return React.forwardRef((props, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WithWindowDimensions {...props} forwardedRef={ref} />
    ));
}

export {
    windowDimensionsPropTypes,
};
