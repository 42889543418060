import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {figmaContributionProps} from './propTypesPair';

const propTypes = {
    /** The Figma contributions nunber for the employee */
    contributions: figmaContributionProps,

    /** Classes to be added to the title */
    titleClasses: PropTypes.string,

    /** Classes to be added to the value */
    valueClasses: PropTypes.string,

    /** The keys that need to be bold */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** The keys to show */
    keysToShow: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    contributions: {},
    titleClasses: '',
    valueClasses: '',
    winningKeys: [],
    keysToShow: [],
};

const FigmaContributions = ({
    contributions, titleClasses, valueClasses, winningKeys, keysToShow,
}) => (
    <>
        {(_.contains(keysToShow, 'figmaContributions') && contributions.figmaContributions !== 0) && (
            <div className="row mb-1">
                <div className={titleClasses}>Figma Edits</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'figmaContributions') ? 'font-weight-bold' : ''}`}>
                    {contributions.figmaContributions || 0}
                </div>
            </div>
        )}
    </>
);

FigmaContributions.propTypes = propTypes;
FigmaContributions.defaultProps = defaultProps;
FigmaContributions.displayName = 'FigmaContributions';

export default FigmaContributions;
