import PropTypes from 'prop-types';

const compensationPropTypes = PropTypes.shape({
    /** The date the compensation record was created */
    created: PropTypes.string,

    /** The date the compensation went into effect */
    effectiveDate: PropTypes.string,

    /** The reason for the compensation record */
    reason: PropTypes.string,

    /** The amount of salary the employee is taking in cash */
    salary: PropTypes.number,

    /** The total salary */
    totalSalary: PropTypes.number,

    /** The amount of salary the employee is taking in equity */
    salaryExchangedForEquity: PropTypes.number,

    /** The total salary the employee is taking in equity */
    totalSalaryExchangedForEquity: PropTypes.number,

    /** The amount of compensation that the employee got from the 30% grant */
    grantCompensationAmount: PropTypes.number,

    /** The total amount of compensation that the employee got from the 30% grant */
    totalGrantCompensationAmount: PropTypes.number,

    /** The number of options the employee has */
    numberOfOptions: PropTypes.number,

    /** The total number of options */
    totalNumberOfOptions: PropTypes.number,

    /** The local currency of the employee */
    localCurrency: PropTypes.string,

    /** The exchange rate used to convert the local currency to USD */
    localCurrencyExchangeRate: PropTypes.number,
});

export default compensationPropTypes;
