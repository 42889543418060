import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    previousPeriodStartDate: PropTypes.string,
};
const defaultProps = {
    previousPeriodStartDate: '',
};
const ManageMatchesModifyWhitelist = ({previousPeriodStartDate}) => (
    <div className="card">
        <div className="card-header">
            Step 3: Update the
            <code> OLD_WHITELIST_EMPLOYEES</code>
        </div>
        <div className="card-body">
            <p>
                Do this
                <strong> three weeks </strong>
                before comp review begins so that the data can be checked for errors and people will see the correct information on their profile.
                {' '}
                Follow the instructions
                {' '}
                <a
                    href="https://stackoverflowteams.com/c/expensify/questions/16130/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
                .
            </p>
            <p>
                The date that the previous comp review period started is
                {' '}
                <code>
                    {previousPeriodStartDate || 'unknown'}
                </code>
                .
            </p>
        </div>
    </div>
);

ManageMatchesModifyWhitelist.propTypes = propTypes;
ManageMatchesModifyWhitelist.defaultProps = defaultProps;
ManageMatchesModifyWhitelist.displayName = 'ManageMatchesModifyWhitelist';

export default ManageMatchesModifyWhitelist;
