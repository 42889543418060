import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The email address of the person */
    email: PropTypes.string,

    /** The slackID of the person */
    slackID: PropTypes.string,

    /** The slack username of the person */
    slackUserName: PropTypes.string,

    /** The Github username of the person */
    githubUserName: PropTypes.string,
};

const defaultProps = {
    email: '',
    slackID: '',
    slackUserName: '',
    githubUserName: '',
};

/**
 * Copies the search query to the users clipboard and opens the Slack app. It is not possible
 * to deep link into a user search, so the user must perform the search manually.
 * @param {String} slackID
 */
const copyToClipboard = (slackID) => {
    const slackQueryText = `from:<@${slackID}>`;

    // Copy the search term to the users clipboard
    navigator.clipboard.writeText(slackQueryText).then(() => {
        // A channel must be provided for Slack deep links, so we default to #general for non-whatsnext queries
        window.open('https://slack.com/app_redirect?channel=general', '_blank');
    });
};

const UserAccounts = props => (
    <div className="mt-2">
        <a
            href={`https://mail.google.com/mail/u/0/#search/from:"${props.email}"`}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3 text-nowrap"
        >
            <em>{props.email}</em>
        </a>
        {' '}
        <span
            role="button"
            tabIndex={0}
            className="link-span mr-3 text-nowrap"
            onClick={() => copyToClipboard(props.slackID)}
        >
            Slack ID:
            <em>{` @${props.slackUserName}`}</em>
        </span>
        {' '}
        <a
                        /* eslint-disable-next-line max-len */
            href={`https://github.com/${props.githubUserName}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-nowrap"
        >
            GitHub:
            <em>{` @${props.githubUserName}`}</em>
        </a>
    </div>
);

UserAccounts.propTypes = propTypes;
UserAccounts.defaultProps = defaultProps;
UserAccounts.displayName = 'UserAccounts';

export default UserAccounts;
