import React from 'react';
import PropTypes from 'prop-types';
import UserAccounts from '../../components/UserAccounts';

const propTypes = {
    /** The description of the person */
    description: PropTypes.string,

    /** The email address of the person */
    email: PropTypes.string,

    /** The slackID of the person */
    slackID: PropTypes.string,

    /** The slack username of the person */
    slackUserName: PropTypes.string,

    /** The Github username of the person */
    githubUserName: PropTypes.string,
};

const defaultProps = {
    description: '',
    email: '',
    slackID: '',
    slackUserName: '',
    githubUserName: '',
};

const Description = props => (
    <>
        <div className="description">{props.description}</div>
        <UserAccounts
            email={props.email}
            slackID={props.slackID}
            slackUserName={props.slackUserName}
            githubUserName={props.githubUserName}
        />
    </>
);

Description.propTypes = propTypes;
Description.defaultProps = defaultProps;
Description.displayName = 'Description';

export default Description;
