import _ from 'underscore';
import METRICDATA from '../../METRICDATA';

const KEYS = {
    CONCIERGE: {
        CUSTOMER_CHATS: {
            FIRST_RESPONDER: 'firstResponder',
            FIRST_RESPONDER_PLUS: 'firstResponderPlus',
            TRIAGE: 'triage',
            SUPPORTAL: 'supportal',
            LAUNCH: 'launch',
            OPS: 'operations',
            C4B: 'c4b',
            MANIFESTO: 'manifesto',
            RETENTION: 'retention',
            SFO_BAR: 'sfoBar',
            PDX_BAR: 'pdxBar',
            SFO_LOUNGE: 'sfoLounge',
            PDX_LOUNGE: 'pdxLounge',
            RESEARCH_TEAM: 'researchTeam',
            TOTAL: 'total',
        },
        QA_CHATS: {
            TOTAL: 'total',
        },
    },
    GITHUB: {
        COMMITS: 'commits',
        PULL_REQUESTS: 'pullRequests',
        REVIEWS: 'reviews',
        OPENED_ISSUES: 'openedIssues',
        TOTAL: 'total',
        CLOSED_ISSUES: 'closedIssues',
        OPEN_SOURCE: 'openSource',
        COMMENTS: 'githubComments',
    },
    SLACK: {
        CHANNELS: 'channels',
        MESSAGES: 'messages',
    },
    STACK_OVERFLOW_REPUTATION: 'stackoverflowReputation',
    FIGMA_CONTRIBUTIONS: 'figmaContributions',
    CHRONOS_HOURS_WORKED: 'hoursWorked',
    AMBASSADOR_EVENT_DAYS: 'ambassadorEventDays',
};

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getTotalGithubContributions({email}) {
    return METRICDATA.GITHUB_NUMBERS[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getGithubComments({email}) {
    return METRICDATA.GITHUB_COMMENTS[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getFigmaContributions({email}) {
    return METRICDATA.FIGMA_NUMBERS[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getConciergeKPIs({email}) {
    return METRICDATA.CONCIERGE_KPIS[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getChronosTopProjects({email}) {
    return METRICDATA.TOP_PROJECTS[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getChronosHoursWorked({email}) {
    return METRICDATA.HOURS_WORKED[email] || {};
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Object}
 */
function getStackoverflowReputation({email}) {
    return METRICDATA.STACK_OVERFLOW_REPUTATION[email] || {};
}

/**
 * @param {Object} rankings
 * @returns {Object}
 */
function getSortedRankings(rankings) {
    const rankingsEntries = Object.entries(rankings);
    rankingsEntries.sort((a, b) => {
        // Sort by metric value first
        if (b[1] !== a[1]) {
            return b[1] - a[1];
        }

        // If the metric value is the same sort alphabetically
        return a[0].localeCompare(b[0]);
    });
    return rankingsEntries;
}

/**
 * @param {String} key
 * @return {Array}
 */
function getGithubRankings(key) {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.GITHUB_NUMBERS?.[email]?.[key] ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @param {String} key
 * @return {Array}
 */
function getGithubCommentRankings() {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.GITHUB_COMMENTS?.[email]?.githubComments ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @return {Array}
 */
function getFigmaRankings() {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.FIGMA_NUMBERS?.[email]?.figmaContributions ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @param {String} key
 * @return {Array}
 */
function getTotalCustomerChatsReceivedRankings(key) {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.CONCIERGE_KPIS?.[email]?.totalCustomerChatsReceived?.[key] ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @param {String} key
 * @return {Array}
 */
function getTotalQAChatsHandledRankings(key) {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.CONCIERGE_KPIS?.[email]?.totalQAChatsHandled?.[key] ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @returns {Array}
 */
function getChronosHoursWorkedRankings() {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.HOURS_WORKED?.[email]?.hoursWorked ?? 0;
    });
    return getSortedRankings(rankings);
}

/**
 * @returns {Array}
 */
function getStackOverflowReputationRankings() {
    const rankings = {};
    _.each(METRICDATA.WHITELIST_EMAILS, (email) => {
        rankings[email] = METRICDATA.STACK_OVERFLOW_REPUTATION?.[email]?.reputation ?? 1;
    });
    return getSortedRankings(rankings);
}

function isTotalCustomerChatsReceivedMetric(key, label) {
    return (key === KEYS.CONCIERGE.CUSTOMER_CHATS.TOTAL && label === 'Concierge Chats Total');
}

function isTotalQAChatsHandledMetric(key, label) {
    return (key === KEYS.CONCIERGE.QA_CHATS.TOTAL && label === 'Concierge QA Chats Handled');
}

function isConciergeQueueMetric(key) {
    return key !== KEYS.CONCIERGE.CUSTOMER_CHATS.TOTAL && Object.values(KEYS.CONCIERGE.CUSTOMER_CHATS).includes(key);
}

function isGithubMetric(key, label) {
    return (key === KEYS.GITHUB.TOTAL && label === 'Total GH Contributions') || (key !== KEYS.GITHUB.TOTAL && Object.values(KEYS.GITHUB).includes(key));
}

function getRankingsForKey(key, label = '') {
    if (isTotalCustomerChatsReceivedMetric(key, label) || isConciergeQueueMetric(key)) {
        return getTotalCustomerChatsReceivedRankings(key);
    }
    if (isTotalQAChatsHandledMetric(key, label)) {
        return getTotalQAChatsHandledRankings(key);
    }
    if (isGithubMetric(key, label)) {
        if (key === KEYS.GITHUB.COMMENTS) {
            return getGithubCommentRankings();
        }
        return getGithubRankings(key);
    }
    switch (key) {
        case KEYS.FIGMA_CONTRIBUTIONS:
            return getFigmaRankings();
        case KEYS.CHRONOS_HOURS_WORKED:
            return getChronosHoursWorkedRankings();
        case KEYS.STACK_OVERFLOW_REPUTATION:
            return getStackOverflowReputationRankings();
        default:
            return [];
    }
}

function isFromLocalMetricStore(key) {
    return key !== KEYS.AMBASSADOR_EVENT_DAYS && key !== KEYS.SLACK.MESSAGES && key !== KEYS.SLACK.CHANNELS;
}

export {
    KEYS,
    getTotalGithubContributions,
    getGithubComments,
    getFigmaContributions,
    getConciergeKPIs,
    getChronosTopProjects,
    getChronosHoursWorked,
    getStackoverflowReputation,
    isTotalCustomerChatsReceivedMetric,
    isTotalQAChatsHandledMetric,
    isConciergeQueueMetric,
    getRankingsForKey,
    isFromLocalMetricStore,
    getGithubCommentRankings,
};
