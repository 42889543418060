/**
 * This is a file containing constants for all the top level keys in our store
 */
export default {
    // Holds information about the users account that is logging in
    ACCOUNT: 'account',

    // All the results of the matches done so far
    ALL_MATCH_RESULTS: 'allMatchResults',

    // The last error that happened from an API request
    API_ERROR: 'apiError',

    // Holds information about the state of the app (like if it's in a loading state)
    APP: 'app',

    // When this key is changed, the active page changes (see Expensify.js and `redirect` in actions/App.js)
    APP_REDIRECT_TO: 'appRedirectTo',

    // Holds all of the matches that were autocompleted for a pair of people
    AUTO_MATCHES: 'autoMatches',

    MANAGEMATCHES: {
        // Information about who is being excluded from comp review
        EXCLUSIONS: 'manageMatchesExclusions',

        // An overview of all the matches happening in a comp review period
        OVERVIEW: 'manageMatchesOverview',

        // Information related to generating new matches
        GENERATE: 'manageMatchesGenerate',

        // Information related to generating a full set of matches
        GENERATE_ALL: 'manageMatchesGenerateAll',

        // Information related to the resetting of matches
        RESET: 'manageMatchesReset',

        // Information about removing people from comp
        REMOVE_FROM_COMP: 'manageMatchesRemoveFromComp',

        // Information related to setting the current comp period
        SETCURRENTPERIOD: 'manageMatchesSetCurrentPeriod',

        // Information related to setting the end date of the comp period
        SETPERIODENDDATE: 'manageMatchesSetPeriodEndDate',

        // Information related to setting the start date of the comp period
        SETPERIODSTARTDATE: 'manageMatchesSetPeriodStartDate',
    },

    // An overview of everyone's current compensation
    COMPENSATION_OVERVIEW: 'compensationOverview',

    // Holds data temporary that is uploaded from a CSV so the user can confirm it's the right data before uploading it
    COMPENSATION_TO_CONFIRM_FOR_UPLOAD: 'compensationToConfirmForUpload',

    // The current URL, you should not change this directly (use `redirect` in App.js)
    CURRENT_URL: 'currentURL',

    // Credentials to authenticate the user
    CREDENTIALS: 'credentials',

    // An array of all the email addresses of employees (past and present)
    EMAILS: 'emails',

    // Stores work descriptions per user
    WORK_DESCRIPTION: 'workDescription',

    // The results of the matches for a single person
    MATCH_RESULTS_FOR_EMAIL: 'matchResultsForEmail',

    // Has information about the network status (offline/online)
    NETWORK: 'network',

    // All the info about the pair in the visible match
    PAIR: 'pair',

    // Information about the permissions someone has in the app
    PERMISSIONS: 'permissions',

    // All the info about a previous pair that was just ranked
    PREVIOUS_PAIR: 'previousPair',

    // Stores profile data per user
    PROFILE: 'profile',

    // Information about the current session (authToken, accountID, email, loading, error)
    SESSION: 'session',

    // Collection Keys
    COLLECTION: {
        // Holds the compensation data for specific employees
        COMPENSATION: 'compensation_',
    },

    // Stores the count of github contributions per user.
    GITHUB_CONTRIBUTIONS: 'githubContributions',

    // Stores the top projects per user
    CHRONOS_TOP_PROJECTS: 'chronosTopProjects',

    // Stores the number of hours logged in Chronos per user
    CHRONOS_HOURS_WORKED: 'chronosHoursWorked',

    // Stores the total customer chats received per user
    CONCIERGE_TOTAL_CUSTOMER_CHATS_RECEIVED: 'conciergeTotalCustomerChatsReceived',

    // Stores the total QA chats handled per user
    CONCIERGE_TOTAL_QA_CHATS_HANDLED: 'conciergeTotalQAChatsHandled',

    // Stores the count of figma contributions per user.
    FIGMA_CONTRIBUTIONS: 'figmaContributions',

    // Stores the users Stackoverflow reputation
    STACKOVERFLOW_REPUTATION: 'stackoverflowReputation',

    // Stores the user's number of GitHub comments
    GITHUB_COMMENTS: 'githubComments',

    // Stores if dark theme is enabled in the UI
    APP_DARK_THEME_ENABLED: 'appDarkThemeEnabled',

    // Stores the Slack username per user
    SLACK_USERNAME: 'slackUsername',
};
