import React from 'react';
import $ from 'jquery';
import _ from 'underscore';
import PropTypes from 'prop-types';

const propTypes = {
    /** The callback to trigger when the event occurs */
    callback: PropTypes.func.isRequired,
};

class KeyUp extends React.Component {
    constructor(props) {
        super(props);
        this.id = _.uniqueId('keyup_');
    }

    componentDidMount() {
        $(document).on(`keyup.${this.id}`, this.props.callback);
    }

    componentWillUnmount() {
        $(document).off(`keyup.${this.id}`);
    }

    render() {
        return null;
    }
}

KeyUp.propTypes = propTypes;
KeyUp.displayName = 'KeyUp';

export default KeyUp;
