import React, {useState, useRef} from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import _ from 'underscore';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import {dismissGenerateError, dismissGenerateSuccess, generateMatches} from '../../libs/actions/ManageMatches';
import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';

const propTypes = {
    ...overviewPropTypes,

    generate: PropTypes.shape({
        loading: PropTypes.bool,
        success: PropTypes.string,
        error: PropTypes.string,
    }),
};
const defaultProps = {
    ...overviewDefaultProps,
    generate: {
        loading: false,
        success: null,
        error: null,
    },
};

const ManageMatchesAddToComp = (props) => {
    const [shouldShowConfirm, showConfirm] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const emailsRef = useRef(null);
    const submit = () => {
        const emails = emailsRef.current.value;

        if (!emails) {
            setValidationError('You must include at least one email');
            return;
        }

        const arrayOfEmails = _.map(emails.split(','), email => email.trim());

        // Make sure that if emails is provided, that it's a comma separate list of emails
        if (_.some(arrayOfEmails, email => email.indexOf('@') === -1)) {
            setValidationError('At least one of the entries appears to not be an email address');
            return;
        }

        generateMatches(arrayOfEmails);
        setValidationError(null);
        showConfirm(false);
    };

    return (
        <div>
            {shouldShowConfirm ? (
                <>
                    <form className="mb-4">
                        <div className="form-group">
                            <label htmlFor="emails">Specify a comma separated list of email addresses that you want to put into comp review:</label>
                            <textarea
                                id="emails"
                                ref={emailsRef}
                                className={`form-control ${validationError ? 'is-invalid' : ''}`}
                                aria-label="With textarea"
                            />
                            <div className="invalid-feedback">
                                {validationError}
                            </div>
                        </div>
                    </form>

                    <button
                        type="button"
                        className="btn btn-success mr-4"
                        onClick={submit}
                    >
                        Yes, add them
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => showConfirm(false)}
                    >
                        No, cancel
                    </button>
                </>
            ) : (
                <>
                    <button
                        type="button"
                        className="btn btn-primary mr-4"
                        onClick={() => showConfirm(true)}
                    >
                        Add people to comp review
                    </button>
                    <Spinner shouldShow={props.generate.loading} optionalText="This might take a while. Don't refresh the page." />
                </>
            )}

            <Alert
                shouldShow={Boolean(props.generate.error)}
                onDismiss={dismissGenerateError}
                type="danger"
            >
                <strong>Error:</strong>
                {` ${props.generate.error}`}
            </Alert>

            <Alert
                shouldShow={Boolean(props.generate.success)}
                onDismiss={dismissGenerateSuccess}
            >
                {props.generate.success}
            </Alert>
        </div>
    );
};

ManageMatchesAddToComp.propTypes = propTypes;
ManageMatchesAddToComp.defaultProps = defaultProps;

export default compose(
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
        generate: {
            key: ONYXKEYS.MANAGEMATCHES.GENERATE,
        },
    }),
)(ManageMatchesAddToComp);
