const regularTeamUrls = {
    concierge: 'https://stackoverflowteams.com/c/expensify/questions/14464',
    'investor-relations': 'https://stackoverflowteams.com/c/expensify/questions/17167/',
    'bank-audits': 'https://stackoverflowteams.com/c/expensify/questions/18026/',
    'finance-ring0': 'https://stackoverflowteams.com/c/expensify/questions/17161',
    'finance-ring1': 'https://stackoverflowteams.com/c/expensify/questions/17161',
    'finance-ring2': 'https://stackoverflowteams.com/c/expensify/questions/17161',
    'finance-ring3': 'https://stackoverflowteams.com/c/expensify/questions/17161',
    'finance-ring4': 'https://stackoverflowteams.com/c/expensify/questions/17161',
};

export default regularTeamUrls;
