import React from 'react';
import {withOnyx} from 'react-native-onyx';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import ONYXKEYS from '../ONYXKEYS';
import PageHeader from '../components/PageHeader';
import {redirect} from '../libs/actions/App';
import ROUTES from '../ROUTES';

const propTypes = {
    session: PropTypes.shape({
        email: PropTypes.string.isRequired,
    }).isRequired,
};

const InstructionsPage = props => (
    <>
        <PageHeader titleTwo="Instructions" />

        <h2>How do I use this tool?</h2>
        <p>
            This tool is designed to help you with ranking all employees. Take a look at
            {' '}
            <a
                href="https://stackoverflow.com/c/expensify/questions/8061"
                target="_blank"
                rel="noopener noreferrer"
            >
                this Stack Overflow
            </a>
            {' '}
            for more details.
        </p>

        <h2>How many pairs of people do I have to do?</h2>
        <p>
            You need to compare every person in the company against everyone else in the company once to complete
            {' '}
            an eligible dataset. Take a look at
            {' '}
            <a
                href="https://stackoverflow.com/c/expensify/questions/8069"
                target="_blank"
                rel="noopener noreferrer"
            >
                this Stack Overflow
            </a>
            {' '}
            for more details
        </p>

        <h2>How do I decide who should be paid more?</h2>
        <p>
            For every pair of people you are presented with, you must choose who you think should be paid more.
            Take a look at
            {' '}
            <a
                href="https://stackoverflow.com/c/expensify/questions/8067"
                target="_blank"
                rel="noopener noreferrer"
            >
                this Stack Overflow
            </a>
            {' '}
            for more details.
        </p>

        <h2>Are there any more FAQs?</h2>
        <p>
            Yes! Take a look at the `compreview` label in
            {' '}
            <a
                href="https://stackoverflow.com/c/expensify/questions/tagged/642"
                target="_blank"
                rel="noopener noreferrer"
            >
                Stack Overflow
            </a>
            {' '}
             for more details.
        </p>

        <div className="d-flex">
            <button
                onClick={() => redirect(ROUTES.PAIR)}
                className="btn btn-success flex-grow-1 mw-500"
                type="button"
            >
                Begin Now
            </button>

            <button
                onClick={() => redirect(ROUTES.getProfileRoute(lodashGet(props, 'session.email', '')))}
                className="btn btn-secondary ml-2 flex-grow-1 mw-500"
                type="button"
            >
                View my profile
            </button>
        </div>
    </>
);

InstructionsPage.propTypes = propTypes;
export default withOnyx({
    session: {
        key: ONYXKEYS.SESSION,
    },
})(InstructionsPage);
