import _ from 'underscore';
import Onyx from 'react-native-onyx';
import ONYXKEYS from '../ONYXKEYS';
import NetworkConnection from './NetworkConnection';

/**
 * Send an HTTP request, and attempt to resolve the json response.
 * If there is a network error, we'll set the application offline.
 *
 * @param {String} url
 * @param {String} method
 * @param {Object} body
 * @returns {Promise}
 */
function processHTTPRequest(url, method = 'get', body = null) {
    return fetch(url, {
        credentials: 'same-origin',
        method,
        body,
    })
        .then(response => response.json())

        // This will catch any HTTP network errors (like 404s and such), not to be confused with jsonCode which this
        // does NOT catch
        .catch(() => {
            NetworkConnection.setOfflineStatus(true);

            // Set an error state and signify we are done loading
            Onyx.merge(ONYXKEYS.SESSION, {loading: false, error: 'Cannot connect to server'});

            // Throw a new error to prevent any other `then()` in the promise chain from being triggered (until another
            // catch() happens
            throw new Error('API is offline');
        });
}

/**
 * Makes XHR request
 * @param {String} command the name of the API command
 * @param {Object} data parameters for the API command
 * @param {String} type HTTP request type (get/post)
 * @param {String} urlRoot The root URL to make requests to
 * @returns {Promise}
 */
function xhr(command, data, type = 'post', urlRoot) {
    const formData = new FormData();
    _.each(data, (val, key) => formData.append(key, val));
    return processHTTPRequest(`${urlRoot}api?command=${command}`, type, formData);
}

export default {
    xhr,
};
