import Onyx from 'react-native-onyx';
import ONYXKEYS from '../../ONYXKEYS';
import ROUTES from '../../ROUTES';
import {redirect} from './App';
import NetworkConnection from '../NetworkConnection';

let currentURL;
Onyx.connect({
    key: ONYXKEYS.CURRENT_URL,
    callback: val => currentURL = val,
});

let isCurrentlyRedirecting = false;

/**
 * Clears the Onyx store and redirects to the sign in page.
 * Normally this method would live in Session.js, but that would cause a circular dependency with Network.js.
 *
 * @param {String} [errorMessage] error message to be displayed on the sign in page
 */
function redirectToSignIn(errorMessage) {
    if (isCurrentlyRedirecting) {
        return;
    }

    NetworkConnection.stopListeningForReconnect();

    if (!currentURL) {
        return;
    }

    // If we are already on the signin page, don't redirect
    if (currentURL.indexOf('signin') !== -1) {
        return;
    }
    isCurrentlyRedirecting = true;
    Onyx.clear().then(() => {
        if (errorMessage) {
            Onyx.set(ONYXKEYS.SESSION, {error: errorMessage});
        }
        redirect(ROUTES.SIGNIN);
        isCurrentlyRedirecting = false;
    });
}

export default redirectToSignIn;
