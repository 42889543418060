import _ from 'underscore';
import lodashGet from 'lodash/get';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import propTypesIndividual from './propTypesIndividual';
import {chronosTopProjectProps, propTypesContributions} from './propTypesPair';
import Description from './Description';
import UsefulLinks from './UsefulLinks';
import Teams from './Teams';
import Projects from './Projects';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../components/withWindowDimensions';

const propTypes = {
    /** The individual to show details for */
    individual: propTypesIndividual.isRequired,

    /** This person's slack name */
    slackUsername: PropTypes.string.isRequired,

    /** A list of this individual's teams to highlight because they are not contained in the list of teams of the person they're being compared with */
    uniqueOrHigherTeams: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** This person's github contribution data */
    githubContributions: propTypesContributions,

    /** This person's top projects in Chronos */
    chronosTopProjects: chronosTopProjectProps,

    /** Function to execute when the select button is pressed */
    onSelect: PropTypes.func,

    /** Should the select button be visible? */
    shouldShowSelectButton: PropTypes.bool,

    /** An array containing the min-height for each row. This is used to synchronize two IndividualDetails views on wide screens and line them up side-by-side */
    minRowHeights: PropTypes.arrayOf(PropTypes.number),

    /** Function used to inform parent of measured row heights to synchronize two IndividualDetails views on wide screens and line them up side-by-side */
    updateRowHeights: PropTypes.func,

    ...windowDimensionsPropTypes,
};

const defaultProps = {
    githubContributions: {},
    chronosTopProjects: {},
    onSelect: () => {},
    shouldShowSelectButton: true,
    minRowHeights: [],
    updateRowHeights: () => {},
};

class IndividualSummary extends Component {
    constructor(props) {
        super(props);
        this.rowRefs = [];
    }

    componentDidMount() {
        this.updateRowHeights();
    }

    componentDidUpdate(prevProps) {
        if (this.props.windowWidth === prevProps.windowWidth) {
            return;
        }
        this.updateRowHeights();
    }

    updateRowHeights() {
        const rowHeights = _.map(this.rowRefs, container => container.clientHeight);
        this.props.updateRowHeights(rowHeights);
    }

    render() {
        return (
            <div className="container">
                <div className="container-header">
                    <div className="align-self-center">
                        {this.props.shouldShowSelectButton && (
                            <button
                                className="d-inline btn btn-success mr-3 mb-2 p-3"
                                onClick={this.props.onSelect}
                                type="button"
                            >
                                Select
                            </button>
                        )}
                        <h3 className="d-inline">
                            {this.props.individual.name}
                        </h3>
                    </div>
                </div>
                <div className="mt-md-3" ref={node => this.rowRefs[0] = node} style={{minHeight: lodashGet(this.props.minRowHeights, '[0]', 0)}}>
                    <Description
                        description={this.props.individual.description}
                        email={this.props.individual.email}
                        slackID={this.props.individual.slackID}
                        slackUserName={this.props.slackUsername}
                        githubUserName={lodashGet(this.props.githubContributions, 'username', '')}
                    />
                </div>
                <div className="mt-2 mt-md-4" ref={node => this.rowRefs[1] = node} style={{minHeight: lodashGet(this.props.minRowHeights, '[1]', 0)}}>
                    <UsefulLinks email={this.props.individual.email} slackID={this.props.individual.slackID} />
                </div>
                <div className="mt-2 mt-md-3 text-break" ref={node => this.rowRefs[2] = node} style={{minHeight: lodashGet(this.props.minRowHeights, '[2]', 0)}}>
                    <Teams
                        teams={this.props.individual.teams}
                        newTeams={this.props.individual.newTeams}
                        oldTeams={this.props.individual.oldTeams}
                        uniqueOrHigherTeams={this.props.uniqueOrHigherTeams}
                    />
                </div>
                <div className="mt-md-3" ref={node => this.rowRefs[3] = node} style={{minHeight: lodashGet(this.props.minRowHeights, '[3]', 0)}}>
                    <Projects chronosTopProjects={this.props.chronosTopProjects} />
                </div>
            </div>
        );
    }
}

IndividualSummary.propTypes = propTypes;
IndividualSummary.defaultProps = defaultProps;

export default withWindowDimensions(IndividualSummary);
