import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import _ from 'underscore';
import lodashGet from 'lodash/get';
import ONYXKEYS from '../../ONYXKEYS';
import {getCompensationHistory} from '../../libs/actions/Compensation';
import compensationPropTypes from './compensationPropTypes';
import round from '../../libs/round';

const propTypes = {
    /** The email of the employee to show the compensation data for */
    email: PropTypes.string.isRequired,

    /** From Onyx: The compensation data for the employee */
    compensation: PropTypes.arrayOf(compensationPropTypes),
};
const defaultProps = {
    compensation: [],
};

class CompensationEditTable extends React.Component {
    componentDidMount() {
        getCompensationHistory(this.props.email);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.email !== this.props.email) {
            getCompensationHistory(this.props.email);
        }
    }

    render() {
        const currencyUSDFormatter = new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'});
        return (
            <div className="card mt-4">
                <div className="card-body">
                    <h5 className="card-title">Compensation History</h5>
                </div>
                {this.props.compensation.length > 0 && (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Effective Date</th>
                                <th>Reason</th>
                                <th>Local Currency</th>
                                <th>Currency Exchange</th>
                                <th>Cash Salary</th>
                                <th>Salary Exchanged for Equity</th>
                                <th>Grant Salary</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.compensation && this.props.compensation
                                .map(row => (
                                    <tr key={_.uniqueId()}>
                                        <td>
                                            {row.effectiveDate || row.created}
                                        </td>
                                        <td>
                                            {row.reason}
                                        </td>
                                        <td>
                                            {row.localCurrency}
                                        </td>
                                        <td>
                                            {`${round(row.localCurrencyExchangeRate / 100)}%`}
                                        </td>
                                        <td>
                                            {currencyUSDFormatter.format(row.salary / 100)}
                                        </td>
                                        <td>
                                            {currencyUSDFormatter.format(row.salaryExchangedForEquity / 100)}
                                        </td>
                                        <td>
                                            {currencyUSDFormatter.format(row.grantCompensationAmount / 100)}
                                        </td>
                                        <td>
                                            {row.numberOfOptions}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total Compensation</th>
                                {/* eslint-disable-next-line max-len */}
                                <th>{currencyUSDFormatter.format((lodashGet(this.props.compensation, '[0].totalSalary', 0) + lodashGet(this.props.compensation, '[0].totalSalaryExchangedForEquity', 0) + lodashGet(this.props.compensation, '[0].totalGrantCompensationAmount', 0)) / 100)}</th>
                                <th>{' '}</th>
                                <th>{' '}</th>
                                <th>{currencyUSDFormatter.format(lodashGet(this.props.compensation, '[0].totalSalary', 0) / 100)}</th>
                                <th>{currencyUSDFormatter.format(lodashGet(this.props.compensation, '[0].totalSalaryExchangedForEquity', 0) / 100)}</th>
                                <th>{currencyUSDFormatter.format(lodashGet(this.props.compensation, '[0].totalGrantCompensationAmount', 0) / 100)}</th>
                                <th>{lodashGet(this.props.compensation, '[0].totalNumberOfOptions', '')}</th>
                            </tr>
                        </tfoot>
                    </table>
                )}
            </div>
        );
    }
}

CompensationEditTable.propTypes = propTypes;
CompensationEditTable.defaultProps = defaultProps;

export default withOnyx({
    compensation: {
        key: ({email}) => `${ONYXKEYS.COLLECTION.COMPENSATION}${email}`,
    },
})(CompensationEditTable);
