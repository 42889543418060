/* eslint-disable */
export default {
  GITHUB_NUMBERS: {
    "dbarrett@expensify.com": {
      pullRequests: 1,
      openedIssues: 151,
      reviews: 4,
      commits: 2,
      closedIssues: 328,
      openSource: 0,
      total: 486,
      username: "quinthar",
    },
    "jason@expensify.com": {
      pullRequests: 5,
      openedIssues: 186,
      reviews: 1,
      commits: 5,
      closedIssues: 1058,
      openSource: 9,
      total: 1264,
      username: "JmillsExpensify",
    },
    "puneet@expensify.com": {
      pullRequests: 178,
      openedIssues: 192,
      reviews: 613,
      commits: 1316,
      closedIssues: 1025,
      openSource: 1599,
      total: 4923,
      username: "puneetlath",
    },
    "gknight@expensify.com": {
      pullRequests: 6,
      openedIssues: 243,
      reviews: 15,
      commits: 7,
      closedIssues: 885,
      openSource: 0,
      total: 1156,
      username: "garrettmknight",
    },
    "daniel@expensify.com": {
      pullRequests: 3,
      openedIssues: 166,
      reviews: 1,
      commits: 7,
      closedIssues: 810,
      openSource: 0,
      total: 987,
      username: "danielrvidal",
    },
    "robert@expensify.com": {
      pullRequests: 298,
      openedIssues: 117,
      reviews: 641,
      commits: 1366,
      closedIssues: 980,
      openSource: 463,
      total: 3865,
      username: "robertjchen",
    },
    "francois@expensify.com": {
      pullRequests: 461,
      openedIssues: 142,
      reviews: 775,
      commits: 1802,
      closedIssues: 710,
      openSource: 604,
      total: 4494,
      username: "francoisl",
    },
    "shawn@expensify.com": {
      pullRequests: 75,
      openedIssues: 43,
      reviews: 125,
      commits: 338,
      closedIssues: 931,
      openSource: 156,
      total: 1668,
      username: "shawnborton",
    },
    "ryan@expensify.com": {
      pullRequests: 4,
      openedIssues: 43,
      reviews: 3,
      commits: 4,
      closedIssues: 1032,
      openSource: 0,
      total: 1086,
      username: "ryanschaffer",
    },
    "carlos@expensify.com": {
      pullRequests: 207,
      openedIssues: 154,
      reviews: 502,
      commits: 1257,
      closedIssues: 960,
      openSource: 1070,
      total: 4150,
      username: "cead22",
    },
    "dcardoza@expensify.com": {
      pullRequests: 38,
      openedIssues: 316,
      reviews: 14,
      commits: 75,
      closedIssues: 3560,
      openSource: 0,
      total: 4003,
      username: "davidcardoza",
    },
    "florent@expensify.com": {
      pullRequests: 539,
      openedIssues: 204,
      reviews: 983,
      commits: 2082,
      closedIssues: 872,
      openSource: 487,
      total: 5167,
      username: "flodnv",
    },
    "al@expensify.com": {
      pullRequests: 16,
      openedIssues: 82,
      reviews: 0,
      commits: 44,
      closedIssues: 2199,
      openSource: 0,
      total: 2341,
      username: "alexpensify",
    },
    "ionatan@expensify.com": {
      pullRequests: 389,
      openedIssues: 152,
      reviews: 719,
      commits: 1486,
      closedIssues: 714,
      openSource: 256,
      total: 3716,
      username: "iwiznia",
    },
    "nkuoch@expensify.com": {
      pullRequests: 426,
      openedIssues: 43,
      reviews: 359,
      commits: 1184,
      closedIssues: 767,
      openSource: 673,
      total: 3452,
      username: "nkuoch",
    },
    "cortney@expensify.com": {
      pullRequests: 7,
      openedIssues: 128,
      reviews: 0,
      commits: 11,
      closedIssues: 2122,
      openSource: 0,
      total: 2268,
      username: "CortneyOfstad",
    },
    "sheena@expensify.com": {
      pullRequests: 40,
      openedIssues: 161,
      reviews: 3,
      commits: 61,
      closedIssues: 2379,
      openSource: 0,
      total: 2644,
      username: "strepanier03",
    },
    "rdonato@expensify.com": {
      pullRequests: 7,
      openedIssues: 130,
      reviews: 0,
      commits: 8,
      closedIssues: 2511,
      openSource: 0,
      total: 2656,
      username: "ryanldonato",
    },
    "tgolen@expensify.com": {
      pullRequests: 312,
      openedIssues: 145,
      reviews: 562,
      commits: 2694,
      closedIssues: 617,
      openSource: 674,
      total: 5004,
      username: "tgolen",
    },
    "zach@expensify.com": {
      pullRequests: 30,
      openedIssues: 157,
      reviews: 3,
      commits: 40,
      closedIssues: 2254,
      openSource: 0,
      total: 2484,
      username: "zsgreenwald",
    },
    "slafortune@expensify.com": {
      pullRequests: 1,
      openedIssues: 118,
      reviews: 0,
      commits: 0,
      closedIssues: 2168,
      openSource: 0,
      total: 2287,
      username: "slafortune",
    },
    "corinne@expensify.com": {
      pullRequests: 14,
      openedIssues: 212,
      reviews: 0,
      commits: 37,
      closedIssues: 2019,
      openSource: 0,
      total: 2282,
      username: "OfstadC",
    },
    "april@expensify.com": {
      pullRequests: 7,
      openedIssues: 106,
      reviews: 1,
      commits: 10,
      closedIssues: 1981,
      openSource: 0,
      total: 2105,
      username: "abekkala",
    },
    "jenna@expensify.com": {
      pullRequests: 8,
      openedIssues: 102,
      reviews: 1,
      commits: 12,
      closedIssues: 605,
      openSource: 0,
      total: 728,
      username: "heyjennahay",
    },
    "tyler@expensify.com": {
      pullRequests: 291,
      openedIssues: 43,
      reviews: 346,
      commits: 1237,
      closedIssues: 529,
      openSource: 366,
      total: 2812,
      username: "tylerkaraszewski",
    },
    "lindsey@expensify.com": {
      pullRequests: 2,
      openedIssues: 156,
      reviews: 2,
      commits: 4,
      closedIssues: 934,
      openSource: 0,
      total: 1098,
      username: "LLPeckham",
    },
    "andrew@expensify.com": {
      pullRequests: 195,
      openedIssues: 148,
      reviews: 578,
      commits: 1202,
      closedIssues: 691,
      openSource: 965,
      total: 3779,
      username: "AndrewGable",
    },
    "youssef@expensify.com": {
      pullRequests: 204,
      openedIssues: 51,
      reviews: 469,
      commits: 1761,
      closedIssues: 533,
      openSource: 645,
      total: 3663,
      username: "youssef-lr",
    },
    "yuwen@expensify.com": {
      pullRequests: 292,
      openedIssues: 122,
      reviews: 462,
      commits: 1711,
      closedIssues: 586,
      openSource: 458,
      total: 3631,
      username: "yuwenmemon",
    },
    "caitlin@expensify.com": {
      pullRequests: 29,
      openedIssues: 78,
      reviews: 0,
      commits: 30,
      closedIssues: 1679,
      openSource: 0,
      total: 1816,
      username: "caitlinwhite1",
    },
    "anu@expensify.com": {
      pullRequests: 1,
      openedIssues: 211,
      reviews: 0,
      commits: 1,
      closedIssues: 851,
      openSource: 0,
      total: 1064,
      username: "anmurali",
    },
    "tom@expensify.com": {
      pullRequests: 16,
      openedIssues: 182,
      reviews: 4,
      commits: 22,
      closedIssues: 973,
      openSource: 102,
      total: 1299,
      username: "trjExpensify",
    },
    "sonia@expensify.com": {
      pullRequests: 55,
      openedIssues: 170,
      reviews: 18,
      commits: 92,
      closedIssues: 3212,
      openSource: 0,
      total: 3547,
      username: "sonialiap",
    },
    "sasha@expensify.com": {
      pullRequests: 57,
      openedIssues: 280,
      reviews: 14,
      commits: 95,
      closedIssues: 2739,
      openSource: 0,
      total: 3185,
      username: "sakluger",
    },
    "wallroth@expensify.com": {
      pullRequests: 8,
      openedIssues: 203,
      reviews: 0,
      commits: 14,
      closedIssues: 1123,
      openSource: 0,
      total: 1348,
      username: "nikihatesgh",
    },
    "john@expensify.com": {
      pullRequests: 266,
      openedIssues: 79,
      reviews: 608,
      commits: 1307,
      closedIssues: 878,
      openSource: 387,
      total: 3525,
      username: "johnmlee101",
    },
    "cole@expensify.com": {
      pullRequests: 145,
      openedIssues: 133,
      reviews: 171,
      commits: 603,
      closedIssues: 1112,
      openSource: 0,
      total: 2164,
      username: "coleaeason",
    },
    "scv@expensify.com": {
      pullRequests: 43,
      openedIssues: 464,
      reviews: 3,
      commits: 69,
      closedIssues: 856,
      openSource: 0,
      total: 1435,
      username: "saracouto",
    },
    "flavia@expensify.com": {
      pullRequests: 17,
      openedIssues: 204,
      reviews: 10,
      commits: 21,
      closedIssues: 1832,
      openSource: 0,
      total: 2084,
      username: "flaviadefaria",
    },
    "james@expensify.com": {
      pullRequests: 92,
      openedIssues: 140,
      reviews: 1,
      commits: 157,
      closedIssues: 698,
      openSource: 0,
      total: 1088,
      username: "jamesdeanexpensify",
    },
    "lauren@expensify.com": {
      pullRequests: 44,
      openedIssues: 296,
      reviews: 20,
      commits: 66,
      closedIssues: 2650,
      openSource: 0,
      total: 3076,
      username: "laurenreidexpensify",
    },
    "mallen@expensify.com": {
      pullRequests: 22,
      openedIssues: 296,
      reviews: 1,
      commits: 25,
      closedIssues: 1022,
      openSource: 0,
      total: 1366,
      username: "mallenexpensify",
    },
    "rocio@expensify.com": {
      pullRequests: 321,
      openedIssues: 76,
      reviews: 547,
      commits: 2012,
      closedIssues: 830,
      openSource: 973,
      total: 4759,
      username: "pecanoro",
    },
    "dbondy@expensify.com": {
      pullRequests: 215,
      openedIssues: 93,
      reviews: 504,
      commits: 1416,
      closedIssues: 599,
      openSource: 402,
      total: 3229,
      username: "bondydaa",
    },
    "chirag@expensify.com": {
      pullRequests: 205,
      openedIssues: 115,
      reviews: 431,
      commits: 1430,
      closedIssues: 604,
      openSource: 249,
      total: 3034,
      username: "chiragsalian",
    },
    "shaelyn@expensify.com": {
      pullRequests: 16,
      openedIssues: 200,
      reviews: 1,
      commits: 26,
      closedIssues: 1473,
      openSource: 0,
      total: 1716,
      username: "shmaxey",
    },
    "scott@expensify.com": {
      pullRequests: 229,
      openedIssues: 87,
      reviews: 428,
      commits: 1858,
      closedIssues: 653,
      openSource: 490,
      total: 3745,
      username: "deetergp",
    },
    "milja@expensify.com": {
      pullRequests: 10,
      openedIssues: 120,
      reviews: 0,
      commits: 34,
      closedIssues: 2192,
      openSource: 0,
      total: 2356,
      username: "miljakljajic",
    },
    "greg@expensify.com": {
      pullRequests: 31,
      openedIssues: 191,
      reviews: 1,
      commits: 48,
      closedIssues: 3243,
      openSource: 0,
      total: 3514,
      username: "greg-schroeder",
    },
    "jschuster@expensify.com": {
      pullRequests: 64,
      openedIssues: 250,
      reviews: 19,
      commits: 122,
      closedIssues: 2680,
      openSource: 0,
      total: 3135,
      username: "johncschuster",
    },
    "jli@expensify.com": {
      pullRequests: 12,
      openedIssues: 138,
      reviews: 8,
      commits: 30,
      closedIssues: 2367,
      openSource: 0,
      total: 2555,
      username: "jliexpensify",
    },
    "selliott@expensify.com": {
      pullRequests: 39,
      openedIssues: 135,
      reviews: 2,
      commits: 38,
      closedIssues: 2679,
      openSource: 0,
      total: 2893,
      username: "stephanieelliott",
    },
    "isa@expensify.com": {
      pullRequests: 15,
      openedIssues: 132,
      reviews: 1,
      commits: 12,
      closedIssues: 2534,
      openSource: 0,
      total: 2694,
      username: "isabelastisser",
    },
    "rachael@expensify.com": {
      pullRequests: 39,
      openedIssues: 202,
      reviews: 0,
      commits: 46,
      closedIssues: 2955,
      openSource: 0,
      total: 3242,
      username: "RachCHopkins",
    },
    "nmendonca@expensify.com": {
      pullRequests: 18,
      openedIssues: 142,
      reviews: 3,
      commits: 30,
      closedIssues: 2793,
      openSource: 0,
      total: 2986,
      username: "NicMendonca",
    },
    "lschurr@expensify.com": {
      pullRequests: 34,
      openedIssues: 153,
      reviews: 5,
      commits: 55,
      closedIssues: 2543,
      openSource: 0,
      total: 2790,
      username: "lschurr",
    },
    "ntooker@expensify.com": {
      pullRequests: 1,
      openedIssues: 165,
      reviews: 0,
      commits: 1,
      closedIssues: 713,
      openSource: 0,
      total: 880,
      username: "NickTooker",
    },
    "ted@expensify.com": {
      pullRequests: 97,
      openedIssues: 919,
      reviews: 20,
      commits: 220,
      closedIssues: 2690,
      openSource: 123,
      total: 4069,
      username: "twisterdotcom",
    },
    "mitch@expensify.com": {
      pullRequests: 17,
      openedIssues: 237,
      reviews: 2,
      commits: 21,
      closedIssues: 2458,
      openSource: 0,
      total: 2735,
      username: "MitchExpensify",
    },
    "ariel@expensify.com": {
      pullRequests: 17,
      openedIssues: 510,
      reviews: 4,
      commits: 23,
      closedIssues: 2374,
      openSource: 0,
      total: 2928,
      username: "arielgreen",
    },
    "mlewis@expensify.com": {
      pullRequests: 122,
      openedIssues: 412,
      reviews: 9,
      commits: 173,
      closedIssues: 2454,
      openSource: 0,
      total: 3170,
      username: "maddylewis",
    },
    "bfitz@expensify.com": {
      pullRequests: 28,
      openedIssues: 171,
      reviews: 6,
      commits: 43,
      closedIssues: 2605,
      openSource: 0,
      total: 2853,
      username: "bfitzexpensify",
    },
    "neil@expensify.com": {
      pullRequests: 174,
      openedIssues: 152,
      reviews: 474,
      commits: 1922,
      closedIssues: 484,
      openSource: 897,
      total: 4103,
      username: "neil-marcellini",
    },
    "nikkiwines@expensify.com": {
      pullRequests: 234,
      openedIssues: 54,
      reviews: 476,
      commits: 1690,
      closedIssues: 544,
      openSource: 500,
      total: 3498,
      username: "NikkiWines",
    },
    "bstites@expensify.com": {
      pullRequests: 139,
      openedIssues: 96,
      reviews: 459,
      commits: 1314,
      closedIssues: 649,
      openSource: 1132,
      total: 3789,
      username: "stitesExpensify",
    },
    "marc@expensify.com": {
      pullRequests: 337,
      openedIssues: 412,
      reviews: 573,
      commits: 2521,
      closedIssues: 626,
      openSource: 987,
      total: 5456,
      username: "marcaaron",
    },
    "mmoore@expensify.com": {
      pullRequests: 38,
      openedIssues: 260,
      reviews: 1,
      commits: 54,
      closedIssues: 2951,
      openSource: 0,
      total: 3304,
      username: "muttmuure",
    },
    "c.dobrzyn@expensify.com": {
      pullRequests: 32,
      openedIssues: 240,
      reviews: 5,
      commits: 42,
      closedIssues: 2564,
      openSource: 0,
      total: 2883,
      username: "Christinadobrzyn",
    },
    "alberto@expensify.com": {
      pullRequests: 224,
      openedIssues: 103,
      reviews: 349,
      commits: 1811,
      closedIssues: 653,
      openSource: 525,
      total: 3665,
      username: "Gonals",
    },
    "aimee@expensify.com": {
      pullRequests: 15,
      openedIssues: 112,
      reviews: 1,
      commits: 15,
      closedIssues: 1697,
      openSource: 0,
      total: 1840,
      username: "aimeebarigian",
    },
    "victoria@expensify.com": {
      pullRequests: 1,
      openedIssues: 37,
      reviews: 0,
      commits: 3,
      closedIssues: 716,
      openSource: 0,
      total: 757,
      username: "VictoriaExpensify",
    },
    "jkaufman@expensify.com": {
      pullRequests: 22,
      openedIssues: 226,
      reviews: 2,
      commits: 45,
      closedIssues: 2581,
      openSource: 0,
      total: 2876,
      username: "joekaufmanexpensify",
    },
    "jpersaud@expensify.com": {
      pullRequests: 309,
      openedIssues: 173,
      reviews: 388,
      commits: 1514,
      closedIssues: 730,
      openSource: 0,
      total: 3114,
      username: "justinpersaud",
    },
    "zany@expensify.com": {
      pullRequests: 7,
      openedIssues: 204,
      reviews: 1,
      commits: 12,
      closedIssues: 2314,
      openSource: 0,
      total: 2538,
      username: "zanyrenney",
    },
    "cheryl@expensify.com": {
      pullRequests: 3,
      openedIssues: 220,
      reviews: 0,
      commits: 3,
      closedIssues: 2505,
      openSource: 0,
      total: 2731,
      username: "CherylWalsh",
    },
    "rafe@expensify.com": {
      pullRequests: 355,
      openedIssues: 212,
      reviews: 389,
      commits: 1540,
      closedIssues: 719,
      openSource: 40,
      total: 3255,
      username: "rafecolton",
    },
    "k.alexander@expensify.com": {
      pullRequests: 52,
      openedIssues: 171,
      reviews: 16,
      commits: 175,
      closedIssues: 2738,
      openSource: 0,
      total: 3152,
      username: "kadiealexander",
    },
    "dylan@expensify.com": {
      pullRequests: 23,
      openedIssues: 162,
      reviews: 0,
      commits: 24,
      closedIssues: 1955,
      openSource: 0,
      total: 2164,
      username: "dylanexpensify",
    },
    "adele@expensify.com": {
      pullRequests: 25,
      openedIssues: 214,
      reviews: 0,
      commits: 30,
      closedIssues: 2140,
      openSource: 0,
      total: 2409,
      username: "adelekennedy",
    },
    "jules@expensify.com": {
      pullRequests: 179,
      openedIssues: 263,
      reviews: 673,
      commits: 1512,
      closedIssues: 733,
      openSource: 1021,
      total: 4381,
      username: "Julesssss",
    },
    "jack@expensify.com": {
      pullRequests: 378,
      openedIssues: 340,
      reviews: 705,
      commits: 2809,
      closedIssues: 863,
      openSource: 1325,
      total: 6420,
      username: "thienlnam",
    },
    "maxence@expensify.com": {
      pullRequests: 424,
      openedIssues: 173,
      reviews: 510,
      commits: 1648,
      closedIssues: 999,
      openSource: 339,
      total: 4093,
      username: "madmax330",
    },
    "maria@expensify.com": {
      pullRequests: 245,
      openedIssues: 71,
      reviews: 524,
      commits: 1820,
      closedIssues: 649,
      openSource: 748,
      total: 4057,
      username: "MariaHCD",
    },
    "phs@expensify.com": {
      pullRequests: 526,
      openedIssues: 133,
      reviews: 384,
      commits: 1606,
      closedIssues: 744,
      openSource: 0,
      total: 3393,
      username: "fukawi2",
    },
    "alexbeaman@expensify.com": {
      pullRequests: 298,
      openedIssues: 158,
      reviews: 657,
      commits: 2166,
      closedIssues: 633,
      openSource: 767,
      total: 4679,
      username: "Beamanator",
    },
    "monil@expensify.com": {
      pullRequests: 257,
      openedIssues: 88,
      reviews: 616,
      commits: 1564,
      closedIssues: 623,
      openSource: 996,
      total: 4144,
      username: "MonilBhavsar",
    },
    "kortney@expensify.com": {
      pullRequests: 7,
      openedIssues: 125,
      reviews: 0,
      commits: 8,
      closedIssues: 1144,
      openSource: 0,
      total: 1284,
      username: "kortneywynn",
    },
    "trent@expensify.com": {
      pullRequests: 0,
      openedIssues: 39,
      reviews: 0,
      commits: 8,
      closedIssues: 644,
      openSource: 0,
      total: 691,
      username: "trentpetty",
    },
    "aldo@expensify.com": {
      pullRequests: 279,
      openedIssues: 126,
      reviews: 613,
      commits: 1641,
      closedIssues: 513,
      openSource: 516,
      total: 3688,
      username: "aldo-expensify",
    },
    "nmetcalf@expensify.com": {
      pullRequests: 253,
      openedIssues: 125,
      reviews: 439,
      commits: 771,
      closedIssues: 622,
      openSource: 0,
      total: 2210,
      username: "nathanmetcalf",
    },
    "marco@expensify.com": {
      pullRequests: 235,
      openedIssues: 87,
      reviews: 506,
      commits: 2300,
      closedIssues: 549,
      openSource: 650,
      total: 4327,
      username: "marcochavezf",
    },
    "dsilva@expensify.com": {
      pullRequests: 316,
      openedIssues: 386,
      reviews: 525,
      commits: 2447,
      closedIssues: 699,
      openSource: 404,
      total: 4777,
      username: "danieldoglas",
    },
    "haley@expensify.com": {
      pullRequests: 66,
      openedIssues: 728,
      reviews: 4,
      commits: 88,
      closedIssues: 1715,
      openSource: 0,
      total: 2601,
      username: "HaleyJacobson",
    },
    "amy@expensify.com": {
      pullRequests: 154,
      openedIssues: 54,
      reviews: 535,
      commits: 1091,
      closedIssues: 459,
      openSource: 752,
      total: 3045,
      username: "amyevans",
    },
    "vivek@expensify.com": {
      pullRequests: 280,
      openedIssues: 109,
      reviews: 552,
      commits: 1680,
      closedIssues: 640,
      openSource: 557,
      total: 3818,
      username: "techievivek",
    },
    "arosiclair@expensify.com": {
      pullRequests: 150,
      openedIssues: 73,
      reviews: 461,
      commits: 1607,
      closedIssues: 460,
      openSource: 495,
      total: 3246,
      username: "arosiclair",
    },
    "jeremyhollm@expensify.com": {
      pullRequests: 2,
      openedIssues: 53,
      reviews: 12,
      commits: 15,
      closedIssues: 961,
      openSource: 0,
      total: 1043,
      username: "jeremyhollmexpensify",
    },
    "cj@expensify.com": {
      pullRequests: 1,
      openedIssues: 144,
      reviews: 0,
      commits: 1,
      closedIssues: 1556,
      openSource: 0,
      total: 1702,
      username: "cjjohnson121",
    },
    "srikar.parsi@expensify.com": {
      pullRequests: 85,
      openedIssues: 29,
      reviews: 394,
      commits: 723,
      closedIssues: 364,
      openSource: 372,
      total: 1967,
      username: "srikarparsi",
    },
    "shannon@expensify.com": {
      pullRequests: 2,
      openedIssues: 153,
      reviews: 0,
      commits: 18,
      closedIssues: 861,
      openSource: 0,
      total: 1034,
      username: "shannonmccallexfy",
    },
    "cristi@expensify.com": {
      pullRequests: 261,
      openedIssues: 82,
      reviews: 546,
      commits: 2360,
      closedIssues: 540,
      openSource: 994,
      total: 4783,
      username: "cristipaval",
    },
    "jasper@expensify.com": {
      pullRequests: 316,
      openedIssues: 129,
      reviews: 488,
      commits: 3066,
      closedIssues: 556,
      openSource: 504,
      total: 5059,
      username: "jasperhuangg",
    },
    "dgalerosen@expensify.com": {
      pullRequests: 153,
      openedIssues: 73,
      reviews: 369,
      commits: 1063,
      closedIssues: 425,
      openSource: 938,
      total: 3021,
      username: "dangrous",
    },
    "georgia@expensify.com": {
      pullRequests: 143,
      openedIssues: 238,
      reviews: 512,
      commits: 1420,
      closedIssues: 650,
      openSource: 1536,
      total: 4499,
      username: "grgia",
    },
    "mfillari@expensify.com": {
      pullRequests: 0,
      openedIssues: 32,
      reviews: 1,
      commits: 0,
      closedIssues: 611,
      openSource: 0,
      total: 644,
      username: "MarcFillari",
    },
    "danny@expensify.com": {
      pullRequests: 9,
      openedIssues: 2,
      reviews: 49,
      commits: 29,
      closedIssues: 234,
      openSource: 232,
      total: 555,
      username: "dannymcclain",
    },
    "lucien@expensify.com": {
      pullRequests: 139,
      openedIssues: 91,
      reviews: 254,
      commits: 1526,
      closedIssues: 325,
      openSource: 658,
      total: 2993,
      username: "lakchote",
    },
    "jon@expensify.com": {
      pullRequests: 12,
      openedIssues: 12,
      reviews: 29,
      commits: 17,
      closedIssues: 208,
      openSource: 201,
      total: 479,
      username: "dubielzyk-expensify",
    },
    "rodrigo@expensify.com": {
      pullRequests: 154,
      openedIssues: 45,
      reviews: 265,
      commits: 1177,
      closedIssues: 310,
      openSource: 370,
      total: 2321,
      username: "rlinoz",
    },
    "dan@expensify.com": {
      pullRequests: 53,
      openedIssues: 38,
      reviews: 104,
      commits: 253,
      closedIssues: 194,
      openSource: 0,
      total: 642,
      username: "smarthall",
    },
    "blimpich@expensify.com": {
      pullRequests: 90,
      openedIssues: 44,
      reviews: 198,
      commits: 504,
      closedIssues: 237,
      openSource: 285,
      total: 1358,
      username: "blimpich",
    },
    "carlosmiceli@expensify.com": {
      pullRequests: 15,
      openedIssues: 13,
      reviews: 18,
      commits: 30,
      closedIssues: 66,
      openSource: 0,
      total: 142,
      username: "carlosmiceli",
    },
    "cmartins@expensify.com": {
      pullRequests: 518,
      openedIssues: 438,
      reviews: 1075,
      commits: 4050,
      closedIssues: 1086,
      openSource: 3542,
      total: 10709,
      username: "luacmartins",
    },
    "vit@expensify.com": {
      pullRequests: 503,
      openedIssues: 446,
      reviews: 1553,
      commits: 3046,
      closedIssues: 1267,
      openSource: 5470,
      total: 12285,
      username: "moutiny",
    },
    "rory@expensify.com": {
      pullRequests: 394,
      openedIssues: 264,
      reviews: 717,
      commits: 2279,
      closedIssues: 699,
      openSource: 2960,
      total: 7313,
      username: "roryabraham",
    },
  },
  GITHUB_COMMENTS: {
    "dbarrett@expensify.com": { githubComments: 1356 },
    "jason@expensify.com": { githubComments: 5849 },
    "puneet@expensify.com": { githubComments: 4783 },
    "gknight@expensify.com": { githubComments: 3689 },
    "daniel@expensify.com": { githubComments: 1680 },
    "robert@expensify.com": { githubComments: 3618 },
    "francois@expensify.com": { githubComments: 3543 },
    "shawn@expensify.com": { githubComments: 6341 },
    "ryan@expensify.com": { githubComments: 1390 },
    "carlos@expensify.com": { githubComments: 4074 },
    "dcardoza@expensify.com": { githubComments: 3169 },
    "ionatan@expensify.com": { githubComments: 3129 },
    "nkuoch@expensify.com": { githubComments: 2177 },
    "cortney@expensify.com": { githubComments: 7514 },
    "rdonato@expensify.com": { githubComments: 1998 },
    "tgolen@expensify.com": { githubComments: 3143 },
    "zach@expensify.com": { githubComments: 1578 },
    "slafortune@expensify.com": { githubComments: 4294 },
    "april@expensify.com": { githubComments: 7313 },
    "jenna@expensify.com": { githubComments: 2167 },
    "tyler@expensify.com": { githubComments: 1223 },
    "lindsey@expensify.com": { githubComments: 2148 },
    "andrew@expensify.com": { githubComments: 2017 },
    "youssef@expensify.com": { githubComments: 3030 },
    "yuwen@expensify.com": { githubComments: 2369 },
    "caitlin@expensify.com": { githubComments: 1965 },
    "anu@expensify.com": { githubComments: 1915 },
    "tom@expensify.com": { githubComments: 6594 },
    "wallroth@expensify.com": { githubComments: 2295 },
    "john@expensify.com": { githubComments: 3394 },
    "cole@expensify.com": { githubComments: 1911 },
    "scv@expensify.com": { githubComments: 2620 },
    "flavia@expensify.com": { githubComments: 5228 },
    "james@expensify.com": { githubComments: 2757 },
    "mallen@expensify.com": { githubComments: 4722 },
    "kevin@expensify.com": { githubComments: 1845 },
    "rocio@expensify.com": { githubComments: 3826 },
    "dbondy@expensify.com": { githubComments: 3809 },
    "chirag@expensify.com": { githubComments: 2094 },
    "shaelyn@expensify.com": { githubComments: 3963 },
    "scott@expensify.com": { githubComments: 2617 },
    "milja@expensify.com": { githubComments: 1982 },
    "jli@expensify.com": { githubComments: 7714 },
    "selliott@expensify.com": { githubComments: 5310 },
    "nmendonca@expensify.com": { githubComments: 5047 },
    "ntooker@expensify.com": { githubComments: 1319 },
    "mitch@expensify.com": { githubComments: 5345 },
    "ariel@expensify.com": { githubComments: 5910 },
    "bfitz@expensify.com": { githubComments: 6206 },
    "sofie@expensify.com": { githubComments: 3940 },
    "neil@expensify.com": { githubComments: 3690 },
    "nikkiwines@expensify.com": { githubComments: 2669 },
    "bstites@expensify.com": { githubComments: 2106 },
    "marc@expensify.com": { githubComments: 4688 },
    "mmoore@expensify.com": { githubComments: 6936 },
    "alberto@expensify.com": { githubComments: 1931 },
    "aimee@expensify.com": { githubComments: 2620 },
    "victoria@expensify.com": { githubComments: 1781 },
    "jpersaud@expensify.com": { githubComments: 2673 },
    "zany@expensify.com": { githubComments: 7775 },
    "cheryl@expensify.com": { githubComments: 4399 },
    "rafe@expensify.com": { githubComments: 3365 },
    "k.alexander@expensify.com": { githubComments: 6583 },
    "dylan@expensify.com": { githubComments: 5977 },
    "adele@expensify.com": { githubComments: 3848 },
    "jules@expensify.com": { githubComments: 5541 },
    "jack@expensify.com": { githubComments: 4483 },
    "maxence@expensify.com": { githubComments: 4120 },
    "maria@expensify.com": { githubComments: 3349 },
    "phs@expensify.com": { githubComments: 2776 },
    "rory@expensify.com": { githubComments: 6009 },
    "alexbeaman@expensify.com": { githubComments: 4832 },
    "monil@expensify.com": { githubComments: 4409 },
    "kortney@expensify.com": { githubComments: 4339 },
    "cmartins@expensify.com": { githubComments: 6784 },
    "trent@expensify.com": { githubComments: 2547 },
    "aldo@expensify.com": { githubComments: 4407 },
    "nmetcalf@expensify.com": { githubComments: 2398 },
    "marco@expensify.com": { githubComments: 3606 },
    "dsilva@expensify.com": { githubComments: 3700 },
    "haley@expensify.com": { githubComments: 6619 },
    "amy@expensify.com": { githubComments: 1854 },
    "vivek@expensify.com": { githubComments: 3643 },
    "arosiclair@expensify.com": { githubComments: 1850 },
    "jeremyhollm@expensify.com": { githubComments: 2423 },
    "cj@expensify.com": { githubComments: 5578 },
    "srikar.parsi@expensify.com": { githubComments: 1303 },
    "shannon@expensify.com": { githubComments: 2831 },
    "cristi@expensify.com": { githubComments: 2731 },
    "jasper@expensify.com": { githubComments: 2767 },
    "dgalerosen@expensify.com": { githubComments: 2685 },
    "georgia@expensify.com": { githubComments: 2945 },
    "mfillari@expensify.com": { githubComments: 1683 },
    "danny@expensify.com": { githubComments: 1685 },
    "lucien@expensify.com": { githubComments: 1132 },
    "jon@expensify.com": { githubComments: 1118 },
    "rodrigo@expensify.com": { githubComments: 1375 },
    "dan@expensify.com": { githubComments: 730 },
    "blimpich@expensify.com": { githubComments: 959 },
    "carlosmiceli@expensify.com": { githubComments: 155 },
    "al@expensify.com": { githubComments: 10228 },
    "sheena@expensify.com": { githubComments: 8857 },
    "corinne@expensify.com": { githubComments: 7441 },
    "sonia@expensify.com": { githubComments: 8836 },
    "sasha@expensify.com": { githubComments: 7632 },
    "lauren@expensify.com": { githubComments: 8206 },
    "greg@expensify.com": { githubComments: 8832 },
    "jschuster@expensify.com": { githubComments: 9671 },
    "isa@expensify.com": { githubComments: 8991 },
    "rachael@expensify.com": { githubComments: 9938 },
    "lschurr@expensify.com": { githubComments: 8836 },
    "ted@expensify.com": { githubComments: 8491 },
    "mlewis@expensify.com": { githubComments: 8077 },
    "c.dobrzyn@expensify.com": { githubComments: 9070 },
    "jkaufman@expensify.com": { githubComments: 12714 },
    "vit@expensify.com": { githubComments: 10898 },
    "florent@expensify.com": { githubComments: 6119 },
  },
  FIGMA_NUMBERS: {
    "dbarrett@expensify.com": { figmaContributions: 0 },
    "jason@expensify.com": { figmaContributions: 240 },
    "puneet@expensify.com": { figmaContributions: 32 },
    "gknight@expensify.com": { figmaContributions: 28 },
    "daniel@expensify.com": { figmaContributions: 36 },
    "robert@expensify.com": { figmaContributions: 0 },
    "francois@expensify.com": { figmaContributions: 0 },
    "shawn@expensify.com": { figmaContributions: 1475 },
    "ryan@expensify.com": { figmaContributions: 0 },
    "carlos@expensify.com": { figmaContributions: 0 },
    "dcardoza@expensify.com": { figmaContributions: 34 },
    "florent@expensify.com": { figmaContributions: 0 },
    "al@expensify.com": { figmaContributions: 0 },
    "ionatan@expensify.com": { figmaContributions: 0 },
    "nkuoch@expensify.com": { figmaContributions: 0 },
    "cortney@expensify.com": { figmaContributions: 0 },
    "sheena@expensify.com": { figmaContributions: 0 },
    "rdonato@expensify.com": { figmaContributions: 14 },
    "tgolen@expensify.com": { figmaContributions: 3 },
    "zach@expensify.com": { figmaContributions: 24 },
    "slafortune@expensify.com": { figmaContributions: 0 },
    "corinne@expensify.com": { figmaContributions: 0 },
    "april@expensify.com": { figmaContributions: 0 },
    "jenna@expensify.com": { figmaContributions: 0 },
    "tyler@expensify.com": { figmaContributions: 0 },
    "lindsey@expensify.com": { figmaContributions: 0 },
    "andrew@expensify.com": { figmaContributions: 8 },
    "youssef@expensify.com": { figmaContributions: 0 },
    "yuwen@expensify.com": { figmaContributions: 0 },
    "caitlin@expensify.com": { figmaContributions: 9 },
    "anu@expensify.com": { figmaContributions: 0 },
    "tom@expensify.com": { figmaContributions: 224 },
    "sonia@expensify.com": { figmaContributions: 0 },
    "sasha@expensify.com": { figmaContributions: 10 },
    "wallroth@expensify.com": { figmaContributions: 0 },
    "john@expensify.com": { figmaContributions: 0 },
    "cole@expensify.com": { figmaContributions: 28 },
    "scv@expensify.com": { figmaContributions: 2 },
    "flavia@expensify.com": { figmaContributions: 0 },
    "james@expensify.com": { figmaContributions: 59 },
    "lauren@expensify.com": { figmaContributions: 0 },
    "mallen@expensify.com": { figmaContributions: 5 },
    "kevin@expensify.com": { figmaContributions: 173 },
    "rocio@expensify.com": { figmaContributions: 0 },
    "dbondy@expensify.com": { figmaContributions: 0 },
    "chirag@expensify.com": { figmaContributions: 1 },
    "shaelyn@expensify.com": { figmaContributions: 0 },
    "scott@expensify.com": { figmaContributions: 0 },
    "milja@expensify.com": { figmaContributions: 1 },
    "greg@expensify.com": { figmaContributions: 2 },
    "jschuster@expensify.com": { figmaContributions: 0 },
    "jli@expensify.com": { figmaContributions: 0 },
    "selliott@expensify.com": { figmaContributions: 0 },
    "isa@expensify.com": { figmaContributions: 0 },
    "rachael@expensify.com": { figmaContributions: 0 },
    "nmendonca@expensify.com": { figmaContributions: 0 },
    "lschurr@expensify.com": { figmaContributions: 1 },
    "ntooker@expensify.com": { figmaContributions: 0 },
    "ted@expensify.com": { figmaContributions: 22 },
    "mitch@expensify.com": { figmaContributions: 139 },
    "ariel@expensify.com": { figmaContributions: 0 },
    "mlewis@expensify.com": { figmaContributions: 3 },
    "bfitz@expensify.com": { figmaContributions: 0 },
    "sofie@expensify.com": { figmaContributions: 0 },
    "neil@expensify.com": { figmaContributions: 6 },
    "nikkiwines@expensify.com": { figmaContributions: 2 },
    "bstites@expensify.com": { figmaContributions: 0 },
    "marc@expensify.com": { figmaContributions: 0 },
    "mmoore@expensify.com": { figmaContributions: 0 },
    "c.dobrzyn@expensify.com": { figmaContributions: 0 },
    "alberto@expensify.com": { figmaContributions: 0 },
    "aimee@expensify.com": { figmaContributions: 0 },
    "victoria@expensify.com": { figmaContributions: 0 },
    "jkaufman@expensify.com": { figmaContributions: 104 },
    "jpersaud@expensify.com": { figmaContributions: 0 },
    "zany@expensify.com": { figmaContributions: 26 },
    "cheryl@expensify.com": { figmaContributions: 0 },
    "rafe@expensify.com": { figmaContributions: 13 },
    "k.alexander@expensify.com": { figmaContributions: 0 },
    "dylan@expensify.com": { figmaContributions: 19 },
    "adele@expensify.com": { figmaContributions: 2 },
    "jules@expensify.com": { figmaContributions: 53 },
    "jack@expensify.com": { figmaContributions: 26 },
    "maxence@expensify.com": { figmaContributions: 0 },
    "maria@expensify.com": { figmaContributions: 0 },
    "phs@expensify.com": { figmaContributions: 0 },
    "rory@expensify.com": { figmaContributions: 1 },
    "alexbeaman@expensify.com": { figmaContributions: 0 },
    "monil@expensify.com": { figmaContributions: 5 },
    "kortney@expensify.com": { figmaContributions: 0 },
    "vit@expensify.com": { figmaContributions: 3 },
    "cmartins@expensify.com": { figmaContributions: 0 },
    "trent@expensify.com": { figmaContributions: 0 },
    "aldo@expensify.com": { figmaContributions: 0 },
    "nmetcalf@expensify.com": { figmaContributions: 0 },
    "marco@expensify.com": { figmaContributions: 16 },
    "dsilva@expensify.com": { figmaContributions: 0 },
    "haley@expensify.com": { figmaContributions: 0 },
    "amy@expensify.com": { figmaContributions: 0 },
    "vivek@expensify.com": { figmaContributions: 5 },
    "arosiclair@expensify.com": { figmaContributions: 0 },
    "jeremyhollm@expensify.com": { figmaContributions: 0 },
    "cj@expensify.com": { figmaContributions: 0 },
    "srikar.parsi@expensify.com": { figmaContributions: 0 },
    "shannon@expensify.com": { figmaContributions: 0 },
    "cristi@expensify.com": { figmaContributions: 0 },
    "jasper@expensify.com": { figmaContributions: 0 },
    "dgalerosen@expensify.com": { figmaContributions: 0 },
    "georgia@expensify.com": { figmaContributions: 87 },
    "mfillari@expensify.com": { figmaContributions: 0 },
    "danny@expensify.com": { figmaContributions: 1017 },
    "lucien@expensify.com": { figmaContributions: 3 },
    "jon@expensify.com": { figmaContributions: 811 },
    "rodrigo@expensify.com": { figmaContributions: 0 },
    "dan@expensify.com": { figmaContributions: 0 },
    "blimpich@expensify.com": { figmaContributions: 0 },
    "carlosmiceli@expensify.com": { figmaContributions: 0 },
  },
  TOP_PROJECTS: {
    "dbarrett@expensify.com": {
      topProjects: [
        "Launch Expensify Chat fo' reals, yo for 128 days (last worked on it: June 29, 2023)",
        "Accelerate reunification of approve&gt;reimburse flows with Teachers Unite! for 58 days (last worked on it: August 25, 2023)",
        "#chat for 55 days (last worked on it: October 19, 2023)",
        "#idea-nav for 15 days (last worked on it: November 3, 2023)",
        "#wave9-collect-signups for 19 days (last worked on it: November 21, 2023)",
        "#vip-vsb for 182 days",
      ],
    },
    "jason@expensify.com": {
      topProjects: [
        "N7 - Free Plan Employees for 187 days (last worked on it: July 22, 2023)",
        "wave4 scan receipts for 1 day (last worked on it: July 22, 2023)",
        "wave4 scan receipts for 74 days (last worked on it: October 6, 2023)",
        "Figure out ideal global nav for 71 days (last worked on it: December 15, 2023)",
        "ideal nav, simplified collect, money for 119 days (last worked on it: April 12, 2024)",
        "#wave-control and search for 39 days",
      ],
    },
    "puneet@expensify.com": {
      topProjects: [
        "@mentions for 105 days (last worked on it: May 11, 2023)",
        "#vip-waq-everywhere for 40 days (last worked on it: June 20, 2023)",
        "Securing Logins for SNH for 50 days (last worked on it: August 8, 2023)",
        "#wave6-categories-tags for 155 days (last worked on it: January 10, 2024)",
        "#wave6-collect-submitters for 70 days (last worked on it: March 20, 2024)",
        "#vip-vsb mentions v2: accountID and room mentions for 62 days",
      ],
    },
    "gknight@expensify.com": {
      topProjects: [
        "LTIP [Available] for 123 days (last worked on it: May 11, 2023)",
        "Sabbatical till 7/15 for 67 days (last worked on it: July 17, 2023)",
        "Back from sabbatical [Available] for 4 days (last worked on it: July 20, 2023)",
        "LT Database Update for Current Employee Exchanges for 35 days (last worked on it: August 31, 2023)",
        "hlep for 1 day (last worked on it: August 31, 2023)",
        "RSU/SPMP Sell-to-Cover update to save ~$500k in cash/quarter for 71 days (last worked on it: November 9, 2023)",
        "Instant Submit for Free and Control workspaces for 55 days (last worked on it: January 2, 2024)",
        "Instant Submit for Collect and Wave 5/6 migrations for 123 days (last worked on it: May 17, 2024)",
        "Free &gt; Collect migration and NewDot Upgrades for 5 days",
      ],
    },
    "daniel@expensify.com": {
      topProjects: [
        "improving account management and expensiconX for 94 days (last worked on it: April 14, 2023)",
        "upgrading Concierge with ChatGPT (AgentZero) for 95 days (last worked on it: July 17, 2023)",
        "project Bring AgentZero to sales process and Wave4 - Saastr/Request flow for 65 days (last worked on it: September 20, 2023)",
        "wave8 receipt update, launch p2p for 51 days (last worked on it: November 9, 2023)",
        "Get new users on NewDot (wave6/wave9) for 132 days (last worked on it: March 20, 2024)",
        "wave-collect: kill freeplan creation, get v1 vip-billpay out, vip-split may release for 48 days (last worked on it: May 7, 2024)",
        "critical viral path clean up, wave-collect stage 3 onboarding for 15 days",
      ],
    },
    "robert@expensify.com": {
      topProjects: [
        '[SNH] Share via QR code, [VIP SDR 100] "custom subject" NewDot email addresses, [#accounting] vacation delegate bug for 26 days (last worked on it: March 21, 2023)',
        "[SNH] Share via QR code, #encryptify project reboot for 58 days (last worked on it: May 18, 2023)",
        "#keep-our-banking-connections, #encryptify for 53 days (last worked on it: July 10, 2023)",
        "QSP, #keep-our-banking-connections, #encryptify for 8 days (last worked on it: July 17, 2023)",
        "QSP/QFP, Commercial Card Feeds, #encryptify for 44 days (last worked on it: August 30, 2023)",
        "Hold Requests, Bancorp KYC, QSP/QUA/SOX items, #encryptify for 30 days (last worked on it: September 29, 2023)",
        "Hold Requests, Bancorp KYC for 1 day (last worked on it: September 29, 2023)",
        "W7: Hold Requests, W8: Bancorp KYC for 54 days (last worked on it: November 21, 2023)",
        "W7: Hold Requests, VIP: Card Program Manager (Bancorp KYC), VIP: E2E #encryptify for 99 days (last worked on it: February 27, 2024)",
        "W7: Hold Requests, VIP: E2E #encryptify for 77 days (last worked on it: May 14, 2024)",
        "#fast-apis: ScrapeCard performance, #wave-collect: hold request polish/bugs, #vip-split: E2E #encryptify for 7 days",
      ],
    },
    "francois@expensify.com": {
      topProjects: [
        "Sage Intacct Tax for 14 days (last worked on it: September 11, 2023)",
        "Improve performance of SavePolicy; Help with wave5 bug for 9 days (last worked on it: September 20, 2023)",
        "Wave6 Add support for auto-categorization in RequestMoney flow for 13 days (last worked on it: October 2, 2023)",
        "Update monthly settlement negative balances logic for fraud prevention for 42 days (last worked on it: November 13, 2023)",
        "wave9-collect-signups for 114 days (last worked on it: March 5, 2024)",
        "wave-collect &amp; qbd-connection-codat-2024-06-30 for 77 days",
      ],
    },
    "shawn@expensify.com": {
      topProjects: [
        "Improve our visual design system for 547 days (last worked on it: October 27, 2023)",
        "#ideal-nav for 83 days (last worked on it: January 17, 2024)",
        "#wave8 Testing &amp; Implementation &amp; #vip-travel for 35 days (last worked on it: February 21, 2024)",
        "Wave 8: Money Page for 14 days (last worked on it: March 5, 2024)",
        "#wave-collect for 77 days",
      ],
    },
    "ryan@expensify.com": {
      topProjects: [
        "Concierge Travel improvements for 50 days (last worked on it: March 28, 2023)",
        "Concierge travel, product placement, auditor RFP for 227 days (last worked on it: November 9, 2023)",
        "Bancorp reserve account requirements &amp; CIBC revolver. for 20 days (last worked on it: November 29, 2023)",
        "VIP-Travel, bancorp reserve account requirements, and CIBC revolver for 92 days (last worked on it: February 28, 2024)",
        "VIP-travel, facilitate an irrevocable LOC between CIBC and Bancorp to reduce restricted cash burden of the E card program for 83 days",
      ],
    },
    "carlos@expensify.com": {
      topProjects: [
        "SOX for 174 days (last worked on it: March 20, 2023)",
        "clea for 1 day (last worked on it: March 20, 2023)",
        'Add "Contact Sales" button to OldDot <a href="https://github.com/Expensify/Expensify/issues/265968" target="_blank" rel="noreferrer noopener">GH</a> for 42 days (last worked on it: July 3, 2023)',
        'Increase Paid Users via 50% discount in exchange for annual seats (<a href="https://docs.google.com/document/d/1elsvwDHELsI45uRz4MlLzC2m2TP24bD2GPtDjEcirLU/edit" target="_blank" rel="noreferrer noopener">Doc</a>) for 68 days (last worked on it: September 12, 2023)',
        "Wave 9: Violations for 113 days (last worked on it: January 2, 2024)",
        "Wave 6 (Violations) for 78 days (last worked on it: March 20, 2024)",
        "Wave Control Violations for 62 days",
      ],
    },
    "dcardoza@expensify.com": {
      topProjects: [
        "<br />Guides commission, VIP SDR-100 for 109 days (last worked on it: June 2, 2023)",
        "<br />Card rev share program, cobranded cards, SDRs for 138 days (last worked on it: October 17, 2023)",
        "<br />Card rev share, Approved for 79 days (last worked on it: January 4, 2024)",
        "vip bill pay invoicing for 138 days (last worked on it: May 21, 2024)",
        "#vip-bill-pay, #approved-team, selling travel for 1 day",
      ],
    },
    "florent@expensify.com": {
      topProjects: [
        "Achieve continuous WAQ in our backend performance for 206 days (last worked on it: September 14, 2023)",
        '"Use Cash Back to pay for the Expensify bill" for 61 days (last worked on it: November 13, 2023)',
        "#vip-fast-apis for 37 days (last worked on it: December 19, 2023)",
        "Wave 6 for 64 days (last worked on it: March 13, 2024)",
        "Wave Collect for 69 days",
      ],
    },
    "al@expensify.com": {
      topProjects: [
        "ExpensiConX Planning and Concierge Chores for 202 days (last worked on it: June 20, 2023)",
        "Concierge Chores and Onshore Planning Committee for 148 days (last worked on it: November 15, 2023)",
        "Concierge Chores for 112 days (last worked on it: March 5, 2024)",
        "Critical and High Bug Zero Assignments for #Wave-Control &amp; #vip-split for 28 days (last worked on it: April 1, 2024)",
        "Resource Updates for removing the Free Plan and Wave Collect for 23 days (last worked on it: April 24, 2024)",
        "Resource Manager for Wave Collect for 27 days",
      ],
    },
    "ionatan@expensify.com": {
      topProjects: [
        "available for 126 days (last worked on it: May 15, 2023)",
        "wave 3 for 9 days (last worked on it: May 23, 2023)",
        "auto merge reports in oldDot for 11 days (last worked on it: June 2, 2023)",
        "#ditch-pusher for 53 days (last worked on it: July 25, 2023)",
        "reliable updates for 85 days (last worked on it: January 5, 2024)",
        "wave6 for 98 days (last worked on it: April 23, 2024)",
        "VIP travel for 28 days",
      ],
    },
    "nkuoch@expensify.com": {
      topProjects: [
        "SRAM leaderboard metrics for 7 days (last worked on it: March 1, 2023)",
        "Brex verification letter for vbba for 12 days (last worked on it: March 13, 2023)",
        "OOO Sabbatical April 3 til June 4 for 64 days (last worked on it: June 5, 2023)",
        "Manual Requests (missing credit bank account flow) for 13 days (last worked on it: June 29, 2023)",
        "Provide Approved! firms card rev share for 7 days (last worked on it: July 6, 2023)",
        "Wave 3: Manual Requests for 5 days (last worked on it: July 10, 2023)",
        "Design Doc - Provide Approved! firms card rev share for 64 days (last worked on it: September 12, 2023)",
        "ECUK - TPL files for 13 days (last worked on it: September 27, 2023)",
        "Unlimited virtual cards for 6 days (last worked on it: October 2, 2023)",
        "Set custom limits for non-Plaid VBAs for 23 days (last worked on it: October 25, 2023)",
        "Clearing my plate from many assigned issues (bugs, chores ...) before taking a new top for 2 days (last worked on it: November 2, 2023)",
        "admin issued virtual cards for 14 days (last worked on it: November 16, 2023)",
        "Wave 6 VBA Refactor for 48 days (last worked on it: January 2, 2024)",
        "wave 6 for 107 days (last worked on it: April 18, 2024)",
        "Card program manager for 33 days",
      ],
    },
    "cortney@expensify.com": {
      topProjects: [
        "Autofy Contract Project for 186 days (last worked on it: April 14, 2023)",
        "project Sabbatical until 6/18/23 for 74 days (last worked on it: June 27, 2023)",
        "QBD documentation for Autofy for 78 days (last worked on it: September 12, 2023)",
        "Sync Manager Vendor Replacement &amp; Customer Advisory Committee Design for 147 days (last worked on it: February 5, 2024)",
        "QBD Sync Manager Replacement and Upgrade for 66 days (last worked on it: April 10, 2024)",
        "QBD Sync Manager Replacement/Upgrade &amp; VIP-VSB Resource Lead for 42 days",
      ],
    },
    "sheena@expensify.com": {
      topProjects: [
        "Chores, Team process Improvements, AMing for 155 days (last worked on it: June 15, 2023)",
        "Sabbatical June 16 until August 17 for 65 days (last worked on it: August 19, 2023)",
        "Ramp up from sabbatical for 5 days (last worked on it: August 23, 2023)",
        "Managed customer retention and engagement for 105 days (last worked on it: December 6, 2023)",
        "Card Management updates for 101 days (last worked on it: March 15, 2024)",
        "Concierge team improvements projects for 6 days (last worked on it: March 20, 2024)",
        "#wave-collect - <em>Improving HelpDot</em> for 62 days",
      ],
    },
    "rdonato@expensify.com": {
      topProjects: [
        "focus Approved! firms, Sage Intacct CRE, Sage Intacct VAT/GST, CalCPA partnership for 274 days (last worked on it: November 22, 2023)",
        "T100 #approvedteam, Global Sage Strategy for 125 days (last worked on it: March 25, 2024)",
        "focus Add Sage Intacct to New Expensify #wave-control, T100 Firms, Global Sage Strategy for 8 days (last worked on it: April 2, 2024)",
        "now for 2 days (last worked on it: April 3, 2024)",
        "focus Add Sage Intacct to New Expensify #wave-control, T100 Firms, Global Sage Strategy for 48 days",
      ],
    },
    "tgolen@expensify.com": {
      topProjects: [
        "Migrate Chronos to NewDot for 73 days (last worked on it: April 12, 2023)",
        "implement prettier in App for 21 days (last worked on it: May 3, 2023)",
        "Unifying pusher events for 83 days (last worked on it: July 24, 2023)",
        "wave 5 for 2 days (last worked on it: July 25, 2023)",
        "vip reliable updates for 28 days (last worked on it: August 22, 2023)",
        "wave 5 - distance receipts for 184 days (last worked on it: February 21, 2024)",
        "Wave 5: Deprecate Free for 13 days (last worked on it: March 4, 2024)",
        "#Wave-Collect for 78 days",
      ],
    },
    "zach@expensify.com": {
      topProjects: [
        "#VIP-SDRs, #churnedcustomers_engaged, #partnermanager, #n06_99-integratedsupport, best practice guides for 138 days (last worked on it: June 12, 2023)",
        "#sdr-program, #guides-team, #playbooks for 37 days (last worked on it: July 18, 2023)",
        "#sdr-program, #guides-team, #wave2, #playbooks, #churnedcustomers_engaged for 15 days (last worked on it: August 2, 2023)",
        "#wave9, #wave10 (monitoring/contributing in preparation for NetSuite support), #wave2, #sdr-program, #guides-team, #playbooks, #churnedcustomers_engaged for 1 day (last worked on it: August 2, 2023)",
        "#wave9, #wave10 (monitoring/contributing in preparation for NetSuite support), #wave2, #sdr-program, #guides-team, #playbooks, #churnedcustomers_engaged for 6 days (last worked on it: August 8, 2023)",
        "#wave2, #wave9, #wave10, #sdr-program, #guides-team, #playbooks, #churnedcustomers_engaged for 12 days (last worked on it: August 20, 2023)",
        "#wave9-expensify-submitters for 6 days (last worked on it: August 25, 2023)",
        "#wave9, #approved-team for 88 days (last worked on it: November 20, 2023)",
        "#wave8 #approved-team for 24 days (last worked on it: December 14, 2023)",
        "#wave8 #approved-team #vip-vsb for 83 days (last worked on it: March 5, 2024)",
        "#wave-control #vip-vsb #approved-team for 77 days",
      ],
    },
    "slafortune@expensify.com": {
      topProjects: [
        "ExpensiConX, new Expensify Card order/art approval for 148 days (last worked on it: June 13, 2023)",
        "Work to find a way to manage/replace/improve ZT for 37 days (last worked on it: July 20, 2023)",
        "Complete the AMEX migration for Biz and then Personal,  make ZingTree better for 120 days (last worked on it: November 16, 2023)",
        "Back from sabbatical, catching up and finding a project for 14 days (last worked on it: November 30, 2023)",
        "Budgeting internal resources - Chores - anything else I can help with for 61 days (last worked on it: January 29, 2024)",
        "HelpDoc Migration, Chores, available for 93 days (last worked on it: May 1, 2024)",
        "Chores, available for 20 days",
      ],
    },
    "corinne@expensify.com": {
      topProjects: [
        "Passwordless Rollout/Communication/ExpensiconX for 135 days (last worked on it: April 28, 2023)",
        "Passwordless Rollout/Communication - Outsource VBA Checks to SRs for 116 days (last worked on it: August 22, 2023)",
        "Migrate existing Expensify Card spend to our new program to classify I/C as revenue for 266 days",
      ],
    },
    "april@expensify.com": {
      topProjects: [
        "Amex connection transition, Bug Zero, everything Concierge for 212 days (last worked on it: June 30, 2023)",
        "Sabbatical for 67 days (last worked on it: September 5, 2023)",
        "Account Management, mentor, amex personal connection transition for 1 day (last worked on it: September 5, 2023)",
        "Bug Zero, Account Management, Amex personal connection transition for 49 days (last worked on it: October 24, 2023)",
        "Money 20/20 for 1 day (last worked on it: October 24, 2023)",
        "Money 20/20 for 7 days (last worked on it: October 31, 2023)",
        "Bug Zero, Account Management,Amexpersonal connection transition for 98 days (last worked on it: February 5, 2024)",
        "Help Site migration, Bug Zero, Account Manager for 2 days (last worked on it: February 6, 2024)",
        "HelpDot migration, Bug Zero, Account Management for 64 days (last worked on it: April 10, 2024)",
        "Amex FDX personal transition, Bug Zero, Account Management for 6 days (last worked on it: April 15, 2024)",
        "Xerocon planning, Amex FDX personal transition, Bug Zero, Account Management for 36 days",
      ],
    },
    "jenna@expensify.com": {
      topProjects: [
        "Accounting and audit for 825 days (last worked on it: March 4, 2024)",
        "Month end close, Bill Pay, Bank accounts, Cash back for 38 days (last worked on it: April 11, 2024)",
        "Finance chores, Bank accounts, Cash back, Bill Pay for 40 days",
      ],
    },
    "tyler@expensify.com": {
      topProjects: [
        "Bedrock improving Bedrock reliability and performance. for 851 days (last worked on it: June 30, 2023)",
        "wave-6-collect-submitters for 73 days (last worked on it: January 31, 2024)",
        "wave-5-free-submitters for 27 days (last worked on it: February 27, 2024)",
        "wave 6: policy migration for 30 days (last worked on it: March 27, 2024)",
        "bedrock standdown/shutdown speed for 55 days",
      ],
    },
    "lindsey@expensify.com": {
      topProjects: [
        "ExpensiCon III logistics, ExpensiCon III direct customer reach-outs, Improve SEO for 176 days (last worked on it: June 1, 2023)",
        "SEO, EYOC SNH &amp; Guide for 67 days (last worked on it: August 7, 2023)",
        "SEO &amp; EYOC for 38 days (last worked on it: September 14, 2023)",
        "SEO Phase 2 for 56 days (last worked on it: November 8, 2023)",
        "SEO and Email Marketing for 21 days (last worked on it: November 29, 2023)",
        "SEO optimization &amp; Wave 9 - Design NewDot onboarding (GBR + Harpoon + A/B test for 1 day (last worked on it: November 29, 2023)",
        "SEO optimization &amp; Wave 9 - Design NewDot onboarding (GBR + Harpoon + A/B test) for 100 days (last worked on it: March 7, 2024)",
        "Harpoon 2.0 project, vip-billpay marketing for 75 days",
      ],
    },
    "andrew@expensify.com": {
      topProjects: [
        "Add 'Support' in LHN of OldDot and OldApp for 65 days (last worked on it: June 21, 2023)",
        "Wave 4: Scan Receipts for 42 days (last worked on it: August 21, 2023)",
        '<a href="https://expensify.slack.com/archives/C04JD99283A" target="_blank" rel="noreferrer noopener">#wave1-managed-support</a> and <a href="https://expensify.slack.com/archives/C057F53HR40" target="_blank" rel="noreferrer noopener">#wave4-receipt-scanning-saastr-06sep2023</a> for 18 days (last worked on it: September 7, 2023)',
        "#wave1-managed-support for 42 days (last worked on it: October 19, 2023)",
        '<a href="https://expensify.slack.com/archives/C05G14REJRY" target="_blank" rel="noreferrer noopener">#wave10-hybrid-mobile</a> for 33 days (last worked on it: November 20, 2023)',
        '<a href="https://expensify.enterprise.slack.com/archives/C05DWUDHVK7" target="_blank" rel="noreferrer noopener">#wave5-free-submitters</a>: HybridApp Alpha  for 80 days (last worked on it: February 8, 2024)',
        '<a href="https://expensify.enterprise.slack.com/archives/C05DWUDHVK7" target="_blank" rel="noreferrer noopener">#wave5-deprecate-free</a> <a href="https://docs.google.com/document/d/1RfKAcH4fdlPKUzHDfKLjSZaMnsOBddHPp2cLq504QQY/edit?usp=drive_link" target="_blank" rel="noreferrer noopener">HybridApp: Production</a> for 27 days (last worked on it: March 5, 2024)',
        '<a href="https://expensify.enterprise.slack.com/archives/C036QM0SLJK" target="_blank" rel="noreferrer noopener">#wave-collect</a> <a href="https://docs.google.com/document/d/1RfKAcH4fdlPKUzHDfKLjSZaMnsOBddHPp2cLq504QQY/edit?usp=drive_link" target="_blank" rel="noreferrer noopener">HybridApp: Production</a> for 77 days',
      ],
    },
    "youssef@expensify.com": {
      topProjects: [
        "Separate QA chats in Comp for 27 days (last worked on it: March 21, 2023)",
        "finishing up manual settlement and proactive notification for settling early for 38 days (last worked on it: April 27, 2023)",
        "CreateTask command for 35 days (last worked on it: June 1, 2023)",
        "ClaimAnonymousAccount command for 36 days (last worked on it: July 6, 2023)",
        "Teachers Unite project for 45 days (last worked on it: August 20, 2023)",
        "Wave7 TeachersUnite/CollectApprovers for 25 days (last worked on it: September 13, 2023)",
        "Wave7 - Adding splits to scanned receipts for 107 days (last worked on it: December 28, 2023)",
        "Wave7 and #vip-split for 36 days (last worked on it: February 1, 2024)",
        "investigating CSV upload for large customers being too slow and eventually fails for 14 days (last worked on it: February 15, 2024)",
        "Wave6: Improving creating &amp; editing Instant Submit reports on NewDot for 20 days (last worked on it: March 5, 2024)",
        "wave-collect &amp; vip-split for 77 days",
      ],
    },
    "yuwen@expensify.com": {
      topProjects: [
        "N6.99 - Workspace Change History &amp; Automating Customer Assignments to Account Managers for 69 days (last worked on it: May 1, 2023)",
        "Wave 2 - SF Lounge WiFi Improvements for 12 days (last worked on it: May 12, 2023)",
        "[Wave1] Automating Account Management for 48 days (last worked on it: June 29, 2023)",
        "Wave 6: Categories for NewDot for 92 days (last worked on it: September 29, 2023)",
        "On Sabbatical! for 67 days (last worked on it: December 4, 2023)",
        "Wave 6: Collect Submitters for 87 days (last worked on it: February 29, 2024)",
        "#vip-vsb Moving tracked expenses logic (which supports wave6 stuff anyway) for 1 day (last worked on it: February 29, 2024)",
        "#vip-vsb Actionable Whispers (which supports wave6 stuff anyway) for 20 days (last worked on it: March 20, 2024)",
        "#wave-control: Pending Receipts for 57 days (last worked on it: May 15, 2024)",
        "#wave-control: NetSuite for 6 days",
      ],
    },
    "caitlin@expensify.com": {
      topProjects: [
        "ECIII Branding for 450 days (last worked on it: July 21, 2023)",
        "focus Available for 32 days (last worked on it: August 21, 2023)",
        "[Mobile Parity] - Report Fields for 50 days (last worked on it: October 9, 2023)",
        "- Wave 9 for 52 days (last worked on it: November 30, 2023)",
        "Report Fields + Help. Improvements Project for 91 days (last worked on it: February 28, 2024)",
        "HelpDot Improvements for 83 days",
      ],
    },
    "anu@expensify.com": {
      topProjects: [
        "working on a range of VIP projects for 57 days (last worked on it: March 30, 2023)",
        "working on N8 for 86 days (last worked on it: June 23, 2023)",
        "Supporting all things Sales and Marketing and polish for Global Reimbursement for 165 days (last worked on it: December 4, 2023)",
        "wave 9, ND onboarding for 107 days (last worked on it: March 20, 2024)",
        "wave collect, specifically workspace card feeds and stage 2 onboarding, passively working on bill pay for the payment flows for 62 days",
      ],
    },
    "tom@expensify.com": {
      topProjects: [
        "Free plan employees (N7), deprecate Guides in EW for 98 days (last worked on it: May 9, 2023)",
        "wave3 for EC3 for 93 days (last worked on it: August 10, 2023)",
        "Scan Receipt (wave4), Collect Approvers (wave 7) for 98 days (last worked on it: November 15, 2023)",
        "Ideal Nav (wave8), Collect Approvers (wave 7) for 119 days (last worked on it: March 12, 2024)",
        "#wave-collect for 71 days",
      ],
    },
    "sonia@expensify.com": {
      topProjects: [
        "Adjusting to the new coach teams for 186 days (last worked on it: March 27, 2023)",
        "Retention Whispers for 22 days (last worked on it: December 20, 2023)",
        "Retention Whispers for 27 days (last worked on it: January 15, 2024)",
        "NewDot classicRedirect under wave5 for 1 day (last worked on it: January 15, 2024)",
        "Retention Whispers, and NewDot classicRedirect wave5 for 66 days (last worked on it: March 20, 2024)",
        "Retention Whispers for 62 days",
      ],
    },
    "sasha@expensify.com": {
      topProjects: [
        "Account Management, Workspace Change History, N6.99 Milestone for 174 days (last worked on it: July 13, 2023)",
        "Wave 1 (NewDot performance/reliability for Guides), Account Management for 204 days (last worked on it: February 2, 2024)",
        "Accounting Integration Error Handling in NewDot for 9 days (last worked on it: February 14, 2024)",
        "QBO Export and Error Handling in NewDot for 98 days",
      ],
    },
    "wallroth@expensify.com": {
      topProjects: [
        "<br />Lounges / Allocations reboot / EXFY legal support for 491 days (last worked on it: April 17, 2023)",
        "SFO Lounge Open + Q1 close + Q1 Earnings Week for 50 days (last worked on it: June 6, 2023)",
        "Accounting Ops onboarding + Allocations &gt;&gt; Alteryx + Lounges + Offshore planning for 16 days (last worked on it: June 22, 2023)",
        "Accounting Ops onboarding + Allocations &gt;&gt; Alteryx + Global Reimbursements + Offshore planning for 195 days (last worked on it: January 2, 2024)",
        "Q4'23 Allocations + 2024 Allocations reboot for 66 days (last worked on it: March 8, 2024)",
        '<a href="mailto:april@expensify.com">april@expensify.com</a> for 1 day (last worked on it: April 1, 2024)',
        "Q1 allocations / GR to ND  <emoji></emoji> for 37 days (last worked on it: May 9, 2024)",
        "Q1 Earnings week for 8 days (last worked on it: May 16, 2024)",
        "lots of clean up before medical leave for 4 days",
      ],
    },
    "john@expensify.com": {
      topProjects: [
        "passwordless for 186 days (last worked on it: May 5, 2023)",
        "Wifi improvements for SFO/EC3 for 26 days (last worked on it: May 31, 2023)",
        "QSP for 45 days (last worked on it: July 14, 2023)",
        "passwordless cleanup/reliable updates for 25 days (last worked on it: August 8, 2023)",
        "Reliable updates reviews + fast apis for 36 days (last worked on it: September 13, 2023)",
        "unvalidated newdot sign in for 44 days (last worked on it: January 10, 2024)",
        "#vip-split/wave-collect for CloudFlare API schema changes and resource limits for 78 days",
      ],
    },
    "cole@expensify.com": {
      topProjects: [
        "Q/SOX/MTLs for 491 days (last worked on it: March 4, 2024)",
        "MTLs/vip-split/Sub Audits for 2 days (last worked on it: March 6, 2024)",
        "MTLs/Proxy Statement/Sub Audits for 64 days (last worked on it: May 8, 2024)",
        "NY MTL submission/Q1 10-Q for 13 days",
      ],
    },
    "scv@expensify.com": {
      topProjects: [
        "SOX Controls, refinining payroll processes for 441 days (last worked on it: August 1, 2023)",
        "Alteryx chore buildout, budgets, efiling irs for 163 days (last worked on it: January 11, 2024)",
        "EoY tasks, Greenshades, Alteryx buildout for 70 days (last worked on it: March 20, 2024)",
        "Alteryx Implementations, Split for 62 days",
      ],
    },
    "flavia@expensify.com": {
      topProjects: [
        "Account Management 101 ECX presentations, N8 - upgrade path, Sending #admin chats to Concierge, Metrics and Incentives for SRAMs, Automating customer assignments, AM manual customer assignments for 69 days (last worked on it: May 3, 2023)",
        "N8 - upgrade path, Sending #admin chats to Concierge, Metrics and Incentives for SRAMs, Automating customer assignments for 175 days (last worked on it: October 25, 2023)",
        "Retention Whispers, Expensify Travel, Coach Leaderboard, Updating policy creation to default to Collect rather than Control policies, Support decreasing subscriptions upon auto-renewal. for 121 days (last worked on it: February 22, 2024)",
        "PROJECT for 1 day (last worked on it: February 22, 2024)",
        "VIP-Travel for 1 day (last worked on it: February 22, 2024)",
        "VIP-Travel for 1 day (last worked on it: February 22, 2024)",
        "project VIP-Travel for 89 days",
      ],
    },
    "james@expensify.com": {
      topProjects: [
        "ExpensiCon III for 351 days (last worked on it: June 13, 2023)",
        "AVAILABLE for 60 days (last worked on it: August 11, 2023)",
        "Wave 5/Distance Requests activation at Small Business Expo LA for 46 days (last worked on it: September 26, 2023)",
        "AVAILABLE for 8 days (last worked on it: October 3, 2023)",
        "FPS SEO project for 8 days (last worked on it: October 10, 2023)",
        "Wave 6 referral project for 72 days (last worked on it: December 21, 2023)",
        "AVAILABLE for 1 day (last worked on it: December 21, 2023)",
        "Wave 8 - Collect Plan Admins QBO (Marketing) for 19 days (last worked on it: January 9, 2024)",
        "AVAILABLE for 28 days (last worked on it: February 6, 2024)",
        "Wave 9/Harpoon for 2 days (last worked on it: February 7, 2024)",
        "Wave9/Harpoon and #vip-vsb for 43 days (last worked on it: March 20, 2024)",
        "#vip-vsb for 8 days (last worked on it: March 27, 2024)",
        "#wave-control and #vip-vsb GTM for 55 days",
      ],
    },
    "lauren@expensify.com": {
      topProjects: [
        "ExpensiconX for 48 days (last worked on it: March 28, 2023)",
        "accountex for 48 days (last worked on it: May 15, 2023)",
        "Add basic Copilot support to NewDot for 52 days (last worked on it: July 14, 2023)",
        "core growth strategy report for 57 days (last worked on it: September 8, 2023)",
        "CSAT Expensify Happiness for 74 days (last worked on it: November 20, 2023)",
        "heads down on chores, customers and wherever else i can help for 12 days (last worked on it: December 1, 2023)",
        "EOY: Help Site migration image clean up for 34 days (last worked on it: January 4, 2024)",
        "wave 8 manual export for 1 day (last worked on it: January 8, 2024)",
        "list for 1 day (last worked on it: January 9, 2024)",
        "wave 8: export CSV + PDF for 1 day (last worked on it: January 9, 2024)",
        '<a href="mailto:lauren@expensify.com">lauren@expensify.com</a> for 1 day (last worked on it: January 9, 2024)',
        "wave 8: export CSV + PDF for 1 day for 3 days (last worked on it: January 12, 2024)",
        "wave 8: export functionality on newdot for 70 days (last worked on it: March 21, 2024)",
        "vip-split helpsite content for release 1 for 44 days (last worked on it: May 10, 2024)",
        "csv export for 12 days",
      ],
    },
    "mallen@expensify.com": {
      topProjects: [
        "BugZero, Contributor, C+.  ECX (design, contributors). PDX Food Cart Pod. (yes, my previous top was 258 days) for 84 days (last worked on it: April 4, 2023)",
        "PDX Food Cart Pod for 195 days (last worked on it: October 16, 2023)",
        "MBG + NewDot for leads for 46 days (last worked on it: November 30, 2023)",
        "Available for 34 days (last worked on it: January 2, 2024)",
        "NewDot Search Results Order for 14 days (last worked on it: January 16, 2024)",
        "Available for 7 days (last worked on it: January 23, 2024)",
        "- Contributor, BZ and bugs (hmu for testing needs!) for 43 days (last worked on it: March 6, 2024)",
        '<a href="https://github.com/Expensify/Expensify/issues/375392#top" target="_blank" rel="noreferrer noopener">Contributor Improvements</a> for 77 days',
      ],
    },
    "kevin@expensify.com": {
      topProjects: [
        "Newsletter 8 for 170 days (last worked on it: July 11, 2023)",
        "Wave 8 for 41 days (last worked on it: August 21, 2023)",
        "#wave8-free-collect-cardholders for 81 days (last worked on it: November 9, 2023)",
        "#wave8 admin card experience, #wave9 sign up research for 11 days (last worked on it: November 20, 2023)",
        "#wave8 admin card experience, #wave9 sign up research for 87 days (last worked on it: February 15, 2024)",
        "Wave 8 Collect plan changes for 35 days (last worked on it: March 20, 2024)",
        "#wave-collect: workspace feeds, plan changes for 62 days",
      ],
    },
    "rocio@expensify.com": {
      topProjects: [
        '"Passwordless + newDot bugs" for 50 days (last worked on it: March 10, 2023)',
        '"Chat Launch" for 118 days (last worked on it: July 6, 2023)',
        '"RSU Grants &amp; Tax Liability for Ex-California Employees" for 42 days (last worked on it: August 29, 2023)',
        '"Wave 7 - Dupe Detection" for 109 days (last worked on it: December 15, 2023)',
        '"Automate our payroll tax filing and payment process with Greenshades" for 14 days (last worked on it: December 29, 2023)',
        '"Wave 9: Violations" for 10 days (last worked on it: January 8, 2024)',
        '"Wave 6: Violations" for 46 days (last worked on it: February 23, 2024)',
        '"Wave 7: Duplicate Detection" for 27 days (last worked on it: March 20, 2024)',
        '"Wave Collect: Dupe Detection" for 62 days',
      ],
    },
    "dbondy@expensify.com": {
      topProjects: [
        "Manual Requests + Threads for 20 days (last worked on it: May 30, 2023)",
        'Global Reimbursements improvements - pass along forex fees to companys <a href="https://github.com/Expensify/Expensify/issues/287211" target="_blank" rel="noreferrer noopener">https://github.com/Expensify/Expensify/issues/287211</a> for 24 days (last worked on it: August 7, 2023)',
        "card-program-manager for 106 days (last worked on it: November 21, 2023)",
        "card-program-manager recarding for 98 days (last worked on it: February 26, 2024)",
        "wave8 for 18 days (last worked on it: March 14, 2024)",
        "wave-collect for 64 days (last worked on it: May 17, 2024)",
        "fireroom-2024-04-29 for 4 days",
      ],
    },
    "chirag@expensify.com": {
      topProjects: [
        "deprecating sequence numbers and WAQ issues for 108 days (last worked on it: March 16, 2023)",
        "ECX for 12 days (last worked on it: March 28, 2023)",
        "catching up on my K2, compose improvements, deprecate sequence cleanup, comment linking for 14 days (last worked on it: April 11, 2023)",
        "threads for 140 days (last worked on it: August 28, 2023)",
        "Concierge updates, sequence number cleanup, my K2 cleanup for 25 days (last worked on it: September 22, 2023)",
        "GTM and GA improvements for our site, sequence number cleanup, my K2 cleanup for 54 days (last worked on it: November 14, 2023)",
        "addressing Concierge bugs, sequence number cleanup for 15 days (last worked on it: November 29, 2023)",
        "Picking up any unassigned wave issues for 83 days (last worked on it: February 20, 2024)",
        "working on vip-vsb issues for 17 days (last worked on it: March 7, 2024)",
        "wave-collect issues, code improvements and chores for 6 days (last worked on it: March 13, 2024)",
        "wave-collect specifically Export Report V2: PDF, picking up random wave issues while i wait for parts of the previous, vip-split improvement issues and chores for 38 days (last worked on it: April 20, 2024)",
        "helping out other wave collect projects, writing part of the search v2 doc. for 11 days (last worked on it: April 30, 2024)",
        "onyxUpdates query improvements and threading for 21 days",
      ],
    },
    "shaelyn@expensify.com": {
      topProjects: [
        "Concierge chores, MTL annual exams and applications, Compliance Team restructure and chores for 342 days (last worked on it: December 21, 2023)",
        "EOY compliance task/prep (annual security training + annual vendor due diligence) for 1 day (last worked on it: December 21, 2023)",
        "EOY compliance task/prep (annual security training + annual vendor due diligence) for 47 days (last worked on it: February 5, 2024)",
        "Annual Vendor Due Diligence for 1 day (last worked on it: February 5, 2024)",
        "Wave 7 Marketing for 1 day (last worked on it: February 5, 2024)",
        "NY MTL Application for 1 day (last worked on it: February 5, 2024)",
        "MTL maintenance for 1 day (last worked on it: February 5, 2024)",
        "Unlimited Virtual Cards Landing page + Blog post for 1 day (last worked on it: February 6, 2024)",
        "Catch up on all things Wave 7 to prep for marketing for 2 days (last worked on it: February 8, 2024)",
        "Wave 7 Marketing ; #card-program-manager + unlimited virtual cards marketing ; PCI Audit [copilot curator] ; Bank of America Audit ; MTL (NY application submit) ; Anti-Money Laundering Program Independent Review audit for 15 days (last worked on it: March 19, 2024)",
        "#card-program-manager + unlimited virtual cards marketing ; PCI Audit [copilot curator] ; Bank of America Audit ; MTL (NY application submit) ; Anti-Money Laundering Program Independent Review audit for 17 days (last worked on it: April 4, 2024)",
        "#card-program-manager + unlimited virtual cards marketing ; Bank of America Audit ; MTL (NY application submit) ; Anti-Money Laundering Program Independent Review (audit)* for 19 days (last worked on it: April 22, 2024)",
        "Bank of America audit; #card-program-manager + unlimited virtual cards marketing prep; Annual vendor review process development/prep for 29 days",
      ],
    },
    "scott@expensify.com": {
      topProjects: [
        "Adding time reporting to Chronos in NewDot for 6 days (last worked on it: March 1, 2023)",
        "Comp review and time reporting for Chronos in NewDot for 28 days (last worked on it: March 29, 2023)",
        "Reporting logged hours for Chronos in NewDot for 191 days (last worked on it: October 5, 2023)",
        "Building Updated Receipts for #wave8-launchsuperapp-22oct23 for 58 days (last worked on it: December 2, 2023)",
        "Cleaning My Plate of the last of the Chronos time tracking migration work for 16 days (last worked on it: December 17, 2023)",
        "Finishing he Chronos Admin Report &amp; getting up to speed on #wave6 for 24 days (last worked on it: January 10, 2024)",
        "Chores &amp; preventing unread message emails for brand new accounts for #vip-vsb for 14 days (last worked on it: January 23, 2024)",
        "Chores &amp; ensure current money requests use datetime for #wave5-free-submitters for 14 days (last worked on it: February 6, 2024)",
        "Wave 6 and a dash of vip-split for 29 days (last worked on it: March 6, 2024)",
        "#wave-collect and some #vip-split (Group Chat) for 14 days (last worked on it: March 19, 2024)",
        "Ensuring 50 visible report actions are returned by <code>GetNewerActions</code> &amp; <code>GetOlderActions</code> #vip-vsb for 2 days (last worked on it: March 21, 2024)",
        "Building T&amp;C modal for #vip-travel for 2 days (last worked on it: March 22, 2024)",
        "Enhancing System Messages for #wave-collect for 1 day (last worked on it: March 22, 2024)",
        "Enhancing System Messages for #wave-collect (with a dash of #vip-travel, to taste) for 60 days",
      ],
    },
    "milja@expensify.com": {
      topProjects: [
        "Commission for SDRs, leaderboard for Guides for 143 days (last worked on it: July 12, 2023)",
        "Guides Experiment for 140 days (last worked on it: November 28, 2023)",
        "Guides Training for 48 days (last worked on it: January 15, 2024)",
        "Manual testing steps for wave 5 for 25 days (last worked on it: February 8, 2024)",
        "wave 6 testing suite for 20 days (last worked on it: February 27, 2024)",
        "Wave 8: PDF export for 23 days (last worked on it: March 21, 2024)",
        "Wave Collect: PDF for 57 days (last worked on it: May 16, 2024)",
        "Upgrading notifications experience for 5 days (last worked on it: May 21, 2024)",
        "list for 1 day (last worked on it: May 21, 2024)",
        "Upgrading notifications experience for 1 day",
      ],
    },
    "greg@expensify.com": {
      topProjects: [
        "VIP Managed 1000, Concierge for 350 days (last worked on it: June 13, 2023)",
        "Reduce cost of the BugZero program by 30% for 21 days (last worked on it: September 6, 2023)",
        "BugZero changes, Wave 6 for 24 days (last worked on it: November 9, 2023)",
        "#wave6-collect-submitters for 138 days (last worked on it: March 25, 2024)",
        "#wave-collect for 57 days",
      ],
    },
    "jschuster@expensify.com": {
      topProjects: [
        "Comp Review and ECX Presentations for 23 days (last worked on it: March 21, 2023)",
        "Account Management and Chores for 254 days (last worked on it: March 28, 2024)",
        "supporting Help Site changes for 54 days",
      ],
    },
    "jli@expensify.com": {
      topProjects: [
        "Chores and ramping up new AM mentee for 6 days (last worked on it: February 27, 2023)",
        "Chores and FR Vendor Migration (#fireroom-2023-02-27-manila-24-7intouch) for 8 days (last worked on it: March 7, 2023)",
        "Instant Teams Peer Coach SOW for 1 day (last worked on it: March 8, 2023)",
        'Autofy QBD fire - <a href="https://github.com/Expensify/Expensify/issues/265726" target="_blank" rel="noreferrer noopener">https://github.com/Expensify/Expensify/issues/265726</a> for 7 days (last worked on it: March 14, 2023)',
        "ABE Conference for 2 days (last worked on it: March 16, 2023)",
        "Concierge + Chores for 5 days (last worked on it: March 20, 2023)",
        "Autofy QBD Fire for 4 days (last worked on it: March 24, 2023)",
        "Autofy QBD Fire - #qbdtestingroom for 35 days (last worked on it: April 27, 2023)",
        "QBD Contract (updates in #qbdtestingroom) for 2 days (last worked on it: April 28, 2023)",
        "RightNetworks/Autofy QBD Contract (updates in #qbdtestingroom) for 33 days (last worked on it: May 31, 2023)",
        "Upgrade our QBD API to Autofy v2 #qbdtestingroom for 3 days (last worked on it: June 2, 2023)",
        "Upgrade our QBD API to Autofy v2 (updates in #qbdtestingroom) for 14 days (last worked on it: June 16, 2023)",
        "Set up a Slack reacji for our Concierge Support Vendors (see #whatsnext) for 3 days (last worked on it: June 18, 2023)",
        "Upgrade our QBD integration to Autofy v2 (#qbdtestingroom for updates) for 37 days (last worked on it: July 25, 2023)",
        "Instant Teams SoW Slack meeting for 1 day (last worked on it: July 25, 2023)",
        "Instant Teams SoW Slack meeting for 7 days (last worked on it: August 1, 2023)",
        "Future-proof the QBD Integration #qbdtestingroom for 10 days (last worked on it: August 11, 2023)",
        "Instant Teams SoW wrap up #instant-teams-sr-expensify-management for 4 days (last worked on it: August 14, 2023)",
        "Future-proof the QBD Integration #qbdtestingroom for 7 days (last worked on it: August 21, 2023)",
        "XeroCon 2023 (Sydney) for 7 days (last worked on it: August 28, 2023)",
        "Finalise Baby/Toddler stuff for Offshore for 8 days (last worked on it: September 4, 2023)",
        'Wind down SNAP-VAX campaign for <a href="https://e.org" target="_blank" rel="noreferrer noopener">E.org</a> for 3 days (last worked on it: September 7, 2023)',
        "Writing Expensify Help migration articles for 29 days (last worked on it: October 6, 2023)",
        "Chores and GH's for 6 days (last worked on it: October 11, 2023)",
        "Create/Update 2% Cashback Internal Resources for 6 days (last worked on it: October 17, 2023)",
        "Chores/GH's/finalising ExpensifyHelp resources for 9 days (last worked on it: October 26, 2023)",
        "Chores/Bug Zero for 50 days (last worked on it: December 15, 2023)",
        "#vip-qbd-codat Design Doc for 82 days (last worked on it: March 5, 2024)",
        "#whats-next posts for Vendor Management Team and Hand-Off Hiring/Tooling Tasks to People Team for 15 days (last worked on it: March 20, 2024)",
        "What's Next post to form a #vendor-management team for 24 days (last worked on it: April 12, 2024)",
        "Managed customer chats/calls for 18 days (last worked on it: April 29, 2024)",
        "Codat/QBD design doc comments/edits before implementation for 3 days (last worked on it: May 2, 2024)",
        "InTouch CX vendor contract analysis for 13 days (last worked on it: May 14, 2024)",
        "Finalise QBD (Codat connector) discussions / start on implementation process for 8 days",
      ],
    },
    "selliott@expensify.com": {
      topProjects: [
        "ECIII Keynotes, Approved Partner management, Legal and Concierge team chores for 105 days (last worked on it: April 4, 2023)",
        "project: Win Your Spot project wrap-up,\u00a0 Legal and Concierge team chores for 150 days (last worked on it: August 31, 2023)",
        "project #wave8-card-program-manager-free-collect-cardholders-22oct2023, SRAM Performance Improvement, for 75 days (last worked on it: November 14, 2023)",
        "project project #wave8-card-program-manager-free-collect-cardholders-22oct2023, Scale 1:many support for 58 days (last worked on it: January 10, 2024)",
        "#card-program-manager: Bancorp KYC files for 27 days (last worked on it: February 6, 2024)",
        "project #card-program-manager: Bancorp KYC files and #wave6-collect-submitters: Instant Submit for Collect resource updates for 1 day (last worked on it: February 6, 2024)",
        "project #card-program-manager: Bancorp KYC files and #wave6-collect-submitters: Instant Submit for Collect resource updates for 1 day (last worked on it: February 6, 2024)",
        "project #card-program-manager: Bancorp KYC files and #wave6-collect-submitters: Instant Submit for Collect resource updates for 9 days (last worked on it: February 14, 2024)",
        "project Concierge team reimagined, otherwise available for 27 days (last worked on it: March 14, 2024)",
        "Concierge team reimagined + vendor handoff project (WN), otherwise available for 36 days (last worked on it: April 18, 2024)",
        "project wave collect resource update, Concierge team reimagined for 23 days (last worked on it: May 10, 2024)",
        "concierge reimagined projects, otherwise available for 12 days",
      ],
    },
    "isa@expensify.com": {
      topProjects: [
        "Chase audit for 97 days (last worked on it: May 24, 2023)",
        "keep-our-banking-connections-4aug2023 for 124 days (last worked on it: September 25, 2023)",
        "Concierge and Compliance team for 178 days (last worked on it: March 20, 2024)",
        '<a href="https://deptagency.com" target="_blank" rel="noreferrer noopener">deptagency.com</a> and <a href="https://circana.com" target="_blank" rel="noreferrer noopener">circana.com</a> launch on April 1st; [card-program-manager-31dec24] Continuous Reconciliation Testing; Expensify Payments - 2024 Bancorp Due Diligence Request; Expensify Inc - 2024 Bancorp Initial Due Diligence Request for 1 day (last worked on it: March 21, 2024)',
        "DEPT Agency and Circana launch on April 1st; [card-program-manager-31dec24] Continuous Reconciliation Testing; Compliance team projects: Expensify Payments - 2024 Bancorp Due Diligence Request; Expensify Inc - 2024 Bancorp Initial Due Diligence Request. for 27 days (last worked on it: April 16, 2024)",
        'Compliance team project: Expensify Inc - 2024 Bancorp Initial Due Diligence Request. <a href="https://deptagency.com" target="_blank" rel="noreferrer noopener">deptagency.com</a> roll out: domain migrations, global reimbursement requests and issues. <a href="https://circana.com" target="_blank" rel="noreferrer noopener">circana.com</a>, migration issues, and calls. for 35 days',
      ],
    },
    "rachael@expensify.com": {
      topProjects: [
        "[Chores, customer-chat support, travel bookings, managing customers, learning accounting ops] for 23 days (last worked on it: March 4, 2023)",
        "Accounting Ops, Managed Customers, Customer Chat support, Chores for 256 days (last worked on it: November 14, 2023)",
        "Accounting Ops, Managed Customers, Customer Chat/SO support, Expensify Wallet phone support, Chores for 30 days (last worked on it: December 14, 2023)",
        "Wave 7 Curation, Accounting Ops, Managed Customers, Chores for 98 days (last worked on it: March 20, 2024)",
        "Concierge team chores, Accounting Ops chores, Managed Customers, Collect Opt-in Migration for 50 days (last worked on it: May 8, 2024)",
        "Concierge team chores, Accounting Ops chores, Managed Customers, Collect help docs for 13 days",
      ],
    },
    "nmendonca@expensify.com": {
      topProjects: [
        "EC3 Influencer strategy, managing customers, invite experience &amp; branding for EC3 for 152 days (last worked on it: July 25, 2023)",
        "Admin Issued Virtual cards, Budgets, Zendesk RFP for 21 days (last worked on it: August 15, 2023)",
        "Admin Issued Virtual cards, Budgets, Direct Customer Advisory Board for 149 days (last worked on it: January 10, 2024)",
        "Unlimited Virtual cards, Managed customer projects for 22 days (last worked on it: January 31, 2024)",
        "Maternity leave until Feb 2025 for 111 days",
      ],
    },
    "lschurr@expensify.com": {
      topProjects: [
        "Account management for 198 days (last worked on it: May 2, 2023)",
        "BZ, Concierge, Payroll, perfecting chores for 114 days (last worked on it: March 20, 2024)",
        "Chores (BZ, Concierge, Payroll), Concierge reimagined QA mini-project for 8 days (last worked on it: March 27, 2024)",
        "Customer projects, QA mini-project, Improve HelpDot for 17 days (last worked on it: April 12, 2024)",
        "Improve HelpDot, Concierge QA mini-project, Team chores (Concierge, BZ, Payroll) for 13 days (last worked on it: April 25, 2024)",
        "Improve HelpDot, Team chores (Concierge, BugZero, Payroll) for 23 days (last worked on it: May 17, 2024)",
        "Team chores (BZ, Concierge, Payroll) for 4 days",
      ],
    },
    "ntooker@expensify.com": {
      topProjects: [
        "WN Projects: Obtain new stock analysts for Expensify; Target Construction Companies w/Vertical Sales, Update Billing Code: store all client relationships in the purchases table for 79 days (last worked on it: March 30, 2023)",
        "Test Vertical Sales with Construction Companies; Lead Attendee Speaking Sessions for ExpensiCon; Obtain new stock analysts for Expensify for 62 days (last worked on it: May 30, 2023)",
        "Vertical Sales: consturction/realestate; ExpensiCon Goals Completion, Cannabis/Crypto Banks for 78 days (last worked on it: August 15, 2023)",
        "teachers unite, Cannabis ACH for 6 days (last worked on it: August 21, 2023)",
        "wave-7-teachers-united-approvers (marketing launch); enable cannabis ACH for Expensify Customers for 59 days (last worked on it: October 18, 2023)",
        "enable cannabis ACH for our customers; Available for 78 days (last worked on it: January 3, 2024)",
        "Available; vip-travel GTM for 48 days (last worked on it: February 19, 2024)",
        "earnings call week for 7 days (last worked on it: February 26, 2024)",
        "GTM for wave5 &amp; vip-travel for 22 days (last worked on it: March 18, 2024)",
        "vip-travel GTM lead; wave-collect GTM support for 64 days",
      ],
    },
    "ted@expensify.com": {
      topProjects: [
        "N7 Account Settings - 2FA for 15 days (last worked on it: March 7, 2023)",
        "QBC London for 6 days (last worked on it: March 13, 2023)",
        "ECX Presentations and 1:1s for 8 days (last worked on it: March 20, 2023)",
        "Automating AM Assignments for 60 days (last worked on it: May 19, 2023)",
        "EC3 for 7 days (last worked on it: May 25, 2023)",
        "Customer Projects (but available) for 69 days (last worked on it: August 1, 2023)",
        "Customer Projects and Parental Leave Prep for 17 days (last worked on it: August 18, 2023)",
        "Parental Leave for 45 days (last worked on it: October 2, 2023)",
        "ramping up from parental leave for 25 days (last worked on it: October 26, 2023)",
        "Account Management projects for 103 days (last worked on it: February 5, 2024)",
        "Mentor Reshuffle, Concierge Reassignments, AM Projects, newDot actionable system messages for 12 days (last worked on it: February 16, 2024)",
        "Mentor Reshuffle, AM Projects, newDot actionable system messages for 27 days (last worked on it: March 14, 2024)",
        "VIP Travel, Mentor Reshuffle, AM Projects for 13 days (last worked on it: March 26, 2024)",
        "VIP Travel, AM Projects for 57 days",
      ],
    },
    "mitch@expensify.com": {
      topProjects: [
        "Pullback Messaging Campaigns for 41 days (last worked on it: February 27, 2023)",
        "Expensify Chat ECX for 23 days (last worked on it: March 21, 2023)",
        "Threads / Unreads for 66 days (last worked on it: May 25, 2023)",
        "Hosting conferences on Expensify Chat for 19 days (last worked on it: June 13, 2023)",
        "Release User Created Rooms &amp; EYOC for 74 days (last worked on it: August 25, 2023)",
        "Release User Created Rooms &amp; Increase Inbox Driven Conversion for 20 days (last worked on it: September 13, 2023)",
        "Wave 8 - Finish Chat for 6 days (last worked on it: September 19, 2023)",
        "Wave 8 Launch Super App for 58 days (last worked on it: November 15, 2023)",
        "Wave 9 Collect Sign Ups for 113 days (last worked on it: March 6, 2024)",
        '#wave-collect  Release 2 "Payment card and subscription management in NewDot" for 48 days (last worked on it: April 23, 2024)',
        "#wave-collect: Billing, paywall, and free trials in NewDot for 29 days",
      ],
    },
    "ariel@expensify.com": {
      topProjects: [
        "implement new grant vesting process for 8 days (last worked on it: August 4, 2023)",
        "cap software process revamp for 33 days (last worked on it: September 5, 2023)",
        "move comp implementation into Alteryx for 7 days (last worked on it: September 11, 2023)",
        "move payroll tasks to Alteryx and outsource accounting ops for 39 days (last worked on it: October 19, 2023)",
        "update exfy forecast for 110 days (last worked on it: February 6, 2024)",
        "#vip-split for 100 days (last worked on it: May 16, 2024)",
      ],
    },
    "mlewis@expensify.com": {
      topProjects: [
        "wrap up Standardizing the SRAM mentor program + implement a rubric to improve SR GH quality for 44 days (last worked on it: March 17, 2023)",
        "Bank Collapse Fire - Cleanup for 29 days (last worked on it: April 14, 2023)",
        "Build Fraud Tool in Supportal / Move OldDot Resources to ExpensifyHelp / SR GH Standards for 50 days (last worked on it: June 2, 2023)",
        "Onboard to Payroll Team / Outsource Compliance VBA Checks to SRs / Move OldDot Resources to ExpensifyHelp for 40 days (last worked on it: July 12, 2023)",
        "Outsource Compliance VBA Checks to SRs / Move OldDot Resources to ExpensifyHelp for 44 days (last worked on it: August 25, 2023)",
        "Polish Compliance VBA Review process for Guides + SRs / Move OldDot Resources to ExpensifyHelp / improve Guide to AM handoff for 39 days (last worked on it: October 2, 2023)",
        "Move OldDot Resources from the Community to ExpensifyHelp + Improve Guide to AM handoff for 57 days (last worked on it: November 28, 2023)",
        "Complete Community migration to HelpDot + Wave9: Improve HelpDot for 113 days (last worked on it: March 20, 2024)",
        "Complete Community migration to HelpDot + Improve HelpDot as a part of Wave Collect / project manage Concierge...Reimagined TM for 14 days (last worked on it: April 3, 2024)",
        "Improve HelpDot as a part of Wave Collect &amp; Resource Management / Vendor Comms for Wave Collect for 6 days (last worked on it: April 8, 2024)",
        "Wave Collect: HelpDot design / content management + Resource Mgmt / Vendor Comms process improvement for 43 days",
      ],
    },
    "bfitz@expensify.com": {
      topProjects: [
        "bringing Onfido checks in-app via API for 253 days (last worked on it: July 12, 2023)",
        "improving SRAM GH issues for 22 days (last worked on it: August 2, 2023)",
        "SRAM Performance Improvement for 71 days (last worked on it: October 11, 2023)",
        "phone support for p2p for Cal MTL for 34 days (last worked on it: November 14, 2023)",
        "phone support for p2p for Cal MTL &amp; improving FR metrics for 112 days (last worked on it: March 5, 2024)",
        "Travel Cards (V1 #vip-travel) for 24 days (last worked on it: March 28, 2024)",
        "#vip-travel (Central Travel Billing &amp; Resource lead for V1) for 54 days",
      ],
    },
    "sofie@expensify.com": {
      topProjects: [
        "Concierge Travel reboot and #expensicon3 Partner Management for 204 days (last worked on it: April 19, 2023)",
        "#expensicon3 Partner Management &amp; Guides PM experiment for 92 days (last worked on it: July 20, 2023)",
        "Approved! Partner Management, Guides PM Experiment, Xerocon Global Planning, ANZ Partnership Renewal for 39 days (last worked on it: August 28, 2023)",
        "settling into the new Approved! team for 59 days (last worked on it: October 25, 2023)",
        "settling into the new Approved! team, EA!U Overhaul project and Approved! Blog &amp; Newsletter project for 7 days (last worked on it: October 31, 2023)",
        "settling into the new Approved! team and the EA!U Overhaul project for 71 days (last worked on it: January 10, 2024)",
        "finishing Recertification Quiz- available afterwards for 22 days (last worked on it: January 31, 2024)",
        "available for 12 days (last worked on it: February 12, 2024)",
        "EA! Playbook for 16 days (last worked on it: February 27, 2024)",
        "#vip-billpay BILL competitor analysis and EA! Playbook for 85 days",
      ],
    },
    "neil@expensify.com": {
      topProjects: [
        '<a href="https://github.com/Expensify/Expensify/issues/254913" target="_blank" rel="noreferrer noopener">Update the logic for "Account Review" automatic reach outs</a> for 14 days (last worked on it: March 10, 2023)',
        'Detailed Design of <a href="https://github.com/Expensify/Expensify/issues/186034" target="_blank" rel="noreferrer noopener">Free Plan: Distance Requests</a> for 131 days (last worked on it: July 18, 2023)',
        'Implementing <a href="https://github.com/Expensify/Expensify/issues/186034" target="_blank" rel="noreferrer noopener">Wave 5: Distance Requests (aka mileage)</a> for 33 days (last worked on it: August 20, 2023)',
        "#wave5-distance-maps-smallbizexpo-06sept2023 for 44 days (last worked on it: October 2, 2023)",
        "#wave5-distance-maps-freeplan for 38 days (last worked on it: November 8, 2023)",
        '<a href="https://github.com/Expensify/App/issues/23291" target="_blank" rel="noreferrer noopener">Enable P2P distance requests</a> for 13 days (last worked on it: November 20, 2023)',
        '#wave5-free-submitters - <a href="https://github.com/Expensify/App/issues/23291" target="_blank" rel="noreferrer noopener">Enable P2P distance requests</a> for 99 days (last worked on it: February 26, 2024)',
        '<a href="https://github.com/Expensify/App/issues/23291" target="_blank" rel="noreferrer noopener">Enable P2P distance requests</a> for 9 days (last worked on it: March 5, 2024)',
        '<a href="https://expensify.enterprise.slack.com/archives/C036QM0SLJK" target="_blank" rel="noreferrer noopener">#wave-collect</a> - <a href="https://github.com/Expensify/App/issues/23291" target="_blank" rel="noreferrer noopener">P2P Distance Requests</a> for 23 days (last worked on it: March 28, 2024)',
        '<a href="https://expensify.enterprise.slack.com/archives/C036QM0SLJK" target="_blank" rel="noreferrer noopener">#wave-collect</a> - <a href="https://github.com/Expensify/Expensify/issues/379913#top" target="_blank" rel="noreferrer noopener">CRITICAL: [Track Expense] [P2P Distance] Distance Requests</a> for 27 days (last worked on it: April 23, 2024)',
        '<a href="https://github.com/Expensify/Expensify/issues/384098" target="_blank" rel="noreferrer noopener">Migrate <code>TransactionUtils::pushTransactionUpdate</code> function to auth</a> for 15 days (last worked on it: May 8, 2024)',
        '#vip-vsb <a href="https://github.com/Expensify/App/issues/23291" target="_blank" rel="noreferrer noopener">[TRACKING] [MEDIUM] Enable P2P distance requests</a> for 13 days',
      ],
    },
    "nikkiwines@expensify.com": {
      topProjects: [
        "#passwordless for 93 days (last worked on it: May 11, 2023)",
        "mute notification preferences &amp; passwordless wrap up for 13 days (last worked on it: May 24, 2023)",
        "Available for 23 days (last worked on it: June 15, 2023)",
        "threads for 15 days (last worked on it: June 30, 2023)",
        "Available for 22 days (last worked on it: July 21, 2023)",
        "Sage Intacct VAT Tax for 53 days (last worked on it: September 12, 2023)",
        "SAML SSO on NewExpensify for 80 days (last worked on it: November 30, 2023)",
        "Helping out with Wave 9 and finishing up SAML for NewDot for 6 days (last worked on it: December 6, 2023)",
        "#wave6-collect-category-tag-submitters and #vip-vsb for 8 days (last worked on it: December 14, 2023)",
        "contributing to #wave6-collect-category-tag-submitters and #wave9-collect-signups for 8 days (last worked on it: December 21, 2023)",
        "contributing to #wave6-collect-category-tag-submitters and #wave5-free-submitters for 27 days (last worked on it: January 17, 2024)",
        "contributing to #vip-split for 2 days (last worked on it: January 18, 2024)",
        "contributing to #wave5-free-submitters for 48 days (last worked on it: March 6, 2024)",
        "#wave-collect for 28 days (last worked on it: April 3, 2024)",
        "#vip-split group summaries for 14 days (last worked on it: April 16, 2024)",
        "#vip-split group summaries (summer) and guided setup (spring) for 35 days",
      ],
    },
    "bstites@expensify.com": {
      topProjects: [
        "Emojis with Margelo for 103 days (last worked on it: April 23, 2023)",
        "Threads for 66 days (last worked on it: June 28, 2023)",
        "Custom Status for 69 days (last worked on it: September 4, 2023)",
        "Increase Inbox Conversion for 15 days (last worked on it: September 19, 2023)",
        "Wave 8: Hybrid Web for 8 days (last worked on it: September 26, 2023)",
        "list for 1 day (last worked on it: September 26, 2023)",
        "Wave 8: Hybrid Web for 39 days (last worked on it: November 3, 2023)",
        "#travel for 48 days (last worked on it: December 21, 2023)",
        "wave5 for 69 days (last worked on it: February 28, 2024)",
        "VIP-Travel for 84 days",
      ],
    },
    "marc@expensify.com": {
      topProjects: [
        "Available for 50 days (last worked on it: April 5, 2023)",
        "N7 / Manual Requests / Expensify Card Transactions &amp; E Receipts for 9 days (last worked on it: April 14, 2023)",
        "N7: Expensify Card Ongoing Use for 19 days (last worked on it: May 2, 2023)",
        "wave3 individual plan / wave5 expensify card for 3 days (last worked on it: May 4, 2023)",
        "wave4 individual plan / wave5 expensify card for 8 days (last worked on it: May 12, 2023)",
        "#vip-waq-everywhere / #wave4-individual-plan / #wave6-expensify-card for 11 days (last worked on it: May 22, 2023)",
        '<a href="mailto:marc@expensify.com">marc@expensify.com</a> for 1 day (last worked on it: May 22, 2023)',
        "#vip-waq-everywhere / #wave4-individual-plan for 37 days (last worked on it: June 28, 2023)",
        "vip-track-plan wave8-free-collect-cardholders for 22 days (last worked on it: July 20, 2023)",
        "Report Pagination / Expensify Card Settings for 33 days (last worked on it: August 21, 2023)",
        "#wave1-managed-support for 1 day (last worked on it: August 21, 2023)",
        "#wave1-managed-support #wave8-free-collect-cardholders for 22 days (last worked on it: September 11, 2023)",
        "wave1: Managed Support / Report pagination in NewDot for 88 days (last worked on it: December 8, 2023)",
        "vip: Group Chats for 7 days (last worked on it: December 14, 2023)",
        "wave9-collect-signups / vip-split-p2p-chat-groups for 93 days (last worked on it: March 16, 2024)",
        "#vip-split Group Chats for 67 days",
      ],
    },
    "mmoore@expensify.com": {
      topProjects: [
        "Account Mgmt and launching (then scaling) the referral program for 336 days (last worked on it: January 10, 2024)",
        "Account Mgmt and NewDot Performance for 132 days",
      ],
    },
    "c.dobrzyn@expensify.com": {
      topProjects: [
        "project QA_beta for Escalation0 for 211 days (last worked on it: March 24, 2023)",
        "project concierge_ops, concierge_management, concierge_development, QA, SR mentor for 270 days (last worked on it: December 18, 2023)",
        "wave 7 for 1 day (last worked on it: December 18, 2023)",
        "project: concierge_ops, concierge_management, concierge_development chores, help with Wave 7 for 3 days (last worked on it: December 21, 2023)",
        'project concierge_ops, concierge_management, concierge_development chores, help with Wave 7, <a href="https://expensify.slack.com/archives/C027726KHT4" target="_blank" rel="noreferrer noopener">#card-program-manager</a>project, Wave 6 bug reporting for 91 days (last worked on it: March 20, 2024)',
        'project <br /><br />Current top project: project concierge_ops, concierge_management, concierge_development chores, help with wave collect - migration plan <a href="https://expensify.slack.com/archives/C027726KHT4" target="_blank" rel="noreferrer noopener">#card-program-manager</a> project for 90 days for 1 day (last worked on it: March 20, 2024)',
        'project <br /><br />Current top project: project concierge_ops, concierge_management, concierge_development chores, help with wave collect - migration plan <a href="https://expensify.slack.com/archives/C027726KHT4" target="_blank" rel="noreferrer noopener">#card-program-manager</a> project for 49 days (last worked on it: May 8, 2024)',
        "<br /><br />project concierge_ops, concierge_management, concierge_development chores, help with wave collect (external/internal docs), BugZero team, Wave Collect - opt-in migration project, card-program-manager project, help with Bill Pay project (external/internal docs) for 14 days",
      ],
    },
    "alberto@expensify.com": {
      topProjects: [
        "ExpensiconX preparation for 13 days (last worked on it: February 27, 2023)",
        "Comp Review for 15 days (last worked on it: March 13, 2023)",
        "N7 Scan Receipts for 17 days (last worked on it: March 30, 2023)",
        "Preparing for Parental leave and working on Smaller issues for 50 days (last worked on it: May 19, 2023)",
        "AVAILABLE working on issues to help around for 27 days (last worked on it: June 14, 2023)",
        "Scan Receipts for 134 days (last worked on it: October 26, 2023)",
        '"Mark as Done" in NewDot for 34 days (last worked on it: November 29, 2023)',
        "Money Request Fixes for 9 days (last worked on it: December 7, 2023)",
        "Cancel Payment Command for 34 days (last worked on it: January 10, 2024)",
        "Wave 5 for 1 day (last worked on it: January 10, 2024)",
        "Wave 5. Currently: Pictures in email notifications for 19 days (last worked on it: January 29, 2024)",
        "Wave 8: Cancel Payments for 18 days (last worked on it: February 15, 2024)",
        "Wave 6: Quick Action Button for 70 days (last worked on it: April 25, 2024)",
        "Travel Central Billing for 5 days (last worked on it: April 30, 2024)",
        "Quick Action Button for 22 days",
      ],
    },
    "aimee@expensify.com": {
      topProjects: [
        "Payroll, Carrot Implementation, On/Offshore Project &amp; Planning for 139 days (last worked on it: February 27, 2023)",
        "On/Offshore Planning for 14 days (last worked on it: March 13, 2023)",
        "Onshore Planning for 22 days (last worked on it: April 4, 2023)",
        "SFO Lounge Opening Projects for 29 days (last worked on it: May 2, 2023)",
        "Tax Vault Review &amp; Payroll for 13 days (last worked on it: May 15, 2023)",
        "PERM Recruitment, Payroll, and Lounges for 37 days (last worked on it: June 20, 2023)",
        "STW Teacher's Unite Project, SFO Lounge Updates, Payroll, Hiring for 87 days (last worked on it: September 15, 2023)",
        "Alteryx, Payroll, Teacher's Unite, SFO Lounge, Hiring for 32 days (last worked on it: October 16, 2023)",
        "Payroll, SFO Loounge, Hiring for 1 day (last worked on it: October 16, 2023)",
        "Payroll, SFO Lounge, Hiring for 7 days (last worked on it: October 23, 2023)",
        "Sequoia Renewal, Payroll, SFO Lounge, Hiring for 2 days (last worked on it: October 24, 2023)",
        "Sequoia Renewal, Payroll, Hiring for 84 days (last worked on it: January 15, 2024)",
        "HelpDot Project, Payroll, Hiring for 58 days (last worked on it: March 13, 2024)",
        "On/Offboarding Vendors Project, HelpDot Project, Payroll, Hiring for 15 days (last worked on it: March 27, 2024)",
        "HelpDot Project, Payroll, Hiring for 55 days",
      ],
    },
    "victoria@expensify.com": {
      topProjects: [
        "Travel for EC3, Vendor management and travel for ECX, Account Management, SRAM leaderboard/metrics overhaul, SRAM reporting for 112 days (last worked on it: May 1, 2023)",
        "Travel for EC3, Travel (unexpected outcomes), Account Management for 88 days (last worked on it: July 28, 2023)",
        "- parental leave until April 2024 for 293 days (last worked on it: May 15, 2024)",
        "project: Chores and building a managed customer case load (working 60%) for 7 days",
      ],
    },
    "jkaufman@expensify.com": {
      topProjects: [
        "Expensify Card interchange as revenue, P2P for 568 days (last worked on it: June 6, 2023)",
        "project #vip-card-program-manager / #wave7-categories for 9 days (last worked on it: June 14, 2023)",
        "project #card-program-manager / #wave7-categories for 1 day (last worked on it: June 15, 2023)",
        "#card-program-manager / #wave6-basic-coding for 12 days (last worked on it: June 26, 2023)",
        "Card program manager / Wave 6 Categories, Tags for 45 days (last worked on it: August 9, 2023)",
        "card program manager external commit for 286 days",
      ],
    },
    "jpersaud@expensify.com": {
      topProjects: [
        "#keep-our-banking-connections for 118 days (last worked on it: May 4, 2023)",
        "Expensify Chat server migration for 33 days (last worked on it: June 5, 2023)",
        "Expensify Chat Server Migration / Build our own Commando Mode for the Expensify Card for 65 days (last worked on it: August 9, 2023)",
        "Build our own Commando Mode for the Expensify Card for 22 days (last worked on it: August 30, 2023)",
        "Migrate to GitHub Actions from Travis CI for 18 days (last worked on it: October 5, 2023)",
        "wave8 integrating newdot / olddot support, newdot performance via github actions for 28 days (last worked on it: November 1, 2023)",
        "Migrate to GitHub Actions / GMail bulk email sending review for 62 days (last worked on it: January 2, 2024)",
        "Email bulk sending requirements / #vip-split (rate limiting bad actors) for 22 days (last worked on it: January 24, 2024)",
        "Add inbound SMS replies to outbound SMS updates #vip-vsb / #vip-split for 42 days (last worked on it: March 5, 2024)",
        "Inbound SMS for #vip-vsb for 56 days (last worked on it: April 30, 2024)",
      ],
    },
    "zany@expensify.com": {
      topProjects: [
        "N7 Onboarding, N10 Curation, SRAM Mentoring improvements for 39 days (last worked on it: March 24, 2023)",
        "N7 Onboarding and curating N10 for 105 days (last worked on it: July 7, 2023)",
        "Onboarding for NewDot, curating wave10 QBO, BZ team overhaul for 46 days (last worked on it: August 21, 2023)",
        "#wave11-collect-qbo-admins and BugZero programme overhaul for 92 days (last worked on it: November 20, 2023)",
        "#wave8-collect-admins curator and QBO project lead for 93 days (last worked on it: February 20, 2024)",
        "*#wave8-collect-admins curato, QBO project lead, Xero project lead for 13 days (last worked on it: March 4, 2024)",
        "#wave8-collect-admins curator, QBO project lead, Xero project lead for 17 days (last worked on it: March 20, 2024)",
        "QBO project lead &amp; Xero project lead for 62 days",
      ],
    },
    "cheryl@expensify.com": {
      topProjects: [
        "Steering Committee &amp; EC3 for 109 days (last worked on it: May 26, 2023)",
        "SNH / SaaStr &amp; Chat / Money2020 &amp; Chat for 20 days (last worked on it: June 14, 2023)",
        "SDR Training | EYOC SaaStr &amp; Money2020 | for 55 days (last worked on it: August 8, 2023)",
        "SDR &amp; SRAM Training | EYOC SaaStr &amp; Money2020 for 13 days (last worked on it: August 21, 2023)",
        "SRAM Training | EYOC SaaStr &amp; Money2020 | Grow Managed Customers for 21 days (last worked on it: September 11, 2023)",
        "Money2020 for 50 days (last worked on it: October 30, 2023)",
        "Account Management | Retention &amp; Growth Projects for 30 days (last worked on it: November 28, 2023)",
        "#Wave8 Migration  | Account Management for 99 days (last worked on it: March 6, 2024)",
        "#Wave-Collect Marketing | Account Management | Vendor Management for 77 days",
      ],
    },
    "rafe@expensify.com": {
      topProjects: [
        "#expensify-aggregation (aka keep our banking connections) for 159 days (last worked on it: April 26, 2023)",
        "#keep-our-banking-connections and #wave3-individual-plan for 93 days (last worked on it: July 27, 2023)",
        "#keep-our-banking-connections for 104 days (last worked on it: November 7, 2023)",
        "banking connections project wrap-up and fast followers for 24 days (last worked on it: December 1, 2023)",
        "senior leaders application for 17 days (last worked on it: December 17, 2023)",
        "FairSelection 1.5 for 3 days (last worked on it: December 19, 2023)",
        "Auditbot cleanup and other EOY improvements for 4 days (last worked on it: December 22, 2023)",
        "p2p for 76 days (last worked on it: March 7, 2024)",
        "wallet transaction OFAC check for p2p + all things #vip-split for 44 days (last worked on it: April 19, 2024)",
        "#vip-split: wallet transaction OFAC check, uneven/delegated splits, group summaries for 19 days (last worked on it: May 8, 2024)",
        "support #fireroom-2024-04-29-siteslows for 6 days (last worked on it: May 14, 2024)",
        "#vip-split OFAC check for p2p, Quarterly Server Patching for 8 days",
      ],
    },
    "k.alexander@expensify.com": {
      topProjects: [
        "Chores/Leveraging Onfido API project for 388 days (last worked on it: December 6, 2023)",
        "Work on QBD external commit and truck along with my chores. for 167 days",
      ],
    },
    "dylan@expensify.com": {
      topProjects: [
        "focus: Bug0, ECX, Card Program Manager for 20 days for 77 days (last worked on it: May 4, 2023)",
        "focus: Card Program Manager, ECUK, Payroll Project, Bug0 for 5 days (last worked on it: May 8, 2023)",
        "focus: #wave5-receipts-distance, #vip-card-program-manager, ECUK for 56 days (last worked on it: July 3, 2023)",
        "focus: curating #wave4, working on NewDot&lt;&gt;OldDOt backwards compatibility, and KYC for #card-program-mananger for 1 day (last worked on it: July 3, 2023)",
        "focus: curating #wave4, working on NewDot&lt;&gt;OldDOt backwards compatibility, and KYC for #card-program-managert for 1 day (last worked on it: July 3, 2023)",
        "focus: curating #wave4, working on NewDot&lt;&gt;OldDOt backwards compatibility, and KYC for #card-program-manager for 17 days (last worked on it: July 19, 2023)",
        "focus: curating #saastr-receipt-scanning-06sep2023 for 64 days (last worked on it: September 20, 2023)",
        "focus: curating #wave4-receipt-scanning-freeplan (formerly #saastr-receipt-scanning-06sep2023) for 55 days (last worked on it: November 13, 2023)",
        "focus: curating #wave5-free-submitter (formerly #wave4-receipt-scanning-freeplan) for 52 days (last worked on it: January 3, 2024)",
        "curating #wave5-free-submitter for 1 day (last worked on it: January 3, 2024)",
        "focus: curating #wave5-free-submitter for 1 day (last worked on it: January 3, 2024)",
        "curating #wave5-free-submitter for 13 days (last worked on it: January 15, 2024)",
        "curating wave5 for 51 days (last worked on it: March 7, 2024)",
        "focus: Show Control Workspaces in NewDot for 6 days (last worked on it: March 12, 2024)",
        "focus: Actionable System Messages and Show Control Workspaces in ND for 8 days (last worked on it: March 20, 2024)",
        "focus: Enhance system messages for 48 days (last worked on it: May 7, 2024)",
        "focuses: enhance system messages, bring copilot to NewDot, group chat creation flow rework for 14 days",
      ],
    },
    "adele@expensify.com": {
      topProjects: [
        "Payroll, On/Offshore 2023, SOX for 133 days (last worked on it: April 11, 2023)",
        "offshore, payroll for 181 days (last worked on it: October 9, 2023)",
        "payroll chores and alteryx for 93 days (last worked on it: January 10, 2024)",
        "available for wave work (otherwise chores and clearing K2) for 76 days (last worked on it: March 26, 2024)",
        "Alteryx flows, payroll improvements for 57 days",
      ],
    },
    "jules@expensify.com": {
      topProjects: [
        "N7: Manual Requests Project for 55 days (last worked on it: April 4, 2023)",
        "Implement DataDogs card spend feature for 14 days (last worked on it: April 17, 2023)",
        "N7: Manual Requests for 30 days (last worked on it: May 17, 2023)",
        "Wave 3: Manual Requests -- prep for EC3 Deadline for 8 days (last worked on it: May 24, 2023)",
        "Wave 3: Manual Requests for 3 days (last worked on it: May 26, 2023)",
        "Notifications: Ensure message appears in app after notification is received for 26 days (last worked on it: June 21, 2023)",
        "VIP: Notification Reliability for 5 days (last worked on it: June 26, 2023)",
        "VIP: Notification Reliability for 15 days (last worked on it: July 14, 2023)",
        "leading fire: 07-12-23-google-play for 7 days (last worked on it: July 21, 2023)",
        "VIP: Notification Reliability for 7 days (last worked on it: July 27, 2023)",
        "Simultaneously install different app versions for 19 days (last worked on it: August 14, 2023)",
        "rebrandify OldDot mobile for 17 days (last worked on it: August 31, 2023)",
        "SOC Audit for 11 days (last worked on it: September 11, 2023)",
        "Visual Design System: OldDot Mobile for 66 days (last worked on it: November 15, 2023)",
        "Visual Design System: OldDot Mobile for 15 days (last worked on it: November 29, 2023)",
        "EOY - Visual Design System for 14 days (last worked on it: December 13, 2023)",
        "Prepare for final 2023 OldDot mobile release for 6 days (last worked on it: December 18, 2023)",
        "complete final 2023 issues for 18 days (last worked on it: January 5, 2024)",
        "ApprovedAccountant query for budgeting feature for 5 days (last worked on it: January 10, 2024)",
        "wave5 &amp; finishing up chores for 2 days (last worked on it: January 11, 2024)",
        "Wave 5 for 50 days (last worked on it: March 1, 2024)",
        "Wave 5 - Build Hybrid App for 20 days (last worked on it: March 20, 2024)",
        "wave-collect (Hybrid App) for 15 days (last worked on it: April 4, 2024)",
        "fireroom-2024-03-27-redirectissue for 28 days (last worked on it: May 1, 2024)",
        "'Prevent dupe notifications' project for 6 days (last worked on it: May 7, 2024)",
        "API performance improvement for 15 days",
      ],
    },
    "jack@expensify.com": {
      topProjects: [
        "Live Markdown Preview in NewDot for 27 days (last worked on it: February 28, 2023)",
        "Expensify Tasks &amp; NewDot Moderation Capabilities for 133 days (last worked on it: July 10, 2023)",
        "Task Cleanup / Wrapup for 31 days (last worked on it: August 9, 2023)",
        "Wave 5: Distance Requests for 12 days (last worked on it: August 21, 2023)",
        "Wave 5 - Distance Requests for 21 days (last worked on it: September 11, 2023)",
        "Revamp #approved-team for 37 days (last worked on it: October 18, 2023)",
        "money2020 for 16 days (last worked on it: November 2, 2023)",
        "Concierge Satisfaction Analysis for 20 days (last worked on it: November 22, 2023)",
        "# wave5-free-submitters for 1 day (last worked on it: November 22, 2023)",
        "#wave5-free-submitters for 49 days (last worked on it: January 9, 2024)",
        "Wave 6: Report Fields for 31 days (last worked on it: February 9, 2024)",
        "Track Expense for 1 day (last worked on it: February 9, 2024)",
        "#vip-vsb: Track Expense for 103 days",
      ],
    },
    "maxence@expensify.com": {
      topProjects: [
        "Global Reimbursements for 138 days (last worked on it: May 22, 2023)",
        "global reimbursements polish &amp; ECUK for 96 days (last worked on it: August 25, 2023)",
        "Card program manager &amp; ECUK for 130 days (last worked on it: January 2, 2024)",
        "wave 6 &amp; vip bill split for 1 day (last worked on it: January 3, 2024)",
        "wave 6 &amp; vip invoicing and bill pay for 71 days (last worked on it: March 13, 2024)",
        "wave collect &amp; VIP invoicing for 70 days",
      ],
    },
    "maria@expensify.com": {
      topProjects: [
        "WAQ fixes &amp; ECX presentation for 35 days (last worked on it: March 21, 2023)",
        "WAQ, bug fixes, chores and N6.99 but otherwise available! for 58 days (last worked on it: May 17, 2023)",
        "WAQ, bug fixes, chores and Outsource VBA Compliance Checks to SRAMs for 33 days (last worked on it: June 19, 2023)",
        "WAQ, bug fixes, chores and Wave 5: Scan Receipts for 8 days (last worked on it: June 26, 2023)",
        "Wave 5: Scan Receipts and Admin issued Virtual Cards for 50 days (last worked on it: August 15, 2023)",
        "Wave 4: Scan Receipts and Unlimited Virtual Cards for 56 days (last worked on it: October 9, 2023)",
        "Wave 4 polish and Wave 8: P2P Activation for 29 days (last worked on it: November 6, 2023)",
        "Polish for P2P activation &amp; Scan Receipts and Unlimited Virtual Cards for 24 days (last worked on it: November 29, 2023)",
        "Unlimited Virtual Cards for 12 days (last worked on it: December 11, 2023)",
        "Unlimited Virtual Cards and Card Program Manager for 75 days (last worked on it: February 23, 2024)",
        "Card Program Manager and OFAC Watchlist Check for 48 days (last worked on it: April 10, 2024)",
        "OFAC Watchlist Check for 41 days",
      ],
    },
    "phs@expensify.com": {
      topProjects: [
        "#keep-our-banking-connections for 372 days (last worked on it: November 28, 2023)",
        "Rundeck // Migration to BGP IPv4 Addresses in DCs for 18 days (last worked on it: December 15, 2023)",
        "PCI Audit Questions // Email Reputation Fixes // Migration to BGP IPv4 Addresses in DCs // ZFS for 25 days (last worked on it: January 8, 2024)",
        "QSP // Migration to BGP IPv4 Addresses in DCs // ZFS for 28 days (last worked on it: February 5, 2024)",
        "QSP // Partner IP Whitelisting // ZFS for 14 days (last worked on it: February 19, 2024)",
        "SOX Apps UAR [ITGC D3] for 32 days (last worked on it: March 22, 2024)",
        "VPN Certificate Reissue for 6 days (last worked on it: March 27, 2024)",
        "Stop sending SES emails to users who mark them as spam for 55 days",
      ],
    },
    "rory@expensify.com": {
      topProjects: [
        "Open! for 93 days (last worked on it: May 11, 2023)",
        "Populate messages from the message center for 102 days (last worked on it: August 21, 2023)",
        "Comment Linking, Report Pagination for 100 days (last worked on it: November 28, 2023)",
        "list for 43 days (last worked on it: January 10, 2024)",
        "open for 13 days (last worked on it: January 23, 2024)",
        "wave5 for 50 days (last worked on it: March 12, 2024)",
        "HybridApp ftw for 44 days (last worked on it: April 25, 2024)",
        "instacart DEW for May 1st for 7 days (last worked on it: May 1, 2024)",
        "NewDot CP to Prod for 20 days",
      ],
    },
    "alexbeaman@expensify.com": {
      topProjects: [
        "N7 Account Settings &amp; NewDot Image Improvements for 61 days (last worked on it: March 13, 2023)",
        "Finalizing N7 Account Settings &amp; ECX Hackathon for 31 days (last worked on it: April 13, 2023)",
        "Finalizing N7 Account Settings for 21 days (last worked on it: May 3, 2023)",
        "Finalizing N7 Account Settings &amp; Secure public contact info for 13 days (last worked on it: May 15, 2023)",
        "Secure public contact info for 73 days (last worked on it: July 27, 2023)",
        "wave1 reassign guides, wave4 saastr workspace per user, onboarding for 19 days (last worked on it: August 14, 2023)",
        "wave4 saastr workspace per user, onboarding for 7 days (last worked on it: August 21, 2023)",
        "wave4 saastr workspace per user &amp; wave5 sbe distance requests for 23 days (last worked on it: September 13, 2023)",
        "wave4 instant submit, wave4/5 activation cleanup for 5 days (last worked on it: September 18, 2023)",
        "wave4 instant submit detailed doc for 67 days (last worked on it: November 23, 2023)",
        "wave5 free submitters for 20 days (last worked on it: December 12, 2023)",
        "wave5 free submitters &amp; wave6 collect submitters - Instant Submit for 93 days (last worked on it: March 13, 2024)",
        "wave-collect (instant submit) for 34 days (last worked on it: April 15, 2024)",
        "wave-collect (instant submit), vip-vsb (p2p distance) for 29 days (last worked on it: May 14, 2024)",
        "wave-control (multi-approver flow), wip-vsb (p2p distance) for 8 days",
      ],
    },
    "monil@expensify.com": {
      topProjects: [
        "N7 - 2FA settings in newdot for 14 days (last worked on it: March 13, 2023)",
        "N7 - 2FA settings in newdot for 14 days and Unread Marker Indicators in NewDot for 44 days (last worked on it: April 26, 2023)",
        "wave1 Policy change logs and wave4 2FA settings for 27 days (last worked on it: May 22, 2023)",
        "#vip-waq-everywhere - Unread Markers for 113 days (last worked on it: September 12, 2023)",
        "#wave9 - Tax tracking in Newdot for 85 days (last worked on it: December 5, 2023)",
        "#wave8 - Tax tracking in Newdot for 24 days (last worked on it: December 28, 2023)",
        "#wave6 Tax tracking in newdot for 71 days (last worked on it: March 12, 2024)",
        "wave-collect - Track Tax for 71 days",
      ],
    },
    "kortney@expensify.com": {
      topProjects: [
        "January 2023 Close, EY audit and 10K for 29 days (last worked on it: March 1, 2023)",
        "January and February 2023 close, UK Stat Audit, SOX 2023 for 13 days (last worked on it: March 13, 2023)",
        "February 2023 close, UK Stat Audit, SOX 2023 for 14 days (last worked on it: March 27, 2023)",
        "February and March 2023 close, UK Stat Audit, SOX 2023* for 1 day (last worked on it: March 27, 2023)",
        "February and March 2023 close, UK Stat Audit, SOX 2023 for 21 days (last worked on it: April 17, 2023)",
        "March/Q1'23 close and 10Q filing, April Close, SOX 2023 for 29 days (last worked on it: May 15, 2023)",
        "April and May close, SOX 2023 for 28 days (last worked on it: June 12, 2023)",
        "May and June/Q2 close, SOX 2023 for 51 days (last worked on it: August 1, 2023)",
        "Q2 Filing, July close, SOX 2023 (Newdot focus) for 45 days (last worked on it: September 15, 2023)",
        "Monthly closes and Quarterly filings, SOX 2023 (Newdot) for 161 days (last worked on it: February 22, 2024)",
        "Monthly closes and Quarterly filings, SOX 2024 (PTP, Newdot) for 89 days",
      ],
    },
    "vit@expensify.com": {
      topProjects: [
        "App Navigation Reboot and Manual Requests for 120 days (last worked on it: May 5, 2023)",
        "Wave 4 NewDot Requests for 70 days (last worked on it: July 14, 2023)",
        "Helping closing wave 1 with pagination and wave 3 manual requests for 7 days (last worked on it: July 21, 2023)",
        "Wave 3 finish - EditTask and other issues to unblock later waves for 32 days (last worked on it: August 21, 2023)",
        "wave3-cplus for 15 days (last worked on it: September 4, 2023)",
        "wave 4 and wave 5 conferences activation for 15 days (last worked on it: September 19, 2023)",
        "Wave6 Drafts for 50 days (last worked on it: November 7, 2023)",
        "Wave 6 Core team - Drafts, Workspace Chats for Paid plans, Bottom Up flow for 88 days (last worked on it: February 2, 2024)",
        "Wave6 Core Team &amp; Wave8 Ideal Nav and helping with Simplified Collect for 40 days (last worked on it: March 13, 2024)",
        "Wave Collect - Simplified Collect, Ideal Nav follow ups and performance project management for 40 days (last worked on it: April 21, 2024)",
        "Wave Collect Release 1 - helping with onboarding, accounting integrations and with vip-split Split expense project for 23 days (last worked on it: May 14, 2024)",
        "working on NewDot - Quality report next steps migration and Wave Control Workspace Feeds for 6 days (last worked on it: May 19, 2024)",
        "NewDot Quality, Report next steps migration to Auth and Wave Control Workspace Feeds for 2 days",
      ],
    },
    "cmartins@expensify.com": {
      topProjects: [
        "Manual Requests for 209 days (last worked on it: August 15, 2023)",
        "wave4 - Scan receipts for 7 days (last worked on it: August 21, 2023)",
        "#wave4-receipt-scanning-saastr-06sep2023 for 67 days (last worked on it: October 26, 2023)",
        "wave8 - company card settings/transactions for 77 days (last worked on it: January 11, 2024)",
        "wave5-free-submitters for 15 days (last worked on it: January 25, 2024)",
        "wave8: simplified collect for 55 days (last worked on it: March 20, 2024)",
        "wave-collect: Simplified Collect for 15 days (last worked on it: April 3, 2024)",
        "wave-collect: Search v1 for 48 days",
      ],
    },
    "trent@expensify.com": {
      topProjects: [
        "10k, Audit for 38 days (last worked on it: March 13, 2023)",
        "Tech Accounting Projects, February Close for 9 days (last worked on it: March 21, 2023)",
        "Tech Accounting Projects, Q1 2023 Close Prep for 14 days (last worked on it: April 3, 2023)",
        "Q1 Finance Close for 17 days (last worked on it: April 19, 2023)",
        "10Q and Audit for 62 days (last worked on it: June 19, 2023)",
        "Tech Accounting Projects, Q2 Close Prep for 15 days (last worked on it: July 4, 2023)",
        "Q2 Close and 10Q for 36 days (last worked on it: August 9, 2023)",
        "July Close and Technical Accounting Issues for 6 days (last worked on it: August 15, 2023)",
        "Tech Accounting Issues and SOX for 15 days (last worked on it: August 30, 2023)",
        "Tech Accounting Issues and August Close for 71 days (last worked on it: November 8, 2023)",
        "10Q for Q3 2023 for 1 day (last worked on it: November 8, 2023)",
        "October Close, Interim Audit Testing, Tech Accounting Reviews for 12 days (last worked on it: November 20, 2023)",
        "Interim Audit, Tech Accounting Memos/Reviews for 9 days (last worked on it: November 28, 2023)",
        "November Close, Interim Audit, Tech Accounting Reviews for 21 days (last worked on it: December 19, 2023)",
        "Annual Close and Financial Statement Audit for 71 days (last worked on it: February 27, 2024)",
        "February Close, Q1 2024 Prep for 22 days (last worked on it: March 20, 2024)",
        "Q1 2024 Prep, Tech Actg Chores for 10 days (last worked on it: March 29, 2024)",
        "Q1 2024 Close, 10Q for 43 days (last worked on it: May 10, 2024)",
        "April Close, SOX Walkthroughs for 11 days",
      ],
    },
    "aldo@expensify.com": {
      topProjects: [
        "Retry debits / resend test transactions in-app for 101 days (last worked on it: May 12, 2023)",
        "Manual Requests - NewDot for 102 days (last worked on it: August 21, 2023)",
        "#wave1-managed-support for 116 days (last worked on it: December 14, 2023)",
        "Wave8 for 83 days (last worked on it: March 6, 2024)",
        "Wave8: QBO integration for NewDot for 15 days (last worked on it: March 20, 2024)",
        "QBO integration for NewDot for 62 days",
      ],
    },
    "nmetcalf@expensify.com": {
      topProjects: [
        "#keep-our-banking-connections for 67 days (last worked on it: March 30, 2023)",
        "QSP for 12 days (last worked on it: April 11, 2023)",
        "#keep-our-banking-connections for 64 days (last worked on it: June 14, 2023)",
        "QUA for 42 days (last worked on it: July 25, 2023)",
        "fixing small things, exploring Engineering for 106 days (last worked on it: November 8, 2023)",
        "PCI Evidence collection for 12 days (last worked on it: November 19, 2023)",
        "Quarterly User Audit for 57 days (last worked on it: January 15, 2024)",
        "#fireroom-2023-10-01-site-slowness for 32 days (last worked on it: February 16, 2024)",
        "Chores and infra QOL improvements for 22 days (last worked on it: March 8, 2024)",
        "RNO DC Cabinet Migration for 75 days",
      ],
    },
    "marco@expensify.com": {
      topProjects: [
        "Expensify Your Own Conference + ECX Hackathon for 41 days (last worked on it: March 21, 2023)",
        "Launch Expensify Chat (Google/Apple sign-in + Unauthenticated public rooms) for 101 days (last worked on it: June 29, 2023)",
        "Teachers Unite + Google/Apple sign-in for 54 days (last worked on it: August 21, 2023)",
        "#wave7-teachers-unite-collect-approvers for 142 days (last worked on it: January 10, 2024)",
        "#wave7-collect-approvers for 38 days (last worked on it: February 17, 2024)",
        "#wave7-collect-approvers + #wave9-collect-signups (Harpoon 2.0) for 19 days (last worked on it: March 6, 2024)",
        "#wave-collect for 76 days",
      ],
    },
    "dsilva@expensify.com": {
      topProjects: [
        "NewDot Search for 130 days (last worked on it: June 13, 2023)",
        "Vacation for 20 days (last worked on it: July 3, 2023)",
        "Catching up from OOO for 3 days (last worked on it: July 5, 2023)",
        "vip-fast-apis, ditch pusher and search for 8 days (last worked on it: July 12, 2023)",
        "Wave1 issues for 13 days (last worked on it: July 25, 2023)",
        "vip-reliable-updates, wave1 for 36 days (last worked on it: August 29, 2023)",
        "#vip-reliable-updates for 55 days (last worked on it: October 23, 2023)",
        "ramping up from OOO for 8 days (last worked on it: October 30, 2023)",
        "reliable updates for 32 days (last worked on it: November 30, 2023)",
        "#card-program-manager for 39 days (last worked on it: January 8, 2024)",
        "PCI assessment, wave 8 for 59 days (last worked on it: March 7, 2024)",
        "PCI assessment, #wave-collect, #vip-travel for 51 days (last worked on it: April 26, 2024)",
        "#vip-travel for 21 days (last worked on it: May 16, 2024)",
        "[since 2024-05-06] full focus on #fireroom-2024-04-29-siteslows for 5 days",
      ],
    },
    "haley@expensify.com": {
      topProjects: [
        "Year-End Close &amp; Audit for 66 days (last worked on it: March 10, 2023)",
        "project Q1 month-end closes &amp; EP Audit for 33 days (last worked on it: April 12, 2023)",
        "Q1 Month-End Close &amp; 10Q Filing for 29 days (last worked on it: May 10, 2023)",
        "Q2 Month-End Close for 1 day (last worked on it: May 10, 2023)",
        "project Q2 Month-End Closes for 7 days (last worked on it: May 17, 2023)",
        'Q2 Month-End Closes &amp; <a href="https://E.org" target="_blank" rel="noreferrer noopener">E.org</a> Audit for 48 days (last worked on it: July 3, 2023)',
        "project Q2 Close &amp; 10Q Filing for 23 days (last worked on it: July 25, 2023)",
        "Q2 Close &amp; 10Q Filing &amp; 401k Audit for 16 days (last worked on it: August 10, 2023)",
        "Q3 Month-End Closes &amp; 401k Audit for 21 days (last worked on it: August 31, 2023)",
        "Q3 Month-End Closes for 33 days (last worked on it: October 2, 2023)",
        "Q3 Quarter-End Close &amp; Financial Prep for 1 day (last worked on it: October 2, 2023)",
        "project op Q3 Quarter-End Close &amp; Financial Prep for 36 days (last worked on it: November 6, 2023)",
        "Q4 Quarter-End Close &amp; Financial Prep for 60 days (last worked on it: January 5, 2024)",
        "Year-end Close &amp; 10k Prep for 41 days (last worked on it: February 14, 2024)",
        '10k, <a href="https://e.org" target="_blank" rel="noreferrer noopener">E.org</a>, and EP FS and Q1 Close for 15 days (last worked on it: February 28, 2024)',
        '<a href="https://e.org" target="_blank" rel="noreferrer noopener">E.org</a> and EP Audit/FS and Q1 Close for 33 days (last worked on it: April 1, 2024)',
        "Q1 Close &amp; FS Prep for 44 days (last worked on it: May 14, 2024)",
        "Q2 Quarter-End Close &amp; Financial Prep &amp; 401k Audit Prep for 8 days",
      ],
    },
    "amy@expensify.com": {
      topProjects: [
        "ECX prep and Amex FDX transition for 30 days (last worked on it: March 23, 2023)",
        "Available for 20 days (last worked on it: April 12, 2023)",
        "N7 Manual Requests issue, Amex bugs, &amp; chores for 15 days (last worked on it: April 26, 2023)",
        "Launch Expensify Chat: #announce room and WAQ bugs for 76 days (last worked on it: July 10, 2023)",
        "ECUK for 22 days (last worked on it: August 11, 2023)",
        "Wave 6: Tags for 10 days (last worked on it: August 21, 2023)",
        "#wave6-collect-category-tag-submitters for 198 days (last worked on it: March 5, 2024)",
        "#wave-collect for 77 days",
      ],
    },
    "vivek@expensify.com": {
      topProjects: [
        "passwordless improvements, route #admin chats to concierge for 23 days (last worked on it: March 10, 2023)",
        "implement add PM assignment tool and add SRAM to #admin rooms for 48 days (last worked on it: May 30, 2023)",
        "private notes in newDot for 59 days (last worked on it: July 27, 2023)",
        "private notes implementation for 49 days (last worked on it: September 15, 2023)",
        "vip-approved-team project for 12 days (last worked on it: September 27, 2023)",
        "wave8-launchsuperapp-22oct23 for 65 days (last worked on it: November 30, 2023)",
        "retention whispers doc for 36 days (last worked on it: January 5, 2024)",
        "working on wave issues across waves for 32 days (last worked on it: February 12, 2024)",
        "Add Expense tracking to newDot and wave issues for 99 days (last worked on it: May 21, 2024)",
        "Unvalidated signups on newDot for 1 day",
      ],
    },
    "arosiclair@expensify.com": {
      topProjects: [
        "Rework Notifications for 167 days (last worked on it: April 5, 2023)",
        "Expensify Tasks for 6 days (last worked on it: April 11, 2023)",
        "Free Plan: Distance Requests for 36 days (last worked on it: May 18, 2023)",
        "vip-waq-everywhere for 28 days (last worked on it: June 14, 2023)",
        "vip-reliable-notifications for 59 days (last worked on it: August 11, 2023)",
        "Wave 5 Distance Requests for 10 days (last worked on it: August 21, 2023)",
        "#wave5-distance-maps-smallbizexpo-06sept2023 for 87 days (last worked on it: November 15, 2023)",
        "Notification Polish for 39 days (last worked on it: December 29, 2023)",
        "#vip-vsb for 82 days (last worked on it: March 19, 2024)",
        "#wave-collect for 63 days",
      ],
    },
    "jeremyhollm@expensify.com": {
      topProjects: [
        "January Close and YE financials/audit for 28 days (last worked on it: March 6, 2023)",
        "February close for 28 days (last worked on it: April 3, 2023)",
        "March Close and Q1 Filings for 25 days (last worked on it: April 27, 2023)",
        "Alteryx Automation! for 257 days (last worked on it: January 9, 2024)",
        "Year-end close and annual audit for 64 days (last worked on it: March 13, 2024)",
        "Alteryx workflows for 70 days",
      ],
    },
    "cj@expensify.com": {
      topProjects: [
        "Q4 month end close for 94 days (last worked on it: March 16, 2023)",
        "Q1 month end close for 50 days (last worked on it: May 4, 2023)",
        "project Q2 close for 100 days (last worked on it: August 16, 2023)",
        "Q3 close for 77 days (last worked on it: November 1, 2023)",
        "Q4 close for 119 days (last worked on it: February 27, 2024)",
        "Q1 close for 83 days (last worked on it: May 20, 2024)",
        "Q2 close for 1 day",
      ],
    },
    "srikar.parsi@expensify.com": {
      topProjects: [
        "WAQ for 180 days (last worked on it: May 16, 2023)",
        "Available for 1 day (last worked on it: May 16, 2023)",
        "project available for 1 day (last worked on it: May 16, 2023)",
        "focus available for 9 days (last worked on it: May 24, 2023)",
        "focus threads for 49 days (last worked on it: July 12, 2023)",
        "Available for 1 day (last worked on it: July 12, 2023)",
        "focus Available for 9 days (last worked on it: July 20, 2023)",
        "project Simplify Global Create Menu for 175 days (last worked on it: January 10, 2024)",
        "Wave 1 for 23 days (last worked on it: February 1, 2024)",
        "Wave 6 for 12 days (last worked on it: February 13, 2024)",
        "wave 6 for 36 days (last worked on it: March 20, 2024)",
        "Wave Collect (Archived and Closed Reports) for 62 days",
      ],
    },
    "shannon@expensify.com": {
      topProjects: [
        "december close and 10K for 56 days (last worked on it: March 6, 2023)",
        "jan and feb close for 8 days (last worked on it: March 13, 2023)",
        "ECX for 14 days (last worked on it: March 27, 2023)",
        "feb close/Q1 prep for 8 days (last worked on it: April 3, 2023)",
        "Q1 close for 40 days (last worked on it: May 12, 2023)",
        "April close for 22 days (last worked on it: June 2, 2023)",
        "may close for 21 days (last worked on it: June 23, 2023)",
        "Q2 close for 47 days (last worked on it: August 8, 2023)",
        "July close and SOX for 16 days (last worked on it: August 23, 2023)",
        "tax compliance/sox for 40 days (last worked on it: October 2, 2023)",
        "Q3 close for 43 days (last worked on it: November 13, 2023)",
        "october close and tax compliance for 15 days (last worked on it: November 27, 2023)",
        "tax compliance for 36 days (last worked on it: January 2, 2024)",
        "december close for 51 days (last worked on it: February 21, 2024)",
        "10k/earnings week for 13 days (last worked on it: March 5, 2024)",
        "feb close for 28 days (last worked on it: April 1, 2024)",
        "Q1 for 42 days (last worked on it: May 13, 2024)",
        "tax projects for 8 days",
      ],
    },
    "cristi@expensify.com": {
      topProjects: [
        "Account Settings in NewDot for 81 days (last worked on it: May 9, 2023)",
        "Manual Requests for 80 days (last worked on it: July 27, 2023)",
        "Wave4 - Receipt scanning Saastr for 9 days (last worked on it: August 4, 2023)",
        "Wave4, SmartScan at Saastr and Onboarding for 40 days (last worked on it: September 13, 2023)",
        "Wave4 - Instant Submit for 7 days (last worked on it: September 19, 2023)",
        "Wave4 - Backward Compatibility for 51 days (last worked on it: November 9, 2023)",
        "Wave5 - Instant Submit for 62 days (last worked on it: January 10, 2024)",
        "Wave5 - Release 1: Migrate 1% Free Plan Workspaces for 35 days (last worked on it: February 14, 2024)",
        "Wave6: Fix cross-compatibility bugs and promote NewDot on every NewDot-compatible expense report in OldDot for 1 day (last worked on it: February 14, 2024)",
        "Wave6 Release 1: Fix cross-compatibility bugs and promote NewDot on every NewDot-compatible expense report in OldDot for 22 days (last worked on it: March 6, 2024)",
        "Wave Collect - Release 1 for 20 days (last worked on it: March 26, 2024)",
        "[vip-billpay] Invoicing V1 - Release 1 for 56 days",
      ],
    },
    "jasper@expensify.com": {
      topProjects: [
        "EYOC for 104 days (last worked on it: May 24, 2023)",
        "Moderation for 23 days (last worked on it: June 15, 2023)",
        "User Created Room refresh for 23 days (last worked on it: July 7, 2023)",
        "User Created Rooms Release for 167 days (last worked on it: January 2, 2024)",
        "Wave 5 bugs for 4 days (last worked on it: January 8, 2024)",
        "Wave 5 Bugs + UCR beta deprecation + Actionable Mention Whispers for 10 days (last worked on it: January 17, 2024)",
        "Wave 5 Bugs + #vip-vsb for 13 days (last worked on it: January 29, 2024)",
        "Wave 6 + #vip-vsb for 39 days (last worked on it: March 7, 2024)",
        "#wave-collect and #vip-vsb for 41 days (last worked on it: April 17, 2024)",
        "#wave-collect spring release for 35 days",
      ],
    },
    "dgalerosen@expensify.com": {
      topProjects: [
        "SRAM Leaderboard for 48 days (last worked on it: March 13, 2023)",
        "attending ExpensiconX for 10 days (last worked on it: March 22, 2023)",
        "Moderation for 108 days (last worked on it: July 7, 2023)",
        "moderation and SRAM leaderboard wrap up for 26 days (last worked on it: August 7, 2023)",
        "Savings Calculator for 24 days (last worked on it: August 31, 2023)",
        "Savings Calculator and Budgets for 50 days (last worked on it: October 19, 2023)",
        "Budgets Detailed Design for 35 days (last worked on it: November 22, 2023)",
        "Budgets Implementation for 41 days (last worked on it: January 2, 2024)",
        "#wave-8 for 32 days (last worked on it: February 2, 2024)",
        "wave 8 QBO design doc for 70 days (last worked on it: April 12, 2024)",
        "catching up from parental leave for 33 days (last worked on it: May 14, 2024)",
        "help on fire cleanup and simplified collect polish for 7 days",
      ],
    },
    "georgia@expensify.com": {
      topProjects: [
        "Implementing New Branding for 55 days (last worked on it: March 14, 2023)",
        "ECX 2023 for 6 days (last worked on it: March 20, 2023)",
        "Implement New Branding \ud83d\udcab for 45 days (last worked on it: May 4, 2023)",
        "Threads FE for 28 days (last worked on it: May 31, 2023)",
        "Expensify Card Settings DD for 13 days (last worked on it: June 13, 2023)",
        "Implement New Visual Design System DD for 9 days (last worked on it: June 21, 2023)",
        "Light Mode Implementation for 9 days (last worked on it: July 14, 2023)",
        "Card Settings + Rebrandify for 28 days (last worked on it: August 15, 2023)",
        "Wave 8 - Expensify Card for 6 days (last worked on it: August 21, 2023)",
        "Wave 8 - Free Collect Cardholders for 80 days (last worked on it: November 9, 2023)",
        "Wave 5 - Free Submitters for 93 days (last worked on it: February 9, 2024)",
        "#wave6-collect-submitters for 25 days (last worked on it: March 5, 2024)",
        "Wave Collect for 57 days (last worked on it: May 1, 2024)",
        "Wave Collect for 20 days",
      ],
    },
    "mfillari@expensify.com": {
      topProjects: [
        "2022 10K for 24 days (last worked on it: March 3, 2023)",
        "february close and onboarding for 6 days (last worked on it: March 8, 2023)",
        "Feb Close / 10Q Rollforwards for 27 days (last worked on it: April 3, 2023)",
        "project march close/10Q for 29 days (last worked on it: May 2, 2023)",
        "focus April close and 10q for 9 days (last worked on it: May 10, 2023)",
        "project April close and Q2 readiness for 23 days (last worked on it: June 2, 2023)",
        "project Alteryx and Q2 readiness for 75 days (last worked on it: August 15, 2023)",
        "Alteryx Automation for 141 days (last worked on it: January 3, 2024)",
        "10-K filing for 59 days (last worked on it: March 1, 2024)",
        "Q1 prep for 82 days",
      ],
    },
    "danny@expensify.com": {
      topProjects: [
        "Onboarding and finding the groove for 16 days (last worked on it: September 5, 2023)",
        "Design Support for 170 days (last worked on it: February 22, 2024)",
        "Wave 9 &amp; Money Page for 5 days (last worked on it: February 27, 2024)",
        "Wave 9 &amp; VIP VSB for 2 days (last worked on it: February 28, 2024)",
        "Wave 9 and Wave 8 for 9 days (last worked on it: March 7, 2024)",
        "money/search, ideal nav v2 for 6 days (last worked on it: March 13, 2024)",
        "Money/Search for 2 days (last worked on it: March 14, 2024)",
        "#wave-control, money/insights/search for 4 days (last worked on it: March 18, 2024)",
        "Search/Money &amp; Splits for 1 day (last worked on it: March 20, 2024)",
        "Wave Collect &amp; VIP-Split for 8 days (last worked on it: March 27, 2024)",
        "Wave Collect for 52 days (last worked on it: May 17, 2024)",
        "#vip-split #wave-collect for 4 days",
      ],
    },
    "lucien@expensify.com": {
      topProjects: [
        "onboarding as a new engineer for 52 days (last worked on it: November 1, 2023)",
        "budgeting v1 for 44 days (last worked on it: December 15, 2023)",
        "finishing the budgeting feature and wave6 for 43 days (last worked on it: January 26, 2024)",
        "wave5 for 13 days (last worked on it: February 8, 2024)",
        "wave8 for 1 day (last worked on it: February 8, 2024)",
        "wave8: simplified collect for 42 days (last worked on it: March 20, 2024)",
        "Simplified Collect: Xero for 62 days",
      ],
    },
    "jon@expensify.com": {
      topProjects: [
        "Onboarding for 13 days (last worked on it: October 1, 2023)",
        "Pricing changes for 10 days (last worked on it: October 10, 2023)",
        "Figma variables for 7 days (last worked on it: October 17, 2023)",
        "Referral Program for 8 days (last worked on it: October 24, 2023)",
        "NewDot Navigation for 12 days (last worked on it: November 5, 2023)",
        "SF Bus Ad for 15 days (last worked on it: November 19, 2023)",
        "Wave 8: Card management for 9 days (last worked on it: November 28, 2023)",
        "Ideal Nav for 15 days (last worked on it: December 13, 2023)",
        "Wave 9: Onboarding for 98 days (last worked on it: March 20, 2024)",
        "#vip-split: Group Split for 16 days (last worked on it: April 5, 2024)",
        "#wave-collect: Onboarding Stage 2 videos for 39 days (last worked on it: May 13, 2024)",
        "#wave-control Workspace Rules for 6 days",
      ],
    },
    "rodrigo@expensify.com": {
      topProjects: [
        "onboarding for 62 days (last worked on it: December 5, 2023)",
        "wave 6 - move category onyx updates to Auth for 36 days (last worked on it: January 10, 2024)",
        "Wave 6 but focused on bugs across the waves/vips for 64 days (last worked on it: March 13, 2024)",
        "wave-collect but focused on bugs across the waves/vips for 45 days (last worked on it: April 26, 2024)",
        "mentions v2 - report mentions for 26 days",
      ],
    },
    "dan@expensify.com": {
      topProjects: [
        "Block signups from emails with TLDs from sanctioned countries (external commitment) for 74 days (last worked on it: May 16, 2024)",
        "FIRE! for 5 days",
      ],
    },
    "blimpich@expensify.com": {
      topProjects: [
        "onboarding for 1 day (last worked on it: November 2, 2023)",
        "onboarding for 29 days (last worked on it: December 1, 2023)",
        "increase retention by enabling Coaches to selectively auto-renew subscriptions for 13 days (last worked on it: December 13, 2023)",
        'improve performance of "open report" command for 15 days (last worked on it: December 27, 2023)',
        "engineering chores for 2 days (last worked on it: December 28, 2023)",
        "vip-vsb for 7 days (last worked on it: January 4, 2024)",
        "engineering chores for 2 days (last worked on it: January 5, 2024)",
        "vip-vsb for 1 day (last worked on it: January 6, 2024)",
        "vip-vsb for 32 days (last worked on it: February 6, 2024)",
        "wave-6 for 1 day (last worked on it: February 6, 2024)",
        "#wave6-collect-submitters for 3 days (last worked on it: February 8, 2024)",
        "#vip-split making our receipts meet CA requirements so we don't lose our MTL license for 96 days (last worked on it: May 14, 2024)",
        "#wave-collect for 7 days",
      ],
    },
    "carlosmiceli@expensify.com": {
      topProjects: ["Onboarding and Training for 49 days"],
    },
  },
  HOURS_WORKED: {
    "dbarrett@expensify.com": { hoursWorked: 2985.6700000000023 },
    "jason@expensify.com": { hoursWorked: 3225.39 },
    "puneet@expensify.com": { hoursWorked: 2447.47 },
    "gknight@expensify.com": { hoursWorked: 2324.9300000000003 },
    "daniel@expensify.com": { hoursWorked: 3053.210000000001 },
    "robert@expensify.com": { hoursWorked: 2365.1000000000004 },
    "francois@expensify.com": { hoursWorked: 2671.2499999999986 },
    "shawn@expensify.com": { hoursWorked: 2377.04 },
    "ryan@expensify.com": { hoursWorked: 2656.3600000000015 },
    "carlos@expensify.com": { hoursWorked: 2755.1100000000015 },
    "dcardoza@expensify.com": { hoursWorked: 2617.6700000000014 },
    "florent@expensify.com": { hoursWorked: 2097.3399999999997 },
    "al@expensify.com": { hoursWorked: 2573.650000000002 },
    "ionatan@expensify.com": { hoursWorked: 1808.4900000000002 },
    "nkuoch@expensify.com": { hoursWorked: 1834.64 },
    "cortney@expensify.com": { hoursWorked: 1809.9000000000008 },
    "sheena@expensify.com": { hoursWorked: 2031.029999999998 },
    "rdonato@expensify.com": { hoursWorked: 2676.79 },
    "tgolen@expensify.com": { hoursWorked: 2070.9899999999993 },
    "zach@expensify.com": { hoursWorked: 3209.609999999999 },
    "slafortune@expensify.com": { hoursWorked: 2170.3900000000003 },
    "corinne@expensify.com": { hoursWorked: 1853.7299999999996 },
    "april@expensify.com": { hoursWorked: 1707.4799999999996 },
    "jenna@expensify.com": { hoursWorked: 1548.5199999999998 },
    "tyler@expensify.com": { hoursWorked: 1711.4400000000003 },
    "lindsey@expensify.com": { hoursWorked: 2446.000000000002 },
    "andrew@expensify.com": { hoursWorked: 2340.849999999999 },
    "youssef@expensify.com": { hoursWorked: 2107.0199999999995 },
    "yuwen@expensify.com": { hoursWorked: 2151.3499999999995 },
    "caitlin@expensify.com": { hoursWorked: 2137.7999999999984 },
    "anu@expensify.com": { hoursWorked: 1632.18 },
    "tom@expensify.com": { hoursWorked: 3589.5600000000013 },
    "sonia@expensify.com": { hoursWorked: 2255.2099999999996 },
    "sasha@expensify.com": { hoursWorked: 2153.850000000001 },
    "wallroth@expensify.com": { hoursWorked: 2584.5700000000006 },
    "john@expensify.com": { hoursWorked: 2390.190000000002 },
    "cole@expensify.com": { hoursWorked: 2507.690000000001 },
    "scv@expensify.com": { hoursWorked: 2373.8499999999995 },
    "flavia@expensify.com": { hoursWorked: 2189.2899999999995 },
    "james@expensify.com": { hoursWorked: 2607.87 },
    "lauren@expensify.com": { hoursWorked: 2235.0599999999995 },
    "mallen@expensify.com": { hoursWorked: 2103.82 },
    "kevin@expensify.com": { hoursWorked: 2080.7000000000007 },
    "rocio@expensify.com": { hoursWorked: 2317.509999999998 },
    "dbondy@expensify.com": { hoursWorked: 2238.46 },
    "chirag@expensify.com": { hoursWorked: 1799.65 },
    "shaelyn@expensify.com": { hoursWorked: 2463.34 },
    "scott@expensify.com": { hoursWorked: 2582.7900000000004 },
    "milja@expensify.com": { hoursWorked: 2116.4300000000017 },
    "greg@expensify.com": { hoursWorked: 1866.4899999999996 },
    "jschuster@expensify.com": { hoursWorked: 2195.4499999999994 },
    "jli@expensify.com": { hoursWorked: 2230.2300000000014 },
    "selliott@expensify.com": { hoursWorked: 2673.63 },
    "isa@expensify.com": { hoursWorked: 2172.4800000000005 },
    "rachael@expensify.com": { hoursWorked: 2395.52 },
    "nmendonca@expensify.com": { hoursWorked: 1487.9099999999994 },
    "lschurr@expensify.com": { hoursWorked: 2278.7799999999997 },
    "ntooker@expensify.com": { hoursWorked: 1747.7000000000003 },
    "ted@expensify.com": { hoursWorked: 2167.2199999999993 },
    "mitch@expensify.com": { hoursWorked: 2683.6699999999996 },
    "ariel@expensify.com": { hoursWorked: 1654.0800000000006 },
    "mlewis@expensify.com": { hoursWorked: 2427.7499999999995 },
    "bfitz@expensify.com": { hoursWorked: 2424.2300000000005 },
    "sofie@expensify.com": { hoursWorked: 2352.5300000000007 },
    "neil@expensify.com": { hoursWorked: 2139.41 },
    "nikkiwines@expensify.com": { hoursWorked: 2464.1799999999976 },
    "bstites@expensify.com": { hoursWorked: 2298.76 },
    "marc@expensify.com": { hoursWorked: 2403.479999999998 },
    "mmoore@expensify.com": { hoursWorked: 2110.41 },
    "c.dobrzyn@expensify.com": { hoursWorked: 2193.5699999999993 },
    "alberto@expensify.com": { hoursWorked: 1928.2700000000002 },
    "aimee@expensify.com": { hoursWorked: 2441.5599999999986 },
    "victoria@expensify.com": { hoursWorked: 838.1499999999996 },
    "jkaufman@expensify.com": { hoursWorked: 2772.4100000000003 },
    "jpersaud@expensify.com": { hoursWorked: 2300.8199999999997 },
    "zany@expensify.com": { hoursWorked: 2415.2600000000016 },
    "cheryl@expensify.com": { hoursWorked: 2590.6599999999976 },
    "rafe@expensify.com": { hoursWorked: 2171.3499999999995 },
    "k.alexander@expensify.com": { hoursWorked: 2483.7099999999996 },
    "dylan@expensify.com": { hoursWorked: 2195.3800000000006 },
    "adele@expensify.com": { hoursWorked: 2327.8 },
    "jules@expensify.com": { hoursWorked: 2210.859999999999 },
    "jack@expensify.com": { hoursWorked: 2515.3399999999992 },
    "maxence@expensify.com": { hoursWorked: 2152.020000000001 },
    "maria@expensify.com": { hoursWorked: 2161.4099999999994 },
    "phs@expensify.com": { hoursWorked: 2208.910000000002 },
    "rory@expensify.com": { hoursWorked: 2299.1400000000017 },
    "alexbeaman@expensify.com": { hoursWorked: 2267.4199999999983 },
    "monil@expensify.com": { hoursWorked: 1997.9900000000007 },
    "kortney@expensify.com": { hoursWorked: 1407.79 },
    "vit@expensify.com": { hoursWorked: 2569.859999999999 },
    "cmartins@expensify.com": { hoursWorked: 2558.7599999999993 },
    "trent@expensify.com": { hoursWorked: 2765.139999999999 },
    "aldo@expensify.com": { hoursWorked: 2817.7899999999995 },
    "nmetcalf@expensify.com": { hoursWorked: 1522.1200000000008 },
    "marco@expensify.com": { hoursWorked: 2954.2699999999995 },
    "dsilva@expensify.com": { hoursWorked: 2404.0499999999984 },
    "haley@expensify.com": { hoursWorked: 2700.2799999999984 },
    "amy@expensify.com": { hoursWorked: 2379.9399999999987 },
    "vivek@expensify.com": { hoursWorked: 2149.499999999999 },
    "arosiclair@expensify.com": { hoursWorked: 2100.43 },
    "jeremyhollm@expensify.com": { hoursWorked: 2493.290000000001 },
    "cj@expensify.com": { hoursWorked: 2496.3999999999983 },
    "srikar.parsi@expensify.com": { hoursWorked: 925.4499999999998 },
    "shannon@expensify.com": { hoursWorked: 2300.5699999999997 },
    "cristi@expensify.com": { hoursWorked: 2412.2600000000016 },
    "jasper@expensify.com": { hoursWorked: 1829.9266669999995 },
    "dgalerosen@expensify.com": { hoursWorked: 1887.4499999999987 },
    "georgia@expensify.com": { hoursWorked: 2768.430000000003 },
    "mfillari@expensify.com": { hoursWorked: 2416.5400000000004 },
    "danny@expensify.com": { hoursWorked: 1266.7000000000007 },
    "lucien@expensify.com": { hoursWorked: 1351.3399999999995 },
    "jon@expensify.com": { hoursWorked: 1293.9499999999994 },
    "rodrigo@expensify.com": { hoursWorked: 1323.8999999999999 },
    "dan@expensify.com": { hoursWorked: 1069.93 },
    "blimpich@expensify.com": { hoursWorked: 999.3700000000001 },
    "carlosmiceli@expensify.com": { hoursWorked: 278.49999999999994 },
  },
  CONCIERGE_KPIS: {
    "dbarrett@expensify.com": {
      totalCustomerChatsReceived: {
        total: 804,
        manifesto: 349,
        firstResponder: 9,
        "": 10,
        researchTeam: 436,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "jason@expensify.com": {
      totalCustomerChatsReceived: {
        total: 144,
        manifesto: 1,
        researchTeam: 143,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "puneet@expensify.com": {
      totalCustomerChatsReceived: {
        total: 248,
        manifesto: 65,
        "": 3,
        firstResponder: 2,
        researchTeam: 178,
      },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 0 },
    },
    "gknight@expensify.com": {
      totalCustomerChatsReceived: {
        total: 2085,
        manifesto: 567,
        firstResponder: 85,
        firstResponderPlus: 17,
        supportal: 1,
        "": 7,
        researchTeam: 1407,
        operations: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "daniel@expensify.com": {
      totalCustomerChatsReceived: {
        total: 612,
        manifesto: 116,
        firstResponder: 7,
        operations: 1,
        "": 7,
        retention: 7,
        researchTeam: 474,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "robert@expensify.com": [],
    "francois@expensify.com": [],
    "shawn@expensify.com": {
      totalCustomerChatsReceived: { total: 4, manifesto: 4 },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 0 },
    },
    "ryan@expensify.com": {
      totalCustomerChatsReceived: {
        total: 36,
        manifesto: 13,
        researchTeam: 22,
        firstResponder: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "carlos@expensify.com": {
      totalCustomerChatsReceived: {
        total: 123,
        manifesto: 114,
        supportal: 6,
        "": 3,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "dcardoza@expensify.com": {
      totalCustomerChatsReceived: {
        total: 31,
        firstResponderPlus: 4,
        launch: 3,
        retention: 21,
        supportal: 1,
        firstResponder: 2,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "florent@expensify.com": [],
    "al@expensify.com": {
      totalCustomerChatsReceived: {
        total: 405,
        firstResponderPlus: 28,
        supportal: 277,
        c4b: 39,
        operations: 45,
        firstResponder: 11,
        retention: 5,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 3365 },
    },
    "ionatan@expensify.com": [],
    "nkuoch@expensify.com": [],
    "cortney@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1757,
        firstResponder: 39,
        firstResponderPlus: 700,
        supportal: 571,
        c4b: 255,
        retention: 14,
        operations: 147,
        "": 28,
        researchTeam: 3,
      },
      totalManagedCustomerChatsReceived: { total: 3 },
      totalQAChatsHandled: { total: 3089 },
    },
    "sheena@expensify.com": {
      totalCustomerChatsReceived: {
        total: 974,
        firstResponderPlus: 186,
        supportal: 485,
        firstResponder: 29,
        c4b: 182,
        operations: 79,
        retention: 7,
        "": 6,
      },
      totalManagedCustomerChatsReceived: { total: 8 },
      totalQAChatsHandled: { total: 3214 },
    },
    "rdonato@expensify.com": {
      totalCustomerChatsReceived: {
        total: 3,
        firstResponder: 1,
        supportal: 1,
        launch: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "tgolen@expensify.com": {
      totalCustomerChatsReceived: {
        total: 712,
        manifesto: 231,
        firstResponder: 24,
        "": 4,
        researchTeam: 449,
        supportal: 3,
        firstResponderPlus: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "zach@expensify.com": {
      totalCustomerChatsReceived: { total: 3, manifesto: 3 },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "slafortune@expensify.com": {
      totalCustomerChatsReceived: {
        total: 833,
        supportal: 133,
        c4b: 339,
        "": 5,
        retention: 11,
        operations: 30,
        firstResponderPlus: 295,
        firstResponder: 19,
        manifesto: 1,
      },
      totalManagedCustomerChatsReceived: { total: 2 },
      totalQAChatsHandled: { total: 2781 },
    },
    "corinne@expensify.com": {
      totalCustomerChatsReceived: {
        total: 890,
        supportal: 395,
        firstResponder: 69,
        retention: 8,
        operations: 82,
        firstResponderPlus: 247,
        c4b: 61,
        "": 5,
        manifesto: 23,
      },
      totalManagedCustomerChatsReceived: { total: 8 },
      totalQAChatsHandled: { total: 3067 },
    },
    "april@expensify.com": {
      totalCustomerChatsReceived: {
        total: 647,
        operations: 29,
        c4b: 40,
        supportal: 140,
        firstResponderPlus: 403,
        retention: 8,
        firstResponder: 20,
        "": 7,
      },
      totalManagedCustomerChatsReceived: { total: 7 },
      totalQAChatsHandled: { total: 2852 },
    },
    "jenna@expensify.com": [],
    "tyler@expensify.com": [],
    "lindsey@expensify.com": {
      totalCustomerChatsReceived: {
        total: 57,
        manifesto: 54,
        "": 2,
        operations: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "andrew@expensify.com": {
      totalCustomerChatsReceived: { total: 44, manifesto: 32, sfoLounge: 12 },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "youssef@expensify.com": [],
    "yuwen@expensify.com": {
      totalCustomerChatsReceived: { total: 32, manifesto: 30, launch: 2 },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "caitlin@expensify.com": [],
    "anu@expensify.com": {
      totalCustomerChatsReceived: {
        total: 104,
        firstResponder: 1,
        manifesto: 8,
        supportal: 3,
        researchTeam: 90,
        operations: 1,
        firstResponderPlus: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "tom@expensify.com": {
      totalCustomerChatsReceived: {
        total: 760,
        researchTeam: 730,
        firstResponder: 27,
        operations: 1,
        "": 2,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "sonia@expensify.com": {
      totalCustomerChatsReceived: {
        total: 447,
        supportal: 201,
        "": 2,
        firstResponderPlus: 103,
        c4b: 124,
        firstResponder: 10,
        borgo: 4,
        manifesto: 1,
        retention: 2,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 3868 },
    },
    "sasha@expensify.com": {
      totalCustomerChatsReceived: {
        total: 895,
        supportal: 565,
        firstResponder: 103,
        firstResponderPlus: 115,
        "": 11,
        operations: 2,
        c4b: 59,
        sfoLounge: 3,
        retention: 29,
        researchTeam: 1,
        manifesto: 7,
      },
      totalManagedCustomerChatsReceived: { total: 3 },
      totalQAChatsHandled: { total: 4080 },
    },
    "wallroth@expensify.com": [],
    "john@expensify.com": [],
    "cole@expensify.com": {
      totalCustomerChatsReceived: { total: 0 },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "scv@expensify.com": {
      totalCustomerChatsReceived: {
        total: 84,
        manifesto: 75,
        borgo: 2,
        c4b: 4,
        firstResponder: 2,
        "": 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "flavia@expensify.com": {
      totalCustomerChatsReceived: {
        total: 67,
        supportal: 40,
        c4b: 5,
        firstResponderPlus: 11,
        firstResponder: 7,
        retention: 4,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 2857 },
    },
    "james@expensify.com": {
      totalCustomerChatsReceived: {
        total: 209,
        firstResponder: 1,
        researchTeam: 205,
        "": 3,
      },
      totalManagedCustomerChatsReceived: {
        total: 0,
      },
      totalQAChatsHandled: {
        total: 0,
      },
    },
    "lauren@expensify.com": {
      totalCustomerChatsReceived: {
        total: 2705,
        firstResponder: 63,
        supportal: 723,
        retention: 6,
        manifesto: 1105,
        firstResponderPlus: 154,
        "": 26,
        c4b: 201,
        operations: 93,
        researchTeam: 334,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 4017 },
    },
    "mallen@expensify.com": [],
    "kevin@expensify.com": {
      totalCustomerChatsReceived: {
        total: 78,
        launch: 2,
        researchTeam: 72,
        firstResponder: 2,
        manifesto: 1,
        "": 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "rocio@expensify.com": [],
    "dbondy@expensify.com": [],
    "chirag@expensify.com": [],
    "shaelyn@expensify.com": {
      totalCustomerChatsReceived: {
        total: 415,
        supportal: 139,
        firstResponderPlus: 71,
        c4b: 113,
        operations: 51,
        firstResponder: 22,
        retention: 10,
        "": 6,
        manifesto: 3,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 1961 },
    },
    "scott@expensify.com": [],
    "milja@expensify.com": {
      totalCustomerChatsReceived: {
        total: 31,
        launch: 2,
        firstResponderPlus: 2,
        firstResponder: 2,
        researchTeam: 25,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "greg@expensify.com": {
      totalCustomerChatsReceived: {
        total: 490,
        firstResponder: 23,
        c4b: 13,
        supportal: 394,
        firstResponderPlus: 32,
        operations: 26,
        "": 1,
        retention: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 2826 },
    },
    "jschuster@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1226,
        supportal: 474,
        c4b: 89,
        firstResponderPlus: 551,
        firstResponder: 45,
        operations: 38,
        "": 14,
        retention: 14,
        borgo: 1,
      },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 4006 },
    },
    "jli@expensify.com": {
      totalCustomerChatsReceived: {
        total: 682,
        supportal: 344,
        operations: 97,
        firstResponderPlus: 54,
        c4b: 117,
        firstResponder: 55,
        retention: 10,
        "": 5,
      },
      totalManagedCustomerChatsReceived: { total: 4 },
      totalQAChatsHandled: { total: 3716 },
    },
    "selliott@expensify.com": {
      totalCustomerChatsReceived: {
        total: 386,
        supportal: 219,
        firstResponder: 18,
        "": 3,
        firstResponderPlus: 80,
        operations: 11,
        c4b: 38,
        retention: 17,
      },
      totalManagedCustomerChatsReceived: { total: 3 },
      totalQAChatsHandled: { total: 3508 },
    },
    "isa@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1195,
        supportal: 547,
        c4b: 254,
        operations: 145,
        firstResponderPlus: 195,
        retention: 9,
        firstResponder: 36,
        "": 6,
        borgo: 2,
        sfoLounge: 1,
      },
      totalManagedCustomerChatsReceived: { total: 3 },
      totalQAChatsHandled: { total: 3362 },
    },
    "rachael@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1287,
        supportal: 669,
        firstResponderPlus: 200,
        retention: 53,
        c4b: 184,
        firstResponder: 90,
        operations: 84,
        "": 6,
        sfoLounge: 1,
      },
      totalManagedCustomerChatsReceived: { total: 28 },
      totalQAChatsHandled: { total: 3621 },
    },
    "nmendonca@expensify.com": {
      totalCustomerChatsReceived: {
        total: 410,
        supportal: 119,
        operations: 7,
        firstResponder: 40,
        firstResponderPlus: 216,
        c4b: 23,
        retention: 3,
        "": 2,
      },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 3027 },
    },
    "lschurr@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1493,
        supportal: 1133,
        firstResponder: 42,
        c4b: 99,
        firstResponderPlus: 132,
        operations: 57,
        retention: 22,
        "": 7,
        manifesto: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 3453 },
    },
    "ntooker@expensify.com": [],
    "ted@expensify.com": {
      totalCustomerChatsReceived: {
        total: 752,
        supportal: 443,
        firstResponderPlus: 202,
        retention: 9,
        firstResponder: 26,
        c4b: 51,
        operations: 11,
        manifesto: 4,
        "": 5,
        researchTeam: 1,
      },
      totalManagedCustomerChatsReceived: { total: 13 },
      totalQAChatsHandled: { total: 2861 },
    },
    "mitch@expensify.com": {
      totalCustomerChatsReceived: {
        total: 144,
        firstResponderPlus: 4,
        launch: 2,
        supportal: 3,
        firstResponder: 7,
        retention: 13,
        researchTeam: 114,
        "": 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "ariel@expensify.com": [],
    "mlewis@expensify.com": {
      totalCustomerChatsReceived: {
        total: 658,
        supportal: 255,
        firstResponderPlus: 281,
        firstResponder: 49,
        retention: 4,
        operations: 17,
        "": 6,
        c4b: 45,
        borgo: 1,
      },
      totalManagedCustomerChatsReceived: { total: 6 },
      totalQAChatsHandled: { total: 3137 },
    },
    "bfitz@expensify.com": {
      totalCustomerChatsReceived: {
        total: 711,
        supportal: 428,
        c4b: 128,
        firstResponderPlus: 69,
        "": 5,
        operations: 45,
        firstResponder: 13,
        retention: 22,
        manifesto: 1,
      },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 3540 },
    },
    "sofie@expensify.com": {
      totalCustomerChatsReceived: {
        total: 525,
        retention: 8,
        researchTeam: 508,
        firstResponder: 8,
        "": 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "neil@expensify.com": [],
    "nikkiwines@expensify.com": [],
    "bstites@expensify.com": [],
    "marc@expensify.com": [],
    "mmoore@expensify.com": {
      totalCustomerChatsReceived: {
        total: 248,
        supportal: 21,
        c4b: 12,
        firstResponder: 10,
        firstResponderPlus: 109,
        retention: 1,
        operations: 1,
        researchTeam: 92,
        "": 2,
      },
      totalManagedCustomerChatsReceived: { total: 1 },
      totalQAChatsHandled: { total: 3323 },
    },
    "c.dobrzyn@expensify.com": {
      totalCustomerChatsReceived: {
        total: 2252,
        firstResponder: 172,
        firstResponderPlus: 822,
        operations: 129,
        c4b: 265,
        supportal: 764,
        retention: 7,
        "": 24,
        nycLounge: 6,
        borgo: 9,
        manifesto: 54,
      },
      totalManagedCustomerChatsReceived: { total: 73 },
      totalQAChatsHandled: { total: 3657 },
    },
    "alberto@expensify.com": [],
    "aimee@expensify.com": [],
    "victoria@expensify.com": {
      totalCustomerChatsReceived: {
        total: 338,
        supportal: 144,
        firstResponderPlus: 82,
        firstResponder: 20,
        c4b: 81,
        "": 3,
        operations: 5,
        retention: 3,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 1638 },
    },
    "jkaufman@expensify.com": {
      totalCustomerChatsReceived: {
        total: 1882,
        supportal: 828,
        firstResponderPlus: 708,
        firstResponder: 74,
        operations: 99,
        retention: 38,
        c4b: 125,
        borgo: 2,
        "": 5,
        manifesto: 3,
      },
      totalManagedCustomerChatsReceived: { total: 8 },
      totalQAChatsHandled: { total: 4261 },
    },
    "jpersaud@expensify.com": [],
    "zany@expensify.com": {
      totalCustomerChatsReceived: {
        total: 533,
        firstResponderPlus: 240,
        operations: 66,
        firstResponder: 15,
        supportal: 106,
        c4b: 95,
        "": 8,
        borgo: 3,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 3433 },
    },
    "cheryl@expensify.com": {
      totalCustomerChatsReceived: {
        total: 966,
        supportal: 576,
        firstResponderPlus: 133,
        operations: 68,
        c4b: 88,
        firstResponder: 50,
        "": 13,
        retention: 31,
        launch: 7,
      },
      totalManagedCustomerChatsReceived: { total: 12 },
      totalQAChatsHandled: { total: 3295 },
    },
    "rafe@expensify.com": {
      totalCustomerChatsReceived: {
        total: 9,
        manifesto: 8,
        firstResponderPlus: 1,
      },
      totalManagedCustomerChatsReceived: { total: 0 },
      totalQAChatsHandled: { total: 0 },
    },
    "k.alexander@expensify.com": {
      totalCustomerChatsReceived: {
        total: 921,
        supportal: 272,
        firstResponderPlus: 312,
        retention: 43,
        c4b: 182,
        operations: 52,
        "": 3,
        firstResponder: 45,
        borgo: 1,
        nycLounge: 7,
        manifesto: 3,
        sfoLounge: 1,
      },
      totalManagedCustomerChatsReceived: { total: 5 },
      totalQAChatsHandled: { total: 3695 },
    },
    "dylan@expensify.com": {
      totalCustomerChatsReceived: {
        total: 795,
        firstResponder: 23,
        firstResponderPlus: 2,
        researchTeam: 759,
        "": 11,
      },
      totalManagedCustomerChatsReceived: {
        total: 0,
      },
      totalQAChatsHandled: {
        total: 0,
      },
    },
    "adele@expensify.com": [],
    "jules@expensify.com": [],
    "jack@expensify.com": [],
    "maxence@expensify.com": [],
    "maria@expensify.com": [],
    "phs@expensify.com": [],
    "rory@expensify.com": [],
    "alexbeaman@expensify.com": [],
    "monil@expensify.com": [],
    "kortney@expensify.com": [],
    "vit@expensify.com": [],
    "cmartins@expensify.com": [],
    "trent@expensify.com": [],
    "aldo@expensify.com": [],
    "nmetcalf@expensify.com": [],
    "marco@expensify.com": [],
    "dsilva@expensify.com": [],
    "haley@expensify.com": [],
    "amy@expensify.com": [],
    "vivek@expensify.com": [],
    "arosiclair@expensify.com": [],
    "jeremyhollm@expensify.com": [],
    "cj@expensify.com": [],
    "srikar.parsi@expensify.com": [],
    "shannon@expensify.com": [],
    "cristi@expensify.com": [],
    "jasper@expensify.com": [],
    "dgalerosen@expensify.com": [],
    "georgia@expensify.com": [],
    "mfillari@expensify.com": [],
    "danny@expensify.com": [],
    "lucien@expensify.com": [],
    "jon@expensify.com": [],
    "rodrigo@expensify.com": [],
    "dan@expensify.com": [],
    "blimpich@expensify.com": [],
    "carlosmiceli@expensify.com": [],
  },
  STACK_OVERFLOW_REPUTATION: {
    "dbarrett@expensify.com": { reputation: 3582 },
    "jason@expensify.com": { reputation: 1359 },
    "puneet@expensify.com": { reputation: 1180 },
    "gknight@expensify.com": { reputation: 2307 },
    "daniel@expensify.com": { reputation: 843 },
    "robert@expensify.com": { reputation: 3161 },
    "francois@expensify.com": { reputation: 2960 },
    "shawn@expensify.com": { reputation: 509 },
    "ryan@expensify.com": { reputation: 561 },
    "carlos@expensify.com": { reputation: 2808 },
    "dcardoza@expensify.com": { reputation: 1298 },
    "florent@expensify.com": { reputation: 6586 },
    "al@expensify.com": { reputation: 181 },
    "ionatan@expensify.com": { reputation: 8347 },
    "nkuoch@expensify.com": { reputation: 587 },
    "cortney@expensify.com": { reputation: 349 },
    "sheena@expensify.com": { reputation: 1076 },
    "rdonato@expensify.com": { reputation: 133 },
    "tgolen@expensify.com": { reputation: 3448 },
    "zach@expensify.com": { reputation: 433 },
    "slafortune@expensify.com": { reputation: 575 },
    "corinne@expensify.com": { reputation: 576 },
    "april@expensify.com": { reputation: 104 },
    "jenna@expensify.com": { reputation: 1365 },
    "tyler@expensify.com": { reputation: 398 },
    "lindsey@expensify.com": { reputation: 551 },
    "andrew@expensify.com": { reputation: 1699 },
    "youssef@expensify.com": { reputation: 1 },
    "yuwen@expensify.com": { reputation: 1432 },
    "caitlin@expensify.com": { reputation: 351 },
    "anu@expensify.com": { reputation: 411 },
    "tom@expensify.com": { reputation: 2171 },
    "sonia@expensify.com": { reputation: 938 },
    "sasha@expensify.com": { reputation: 1862 },
    "wallroth@expensify.com": { reputation: 1457 },
    "john@expensify.com": { reputation: 3043 },
    "cole@expensify.com": { reputation: 4361 },
    "scv@expensify.com": { reputation: 2357 },
    "flavia@expensify.com": { reputation: 812 },
    "james@expensify.com": { reputation: 456 },
    "lauren@expensify.com": { reputation: 4163 },
    "mallen@expensify.com": { reputation: 1366 },
    "kevin@expensify.com": { reputation: 1046 },
    "rocio@expensify.com": { reputation: 1407 },
    "dbondy@expensify.com": { reputation: 3867 },
    "chirag@expensify.com": { reputation: 967 },
    "shaelyn@expensify.com": { reputation: 1472 },
    "scott@expensify.com": { reputation: 977 },
    "milja@expensify.com": { reputation: 336 },
    "greg@expensify.com": { reputation: 466 },
    "jschuster@expensify.com": { reputation: 537 },
    "jli@expensify.com": { reputation: 518 },
    "selliott@expensify.com": { reputation: 880 },
    "isa@expensify.com": { reputation: 211 },
    "rachael@expensify.com": { reputation: 1268 },
    "nmendonca@expensify.com": { reputation: 939 },
    "lschurr@expensify.com": { reputation: 151 },
    "ntooker@expensify.com": { reputation: 171 },
    "ted@expensify.com": { reputation: 3527 },
    "mitch@expensify.com": { reputation: 686 },
    "ariel@expensify.com": { reputation: 796 },
    "mlewis@expensify.com": { reputation: 2083 },
    "bfitz@expensify.com": { reputation: 627 },
    "sofie@expensify.com": { reputation: 911 },
    "neil@expensify.com": { reputation: 305 },
    "nikkiwines@expensify.com": { reputation: 1075 },
    "bstites@expensify.com": { reputation: 473 },
    "marc@expensify.com": { reputation: 2256 },
    "mmoore@expensify.com": { reputation: 674 },
    "c.dobrzyn@expensify.com": { reputation: 1303 },
    "alberto@expensify.com": { reputation: 609 },
    "aimee@expensify.com": { reputation: 1361 },
    "victoria@expensify.com": { reputation: 649 },
    "jkaufman@expensify.com": { reputation: 751 },
    "jpersaud@expensify.com": { reputation: 1667 },
    "zany@expensify.com": { reputation: 1388 },
    "cheryl@expensify.com": { reputation: 380 },
    "rafe@expensify.com": { reputation: 5859 },
    "k.alexander@expensify.com": { reputation: 752 },
    "dylan@expensify.com": { reputation: 2079 },
    "adele@expensify.com": { reputation: 1208 },
    "jules@expensify.com": { reputation: 1105 },
    "jack@expensify.com": { reputation: 321 },
    "maxence@expensify.com": { reputation: 1131 },
    "maria@expensify.com": { reputation: 351 },
    "phs@expensify.com": { reputation: 1805 },
    "rory@expensify.com": { reputation: 1128 },
    "alexbeaman@expensify.com": { reputation: 724 },
    "monil@expensify.com": { reputation: 25 },
    "kortney@expensify.com": { reputation: 41 },
    "vit@expensify.com": { reputation: 307 },
    "cmartins@expensify.com": { reputation: 158 },
    "trent@expensify.com": { reputation: 126 },
    "aldo@expensify.com": { reputation: 189 },
    "nmetcalf@expensify.com": { reputation: 1013 },
    "marco@expensify.com": { reputation: 373 },
    "dsilva@expensify.com": { reputation: 221 },
    "haley@expensify.com": { reputation: 380 },
    "amy@expensify.com": { reputation: 66 },
    "vivek@expensify.com": { reputation: 173 },
    "arosiclair@expensify.com": { reputation: 101 },
    "jeremyhollm@expensify.com": { reputation: 21 },
    "cj@expensify.com": { reputation: 111 },
    "srikar.parsi@expensify.com": { reputation: 81 },
    "shannon@expensify.com": { reputation: 53 },
    "cristi@expensify.com": { reputation: 81 },
    "jasper@expensify.com": { reputation: 236 },
    "dgalerosen@expensify.com": { reputation: 265 },
    "georgia@expensify.com": { reputation: 91 },
    "mfillari@expensify.com": { reputation: 110 },
    "danny@expensify.com": { reputation: 41 },
    "lucien@expensify.com": { reputation: 51 },
    "jon@expensify.com": { reputation: 1 },
    "rodrigo@expensify.com": { reputation: 8347 },
    "dan@expensify.com": { reputation: 36 },
    "blimpich@expensify.com": { reputation: 23 },
    "carlosmiceli@expensify.com": { reputation: 11 },
  },
  WHITELIST_EMAILS: [
    "dbarrett@expensify.com",
    "jason@expensify.com",
    "puneet@expensify.com",
    "gknight@expensify.com",
    "daniel@expensify.com",
    "robert@expensify.com",
    "francois@expensify.com",
    "shawn@expensify.com",
    "ryan@expensify.com",
    "carlos@expensify.com",
    "dcardoza@expensify.com",
    "florent@expensify.com",
    "al@expensify.com",
    "ionatan@expensify.com",
    "nkuoch@expensify.com",
    "cortney@expensify.com",
    "sheena@expensify.com",
    "rdonato@expensify.com",
    "tgolen@expensify.com",
    "zach@expensify.com",
    "slafortune@expensify.com",
    "corinne@expensify.com",
    "april@expensify.com",
    "jenna@expensify.com",
    "tyler@expensify.com",
    "lindsey@expensify.com",
    "andrew@expensify.com",
    "youssef@expensify.com",
    "yuwen@expensify.com",
    "caitlin@expensify.com",
    "anu@expensify.com",
    "tom@expensify.com",
    "sonia@expensify.com",
    "sasha@expensify.com",
    "wallroth@expensify.com",
    "john@expensify.com",
    "cole@expensify.com",
    "scv@expensify.com",
    "flavia@expensify.com",
    "james@expensify.com",
    "lauren@expensify.com",
    "mallen@expensify.com",
    "kevin@expensify.com",
    "rocio@expensify.com",
    "dbondy@expensify.com",
    "chirag@expensify.com",
    "shaelyn@expensify.com",
    "scott@expensify.com",
    "milja@expensify.com",
    "greg@expensify.com",
    "jschuster@expensify.com",
    "jli@expensify.com",
    "selliott@expensify.com",
    "isa@expensify.com",
    "rachael@expensify.com",
    "nmendonca@expensify.com",
    "lschurr@expensify.com",
    "ntooker@expensify.com",
    "ted@expensify.com",
    "mitch@expensify.com",
    "ariel@expensify.com",
    "mlewis@expensify.com",
    "bfitz@expensify.com",
    "sofie@expensify.com",
    "neil@expensify.com",
    "nikkiwines@expensify.com",
    "bstites@expensify.com",
    "marc@expensify.com",
    "mmoore@expensify.com",
    "c.dobrzyn@expensify.com",
    "alberto@expensify.com",
    "aimee@expensify.com",
    "victoria@expensify.com",
    "jkaufman@expensify.com",
    "jpersaud@expensify.com",
    "zany@expensify.com",
    "cheryl@expensify.com",
    "rafe@expensify.com",
    "k.alexander@expensify.com",
    "dylan@expensify.com",
    "adele@expensify.com",
    "jules@expensify.com",
    "jack@expensify.com",
    "maxence@expensify.com",
    "maria@expensify.com",
    "phs@expensify.com",
    "rory@expensify.com",
    "alexbeaman@expensify.com",
    "monil@expensify.com",
    "kortney@expensify.com",
    "vit@expensify.com",
    "cmartins@expensify.com",
    "trent@expensify.com",
    "aldo@expensify.com",
    "nmetcalf@expensify.com",
    "marco@expensify.com",
    "dsilva@expensify.com",
    "haley@expensify.com",
    "amy@expensify.com",
    "vivek@expensify.com",
    "arosiclair@expensify.com",
    "jeremyhollm@expensify.com",
    "cj@expensify.com",
    "srikar.parsi@expensify.com",
    "shannon@expensify.com",
    "cristi@expensify.com",
    "jasper@expensify.com",
    "dgalerosen@expensify.com",
    "georgia@expensify.com",
    "mfillari@expensify.com",
    "danny@expensify.com",
    "lucien@expensify.com",
    "jon@expensify.com",
    "rodrigo@expensify.com",
    "dan@expensify.com",
    "blimpich@expensify.com",
    "carlosmiceli@expensify.com",
  ],
};
