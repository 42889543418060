import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';

import PageHeader from '../components/PageHeader';
import MatchRows from '../components/MatchRows';
import appPropTypes from '../propTypesApp';
import {getAllMatchResultsForEmail, switchMatchResultsForEmailPair} from '../libs/actions/Pair';
import {getExclusions} from '../libs/actions/ManageMatches';
import {redirect} from '../libs/actions/App';
import ONYXKEYS from '../ONYXKEYS';
import ROUTES from '../ROUTES';

const propTypes = {
    /** Data about the state of the app */
    app: appPropTypes,

    /** All of the match results for a given email */
    matchResultsForEmail: PropTypes.arrayOf(PropTypes.object),

    /** Comes from React Router */
    /* eslint-disable-next-line react/forbid-prop-types */
    match: PropTypes.any.isRequired,
};

const defaultProps = {
    app: {},
    matchResultsForEmail: [],
};

class MatchesPage extends React.Component {
    componentDidMount() {
        getAllMatchResultsForEmail(this.props.match.params.email);
        getExclusions();
    }

    /**
     * Switch the result of a pair of people
     *
     * @param {String} emailA
     * @param {String} emailB
     * @param {String} newHigher
     */
    switchPair(emailA, emailB, newHigher) {
        switchMatchResultsForEmailPair(emailA, emailB, newHigher);
    }

    render() {
        return (
            <>
                <PageHeader titleTwo="Reviewing Matches" titleThree={this.props.match.params.email} />
                <button
                    onClick={() => redirect(ROUTES.DONE)}
                    className="btn btn-primary mr-2 mb-4"
                    type="button"
                >
                    Back to results
                </button>

                {this.props.app.loading && <div className="text-center">Loading...</div>}

                {!this.props.app.loading && this.props.matchResultsForEmail.length && (
                    <MatchRows
                        matches={this.props.matchResultsForEmail}
                        disabled={this.props.app.isUIDisabled}
                        onSwitch={this.switchPair}
                    />
                )}
            </>
        );
    }
}

MatchesPage.propTypes = propTypes;
MatchesPage.defaultProps = defaultProps;

export default withOnyx({
    app: {key: ONYXKEYS.APP},
    matchResultsForEmail: {key: ONYXKEYS.MATCH_RESULTS_FOR_EMAIL},
})(MatchesPage);
