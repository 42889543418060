import Onyx from 'react-native-onyx';
import Str from 'expensify-common/lib/str';
import _ from 'underscore';

import API_Expensify from '../APIExpensify';
import ROUTES from '../../ROUTES';
import ONYXKEYS from '../../ONYXKEYS';
import CONFIG from '../../CONFIG';

let credentials = {};
Onyx.connect({
    key: ONYXKEYS.CREDENTIALS,
    callback: val => credentials = val,
});

/**
 * Sets API data in the store when we make a successful "Authenticate"/"CreateLogin" request
 *
 * @param {Object} data
 * @param {String} exitTo
 */
function setSuccessfulSignInData(data, exitTo) {
    const redirectURL = exitTo ? Str.normalizeUrl(exitTo) : ROUTES.ROOT;
    Onyx.multiSet({
        [ONYXKEYS.SESSION]: _.pick(data, 'authToken', 'accountID', 'email'),
        [ONYXKEYS.APP_REDIRECT_TO]: redirectURL,
    });
}

/**
 * Sign the user into the application. This will first authenticate their account
 * then it will create a temporary login for them which is used when re-authenticating
 * after an authToken expires.
 *
 * @param {String} login
 * @param {String} validateCode
 * @param {String} [twoFactorAuthCode]
 * @param {String} [exitTo]
 */
function signIn(login, validateCode, twoFactorAuthCode, exitTo) {
    Onyx.merge(ONYXKEYS.ACCOUNT, {error: '', loading: true});

    API_Expensify.Authenticate({
        useExpensifyLogin: true,
        partnerName: CONFIG.EXPENSIFY.PARTNER_NAME,
        partnerPassword: CONFIG.EXPENSIFY.PARTNER_PASSWORD,
        partnerUserID: login,
        validateCode,
        twoFactorAuthCode,
    })
        .then((authenticateResponse) => {
            const autoGeneratedLogin = Str.guid('comp.expensify.com-');
            const autoGeneratedPassword = Str.guid();

            API_Expensify.CreateLogin({
                authToken: authenticateResponse.authToken,
                partnerName: CONFIG.EXPENSIFY.PARTNER_NAME,
                partnerPassword: CONFIG.EXPENSIFY.PARTNER_PASSWORD,
                partnerUserID: autoGeneratedLogin,
                partnerUserSecret: autoGeneratedPassword,
                forceNetworkRequest: true,
                doNotRetry: true,
            })
                .then((createLoginResponse) => {
                    if (createLoginResponse.jsonCode !== 200) {
                        throw new Error(createLoginResponse.message);
                    }

                    // If we have an old generated login for some reason
                    // we should delete it before storing the new details
                    if (credentials && credentials.autoGeneratedLogin) {
                        API_Expensify.DeleteLogin({
                            authToken: authenticateResponse.authToken,
                            partnerUserID: credentials.autoGeneratedLogin,
                            partnerName: CONFIG.EXPENSIFY.PARTNER_NAME,
                            partnerPassword: CONFIG.EXPENSIFY.PARTNER_PASSWORD,
                            doNotRetry: true,
                        })
                            .catch(console.debug);
                    }

                    Onyx.merge(ONYXKEYS.CREDENTIALS, {autoGeneratedLogin, autoGeneratedPassword});
                    setSuccessfulSignInData(createLoginResponse, exitTo);
                })
                .catch((error) => {
                    Onyx.merge(ONYXKEYS.ACCOUNT, {error: error.message});
                })
                .finally(() => {
                    Onyx.merge(ONYXKEYS.ACCOUNT, {loading: false});
                });
        })
        .catch((error) => {
            Onyx.merge(ONYXKEYS.ACCOUNT, {error: error.message, loading: false});
        });
}

/**
 * Send the magic code to the user trying to sign in
 *
 * @param {String} email
 * @returns {Promise}
 */
function beginSignIn(email) {
    Onyx.merge(ONYXKEYS.ACCOUNT, {error: ''});
    return new Promise((resolve) => {
        API_Expensify.BeginSignIn(email)
            .then((beginSignInResponse) => {
                if (beginSignInResponse.jsonCode !== 200) {
                    Onyx.merge(ONYXKEYS.ACCOUNT, {error: beginSignInResponse.message});
                    resolve(false);
                    return;
                }
                resolve(true);
            });
    });
}

export {
    // eslint-disable-next-line import/prefer-default-export
    signIn,
    beginSignIn,
};
