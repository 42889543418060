import React from 'react';
import PropTypes from 'prop-types';
import withCompensationAccessPermission from '../../components/withCompensationAccessPermission';
import {getAllEmails} from '../../libs/actions/Compensation';
import EmailSelector from '../../components/EmailSelector';
import CompensationEditTable from './CompensationEditTable';
import NewCompensationForm from './NewCompensationForm';
import {redirect} from '../../libs/actions/App';
import ROUTES from '../../ROUTES';
import CompensationNavBar from '../../components/CompensationNavBar';

const propTypes = {
    /** Comes from React Router */
    /* eslint-disable-next-line react/forbid-prop-types */
    match: PropTypes.any.isRequired,
};

class CompensationEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.redirectToEditPage = this.redirectToEditPage.bind(this);

        this.state = {};
    }

    componentDidMount() {
        getAllEmails();
    }

    /**
     * @param {String} email
     */
    redirectToEditPage(email) {
        // Redirect to the URL for editing that person's compensation
        redirect(ROUTES.getCompensationEditEmailRoute(email));
    }

    render() {
        const {email} = this.props.match.params;

        return (
            <>
                <CompensationNavBar email={email} />
                <div className="mt-4">
                    <h3>Select Employee:</h3>
                    <EmailSelector onSelect={this.redirectToEditPage} value={email} defaultOption="- Select Employee -" />

                    {email && (
                        <>
                            <NewCompensationForm email={email} />
                            <CompensationEditTable email={email} />
                        </>
                    )}
                </div>
            </>
        );
    }
}

CompensationEditPage.displayName = 'CompensationEditPage';
CompensationEditPage.propTypes = propTypes;

export default withCompensationAccessPermission(CompensationEditPage);
