import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import compose from '../../libs/compose';
import {redirect} from '../../libs/actions/App';
import ROUTES from '../../ROUTES';
import withCompensationAccessPermission from '../../components/withCompensationAccessPermission';
import ONYXKEYS from '../../ONYXKEYS';
import CompensationNavBar from '../../components/CompensationNavBar';
import {createMultipleCompensations, clearDataToConfirmForUpload} from '../../libs/actions/Compensation';
import round from '../../libs/round';

const propTypes = {
    /** Comes from React Router */
    /* eslint-disable-next-line react/forbid-prop-types */
    match: PropTypes.any.isRequired,

    /** The data that needs to be confirmed before it's uploaded */
    dataToConfirm: PropTypes.arrayOf(PropTypes.shape({
        /** Date when the raise is effective */
        effectiveDate: PropTypes.string,

        /** The email of the person for the compensation */
        email: PropTypes.string.isRequired,

        /** The local currency of the raise */
        localCurrency: PropTypes.string,

        /** The local currency exchange percentage of the raise */
        localCurrencyExchangeRate: PropTypes.number,

        /** The reason for why the employee's compensation is changing */
        reason: PropTypes.string,

        /** The amount of cash salary for the person in cents */
        salary: PropTypes.number,

        /** The amount of salary exchanged for equity in cents */
        salaryExchangedForEquity: PropTypes.number,

        /** The amount of compensation coming from grants in cents */
        grantCompensationAmount: PropTypes.number,

        /** The number of options */
        numberOfOptions: PropTypes.number,
    })),
};
const defaultProps = {
    dataToConfirm: [],
};

class CompensationConfirmUploadPage extends React.Component {
    constructor(props) {
        super(props);

        this.uploadData = this.uploadData.bind(this);
    }

    componentWillUnmount() {
        clearDataToConfirmForUpload();
    }

    uploadData() {
        createMultipleCompensations(this.props.dataToConfirm);

        redirect(ROUTES.COMPENSATION_OVERVIEW);
    }

    render() {
        const currencyUSDFormatter = new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'});
        const allDateFormatsAreValid = _.every(this.props.dataToConfirm, row => /^\d{4}-\d{2}-\d{2}$/.test(row.effectiveDate || row.created));
        return (
            <>
                <CompensationNavBar email={this.props.match.params.email} />

                <div className="alert alert-warning mt-4">
                    <ul>
                        <li>These amounts are ADDITIVE to people&apos;s existing compensation.</li>
                        <li>If an email address does not exist in the database, a new employee record will be created for them.</li>
                    </ul>
                </div>

                {this.props.dataToConfirm.length ? (
                    <div className="mt-4">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Effective Date</th>
                                    <th>Email</th>
                                    <th>Local Currency</th>
                                    <th>Currency Exchange</th>
                                    <th>Reason</th>
                                    <th>Raise</th>
                                    <th>Raise Exchanged for Equity</th>
                                    <th>Grant Compensation Amount</th>
                                    <th>Number of Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(this.props.dataToConfirm, row => (
                                    <tr key={row.email}>
                                        <td>{row.effectiveDate || row.created}</td>
                                        <td>{row.email}</td>
                                        <td>{row.localCurrency}</td>
                                        <td>{`${round(row.localCurrencyExchangeRate / 100)}%`}</td>
                                        <td>{row.reason}</td>
                                        <td>{currencyUSDFormatter.format(row.salary / 100)}</td>
                                        <td>{currencyUSDFormatter.format(row.salaryExchangedForEquity / 100)}</td>
                                        <td>{currencyUSDFormatter.format(row.grantCompensationAmount / 100)}</td>
                                        <td>{row.numberOfOptions}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {allDateFormatsAreValid ? (
                            <button className="btn btn-success" type="button" onClick={this.uploadData}>
                                Yes, this data is correct. Upload now.
                            </button>
                        ) : (
                            <div className="alert alert-danger">
                                All dates must be in the format YYYY-mm-dd
                            </div>
                        )}
                    </div>
                ) : (
                    <div>There is no data to confirm and upload.</div>
                )}
            </>
        );
    }
}


CompensationConfirmUploadPage.displayName = 'CompensationUploadPage';
CompensationConfirmUploadPage.propTypes = propTypes;
CompensationConfirmUploadPage.defaultProps = defaultProps;

export default compose(
    withCompensationAccessPermission,
    withOnyx({
        dataToConfirm: {
            key: ONYXKEYS.COMPENSATION_TO_CONFIRM_FOR_UPLOAD,
        },
    }),
)(CompensationConfirmUploadPage);
