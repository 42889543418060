import * as APIUtils from './APIUtils';
import CONFIG from '../CONFIG';

/**
 * @param {Object} parameters
 * @param {String} parameters.higherEmail
 * @param {String} parameters.lowerEmail
 * @returns {Promise}
 */
function AutoMatches_Get(parameters) {
    const commandName = 'AutoMatches_Get';
    APIUtils.requireParameters([
        'higherEmail',
        'higherEmail',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String[]} [parameters.email]
 * @returns {Promise}
 */
function ManageMatches_Exclusions_Add(parameters) {
    const commandName = 'ManageMatches_Exclusions_Add';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String[]} [parameters.email]
 * @returns {Promise}
 */
function ManageMatches_Exclusions_Remove(parameters) {
    const commandName = 'ManageMatches_Exclusions_Remove';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_Exclusions() {
    const commandName = 'ManageMatches_Exclusions';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_Participants() {
    const commandName = 'ManageMatches_Participants';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_Overview() {
    const commandName = 'ManageMatches_Overview';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_OverviewDetails() {
    const commandName = 'ManageMatches_OverviewDetails';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String[]} [parameters.emails]
 * @returns {Promise}
 */
function ManageMatches_Generate(parameters) {
    const commandName = 'ManageMatches_Generate';
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String[]} parameters.emails
 * @returns {Promise}
 */
function ManageMatches_RemoveFromComp(parameters) {
    const commandName = 'ManageMatches_RemoveFromComp';
    APIUtils.requireParameters([
        'emails',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_Reset() {
    const commandName = 'ManageMatches_Reset';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_SetPeriodEnd(parameters) {
    const commandName = 'ManageMatches_SetPeriodEnd';
    APIUtils.requireParameters([
        'date',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function ManageMatches_SetPeriodStart(parameters) {
    const commandName = 'ManageMatches_SetPeriodStart';
    APIUtils.requireParameters([
        'date',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.period
 * @returns {Promise}
 */
function ManageMatches_SetCurrentPeriod(parameters) {
    const commandName = 'ManageMatches_SetCurrentPeriod';
    APIUtils.requireParameters([
        'period',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {Boolean} parameters.isLocked
 * @returns {Promise}
 */
function ManageMatches_ToggleWorkDescriptionsLocked(parameters) {
    const commandName = 'ManageMatches_ToggleWorkDescriptionsLocked';
    APIUtils.requireParameters([
        'isLocked',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @param {String} parameters.localCurrency
 * @param {Number} parameters.localCurrencyExchangeRate
 * @param {Number} parameters.salary
 * @param {Number} parameters.salaryExchangedForEquity
 * @param {Number} parameters.grantCompensationAmount
 * @param {Number} parameters.numberOfOptions
 * @returns {Promise}
 */
function Compensation_Create(parameters) {
    const commandName = 'Compensation_Create';
    APIUtils.requireParameters([
        'email',
        'localCurrency',
        'localCurrencyExchangeRate',
        'salary',
        'salaryExchangedForEquity',
        'grantCompensationAmount',
        'numberOfOptions',
        'reason',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {String} parameters
 * @param {String} parameters.multipleRecords
 * @returns {Promise}
 */
function Compensation_Create_Multiple(parameters) {
    const commandName = 'Compensation_Create_Multiple';
    APIUtils.requireParameters([
        'multipleRecords',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function Compensation_Get_History(parameters) {
    const commandName = 'Compensation_Get_History';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function Compensation_Get_Overview() {
    const commandName = 'Compensation_Get_Overview';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} [parameters.effectiveDate] optional - the effective date of the CSV file to get the compensation data for
 * @returns {Promise}
 */
function Compensation_Download_CSV(parameters) {
    const commandName = 'Compensation_Download_CSV';
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} [parameters.email] optional - uses the currently logged on user's email if omitted
 * @returns {Promise}
 */
function Description_Get(parameters) {
    const commandName = 'Description_Get';
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @param {String} parameters.description
 * @returns {Promise}
 */
function Description_Save(parameters) {
    const commandName = 'Description_Save';
    APIUtils.requireParameters([
        'email',
        'description',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function Emails_Get() {
    const commandName = 'Emails_Get';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * Get all the match results for the person doing comp review
 *
 * @returns {Promise}
 */
function MatchResults_GetAll() {
    return APIUtils.request(
        'MatchResults_GetAll',
        {},
        'post',
        CONFIG.EXPENSIFY.URL_API_ROOT_COMP,
    );
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @returns {Promise}
 */
function MatchResults_GetForEmail(parameters) {
    const commandName = 'MatchResults_GetForEmail';
    APIUtils.requireParameters([
        'email',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @param {String} parameters.note
 * @returns {Promise}
 */
function Note_Save(parameters) {
    const commandName = 'Note_Save';
    APIUtils.requireParameters([
        'email',
        'note',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.email
 * @param {String} parameters.privateNote
 * @returns {Promise}
 */
function PrivateNote_Save(parameters) {
    const commandName = 'PrivateNote_Save';
    APIUtils.requireParameters([
        'email',
        'privateNote',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {String} emailA
 * @param {String} emailB
 * @returns {Promise}
 */
function Pair_Get(emailA, emailB) {
    return APIUtils.request('Pair_Get', {emailA, emailB}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @returns {Promise}
 */
function Pair_GetRandom() {
    return APIUtils.request('Pair_GetRandom', {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.emailA
 * @param {String} parameters.emailB
 * @param {String} parameters.higherEmail
 * @param {String} parameters.noteA
 * @param {String} parameters.noteB
 * @returns {Promise}
 */
function Pair_SaveResult(parameters) {
    const commandName = 'Pair_SaveResult';
    APIUtils.requireParameters([
        'emailA',
        'emailB',
        'higherEmail',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} parameters.createdBy
 * @param {Array} parameters.matches
 * @returns {Promise}
 */
function Pair_ResetMatch(parameters) {
    const commandName = 'Pair_ResetMatch';
    APIUtils.requireParameters([
        'createdBy',
        'matches',
    ], parameters, commandName);
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

function Permissions_Get() {
    const commandName = 'Permissions_Get';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @param {Object} parameters
 * @param {String} [parameters.email] optional - uses the currently logged on user's email if omitted
 * @returns {Promise}
 */
function Profile_Get(parameters) {
    const commandName = 'Profile_Get';
    return APIUtils.request(commandName, parameters, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @returns {Promise}
 */
function SlackMessageRankings_Get() {
    const commandName = 'SlackMessageRankings_Get';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @returns {Promise}
 */
function SlackChannelRankings_Get() {
    const commandName = 'SlackChannelRankings_Get';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

/**
 * @returns {Promise}
 */
function AmbassadorEventDayRankings_Get() {
    const commandName = 'AmbassadorEventDayRankings_Get';
    return APIUtils.request(commandName, {}, 'post', CONFIG.EXPENSIFY.URL_API_ROOT_COMP);
}

export {
    AutoMatches_Get,
    ManageMatches_Exclusions_Add,
    ManageMatches_Exclusions_Remove,
    ManageMatches_Exclusions,
    ManageMatches_Generate,
    ManageMatches_Overview,
    ManageMatches_OverviewDetails,
    ManageMatches_Participants,
    ManageMatches_RemoveFromComp,
    ManageMatches_Reset,
    ManageMatches_SetCurrentPeriod,
    ManageMatches_SetPeriodEnd,
    ManageMatches_SetPeriodStart,
    ManageMatches_ToggleWorkDescriptionsLocked,
    Compensation_Create,
    Compensation_Create_Multiple,
    Compensation_Get_History,
    Compensation_Get_Overview,
    Compensation_Download_CSV,
    Description_Get,
    Description_Save,
    Emails_Get,
    MatchResults_GetAll,
    MatchResults_GetForEmail,
    Note_Save,
    PrivateNote_Save,
    Pair_Get,
    Pair_GetRandom,
    Pair_SaveResult,
    Pair_ResetMatch,
    Permissions_Get,
    Profile_Get,
    SlackMessageRankings_Get,
    SlackChannelRankings_Get,
    AmbassadorEventDayRankings_Get,
};
