import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import Onyx, {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';

import ROUTES from './ROUTES';
import LoggedInPages from './pages/LoggedInPages';
import Sidebar from './components/Sidebar';
import SigninPage from './pages/SigninPage';
import ONYXKEYS from './ONYXKEYS';
import listenToStorageEvents from './libs/listenToStorageEvents';
import Log from './libs/Log';
import {recordCurrentRoute} from './libs/actions/App';
import {getPermissions} from './libs/actions/User';

// Initialize the store when the app loads for the first time
Onyx.init({
    keys: ONYXKEYS,
    initialKeyStates: {

        // Clear any loading and error messages so they do not appear on app startup
        [ONYXKEYS.SESSION]: {loading: false},
        [ONYXKEYS.ACCOUNT]: {loading: false, error: ''},
        [ONYXKEYS.WORK_DESCRIPTION]: {loading: false},
        [ONYXKEYS.PERMISSIONS]: {loading: true},
    },
    registerStorageEventListener: (onStorageEvent) => {
        listenToStorageEvents(onStorageEvent);
    },
});
Onyx.registerLogger(({level, message}) => {
    if (level === 'alert') {
        Log.alert(message, {}, false);
    } else {
        Log.client(message);
    }
});

const propTypes = {
    /* Onyx Props */

    /** A route set by Onyx that we will redirect to if present. Always empty on app init. */
    redirectTo: PropTypes.string,
};

const defaultProps = {
    redirectTo: '',
};

class Comp extends React.Component {
    constructor(props) {
        super(props);

        this.removeLoadingState = this.removeLoadingState.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);

        this.state = {
            isLoading: true,
            authToken: null,
            darkThemeEnabled: false,
        };
    }

    componentDidMount() {
        Onyx.connect({
            key: ONYXKEYS.SESSION,
            callback: this.removeLoadingState,
        });
        Onyx.connect({
            key: ONYXKEYS.APP_DARK_THEME_ENABLED,
            callback: this.toggleTheme,
        });
    }

    /**
     * When the authToken is updated, the app should remove the loading state and handle the authToken
     *
     * @param {Object} [session]
     * @param {String} session.authToken
     */
    removeLoadingState(session) {
        if (session && session.authToken) {
            getPermissions();
        }
        this.setState({
            authToken: session ? session.authToken : null,
            isLoading: false,
        });
    }

    /**
     * Toggle the current theme between light and dark
     *
     * @param {Boolean} darkThemeEnabled
     */
    toggleTheme(darkThemeEnabled) {
        this.setState({darkThemeEnabled});
    }

    render() {
        // Until the authToken has been initialized from Onyx, display a blank page
        if (this.state.isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div className={`content has-minimized-nav ${this.state.darkThemeEnabled ? 'dark' : ''}`}>
                <div className="app-content-wrapper h-100">
                    <main className="app-content">
                        <Router>
                            <Sidebar />

                            {/* If there is ever a property for redirecting, we do the redirect here */}
                            {this.props.redirectTo && <Redirect push to={this.props.redirectTo} />}
                            <Route path="*" render={recordCurrentRoute} />

                            <Switch>
                                <Route
                                    exact
                                    path={ROUTES.ROOT}
                                    render={() => (
                                        this.state.authToken
                                            ? <Redirect to={ROUTES.HOME} />
                                            : <Redirect to={ROUTES.SIGNIN} />
                                    )}
                                />

                                <Route path={ROUTES.SIGNIN} component={SigninPage} />

                                <Route
                                    path="*"
                                    render={() => (

                                        // Render the logged in pages if there is an active session
                                        this.state.authToken
                                            ? <LoggedInPages />
                                            : <Redirect to={ROUTES.SIGNIN} />
                                    )}
                                />
                            </Switch>
                        </Router>
                    </main>
                </div>
            </div>
        );
    }
}

Comp.propTypes = propTypes;
Comp.defaultProps = defaultProps;

export default withOnyx({
    redirectTo: {
        key: ONYXKEYS.APP_REDIRECT_TO,

        // Prevent the prefilling of Onyx data or else the app will always redirect to what the last value was set to.
        // This ends up in a situation where you go to a report, refresh the page, and then rather than seeing the
        // report you are brought back to the root of the site (ie. "/").
        initWithStoredValues: false,
    },
})(Comp);
