import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

const propTypes = {
    /** The data for the person on the left */
    A: PropTypes.shape({
        /** The email address of the person */
        email: PropTypes.string.isRequired,

        /** The name of the person */
        name: PropTypes.string.isRequired,
    }).isRequired,

    /** The data for the person on the right, same as A */
    B: PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,

    /** The email of the person who was ranked higher */
    higher: PropTypes.string.isRequired,

    /** A flag to show if match was autogenerated or not */
    isAutoMatch: PropTypes.bool,

    /** A flag to disable the UI */
    disabled: PropTypes.bool,

    /** Buttons with actions to take for the current row */
    buttons: PropTypes.objectOf(PropTypes.func),
};

const defaultProps = {
    disabled: false,
    isAutoMatch: false,
    buttons: {},
};

const MatchRow = ({
    A, B, higher, disabled, isAutoMatch, buttons,
}) => (
    <div className="row mt-2">
        <div className="col-4 text-center align-self-center">
            {isAutoMatch && (
                <div className="d-block d-sm-inline mr-sm-2">
                    <span className="badge badge-pill badge-secondary">Auto</span>
                </div>
            )}
            {higher === A.email ? A.name : B.name}
        </div>
        <div className="col-4 text-center align-self-center d-flex flex-column flex-sm-row">
            {_.map(buttons, (callback, label) => (
                <button
                    key={label}
                    className="btn btn-sm btn-primary m-1 btn-block"
                    disabled={disabled}
                    onClick={callback}
                    type="button"
                >
                    {label}
                </button>
            ))}
        </div>
        <div className="col-4 text-center align-self-center">
            {higher !== A.email ? A.name : B.name}
        </div>
    </div>
);

MatchRow.propTypes = propTypes;
MatchRow.defaultProps = defaultProps;
MatchRow.displayName = 'MatchRow';

export default MatchRow;
