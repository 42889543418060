import React, {Component} from 'react';
import cn from 'classnames';
import Onyx, {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ONYXKEYS from '../ONYXKEYS';
import ROUTES from '../ROUTES';
import withWindowDimensions, {windowDimensionsPropTypes} from './withWindowDimensions';
import compose from '../libs/compose';

const propTypes = {
    /** From Onyx: data about the permissions the current user has */
    permissions: PropTypes.shape({
        /** Indicates if the user is able to access the compensation data */
        canAccessCompensation: PropTypes.bool,
    }),

    ...windowDimensionsPropTypes,
};

const defaultProps = {
    permissions: {},
};

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.menuToggle = null;
        this.sidepaneWrapper = null;

        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    /**
     * @param {MouseEvent} event
     */
    handleOutsideClick(event) {
        if (!this.sidepaneWrapper || this.sidepaneWrapper.contains(event.target)) {
            return;
        }

        this.menuToggle.checked = false;
    }

    render() {
        return (
            <div className="h-0" ref={node => this.sidepaneWrapper = node}>
                <label htmlFor="menu-toggle" className="menu-icon-label">
                    <div className="menu-icon">
                        <span />
                    </div>
                </label>

                <input type="checkbox" ref={node => this.menuToggle = node} id="menu-toggle" className="menu-toggle" />

                <div className="sidepane">
                    <div
                        className={cn('sidepane-logo', {'d-flex justify-content-end': this.props.isSmallScreenWidth})}
                        onClick={() => this.menuToggle.checked = false}
                        role="button"
                        tabIndex={0}
                    >
                        <Link to={ROUTES.ROOT}>
                            <h1>Expensify</h1>
                        </Link>
                    </div>

                    <div className="sidepane-nav p-2">
                        <ul role="navigation">
                            {this.props.permissions.canAccessCompensation && (
                                <li>
                                    <Link to={ROUTES.COMPENSATION_OVERVIEW}>
                                        Compensation
                                    </Link>
                                </li>
                            )}
                            {this.props.permissions.canAccessManageMatches && (
                                <li>
                                    <Link to={ROUTES.MANAGEMATCHES_OVERVIEW}>
                                        Comp Review
                                    </Link>
                                </li>
                            )}
                            {this.props.session.authToken && this.props.isSmallScreenWidth && (
                                <button
                                    type="button"
                                    className="btn btn-danger w-100 mt-2"
                                    onClick={Onyx.clear}
                                >
                                    Log Out
                                </button>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default compose(
    withWindowDimensions,
    withOnyx({
        permissions: {
            key: ONYXKEYS.PERMISSIONS,
        },
        session: {
            key: ONYXKEYS.SESSION,
        },
    }),
)(Sidebar);
