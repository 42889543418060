import React from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import ONYXKEYS from '../ONYXKEYS';
import * as APIError from '../libs/actions/APIError';
import Alert from '../components/Alert';

const propTypes = {
    /** Any error reported by the API */
    error: PropTypes.string,
};
const defaultProps = {
    error: null,
};
function ErrorBanner({error}) {
    return (
        <Alert
            shouldShow={Boolean(error)}
            onDismiss={APIError.dismiss}
            type="danger"
        >
            <strong>{error}</strong>
            <span className="text-hint pl-2">
                You can dismiss this error and try again by clicking on the X on the right side of this window. If the problem persists and you don&apos;t
                {' '}
                know how to recover from this error, please ping someone in
                {' '}
                <a
                    href="https://expensify.slack.com/archives/C094TGUTZ"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    #infra
                </a>
                .
            </span>
        </Alert>
    );
}

ErrorBanner.propTypes = propTypes;
ErrorBanner.defaultProps = defaultProps;
ErrorBanner.displayName = 'ErrorBanner';

export default withOnyx({
    error: {key: ONYXKEYS.API_ERROR},
})(ErrorBanner);
