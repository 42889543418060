import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The email of the employee */
    email: PropTypes.string,

    /** The number of total matches the employee needs to do */
    totalMatches: PropTypes.number,

    /** The number of matches the employee has completed */
    completedMatches: PropTypes.number,
};
const defaultProps = {
    email: '',
    totalMatches: 0,
    completedMatches: 0,
};
const ManageMatchesDetailedProgressRow = (props) => {
    const progress = props.totalMatches
        ? Math.round((props.completedMatches / props.totalMatches) * 10000) / 100
        : 0;
    return (
        <div className="row">
            <div className="col-4">
                {props.email}
            </div>
            <div className="col-2">
                {`${new Intl.NumberFormat('en-US').format(props.completedMatches)} of ${new Intl.NumberFormat('en-US').format(props.totalMatches)}`}
            </div>
            <div className="col-6">
                <div className="progress mb-4">
                    <div
                        className="progress-bar bg-success"
                        aria-label={`${progress}%`}
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{width: `${progress}%`}}
                    />
                </div>
            </div>
        </div>
    );
};

ManageMatchesDetailedProgressRow.propTypes = propTypes;
ManageMatchesDetailedProgressRow.defaultProps = defaultProps;

export default ManageMatchesDetailedProgressRow;
export {
    propTypes,
};
