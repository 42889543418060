import React, {useState, useEffect} from 'react';
import _ from 'underscore';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import {
    addExclusion,
    getExclusions,
    dismissExclusionsError,
    removeExclusion,
} from '../../libs/actions/ManageMatches';
import ONYXKEYS from '../../ONYXKEYS';
import Alert from '../../components/Alert';

const propTypes = {
    exclusions: PropTypes.shape({
        /** A server error when adding emails */
        error: PropTypes.string,

        /** An array of emails indicating who is excluded from comp review */
        emails: PropTypes.arrayOf(PropTypes.string),
    }),
};
const defaultProps = {
    exclusions: {
        emails: [],
        error: null,
    },
};
const ManageMatchesExclusions = (props) => {
    const [shouldShowExclusions, setShouldShowExclusions] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const [emailToAdd, setEmailToAdd] = useState('');
    const submit = () => {
        if (!emailToAdd) {
            setValidationError('You must include an email');
            return;
        }

        if (emailToAdd.indexOf('@') === -1) {
            setValidationError('That doesn\'t look like an email');
            return;
        }

        addExclusion(emailToAdd, props.exclusions.emails);
        setValidationError(null);
        setEmailToAdd('');
        dismissExclusionsError();
    };

    // Fetch the exclusions when this component mounts
    useEffect(getExclusions, []);

    const currentExclusionCount = props.exclusions && props.exclusions.emails ? props.exclusions.emails.length : 0;

    return (
        <div className="card mt-4">
            <div className="card-header">
                Step 5: Specify who is excluded from comp review
            </div>
            <div className="card-body">
                {shouldShowExclusions ? (
                    <>
                        <button
                            className="btn btn-secondary mr-2"
                            type="button"
                            onClick={() => setShouldShowExclusions(false)}
                        >
                            Hide exclusions
                        </button>
                        <h2 className="mt-4">People currently excluded:</h2>

                        {props.exclusions && !props.exclusions.emails.length && (
                            <p>None.</p>
                        )}

                        <ul className="list-group mt-4">
                            {_.map(props.exclusions.emails, email => (
                                <li className="list-group-item" key={email}>
                                    {email}
                                    <button
                                        className="btn btn-sm btn-danger float-right"
                                        type="button"
                                        onClick={() => removeExclusion(email, props.exclusions.emails)}
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <p className="mt-4">
                            <small>
                                Specify people that need to be excluded from comp review (frozen, leave, PIPs, interns). Email must exist in the whitelist.
                                {' '}
                                New hires DO NOT need to be added because they are filtered out automatically based on the start date in the whitelist.
                            </small>
                        </p>
                        <form className="form-inline mb-4">
                            <label htmlFor="exclusionEmail">Email:</label>
                            <div>
                                <input
                                    id="exclusionEmail"
                                    type="text"
                                    className={`form-control mx-2 ${validationError ? 'is-invalid' : ''}`}
                                    value={emailToAdd}
                                    onChange={e => setEmailToAdd(e.target.value)}
                                    onKeyDown={e => e.key === 'Enter' && submit()}
                                />
                                <span className="invalid-feedback">
                                    {validationError}
                                </span>
                            </div>
                            <button
                                className="btn btn-sm btn-success"
                                type="button"
                                onClick={submit}
                            >
                                Add Exclusion
                            </button>
                        </form>

                        <Alert
                            shouldShow={Boolean(props.exclusions.error)}
                            onDismiss={dismissExclusionsError}
                            type="danger"
                        >
                            <strong>Error:</strong>
                            {` ${props.exclusions.error}`}
                        </Alert>
                    </>
                ) : (
                    <>
                        <button
                            className="btn btn-secondary mr-2"
                            type="button"
                            onClick={() => setShouldShowExclusions(true)}
                        >
                            Show exclusions
                        </button>
                        {`There ${currentExclusionCount === 1 ? 'is one person' : `are ${currentExclusionCount} people`} excluded from comp review.`}
                    </>
                )}
            </div>
        </div>
    );
};
ManageMatchesExclusions.propTypes = propTypes;
ManageMatchesExclusions.defaultProps = defaultProps;

export default withOnyx({
    exclusions: {
        key: ONYXKEYS.MANAGEMATCHES.EXCLUSIONS,
    },
})(ManageMatchesExclusions);
