import Onyx from 'react-native-onyx';
import ONYXKEYS from '../../ONYXKEYS';

function dismiss() {
    Onyx.set(ONYXKEYS.API_ERROR, null);
}
function set(error) {
    Onyx.set(ONYXKEYS.API_ERROR, error);
}

export {
    dismiss,
    set,
};
