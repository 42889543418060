import React from 'react';
import {withOnyx} from 'react-native-onyx';
import lodashGet from 'lodash/get';
import compose from '../../libs/compose';
import {redirect} from '../../libs/actions/App';
import {getRandomPair, selectPair, goToPreviousPair} from '../../libs/actions/Pair';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../components/withWindowDimensions';
import PageHeader from '../../components/PageHeader';
import KeyUp from '../../components/KeyUp';
import appPropTypes from '../../propTypesApp';
import ONYXKEYS from '../../ONYXKEYS';
import ROUTES from '../../ROUTES';
import PairInstructions from './PairInstructions';
import PropTypesPair from './propTypesPair';
import ProfilePair from './ProfilePair';

const propTypes = {
    /** Data about the state of the entire application */
    app: appPropTypes,

    /** Data about the pair of people that need to be enacted upon */
    pair: PropTypesPair,

    /** Data about the previous pair of people that were recently ranked */
    previousPair: PropTypesPair,

    ...windowDimensionsPropTypes,
};

const defaultProps = {
    app: {},
    pair: null,
    previousPair: null,
};

class PairPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            footerHeight: 0,
        };

        this.processKeyPress = this.processKeyPress.bind(this);
        this.selectPerson = this.selectPerson.bind(this);
    }

    componentDidMount() {
        const {previousPair, pair} = this.props;

        // When redirecting from automatches after an undo, we will set the previous pair to the current pair
        // This will prevent the page from generating a new random pair and use the existing details in pair
        if (pair === null
            || lodashGet(previousPair, 'A.email') !== lodashGet(pair, 'A.email')
            || lodashGet(previousPair, 'B.email') !== lodashGet(pair, 'B.email')) {
            getRandomPair();
        }
    }

    /**
     * When the arrow keys are used, select the appropriate person
     *
     * @param {KeyboardEvent} e
     */
    processKeyPress(e) {
        // Only process keys done on the body so that arrow keys can be used in the notes field
        // without  triggering anything
        if (e.target.nodeName !== 'BODY') {
            return;
        }

        switch (e.key) {
            case 'ArrowLeft':
                this.selectPerson('A');
                break;

            case 'ArrowRight':
                this.selectPerson('B');
                break;

            default:
                break;
        }
    }

    /**
     * Select which person won the match
     *
     * @param {String} person
     */
    selectPerson(person) {
        const higherEmail = person === 'A' ? this.props.pair.A.email : this.props.pair.B.email;
        selectPair(this.props.pair.A, this.props.pair.B, higherEmail);
    }

    render() {
        // When there is a new pair being loaded from the API, this page should just not display anything
        // or else you would see the previous pair of people, which is confusing.
        if (this.props.app.loading) {
            return null;
        }

        const {app, pair, previousPair} = this.props;

        // This employee has been assigned no matches and wasn't included in this round of comp review.
        if (!app.totalMatches) {
            return (
                <div>
                    <PageHeader titleTwo="Pair" shouldShowChangeThemesIcon />
                    <p className="text-center">
                        Looks like you weren&apos;t included in comp review! We&apos;ll get ya next time.
                        <br />
                        Check out
                        {' '}
                        <a href="https://stackoverflow.com/c/expensify/questions/8063">this SO</a>
                        {' '}
                        for more information.
                    </p>
                </div>
            );
        }

        // Checks if we are currently on the previously saved match to hide the 'Go Back' button
        const isOnPreviousPair = lodashGet(pair, 'A.email') === lodashGet(previousPair, 'A.email', null)
                               && lodashGet(pair, 'B.email') === lodashGet(previousPair, 'B.email', null);

        // When there is no pair, this means that the process is finished and the only thing
        // that can be done on this page is to view the results
        if (!pair || !pair.A || !pair.B) {
            return (
                <div>
                    <PageHeader titleTwo="Pair" shouldShowChangeThemesIcon />
                    <p className="text-center">
                        Looks like you&apos;re all done, congrats!
                        <br />
                        <button
                            onClick={() => redirect(ROUTES.DONE)}
                            className="btn btn-primary mt-4"
                            type="button"
                        >
                            Go to my results
                        </button>
                    </p>
                </div>
            );
        }

        const noOfMatchesText = `${new Intl.NumberFormat().format(app.matchesPerformed)} out of ${new Intl.NumberFormat().format(app.totalMatches)}`;
        return (
            <>
                <div style={{paddingBottom: this.state.footerHeight / 2}}>
                    <PageHeader
                        titleTwo="Pair"
                        subtitle={noOfMatchesText}
                        shouldShowChangeThemesIcon
                    />

                    <KeyUp callback={this.processKeyPress} />

                    <ProfilePair
                        pair={pair}
                        previousPair={previousPair}
                        onSelectPerson={this.selectPerson}
                    />

                    {!this.props.isSmallScreenWidth && (
                        <div className="row">
                            <div className="col-sm-6 offset-sm-3 text-center">
                                <p className="text-muted mt-4">
                                    Hint: use the arrow keys to select:
                                    {' '}
                                    <code>[left]</code>
                                    {' '}
                                    or
                                    {' '}
                                    <code>[right]</code>
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="row mt-4 mt-md-0">
                        <div className="col">
                            <p className="text-center">
                                <button
                                    onClick={() => redirect(ROUTES.DONE)}
                                    className="btn btn-primary"
                                    type="button"
                                >
                                    Checkout my results so far
                                </button>
                            </p>
                            {this.props.previousPair && !isOnPreviousPair && (
                                <p className="text-center">
                                    <button
                                        onClick={() => goToPreviousPair(
                                            previousPair.A,
                                            previousPair.B,
                                            app.matchesPerformed - 1,
                                        )}
                                        className="btn btn-warning"
                                        type="button"
                                    >
                                        Go to previous match (Will revert auto-matches except manually updated ones)
                                    </button>
                                </p>
                            )}
                        </div>
                    </div>

                    <PairInstructions />
                </div>
                {this.props.isSmallScreenWidth && (
                    <div
                        ref={(node) => {
                            if (!node || node.clientHeight === this.state.footerHeight) {
                                return;
                            }
                            this.setState({footerHeight: node.clientHeight});
                        }}
                        className="fixed-bottom container-fluid page-background"
                    >
                        <div className="row my-2">
                            <div className="col pr-1">
                                <button
                                    onClick={() => this.selectPerson('A')}
                                    className="btn btn-success font-size-bigger w-100 h-100 py-3"
                                    type="button"
                                >
                                    {this.props.pair.A.name}
                                </button>
                            </div>
                            <div className="col pl-1">
                                <button
                                    onClick={() => this.selectPerson('B')}
                                    className="btn btn-success font-size-bigger w-100 h-100 py-3"
                                    type="button"
                                >
                                    {this.props.pair.B.name}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

PairPage.propTypes = propTypes;
PairPage.defaultProps = defaultProps;

export default compose(
    withWindowDimensions,
    withOnyx({
        app: {key: ONYXKEYS.APP},
        pair: {key: ONYXKEYS.PAIR},
        previousPair: {key: ONYXKEYS.PREVIOUS_PAIR},
    }),
)(PairPage);
