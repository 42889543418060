import React from 'react';
import {withOnyx} from 'react-native-onyx';
import lodashGet from 'lodash/get';
import lodashTruncate from 'lodash/truncate';
import _ from 'underscore';
import PropTypes from 'prop-types';
import * as PairPropTypes from './pair/propTypesPair';
import ONYXKEYS from '../ONYXKEYS';
import ROUTES from '../ROUTES';
import * as App from '../libs/actions/App';
import EmailSelector from '../components/EmailSelector';
import getProfile from '../libs/actions/Profile';
import * as Compensation from '../libs/actions/Compensation';
import PageHeader from '../components/PageHeader';
import Teams from './pair/Teams';
import UsefulLinks from './pair/UsefulLinks';
import Projects from './pair/Projects';
import ChronosHours from './pair/ChronosHours';
import Contributions from './pair/Contributions';
import KPIData from './pair/KPIData';
import Reputation from './pair/Reputation';
import GitHubComments from './pair/GitHubComments';
import DescriptionEditor from './DescriptionEditor';
import * as Description from '../libs/actions/Description';
import UserAccounts from '../components/UserAccounts';
import FigmaContributions from './pair/FigmaContributions';
import SlackNumbers from '../components/SlackNumbers';
import AmbassadorEventDays from '../components/AmbassadorEventDays';

const propTypes = {
    /** A list of all employee emails */
    emails: PropTypes.arrayOf(PropTypes.string),

    /** The route match containing the userName param */
    match: PropTypes.shape({
        params: PropTypes.shape({
            userName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,

    /** Basic data about the user's profile */
    profile: PropTypes.shape({
        /** Email address */
        email: PropTypes.string,

        /** Full name */
        name: PropTypes.string,

        /** Whether or not the profile is loading */
        loading: PropTypes.bool,

        /** Their slackID from the whitelist */
        slackID: PropTypes.string,

        /** Their teams from the whitelist */
        teams: PropTypes.arrayOf(PropTypes.string),

        /** An array of new teams that this person has been added to */
        newTeams: PropTypes.arrayOf(PropTypes.string),

        /** An array of teams that the person has been on for a while */
        oldTeams: PropTypes.arrayOf(PropTypes.string),

        /** Any error that occurred while loading the profile */
        error: PropTypes.string,

        /** Slack numbers for the person */
        slackNumbers: PropTypes.shape({
            /** Number of slack messages for this person */
            messages: PropTypes.number,

            /** Number of slack channels for this person */
            channels: PropTypes.number,
        }),

        /** Whether or not work descriptions are currently locked */
        workDescriptionsLocked: PropTypes.bool,
    }),

    /** Contains the github contribution information for each user (by email address) that we've already looked up. */
    githubContributions: PairPropTypes.githubContributionProps,

    /** Contains the figma contribution information for each user (by email address) that we've already looked up. */
    figmaContributions: PropTypes.objectOf(PairPropTypes.figmaContributionProps),

    /** Contains the total Concierge chats received for each user (by email address) that we've already looked up. */
    conciergeTotalCustomerChatsReceived: PairPropTypes.conciergeTotalCustomerChatsReceivedProps,

    /** Contains the total Concierge QA chats handled by each user (by email address) that we're already looked up. */
    conciergeTotalQAChatsHandled: PairPropTypes.conciergeTotalQAChatsHandledProps,

    /** Contains the last six months' worth of top projects for each user (by email address) stored in Chronos. */
    chronosTopProjects: PairPropTypes.chronosTopProjectProps,

    /** An integer showing the amount of hours a user has worked since last comp review */
    chronosHoursWorked: PairPropTypes.chronosHoursWorkedProps,

    /** Contains the user's Stackoverflow reputation */
    stackoverflowReputation: PairPropTypes.stackoverflowReputationProps,

    /** Contains the user's number of GitHub comments */
    githubComments: PairPropTypes.githubCommentsProps,

    /** Contains the Slack username for each user */
    slackUsername: PropTypes.objectOf(PropTypes.shape({
        /** The Slack username */
        username: PropTypes.string,

        /** Today's date */
        date: PropTypes.string,
    })),

    /** Information about the person's work description */
    workDescription: PropTypes.shape({
        /** The work description for the current comp period */
        description: PropTypes.string,

        /** The work description for the previous comp period */
        previousDescription: PropTypes.string,

        /** Whether or not the work description is loading */
        loading: PropTypes.bool,

        /** Any error that occurred while saving the description */
        error: PropTypes.string,
    }),

    /** Information on the current session */
    session: PropTypes.shape({
        /** The email address of the person in the current session */
        email: PropTypes.string.isRequired,
    }).isRequired,
};

const defaultProps = {
    emails: null,
    profile: null,
    githubContributions: {},
    figmaContributions: {},
    conciergeTotalCustomerChatsReceived: {},
    conciergeTotalQAChatsHandled: {},
    chronosTopProjects: {},
    chronosHoursWorked: {},
    slackUsername: {},
    workDescription: {},
    stackoverflowReputation: {},
    githubComments: {},
};

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.currentProfileEmail = `${props.match.params.userName}@expensify.com`;

        this.updateDescription = this.updateDescription.bind(this);
        this.toggleDescriptionEdit = this.toggleDescriptionEdit.bind(this);
        this.saveWorkDescription = this.saveWorkDescription.bind(this);
        this.viewOtherProfile = this.viewOtherProfile.bind(this);

        this.state = {
            isEditingDescription: false,
            description: '',
        };
    }

    componentDidMount() {
        Compensation.getAllEmails();
        this.loadProfile();
    }

    componentDidUpdate(prevProps) {
        const prevUserName = prevProps.match.params.userName;
        const userName = this.props.match.params.userName;
        if (prevUserName === userName) {
            return;
        }
        this.currentProfileEmail = `${this.props.match.params.userName}@expensify.com`;
        this.loadProfile();
    }

    loadProfile() {
        getProfile(this.currentProfileEmail);
        Description.get(this.currentProfileEmail);
    }

    saveWorkDescription() {
        const profile = this.props.profile[this.currentProfileEmail];
        Description.save(profile.email, this.state.description);

        // Set the form back to a non-editing state
        this.setState({
            isEditingDescription: false,
        });
    }

    toggleDescriptionEdit() {
        this.setState(({isEditingDescription}) => ({
            isEditingDescription: !isEditingDescription,
            description: this.props.workDescription[this.currentProfileEmail].description,
        }));
    }

    /**
     * @param {SyntheticEvent} e
     */
    updateDescription(e) {
        const newDescription = lodashTruncate(e.target.value, {length: 280, omission: ''});
        this.setState({description: newDescription});
    }

    /**
     * @param {String} email
     */
    viewOtherProfile(email) {
        this.setState({
            isEditingDescription: false,
            description: '',
        }, () => {
            App.redirect(ROUTES.getProfileRoute(email));
        });
    }

    render() {
        const profile = lodashGet(this.props, ['profile', this.currentProfileEmail]);
        const workDescription = lodashGet(this.props, ['workDescription', this.currentProfileEmail]);

        if (!profile || profile.loading) {
            return (
                <div>
                    <span className="spinner" />
                </div>
            );
        }

        if (profile.error) {
            return (
                <>
                    <PageHeader
                        titleTwo="Profile"
                        shouldShowChangeThemesIcon
                    />
                    <div className="ml-n3">
                        <div className="row">
                            <button
                                onClick={() => App.redirect(ROUTES.INSTRUCTIONS)}
                                className="btn button-row btn-primary ml-3 mr-2"
                                type="button"
                            >
                                Back to instructions
                            </button>
                        </div>
                        <div className="row container-header mt-3">
                            <div className="col-sm-5 align-self-center">
                                <h3 className="d-inline">
                                    {profile.error}
                                </h3>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        const sortedEmails = this.props.emails && this.props.emails.length
            ? this.props.emails.sort()
            : [this.currentProfileEmail];

        return (
            <>
                <PageHeader
                    titleTwo="Profile"
                    shouldShowChangeThemesIcon
                />
                <div>
                    <div className="d-block d-sm-flex">
                        <button
                            onClick={() => App.redirect(ROUTES.INSTRUCTIONS)}
                            className="btn btn-primary button-row mr-sm-3 mb-3"
                            type="button"
                        >
                            Back to instructions
                        </button>
                        <EmailSelector
                            emails={sortedEmails}
                            onSelect={this.viewOtherProfile}
                            value={this.currentProfileEmail}
                        />
                        {!this.props.emails && (
                            <div className="mt-n3 micro-text text-secondary-color">
                                <span className="mr-1">Loading profiles</span>
                                <span className="spinner micro-spinner" />
                            </div>
                        )}
                    </div>
                    <h3 className="mt-3">
                        {profile.name}
                    </h3>
                    <div className="mw-500 mt-3">
                        {this.state.isEditingDescription ? (
                            <>
                                {workDescription.previousDescription && (
                                    <>
                                        <h4>Previous Comp Period Description:</h4>
                                        <div className="description ml-4 mb-4">
                                            {workDescription.previousDescription}
                                        </div>
                                    </>
                                )}

                                {!_.isUndefined(this.state.description) && (
                                    <>
                                        <DescriptionEditor
                                            description={this.state.description}
                                            updateDescription={this.updateDescription}
                                        />
                                        <div>
                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={this.saveWorkDescription}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="btn btn-secondary ml-2"
                                                type="button"
                                                onClick={this.toggleDescriptionEdit}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {!workDescription || workDescription.loading ? (
                                    <div>
                                        <span className="spinner" />
                                    </div>
                                ) : (
                                    <>
                                        <div className="description">{workDescription.description}</div>
                                        {this.props.session.email === this.currentProfileEmail && !profile.workDescriptionsLocked && (
                                            <button
                                                className="btn btn-primary mt-2"
                                                type="button"
                                                onClick={this.toggleDescriptionEdit}
                                            >
                                                {workDescription.description === '' ? 'Add work description' : 'Edit'}
                                            </button>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {profile.workDescriptionsLocked && (
                            <div className="alert alert-danger">
                                The time period to update your work description has passed. If you&apos;d still like to update it, refer to
                                {' '}
                                <a
                                    href="https://stackoverflow.com/c/expensify/questions/13544/13545#13545"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    this SO
                                </a>
                                .
                            </div>
                        )}

                        <UserAccounts
                            email={profile.email}
                            slackID={profile.slackID}
                            slackUserName={lodashGet(this.props.slackUsername, [this.currentProfileEmail, 'username'])}
                            githubUserName={lodashGet(this.props.githubContributions, [this.currentProfileEmail, 'contributions', 'username'], '')}
                        />
                    </div>

                    <div className="mt-4">
                        <UsefulLinks email={profile.email} slackID={profile.slackID} />
                    </div>

                    <div className="mt-3 text-break">
                        <Teams
                            teams={profile.teams}
                            newTeams={profile.newTeams}
                            oldTeams={profile.oldTeams}
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <ChronosHours
                            chronosHoursWorked={lodashGet(this.props.chronosHoursWorked, [this.currentProfileEmail, 'hoursWorked'])}
                        />
                    </div>
                    <div className="mt-3">
                        <Projects chronosTopProjects={
                            {
                                topProjects: lodashGet(this.props.chronosTopProjects, [this.currentProfileEmail, 'topProjects']),
                                error: lodashGet(this.props.chronosTopProjects, [this.currentProfileEmail, 'error']),
                            }
                        }
                        />
                    </div>
                    <div className="mw-300 mt-3 comparison-container">
                        <Contributions
                            contributions={lodashGet(this.props.githubContributions, [this.currentProfileEmail, 'contributions'])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.githubContributions, [this.currentProfileEmail, 'contributions'])).keys()}
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <KPIData
                            totalChats={lodashGet(this.props.conciergeTotalCustomerChatsReceived, [this.currentProfileEmail, 'totalCustomerChatsReceived'])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.conciergeTotalCustomerChatsReceived, [this.currentProfileEmail, 'totalCustomerChatsReceived'])).keys()}
                            totalLabel="Concierge Chats Total"
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <KPIData
                            totalChats={lodashGet(this.props.conciergeTotalQAChatsHandled, [this.currentProfileEmail, 'totalQAChatsHandled'])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.conciergeTotalQAChatsHandled, [this.currentProfileEmail, 'totalQAChatsHandled'])).keys()}
                            totalLabel="Concierge QA Chats Total"
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <SlackNumbers
                            channels={profile.slackNumbers ? profile.slackNumbers.channels : 0}
                            boldChannels={false}
                            messages={profile.slackNumbers ? profile.slackNumbers.messages : 0}
                            boldMessages={false}
                            left
                            profile
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <GitHubComments
                            githubComments={lodashGet(this.props.githubComments, [this.currentProfileEmail])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.githubComments, [this.currentProfileEmail])).keys()}
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <FigmaContributions
                            contributions={lodashGet(this.props.figmaContributions, [this.currentProfileEmail])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.figmaContributions, [this.currentProfileEmail])).keys()}
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <Reputation
                            reputation={lodashGet(this.props.stackoverflowReputation, [this.currentProfileEmail])}
                            rowClasses="m-0"
                            titleClasses="col-9 text-secondary-color text-left"
                            valueClasses="col-3 text-right"
                            keysToShow={_(lodashGet(this.props.stackoverflowReputation, [this.currentProfileEmail])).keys()}
                        />
                    </div>
                    <div className="mw-300 mt-4 comparison-container">
                        <AmbassadorEventDays
                            ambassadorEventDays={profile.ambassadorEventDays}
                            left
                            profile
                        />
                    </div>
                </div>
            </>
        );
    }
}

ProfilePage.propTypes = propTypes;
ProfilePage.defaultProps = defaultProps;

export default withOnyx({
    emails: {
        key: ONYXKEYS.EMAILS,
    },
    workDescription: {
        key: ONYXKEYS.WORK_DESCRIPTION,
    },
    profile: {
        key: ONYXKEYS.PROFILE,
    },
    session: {
        key: ONYXKEYS.SESSION,
    },
    githubContributions: {
        key: ONYXKEYS.GITHUB_CONTRIBUTIONS,
    },
    conciergeTotalCustomerChatsReceived: {
        key: ONYXKEYS.CONCIERGE_TOTAL_CUSTOMER_CHATS_RECEIVED,
    },
    conciergeTotalQAChatsHandled: {
        key: ONYXKEYS.CONCIERGE_TOTAL_QA_CHATS_HANDLED,
    },
    chronosTopProjects: {
        key: ONYXKEYS.CHRONOS_TOP_PROJECTS,
    },
    chronosHoursWorked: {
        key: ONYXKEYS.CHRONOS_HOURS_WORKED,
    },
    stackoverflowReputation: {
        key: ONYXKEYS.STACKOVERFLOW_REPUTATION,
    },
    githubComments: {
        key: ONYXKEYS.GITHUB_COMMENTS,
    },
    slackUsername: {
        key: ONYXKEYS.SLACK_USERNAME,
    },
    figmaContributions: {
        key: ONYXKEYS.FIGMA_CONTRIBUTIONS,
    },
})(ProfilePage);
