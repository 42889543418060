import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The description being edited */
    description: PropTypes.string.isRequired,

    /** Callback method for when the description has been updated */
    updateDescription: PropTypes.func.isRequired,
};

const DescriptionEditor = props => (
    <>
        <p>In 280 characters or less, let people know what you&apos;ve been working on.</p>

        <textarea
            className="form-control flex-grow-1"
            rows="5"
            value={props.description}
            onChange={props.updateDescription}
        />
                Limit
        {' '}
        {props.description.length}
                /280
    </>
);

DescriptionEditor.propTypes = propTypes;
DescriptionEditor.displayName = 'CurrencySelector';
export default DescriptionEditor;
