import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';

import PageHeader from '../../components/PageHeader';
import DoneRow from './DoneRow';
import {redirect} from '../../libs/actions/App';
import {getAllMatchResults, saveNote} from '../../libs/actions/Pair';
import ROUTES from '../../ROUTES';
import ONYXKEYS from '../../ONYXKEYS';
import appPropTypes from '../../propTypesApp';

const propTypes = {
    /** Data about the state of the entire application */
    app: appPropTypes,

    /** An array of all the match results so far */
    allMatchResults: PropTypes.arrayOf(PropTypes.shape({
        /** The email of the person whose results are being shown */
        email: PropTypes.string.isRequired,

        /** The full name of the person */
        name: PropTypes.string.isRequired,

        /** Any notes written about the person */
        note: PropTypes.string.isRequired,

        /** The win percentage of the person */
        winPercent: PropTypes.number.isRequired,
    })),
};

const defaultProps = {
    app: {},
    allMatchResults: [],
};

class DonePage extends React.Component {
    componentDidMount() {
        getAllMatchResults();
    }

    /**
     * Save the note to the database (API)
     *
     * @param {String} note
     * @param {String} email
     */
    saveNote(note, email) {
        saveNote(email, note);
    }

    render() {
        return (
            <>
                <PageHeader titleTwo="Done" />
                <button
                    onClick={() => redirect(ROUTES.PAIR)}
                    className="btn btn-primary mr-2"
                    type="button"
                >
                    Do more matches
                </button>
                <button
                    onClick={() => redirect(ROUTES.INSTRUCTIONS)}
                    className="btn btn-primary mr-2"
                    type="button"
                >
                    Go to start page
                </button>

                {this.props.app.loading && (
                    <div className="text-center m-5">Loading...</div>
                )}

                {!this.props.app.loading && !this.props.allMatchResults.length && (
                    <div className="text-center m-5">No results yet</div>
                )}

                {!this.props.app.loading && this.props.allMatchResults.length > 0 && (
                    <div className="striped-rows mt-5">
                        {this.props.allMatchResults.map(row => (
                            <DoneRow
                                key={row.email}
                                email={row.email}
                                name={row.name}
                                note={row.note}
                                winPercent={row.winPercent}
                                onSaveNote={note => this.saveNote(note, row.email)}
                            />
                        ))}
                    </div>
                )}
            </>
        );
    }
}

DonePage.propTypes = propTypes;
DonePage.defaultProps = defaultProps;

export default withOnyx({
    app: {key: ONYXKEYS.APP},
    allMatchResults: {key: ONYXKEYS.ALL_MATCH_RESULTS},
})(DonePage);
