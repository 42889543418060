/**
 * An accurate way to round to two decimals
 * Taken from https://stackoverflow.com/a/11832950/9114791
 *
 * @param {Number} value
 * @returns {Number}
 */
function round(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

export default round;
