import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** If this is the field currently being sorted */
    isCurrentlySorting: PropTypes.bool.isRequired,

    /** The direction data is being sorted in */
    sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,

    /** A callback function that is triggered when the sort changes */
    onChange: PropTypes.func.isRequired,
};

const TableSortButtons = ({isCurrentlySorting, sortDirection, onChange}) => (
    <>
        <button
            type="button"
            // eslint-disable-next-line max-len
            className={`ml-2 btn btn-sm ${isCurrentlySorting && sortDirection === 'asc' ? 'btn-success' : 'btn-primary'}`}
            onClick={() => onChange('asc')}
        >
            ▲
        </button>
        <button
            type="button"
            // eslint-disable-next-line max-len
            className={`btn btn-sm ${isCurrentlySorting && sortDirection === 'desc' ? 'btn-success' : 'btn-primary'}`}
            onClick={() => onChange('desc')}
        >
            ▼
        </button>
    </>
);

TableSortButtons.propTypes = propTypes;
TableSortButtons.displayName = 'TableSortButtons';

export default TableSortButtons;
