/**
 * This is a file containing constants for all of the routes we want to be able to go to
 */
export default {
    AUTOMATCHES: '/automatches/:higherEmail/:lowerEmail',
    getAutoMatchesRoute: (higherEmail, lowerEmail) => `/automatches/${higherEmail}/${lowerEmail}`,
    DONE: '/done',
    COMPENSATION: '/compensation',
    COMPENSATION_NEW: '/compensation/new',
    COMPENSATION_EDIT: '/compensation/edit',
    COMPENSATION_EDIT_EMAIL: '/compensation/edit/:email',
    getCompensationEditEmailRoute: email => `/compensation/edit/${email}`,
    COMPENSATION_OVERVIEW: '/compensation/overview',
    COMPENSATION_CONFIRM_UPLOAD: '/compensation/confirmUpload',
    MANAGEMATCHES_OVERVIEW: '/managematches/overview',
    HOME: '/home',
    INSTRUCTIONS: '/instructions',
    MATCHES: '/matches/:email',
    getMatchesRoute: email => `/matches/${email}`,
    PAIR: '/pair',
    PROFILE: '/profile/:userName',
    getProfileRoute: email => `/profile/${email.split('@')[0]}`,
    ROOT: '/',
    SIGNIN: '/signin',
};
