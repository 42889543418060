import Onyx from 'react-native-onyx';
import * as API from '../APIComp';
import ONYXKEYS from '../../ONYXKEYS';

/**
 * @param {String} email
 */
function get(email) {
    Onyx.merge(ONYXKEYS.WORK_DESCRIPTION, {[email]: {loading: true}});
    API.Description_Get({email: email || ''})
        .then((data) => {
            if (data.jsonCode !== 200) {
                return;
            }
            Onyx.merge(ONYXKEYS.WORK_DESCRIPTION, {
                [email]: {
                    description: data.data.description,
                    previousDescription: data.data.previousDescription,
                },
            });
        })
        .finally(() => Onyx.merge(ONYXKEYS.WORK_DESCRIPTION, {[email]: {loading: false}}));
}

/**
 * Immediately update the description in Onyx, then save.
 * @param {String} email
 * @param {String} description
 */
function save(email, description) {
    Onyx.merge(ONYXKEYS.WORK_DESCRIPTION, {[email]: {description, error: ''}});
    API.Description_Save({email, description})
        .then((data) => {
            if (data.jsonCode === 200) {
                return;
            }
            Onyx.merge(ONYXKEYS.WORK_DESCRIPTION, {
                [email]: {
                    error: data.data.error,
                },
            });
        });
}

export {
    get,
    save,
};
