import lodashGet from 'lodash/get';
import {KEYS} from '../../../libs/actions/Metric';

/**
 * Format Concierge customer KPI data for comparison.
 *
 * @param {Object} kpis - see PropTypesPair.propTypesTotalChatsReceived for shape
 * @returns {Array<Object>}
 */
function formatConciergeMetrics(kpis) {
    return [
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.FIRST_RESPONDER,
            label: 'First Responder',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.FIRST_RESPONDER, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.FIRST_RESPONDER_PLUS,
            label: 'First Responder Plus',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.FIRST_RESPONDER_PLUS, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.TRIAGE,
            label: 'Triage',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.TRIAGE, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.SUPPORTAL,
            label: 'Coach',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.SUPPORTAL, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.LAUNCH,
            label: 'Launch',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.LAUNCH, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.OPS,
            label: 'Ops',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.OPS, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.C4B,
            label: 'C4B',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.C4B, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.MANIFESTO,
            label: 'Manifesto',
            value: lodashGet(kpis, 'manifesto', 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.RETENTION,
            label: 'Retention',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.RETENTION, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.SFO_BAR,
            label: 'SFO Bar',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.SFO_BAR, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.PDX_BAR,
            label: 'PDX Bar',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.PDX_BAR, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.SFO_LOUNGE,
            label: 'SFO Lounge',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.SFO_LOUNGE, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.PDX_LOUNGE,
            label: 'PDX Lounge',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.PDX_LOUNGE, 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.RESEARCH_TEAM,
            label: 'Research',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.RESEARCH_TEAM, 0),
        },
        {
            label: 'Tier Not Recorded',
            value: lodashGet(kpis, [''], 0),
        },
        {
            key: KEYS.CONCIERGE.CUSTOMER_CHATS.TOTAL,
            label: 'Concierge Chats Total',
            value: lodashGet(kpis, KEYS.CONCIERGE.CUSTOMER_CHATS.TOTAL, 0),
        },
    ];
}

function formatConciergeQAMetrics(kpis) {
    return [
        {
            key: KEYS.CONCIERGE.QA_CHATS.TOTAL,
            label: 'Concierge QA Chats Handled',
            value: lodashGet(kpis, 'total', 0),
        },
    ];
}

/**
 * Format GitHub contribution data for comparison.
 *
 * @param {Object} contributions – see PropTypesPair.propTypesContributions for shape
 * @returns {Array<Object>}
 */
function formatGitHubMetrics(contributions) {
    return [
        {
            key: KEYS.GITHUB.OPENED_ISSUES,
            label: 'Opened GH Issues',
            value: lodashGet(contributions, KEYS.GITHUB.OPENED_ISSUES, 0),
        },
        {
            key: KEYS.GITHUB.CLOSED_ISSUES,
            label: 'Closed, Assigned GH Issues',
            value: lodashGet(contributions, KEYS.GITHUB.CLOSED_ISSUES, 0),
        },
        {
            key: KEYS.GITHUB.PULL_REQUESTS,
            label: 'Pull Requests',
            value: lodashGet(contributions, KEYS.GITHUB.PULL_REQUESTS, 0),
        },
        {
            key: KEYS.GITHUB.REVIEWS,
            label: 'Reviews',
            value: lodashGet(contributions, KEYS.GITHUB.REVIEWS, 0),
        },
        {
            key: KEYS.GITHUB.COMMITS,
            label: 'Commits',
            value: lodashGet(contributions, KEYS.GITHUB.COMMITS, 0),
        },
        {
            key: KEYS.GITHUB.OPEN_SOURCE,
            label: '#expensify-open-source',
            value: lodashGet(contributions, KEYS.GITHUB.OPEN_SOURCE, 0),
        },
        {
            key: KEYS.GITHUB.TOTAL,
            label: 'Total GH Contributions',
            value: lodashGet(contributions, KEYS.GITHUB.TOTAL, 0),
        },
    ];
}

/**
 * Format slack metrics for comparison.
 *
 * @param {Object} metrics
 * @param {Number} metrics.channels
 * @param {Number} metrics.messages
 * @returns {Array<Object>}
 */
function formatSlackMetrics(metrics) {
    return [
        {
            key: KEYS.SLACK.CHANNELS,
            label: 'Slack Work Channels',
            value: lodashGet(metrics, KEYS.SLACK.CHANNELS),
        },
        {
            key: KEYS.SLACK.MESSAGES,
            label: 'Slack Work Messages',
            value: lodashGet(metrics, KEYS.SLACK.MESSAGES),
        },
    ];
}

/**
 * Format Github comment metrics for comparison.
 *
 * @param {Object} metrics – see PropTypesPair.githubCommentsProps for shape
 * @returns {Array<Object>}
 */
function formatGHCommentsMetrics(metrics) {
    return [
        {
            key: KEYS.GITHUB.COMMENTS,
            label: 'GitHub Comments',
            value: lodashGet(metrics, KEYS.GITHUB.COMMENTS),
        },
    ];
}

/**
 * Format StackOverflow metrics for comparison.
 *
 * @param {Object} metrics – see PropTypesPair.stackoverflowReputationProps for shape
 * @returns {Array<Object>}
 */
function formatStackOverflowMetrics(metrics) {
    return [
        {
            key: KEYS.STACK_OVERFLOW_REPUTATION,
            label: 'StackOverflow Reputation',
            value: lodashGet(metrics, KEYS.STACK_OVERFLOW_REPUTATION),
        },
    ];
}

/**
 * Format Figma metrics for comparison.
 *
 * @param {Object} metrics – see PropTypesPair.figmaContributionProps for shape
 * @returns {Array<Object>}
 */
function formatFigmaMetrics(metrics) {
    return [
        {
            key: KEYS.FIGMA_CONTRIBUTIONS,
            label: 'Figma Edits',
            value: lodashGet(metrics, KEYS.FIGMA_CONTRIBUTIONS),
        },
    ];
}

/**
 * Format Chronos metrics for comparison.
 *
 * @param {Object} metrics
 * @param {Number} metrics.hoursWorked
 * @returns {Array<Object>}
 */
function formatChronosMetrics(metrics) {
    return [
        {
            key: KEYS.CHRONOS_HOURS_WORKED,
            label: 'Chronos Hours Worked',
            value: Math.floor(lodashGet(metrics, KEYS.CHRONOS_HOURS_WORKED, 0)),
        },
    ];
}

/**
 * Format Ambassador Event Days metrics for comparison.
 *
 * @param {Number} ambassadorEventDays
 * @returns {Array<Object>}
 */
function formatAmbassadorEventDaysMetrics(ambassadorEventDays) {
    return [
        {
            key: KEYS.AMBASSADOR_EVENT_DAYS,
            label: 'Ambassador Event Days',
            value: ambassadorEventDays,
        },
    ];
}

export {
    formatConciergeMetrics,
    formatConciergeQAMetrics,
    formatGitHubMetrics,
    formatSlackMetrics,
    formatGHCommentsMetrics,
    formatStackOverflowMetrics,
    formatFigmaMetrics,
    formatChronosMetrics,
    formatAmbassadorEventDaysMetrics,
};
