import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** Number of hours worked by the user since the last comp review */
    chronosHoursWorked: PropTypes.number,

};

const defaultProps = {
    chronosHoursWorked: 0,
};

const ChronosHours = ({
    chronosHoursWorked,
}) => (
    <div className="row mb-1">
        <div className="text-left col-9 text-secondary-color">Hours Worked</div>
        <div className="col-3 text-right">
            {Math.floor(chronosHoursWorked)}
        </div>
    </div>
);

ChronosHours.propTypes = propTypes;
ChronosHours.defaultProps = defaultProps;
ChronosHours.displayName = 'ChronosHours';

export default ChronosHours;
