import React, {useState} from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import {
    dismissSetPeriodEndDateError,
    dismissSetPeriodEndDateSuccess,
    setPeriodEndDate,
} from '../../libs/actions/ManageMatches';
import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';

const propTypes = {
    ...overviewPropTypes,

    /** From Onyx - data about setting the start date for the period */
    setPeriodEndDate: PropTypes.shape({
        /** If the operation is currently in progress */
        loading: PropTypes.bool,

        /** A success message from the server */
        success: PropTypes.string,

        /** An error message from the server */
        error: PropTypes.string,
    }),
};
const defaultProps = {
    ...overviewDefaultProps,
    setPeriodEndDate: {
        loading: false,
        success: null,
        error: null,
    },
};

const ManageMatchesSetPeriodEndDate = (props) => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const todaysDate = `${yyyy}-${mm}-${dd}`;

    const [shouldShowConfirmEnd, showConfirmEnd] = useState(false);
    const [newEndDate, setEndDate] = useState(lodashGet(props, 'overview.periodDates.endDate') || todaysDate);

    return (
        <div className="card mt-4">
            <div className="card-header">
                Step 8: Record Date for when the voting ends for the current period.
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h3>
                            End Date (currently:
                            {' '}
                            <code>{lodashGet(props, 'overview.periodDates.endDate') || 'not set'}</code>
                            )
                        </h3>
                        {shouldShowConfirmEnd ? (
                            <div className="my-4">
                                <p>
                                    <strong>
                                        Are you sure you want to record the end date as
                                        <code className="mx-2">{newEndDate}</code>
                                        ?
                                    </strong>
                                </p>

                                <button
                                    type="button"
                                    className="btn btn-success mr-4"
                                    onClick={() => {
                                        setPeriodEndDate(newEndDate);
                                        showConfirmEnd(false);
                                    }}
                                >
                                    Yes, please!
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => showConfirmEnd(false)}
                                >
                                    No, cancel
                                </button>
                            </div>
                        ) : (
                            <form className="form">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="effectiveDate"
                                    required
                                    value={newEndDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary mt-4"
                                    onClick={() => showConfirmEnd(true)}
                                >
                                    Set End Date
                                </button>

                                <Spinner shouldShow={props.setPeriodEndDate.loading} optionalText="This might take a while. Don't refresh the page." />
                            </form>
                        )}
                    </div>
                </div>

                <Alert
                    shouldShow={Boolean(props.setPeriodEndDate.error)}
                    onDismiss={dismissSetPeriodEndDateError}
                    type="danger"
                >
                    <strong>Error:</strong>
                    {` ${props.setPeriodEndDate.error}`}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.setPeriodEndDate.success)}
                    onDismiss={dismissSetPeriodEndDateSuccess}
                >
                    {props.setPeriodEndDate.success}
                </Alert>
            </div>
        </div>
    );
};

ManageMatchesSetPeriodEndDate.propTypes = propTypes;
ManageMatchesSetPeriodEndDate.defaultProps = defaultProps;

export default compose(
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
        setPeriodEndDate: {
            key: ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE,
        },
        setPeriodStartDate: {
            key: ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE,
        },
    }),
)(ManageMatchesSetPeriodEndDate);
