import lodashGet from 'lodash/get';

// eslint-disable-next-line no-undef
const Config = __REACT_WEB_CONFIG__;

/**
 * Let's make everyone's life just a bit easier by adding / to the end of any config URL's if it's not already present
 * @param {String} url
 * @returns {String}
 */
function wrapWithBackslash(url) {
    if (!url.endsWith('/')) {
        return `${url}/`;
    }
    return url;
}

// Set default values to contributor friendly values to make development work out of the box without an .env file
const expensifyCompURL = wrapWithBackslash(lodashGet(Config, 'EXPENSIFY_URL_COMP', 'https://comp.expensify.com/'));
const expensifyURL = wrapWithBackslash(lodashGet(Config, 'EXPENSIFY_URL_COM', 'https://www.expensify.com/'));
const ngrokURL = wrapWithBackslash(lodashGet(Config, 'NGROK_URL', ''));
const useNgrok = lodashGet(Config, 'USE_NGROK', 'false') === 'true';
const useWebProxy = lodashGet(Config, 'USE_WEB_PROXY', 'true') === 'true';
const expensifyComWithProxy = useWebProxy ? '/' : expensifyURL;

// Ngrok helps us avoid many of our cross-domain issues with connecting to our API
// and is required for viewing images on mobile and for developing on android
// To enable, set the USE_NGROK value to true in .env and update the NGROK_URL
const expensifyURLRoot = useNgrok && ngrokURL ? ngrokURL : expensifyComWithProxy;

export default {
    EXPENSIFY: {
        URL_COMP_EXPENSIFY_COM: expensifyCompURL,
        URL_API_ROOT_EXPENSIFY: expensifyURLRoot,
        URL_API_ROOT_COMP: expensifyCompURL,
        PARTNER_NAME: lodashGet(Config, 'EXPENSIFY_PARTNER_NAME', 'chat-expensify-com'),
        PARTNER_PASSWORD: lodashGet(Config, 'EXPENSIFY_PARTNER_PASSWORD', 'e21965746fd75f82bb66'),
    },
    // eslint-disable-next-line no-undef
    IS_IN_PRODUCTION: process.env.NODE_ENV === 'production',
};
