import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {withOnyx} from 'react-native-onyx';

import ONYXKEYS from '../ONYXKEYS';
import ROUTES from '../ROUTES';
import PageHeader from '../components/PageHeader';
import {signIn, beginSignIn} from '../libs/actions/Session';

const propTypes = {
    /* Onyx Props */

    /** The details about the account that the user is signing in with */
    account: PropTypes.shape({
        /** Indicates whether the account is loading and thus the form should show a loading state */
        isLoading: PropTypes.bool,

        /** Error to display when there is an account error returned */
        error: PropTypes.string,
    }),

    /** The credentials of the person signing in */
    credentials: PropTypes.shape({
        login: PropTypes.string,
        password: PropTypes.string,
        twoFactorAuthCode: PropTypes.string,
    }),

    /** The session of the logged in person */
    session: PropTypes.shape({
        /** The authToken for the session */
        authToken: PropTypes.string,
    }),
};

const defaultProps = {
    account: {},
    session: {},
    credentials: {},
};

class SigninPage extends React.Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        this.state = {
            login: '',
            validateCode: '',
            twoFactorAuthCode: '',
            magicCodeSent: false,
        };
    }

    /**
     * When the form is submitted,  call the action for signing in
     *
     * @param {SyntheticEvent} e
     */
    submit(e) {
        e.preventDefault();
        signIn(this.state.login, this.state.validateCode, this.state.twoFactorAuthCode);
    }

    /**
     * Send magic code event
     *
     * @param {SyntheticEvent} e
     */
    sendMagicCode(e) {
        e.preventDefault();
        beginSignIn(this.state.login)
            .then((result) => {
                this.setState({magicCodeSent: result});
            });
    }

    render() {
        // If we end up on the sign in page and have an authToken then
        // we are signed in and should be brought back to the site root
        if (this.props.session.authToken) {
            return <Redirect to={ROUTES.ROOT} />;
        }
        return (
            <>
                <PageHeader titleTwo="Sign In" />
                <form onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-sm-4 col-lg-2 offset-sm-4 offset-lg-5">
                            <div className="form-group">
                                <label htmlFor="login">Email or Phone:</label>
                                <input
                                    type="login"
                                    className="form-control"
                                    id="login"
                                    name="login"
                                    value={this.state.login}
                                    onChange={e => this.setState({login: e.target.value})}
                                    required
                                />
                            </div>
                            {!this.state.magicCodeSent && (
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={e => this.sendMagicCode(e)}
                                    >
                                        Send magic code
                                    </button>
                                </div>
                            )}
                            {this.state.magicCodeSent && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="password">Magic Code:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="validateCode"
                                            name="validateCode"
                                            value={this.state.validateCode}
                                            onChange={e => this.setState({validateCode: e.target.value})}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="twoFactorAuthCode">Two Factor Code:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="twoFactorAuthCode"
                                            name="twoFactorAuthCode"
                                            value={this.state.twoFactorAuthCode}
                                            autoComplete="off"
                                            onChange={e => this.setState({twoFactorAuthCode: e.target.value})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={this.props.account.isLoading}
                                        >
                                            {!this.props.account.isLoading ? 'Log In' : (<div className="spinner" />)}
                                        </button>
                                    </div>
                                </>
                            )}

                            {this.props.account && this.props.account.error && (
                                <p className="alert alert-danger">
                                    {this.props.account.error}
                                </p>
                            )}
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

SigninPage.propTypes = propTypes;
SigninPage.defaultProps = defaultProps;

export default withOnyx({
    account: {key: ONYXKEYS.ACCOUNT},
    credentials: {key: ONYXKEYS.CREDENTIALS},
    session: {key: ONYXKEYS.SESSION},
})(SigninPage);
