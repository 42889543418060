import _ from 'underscore';
import lodashGet from 'lodash/get';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import PropTypesPair, {
    chronosHoursWorkedProps,
    conciergeTotalCustomerChatsReceivedProps,
    conciergeTotalQAChatsHandledProps,
    figmaContributionProps,
    githubContributionProps,
    stackoverflowReputationProps,
    githubCommentsProps,
} from '../propTypesPair';
import * as DataFormattingUtils from './dataFormattingUtils';
import ComparisonBlock from './ComparisonBlock';
import ONYXKEYS from '../../../ONYXKEYS';

const propTypes = {
    /** Data about the pair of people that need to be enacted upon */
    pair: PropTypesPair.isRequired,

    /** Contains the github contribution information for each user (by email address) that we've already looked up. */
    githubContributions: githubContributionProps,

    /** Contains the total Concierge chats received and QA chats handled for each user (by email address) that we've already looked up. */
    conciergeTotalCustomerChatsReceived: conciergeTotalCustomerChatsReceivedProps,

    /** Contains the total Concierge QA chats handled for each user (by email address) that we've already looked up. */
    conciergeTotalQAChatsHandled: conciergeTotalQAChatsHandledProps,

    /** Contains the total Stackoverflow reputation for each user by email address */
    stackOverflowReputation: stackoverflowReputationProps,

    /** Contains the figma contribution information for each user (by email address) that we've already looked up. */
    figmaContributions: PropTypes.objectOf(figmaContributionProps),

    /** Contains the number of hours a user has worked since the start of the last comp review to start of current */
    chronosHoursWorked: chronosHoursWorkedProps,

    /** Sets the key used to decide which metric ranking we should display, scrolls to the ranking table */
    selectMetric: PropTypes.func.isRequired,

    /** Contains the number of comments a user has posted in Github since the start of the last comp review to start of current */
    githubComments: githubCommentsProps,
};

const defaultProps = {
    githubContributions: {},
    conciergeTotalCustomerChatsReceived: {},
    conciergeTotalQAChatsHandled: {},
    stackOverflowReputation: {},
    figmaContributions: {},
    chronosHoursWorked: {},
    githubComments: {},
};

class ComparisonTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Calculate the maximum row heights from both IndividualDetails views – A and B.
     * These become the minimum row heights for both IndividualDetails views, ensuring that they line up side-by-side.
     *
     * @param {String} categoryName
     * @returns {Array<Number>}
     */
    getMinRowHeights(categoryName) {
        const minRowHeights = [];
        const rowHeightsA = lodashGet(this.state, [categoryName, 'rowHeightsA'], []);
        const rowHeightsB = lodashGet(this.state, [categoryName, 'rowHeightsB'], []);
        for (let i = 0; i < rowHeightsA.length; i++) {
            minRowHeights[i] = Math.max(rowHeightsA[i], rowHeightsB[i]);
        }
        return minRowHeights;
    }

    /**
     * @param {String} categoryName
     * @param {String} pairSide
     * @param {Array<Number>} rowHeights
     */
    updateRowHeights(categoryName, pairSide, rowHeights) {
        if (pairSide === 'A') {
            this.setState(prevState => ({[categoryName]: {rowHeightsA: rowHeights, rowHeightsB: lodashGet(prevState, [categoryName, 'rowHeightsB'], [])}}));
        } else {
            this.setState(prevState => ({[categoryName]: {rowHeightsB: rowHeights, rowHeightsA: lodashGet(prevState, [categoryName, 'rowHeightsA'], [])}}));
        }
    }

    render() {
        // First, we get all the data we need in a consistent format that can be used by the ComparisonBlock component
        const formattedData = {
            githubContributions: {
                A: DataFormattingUtils.formatGitHubMetrics(lodashGet(this.props.githubContributions, [this.props.pair.A.email, 'contributions'])),
                B: DataFormattingUtils.formatGitHubMetrics(lodashGet(this.props.githubContributions, [this.props.pair.B.email, 'contributions'])),
            },
            conciergeTotalCustomerChatsReceived: {
                A: DataFormattingUtils.formatConciergeMetrics(lodashGet(this.props.conciergeTotalCustomerChatsReceived, [this.props.pair.A.email, 'totalCustomerChatsReceived'])),
                B: DataFormattingUtils.formatConciergeMetrics(lodashGet(this.props.conciergeTotalCustomerChatsReceived, [this.props.pair.B.email, 'totalCustomerChatsReceived'])),
            },
            conciergeTotalQAChatsHandled: {
                A: DataFormattingUtils.formatConciergeQAMetrics(lodashGet(this.props.conciergeTotalQAChatsHandled, [this.props.pair.A.email, 'totalQAChatsHandled'])),
                B: DataFormattingUtils.formatConciergeQAMetrics(lodashGet(this.props.conciergeTotalQAChatsHandled, [this.props.pair.B.email, 'totalQAChatsHandled'])),
            },
            slackNumbers: {
                A: DataFormattingUtils.formatSlackMetrics(this.props.pair.A.slackNumbers),
                B: DataFormattingUtils.formatSlackMetrics(this.props.pair.B.slackNumbers),
            },
            githubComments: {
                A: DataFormattingUtils.formatGHCommentsMetrics(lodashGet(this.props.githubComments, [this.props.pair.A.email])),
                B: DataFormattingUtils.formatGHCommentsMetrics(lodashGet(this.props.githubComments, [this.props.pair.B.email])),
            },
            stackOverflowReputation: {
                A: DataFormattingUtils.formatStackOverflowMetrics(lodashGet(this.props.stackOverflowReputation, [this.props.pair.A.email])),
                B: DataFormattingUtils.formatStackOverflowMetrics(lodashGet(this.props.stackOverflowReputation, [this.props.pair.B.email])),
            },
            figmaContributions: {
                A: DataFormattingUtils.formatFigmaMetrics(lodashGet(this.props.figmaContributions, [this.props.pair.A.email])),
                B: DataFormattingUtils.formatFigmaMetrics(lodashGet(this.props.figmaContributions, [this.props.pair.B.email])),
            },
            chronosHours: {
                A: DataFormattingUtils.formatChronosMetrics(lodashGet(this.props.chronosHoursWorked, [this.props.pair.A.email])),
                B: DataFormattingUtils.formatChronosMetrics(lodashGet(this.props.chronosHoursWorked, [this.props.pair.B.email])),
            },
            ambassadorEventDays: {
                A: DataFormattingUtils.formatAmbassadorEventDaysMetrics(this.props.pair.A.ambassadorEventDays),
                B: DataFormattingUtils.formatAmbassadorEventDaysMetrics(this.props.pair.B.ambassadorEventDays),
            },
        };

        // Flatten each person's metrics into a single object for each person
        const personAMetrics = {};
        const personBMetrics = {};
        _.each(formattedData, (category) => {
            _.each(category.A, ({label, value}) => personAMetrics[label] = value);
            _.each(category.B, ({label, value}) => personBMetrics[label] = value);
        });

        // Next, determine which metrics each person out-performed the other in
        const winningMetrics = {
            A: [],
            B: [],
        };
        const metricsToExclude = [];
        _.each(formattedData, (category) => {
            _.each(category.A, ({label}) => {
                const personAValue = personAMetrics[label];
                const personBValue = personBMetrics[label];
                if (personAValue > personBValue) {
                    winningMetrics.A.push(label);
                } else if (personBValue > personAValue) {
                    winningMetrics.B.push(label);
                } else if (!personAValue && !personBValue) {
                    // If both people are missing this value or have 0 in this metric, we just won't show it to condense the comparison.
                    metricsToExclude.push(label);
                } else {
                    // This means it's a tie, so everyone wins :)
                    winningMetrics.A.push(label);
                    winningMetrics.B.push(label);
                }
            });
        });

        // Lastly, we need to filter out any data that's:
        //   - missing for both users, OR
        //   - tied at zero for both users
        const filteredData = {};
        _.each(formattedData, (category, categoryName) => {
            if (_.isEmpty(category.A) && _.isEmpty(category.B)) {
                return;
            }

            if (_.every(category.A, metric => _.contains(metricsToExclude, metric.label))) {
                return;
            }

            filteredData[categoryName] = category;
        });


        const defaultLabelClasses = 'col-9 col-md-8 text-secondary-color';
        const labelClasses = {
            left: `${defaultLabelClasses} offset-md-2 text-left`,
            right: `${defaultLabelClasses} order-2 text-right d-none d-md-block`,
        };

        return (
            <div className="mt-n1">
                <div className="row mt-3 d-md-none">
                    <div className="col" />
                    <div className="col-3 text-right">{this.props.pair.A.name}</div>
                    <div className="col-3">{this.props.pair.B.name}</div>
                </div>
                {_.map(filteredData, (category, categoryName) => (
                    <div className="row mt-4 comparison-container" key={categoryName}>
                        <div className="col-9 col-md">
                            <ComparisonBlock
                                metrics={category.A}
                                winningMetrics={winningMetrics.A}
                                metricsToExclude={metricsToExclude}
                                labelClasses={labelClasses.left}
                                valueClasses="col col-md-2 text-right"
                                minRowHeights={this.getMinRowHeights(categoryName)}
                                updateRowHeights={rowHeightsA => this.updateRowHeights(categoryName, 'A', rowHeightsA)}
                                selectMetric={this.props.selectMetric}
                            />
                        </div>
                        <div className="col">
                            <ComparisonBlock
                                metrics={category.B}
                                winningMetrics={winningMetrics.B}
                                metricsToExclude={metricsToExclude}
                                labelClasses={labelClasses.right}
                                valueClasses="col col-md-2 text-left offset-1 offset-md-0"
                                minRowHeights={this.getMinRowHeights(categoryName)}
                                updateRowHeights={rowHeightsB => this.updateRowHeights(categoryName, 'B', rowHeightsB)}
                                selectMetric={this.props.selectMetric}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

ComparisonTable.propTypes = propTypes;
ComparisonTable.defaultProps = defaultProps;

export default withOnyx({
    githubContributions: {key: ONYXKEYS.GITHUB_CONTRIBUTIONS},
    conciergeTotalCustomerChatsReceived: {key: ONYXKEYS.CONCIERGE_TOTAL_CUSTOMER_CHATS_RECEIVED},
    conciergeTotalQAChatsHandled: {key: ONYXKEYS.CONCIERGE_TOTAL_QA_CHATS_HANDLED},
    stackOverflowReputation: {key: ONYXKEYS.STACKOVERFLOW_REPUTATION},
    figmaContributions: {key: ONYXKEYS.FIGMA_CONTRIBUTIONS},
    chronosHoursWorked: {key: ONYXKEYS.CHRONOS_HOURS_WORKED},
    githubComments: {key: ONYXKEYS.GITHUB_COMMENTS},
})(ComparisonTable);
