import Onyx from 'react-native-onyx';
import {Permissions_Get} from '../APIComp';
import ONYXKEYS from '../../ONYXKEYS';

function getPermissions() {
    Onyx.merge(ONYXKEYS.PERMISSIONS, {loading: true});
    Permissions_Get()
        .then((response) => {
            const dataToMergeIntoOnyx = {
                loading: false,
            };

            if (response.jsonCode === 200) {
                dataToMergeIntoOnyx.canAccessCompensation = response.data.canAccessCompensation;
                dataToMergeIntoOnyx.canAccessManageMatches = response.data.canAccessManageMatches;
            }

            Onyx.merge(ONYXKEYS.PERMISSIONS, dataToMergeIntoOnyx);
        });
}

export {
    // eslint-disable-next-line import/prefer-default-export
    getPermissions,
};
