import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The number of ambassador event days this person attended during the Comp period */
    ambassadorEventDays: PropTypes.number.isRequired,
};

const AmbassadorEventDays = ({ambassadorEventDays}) => (
    <div className="row mb-1">
        <div className="text-left col-9 text-secondary-color">Ambassador Event Days</div>
        <div className="col-3 text-right">
            {ambassadorEventDays}
        </div>
    </div>
);

AmbassadorEventDays.propTypes = propTypes;
AmbassadorEventDays.displayName = 'AmbassadorEventDays';

export default AmbassadorEventDays;
