import React from 'react';
import {withOnyx} from 'react-native-onyx';
import ONYXKEYS from '../../ONYXKEYS';
import {toggleWorkDescriptionsLocked} from '../../libs/actions/ManageMatches';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';

const propTypes = {
    ...overviewPropTypes,
};
const defaultProps = {
    ...overviewDefaultProps,
};
const ManageMatchesToggleWorkDescriptions = props => (
    <div className="card">
        <div className="card-header">
            Step 4: Manage if people can edit their work descriptions
        </div>
        <div className="card-body">
            Do this
            <strong> two weeks </strong>
            before comp review begins in order to give people time to write their descriptions and for senior leaders to approve them.
            <br />
            <br />
            <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={() => toggleWorkDescriptionsLocked(!props.overview.workDescriptionsLocked)}
            >
                {`${props.overview.workDescriptionsLocked ? 'Unlock' : 'Lock'} Work Descriptions`}
            </button>
            <br />
            <br />
            {`${props.overview.workDescriptionsLocked ? 'They are currently locked and people cannot edit them' : 'They are currently unlocked and people can edit them'}.`}
        </div>
    </div>
);
ManageMatchesToggleWorkDescriptions.propTypes = propTypes;
ManageMatchesToggleWorkDescriptions.defaultProps = defaultProps;

export default withOnyx({
    overview: {
        key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
    },
})(ManageMatchesToggleWorkDescriptions);
