import Onyx from 'react-native-onyx';
import _ from 'underscore';
import ONYXKEYS from '../../ONYXKEYS';
import {
    ManageMatches_Exclusions_Add,
    ManageMatches_Exclusions_Remove,
    ManageMatches_Exclusions,
    ManageMatches_Generate,
    ManageMatches_Overview,
    ManageMatches_OverviewDetails,
    ManageMatches_RemoveFromComp,
    ManageMatches_Reset,
    ManageMatches_SetCurrentPeriod,
    ManageMatches_SetPeriodEnd,
    ManageMatches_SetPeriodStart,
    ManageMatches_ToggleWorkDescriptionsLocked,
    ManageMatches_Participants,
} from '../APIComp';

function dismissExclusionsError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
        error: null,
    });
}

function dismissGenerateError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE, {
        error: null,
    });
}

function dismissGenerateSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE, {
        success: null,
    });
}

function dismissGenerateAllError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
        error: null,
    });
}

function dismissGenerateAllSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
        success: null,
    });
}

function dismissResetError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.RESET, {
        error: null,
    });
}

function dismissResetSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.RESET, {
        success: null,
    });
}

function dismissSetPeriodEndDateError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE, {
        error: null,
    });
}

function dismissSetPeriodEndDateSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE, {
        success: null,
    });
}

function dismissSetPeriodStartDateError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE, {
        error: null,
    });
}

function dismissSetPeriodStartDateSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE, {
        success: null,
    });
}

function dismissRemoveFromCompError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.REMOVE_FROM_COMP, {
        error: null,
    });
}

function dismissRemoveFromCompSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.REMOVE_FROM_COMP, {
        success: null,
    });
}

function dismissSetCurrentPeriodError() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD, {
        error: null,
    });
}

function dismissSetCurrentPeriodSuccess() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD, {
        success: null,
    });
}

function getManageMatchesOverview() {
    ManageMatches_Overview()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.MANAGEMATCHES.OVERVIEW, response.data);
            }
        });
}

function getManageMatchesOverviewDetails() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.OVERVIEW, {
        isLoadingDetails: true,
    });
    ManageMatches_OverviewDetails()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.merge(ONYXKEYS.MANAGEMATCHES.OVERVIEW, {
                    ...response.data,
                    isLoadingDetails: false,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.OVERVIEW, {
                isLoadingDetails: false,
            });
        });
}

/**
 * @param {String[]} [emails] a specific set of emails to generate matches for
 */
function generateMatches(emails) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_Generate({emails: JSON.stringify(emails)})
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE, {
                loading: false,
                error: response.data.error,
            });
        });
}

function setPeriodEndDate(date) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_SetPeriodEnd({date})
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODENDDATE, {
                loading: false,
            });
        });
}

function setPeriodStartDate(date) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_SetPeriodStart({date})
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETPERIODSTARTDATE, {
                loading: false,
            });
        });
}

function generateAllMatches() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
        loading: true,
        success: null,
        error: null,
        participants: [],
        participantsCompleted: 0,
    });
    ManageMatches_Participants()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
                    participants: response.data,
                    participantsCompleted: 0,
                });

                // Loop through all the participants and generate matches for each one
                const starterPromise = Promise.resolve(null);
                let numberCompleted = 0;

                // Generate matches in chunks of 10 so there is not so much overhead from a bunch of XHRs
                const chunkSize = 10;
                const chunkedEmails = _.chunk(response.data, chunkSize);

                // This is the task that runs for each group of emails, just generating matches for them and then updating the progress in Onyx
                const task = emails => ManageMatches_Generate({emails: JSON.stringify(emails)})
                    .then(() => {
                        numberCompleted += chunkSize;
                        Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
                            participantsCompleted: numberCompleted,
                        });
                    });

                // Run each promise in sequence, waiting for the previous one to be complete
                _.reduce(chunkedEmails, (promise, emails) => promise.then(() => task(emails)), starterPromise)
                    .then(() => {
                        // Now all the matches are done so we can reset the UI and load all the overview information
                        Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
                            loading: false,
                            participants: [],
                            participantsCompleted: 0,
                        });
                        getManageMatchesOverview();
                    });
                return;
            }

            Onyx.merge(ONYXKEYS.MANAGEMATCHES.GENERATE_ALL, {
                loading: false,
                error: 'Could not get a list of participants',
            });
        });
}

/**
 * @param {String[]} [emails] a specific set of emails to generate matches for
 */
function removeFromComp(emails) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.REMOVE_FROM_COMP, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_RemoveFromComp({emails: JSON.stringify(emails)})
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.REMOVE_FROM_COMP, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.REMOVE_FROM_COMP, {
                loading: false,
            });
        });
}

function resetMatches() {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.RESET, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_Reset()
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.RESET, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.RESET, {
                loading: false,
            });
        });
}

/**
 * @param {String} period
 */
function setCurrentPeriod(period) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD, {
        loading: true,
        success: null,
        error: null,
    });
    ManageMatches_SetCurrentPeriod({period})
        .then((response) => {
            if (response.jsonCode === 200) {
                // Get the updated overview information
                getManageMatchesOverview();

                Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD, {
                    loading: false,
                    success: response.data.success,
                    error: response.data.error,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD, {
                loading: false,
                error: response.data.error,
            });
        });
}

/**
 * @param {Boolean} isLocked
 */
function toggleWorkDescriptionsLocked(isLocked) {
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.OVERVIEW, {
        workDescriptionsLocked: isLocked,
    });
    ManageMatches_ToggleWorkDescriptionsLocked({isLocked});
}

/**
 * @param {String} email
 * @param {String[]} existingExclusions
 */
function addExclusion(email, existingExclusions) {
    // Add it optimistically
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
        emails: [...existingExclusions, email],
        error: null,
    });
    ManageMatches_Exclusions_Add({email})
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
                    error: response.data.error,
                    emails: response.data.error && existingExclusions,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
                error: response.data.error,
                emails: existingExclusions,
            });
        });
}

/**
 * @param {String} email
 * @param {String[]} existingExclusions
 */
function removeExclusion(email, existingExclusions) {
    // Remove it optimistically
    Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
        emails: _.without(existingExclusions, email),
    });
    ManageMatches_Exclusions_Remove({email});
}

function getExclusions() {
    ManageMatches_Exclusions()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
                    emails: response.data,
                });
                return;
            }
            Onyx.merge(ONYXKEYS.MANAGEMATCHES.EXCLUSIONS, {
                error: response.data.error,
            });
        });
}

export {
    addExclusion,
    dismissExclusionsError,
    dismissGenerateError,
    dismissGenerateSuccess,
    dismissGenerateAllError,
    dismissGenerateAllSuccess,
    dismissResetError,
    dismissResetSuccess,
    dismissRemoveFromCompError,
    dismissRemoveFromCompSuccess,
    dismissSetCurrentPeriodError,
    dismissSetCurrentPeriodSuccess,
    dismissSetPeriodEndDateError,
    dismissSetPeriodEndDateSuccess,
    dismissSetPeriodStartDateError,
    dismissSetPeriodStartDateSuccess,
    generateMatches,
    generateAllMatches,
    getExclusions,
    getManageMatchesOverview,
    getManageMatchesOverviewDetails,
    removeExclusion,
    removeFromComp,
    resetMatches,
    setCurrentPeriod,
    setPeriodEndDate,
    setPeriodStartDate,
    toggleWorkDescriptionsLocked,
};
