import React from 'react';
import PropTypes from 'prop-types';
import Popover from '../../components/Popover';

const propTypes = {
    /** The slackID of the person */
    slackID: PropTypes.string,

    /** The email address of the person */
    email: PropTypes.string,
};

const defaultProps = {
    email: '',
    slackID: '',
};

class UsefulLinks extends React.Component {
    constructor(props) {
        super(props);
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    /**
     * Copies the whatsnext search query for the person and opens the Slack app to the #whatsnext channel
     */
    copyToClipboard() {
        // Copy the search term to find the person's posts in the #whatsnext channel in Slack
        navigator.clipboard.writeText(`in:#whatsnext from:<@${this.props.slackID}>`).then(() => {
            // Redirect the user to the #whatsnext room in Slack
            window.open('https://expensify.slack.com/app_redirect?channel=whatsnext', '_blank');
        });
    }

    render() {
        return (
            <div>
                <div className="text-secondary-color">Links</div>
                <div>
                    <a
                        /* eslint-disable-next-line max-len */
                        href={`https://mail.google.com/mail/u/0/#search/to:"strategy@expensify.com"+from:"${this.props.email}"`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        strategy@
                    </a>
                    <span
                        role="button"
                        tabIndex={0}
                        className="link-span ml-2"
                        onClick={this.copyToClipboard}
                    >
                        #whatsnext
                    </span>
                    <Popover
                        content={(
                            <ul className="pl-2 mb-0">
                                <li>Click the WhatsNext link in Comp</li>
                                <li>Slack App should open</li>
                                <li>CMD + F to open search</li>
                                <li>CMD + v to paste the predefined search query</li>
                                <li>This shows you all #whatsnext contributions from that person</li>
                            </ul>
                        )}
                        header="how to use WhatsNext Link"
                    >
                        <span className="ml-1 badge badge-secondary">?</span>
                    </Popover>
                </div>
            </div>
        );
    }
}

UsefulLinks.propTypes = propTypes;
UsefulLinks.defaultProps = defaultProps;
UsefulLinks.displayName = 'UsefulLinks';

export default UsefulLinks;
