import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    header: PropTypes.string,
    content: PropTypes.node.isRequired,

    /** The UI to display inside the group */
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    header: undefined,
};

const Popover = props => (
    <div className="popover-trigger-wrapper">
        {props.children}
        <div className="popover">
            {props.header && <h3 className="popover-header">{props.header}</h3>}
            <div className="popover-body">
                {props.content}
            </div>
        </div>
    </div>
);

Popover.propTypes = propTypes;
Popover.defaultProps = defaultProps;

export default Popover;
