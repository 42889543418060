import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {githubCommentsProps} from './propTypesPair';

const propTypes = {
    /** The number of GitHub comments for the employee */
    githubComments: githubCommentsProps,

    /** Classes to be added to the title */
    titleClasses: PropTypes.string,

    /** Classes to be added to the value */
    valueClasses: PropTypes.string,

    /** The keys that need to be bold */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** The keys to show */
    keysToShow: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    githubComments: {},
    titleClasses: '',
    valueClasses: '',
    winningKeys: [],
    keysToShow: [],
};

const GitHubComments = ({
    githubComments, titleClasses, valueClasses, winningKeys, keysToShow,
}) => (
    <>
        {_.contains(keysToShow, 'githubComments') && (
            <div className="row mb-1">
                <div className={titleClasses}>GitHub Comments</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'githubComments') ? 'font-weight-bold' : ''}`}>
                    {githubComments.githubComments || 0}
                </div>
            </div>
        )}
    </>
);

GitHubComments.propTypes = propTypes;
GitHubComments.defaultProps = defaultProps;
GitHubComments.displayName = 'GitHubComments';

export default GitHubComments;
