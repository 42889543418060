import React from 'react';
import {withOnyx} from 'react-native-onyx';
import _ from 'underscore';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
    getRankingsForKey,
    isTotalCustomerChatsReceivedMetric,
    isTotalQAChatsHandledMetric,
    isConciergeQueueMetric,
    isFromLocalMetricStore,
    KEYS,
} from '../../libs/actions/Metric';
import PropTypesIndividual from './propTypesIndividual';
import {AmbassadorEventDayRankings_Get, SlackChannelRankings_Get, SlackMessageRankings_Get} from '../../libs/APIComp';
import ONYXKEYS from '../../ONYXKEYS';

const propTypes = {
    /** The Onyx key/label for the metric we're currently displaying rankings for */
    metricKeyLabelPair: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** Data for person A */
    personA: PropTypesIndividual.isRequired,

    /** Data for person B */
    personB: PropTypesIndividual.isRequired,

    /** Ref used for scrolling */
    // eslint-disable-next-line react/forbid-prop-types
    forwardedRef: PropTypes.object.isRequired,

    /** An array of emails of the people that are excluded from comp review */
    exclusions: PropTypes.shape({
        /** An array of emails indicating who is excluded from comp review */
        emails: PropTypes.arrayOf(PropTypes.string),
    }),
};

const defaultProps = {
    exclusions: {
        email: [],
    },
};

class Rankings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rankings: [],
        };
    }

    componentDidUpdate(prevProps) {
        // eslint-disable-next-line no-unused-vars
        const [_prevKey, prevLabel] = prevProps.metricKeyLabelPair;
        // eslint-disable-next-line no-unused-vars
        const [_key, label] = this.props.metricKeyLabelPair;
        if (prevLabel === label) {
            return;
        }
        this.getRankings();
    }

    getRankings() {
        if (!this.props.metricKeyLabelPair[0]) {
            return;
        }
        const [key, label] = this.props.metricKeyLabelPair;
        if (isFromLocalMetricStore(key)) {
            const rankings = getRankingsForKey(key, label);
            this.setState({rankings});
            return;
        }

        const getRankingsDataFromAPI = (apiMethod) => {
            apiMethod().then(({data}) => {
                this.setState({rankings: data});
            });
        };

        switch (key) {
            case KEYS.AMBASSADOR_EVENT_DAYS:
                getRankingsDataFromAPI(AmbassadorEventDayRankings_Get);
                break;
            case KEYS.SLACK.MESSAGES:
                getRankingsDataFromAPI(SlackMessageRankings_Get);
                break;
            case KEYS.SLACK.CHANNELS:
                getRankingsDataFromAPI(SlackChannelRankings_Get);
                break;
            default:
                break;
        }
    }

    render() {
        const [key, label] = this.props.metricKeyLabelPair;
        const isRowForCurrentPair = email => email === this.props.personA.email || email === this.props.personB.email;
        let currentRank = 1;
        let lowestValueSeen = this.state.rankings.length ? this.state.rankings[0][1] : Number.MAX_SAFE_INTEGER;

        let rankingLabel = '';
        if (isTotalCustomerChatsReceivedMetric(key, label)) {
            rankingLabel = 'Concierge Total Chats Received';
        } else if (isTotalQAChatsHandledMetric(key, label)) {
            rankingLabel = 'Concierge Total QA Chats Handled';
        } else if (isConciergeQueueMetric(key)) {
            rankingLabel = `${label} Chats`;
        } else {
            rankingLabel = label;
        }

        return (
            <div className="mt-5 mb-5 container w-75" ref={this.props.forwardedRef}>
                {Boolean(this.state.rankings.length) && (
                    <>
                        <h1 className="mb-2">
                            Rankings for
                            {' '}
                            {rankingLabel}
                        </h1>
                        {this.state.rankings.map(([email, value], index) => {
                            // For people that are excluded from comp, they are also not shown in the rankings
                            if (_.contains(this.props.exclusions.emails, email)) {
                                return;
                            }

                            if (lowestValueSeen > value) {
                                currentRank = index + 1;
                                lowestValueSeen = value;
                            }
                            return (
                                <div className="row border-bottom" key={email}>
                                    <div className={cn('col col-md-11', {'bg-success font-weight-bold': isRowForCurrentPair(email)})}>{`${currentRank}. ${email}`}</div>
                                    <div className={cn('col col-md-1', {'bg-success font-weight-bold': isRowForCurrentPair(email)})}>{(value ?? 0).toFixed(0)}</div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        );
    }
}

Rankings.propTypes = propTypes;
Rankings.defaultProps = defaultProps;

const forwardedRefComponent = React.forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Rankings {...props} forwardedRef={ref} />
));

// Need to forward the ref here in order for scrolling to Rankings to work upon clicking a metric
export default withOnyx({
    exclusions: {key: ONYXKEYS.MANAGEMATCHES.EXCLUSIONS},
})(forwardedRefComponent);
