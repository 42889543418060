import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** The number of work channels this person participated in */
    channels: PropTypes.number,

    /** The number of work messages this person sent in slack */
    messages: PropTypes.number,

    /** The metrics for which this person out-performed the person they're being compared to */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** Whether we're showing slack numbers on the left panel (true), or the right panel (false) */
    left: PropTypes.bool,

    /** Whether we're showing slack numbers on the profile page */
    profile: PropTypes.bool,
};

const defaultProps = {
    channels: 0,
    messages: 0,
    winningKeys: [],
    left: true,
    profile: false,
};

const getTitleClass = (profile, left) => `${profile || !left ? '' : 'offset-md-9'} ${left ? 'text-left col-9' : 'col-9 text-right order-2'} text-secondary-color`;
const getNumberClass = function (profile, left, bold) {
    return `${profile && left ? 'col-3' : ''} ${!profile && left ? 'col' : ''} ${left ? 'text-right' : 'col-3 text-left'} ${bold ? 'font-weight-bold' : ''}`;
};
const SlackNumbers = ({
    channels, messages, winningKeys, left, profile,
}) => (
    <>
        <div className="row mb-1">
            <div className={getTitleClass(profile, left)}>Slack Work Channels</div>
            <div className={getNumberClass(profile, left, _.contains(winningKeys, 'channels'))}>
                {channels}
            </div>
        </div>
        <div className="row mb-1">
            <div className={getTitleClass(profile, left)}>Slack Work Messages</div>
            <div className={getNumberClass(profile, left, _.contains(winningKeys, 'messages'))}>
                {messages}
            </div>
        </div>
    </>
);

SlackNumbers.propTypes = propTypes;
SlackNumbers.defaultProps = defaultProps;
SlackNumbers.displayName = 'SlackNumbers';

export default SlackNumbers;
