import PropTypes from 'prop-types';

const propTypes = {
    overview: PropTypes.shape({
        /** The current period of comp review */
        period: PropTypes.string,

        /** The dates the current period starts and ends */
        periodDates: PropTypes.shape({
            /** The start date of the current period */
            startDate: PropTypes.string,

            /** The end date of the current period */
            endDate: PropTypes.string,

            /** The start date of the previous comp review period */
            previousPeriodStartDate: PropTypes.string,
        }),

        /** The total number of matches for this comp review period */
        totalMatches: PropTypes.number,

        /** The number of completed matches */
        completedMatches: PropTypes.number,

        /** The number of remaining matches */
        remainingMatches: PropTypes.number,

        /** Whether or not the work descriptions are locked */
        workDescriptionsLocked: PropTypes.bool,
    }),
};

const defaultProps = {
    overview: {
        period: '',
        totalMatches: 0,
        completedMatches: 0,
        remainingMatches: 0,
        workDescriptionsLocked: null,
    },
};

export {
    propTypes,
    defaultProps,
};
