import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import ROUTES from '../../ROUTES';
import PageHeader from '../../components/PageHeader';

const ManageMatchesNavbar = () => (
    <>
        <Route path={ROUTES.MANAGEMATCHES_OVERVIEW}>
            <PageHeader titleOne="Manage Matches" titleTwo="Overview" />
        </Route>
        <>
            <NavLink to={ROUTES.MANAGEMATCHES_OVERVIEW} className="btn btn-primary" activeClassName="nav-active">Manage Matches Overview</NavLink>
        </>
    </>
);

ManageMatchesNavbar.displayName = 'ManageMatchesNavbar';

export default ManageMatchesNavbar;
