import React from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import _ from 'underscore';
import {createCompensation} from '../../libs/actions/Compensation';
import ONYXKEYS from '../../ONYXKEYS';
import compensationPropTypes from './compensationPropTypes';
import round from '../../libs/round';
import CurrencySelector from '../../components/CurrencySelector';

function cleanDollarAmount(amount) {
    return amount.replace(',', '').replace('$', '').trim();
}

const propTypes = {
    /** The email of the person to add a compensation record form */
    email: PropTypes.string.isRequired,

    /** From Onyx: The compensation data for the employee */
    compensation: PropTypes.arrayOf(compensationPropTypes),
};
const defaultProps = {
    compensation: [],
};

class NewCompensationForm extends React.Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
        this.promptToConfirmFormSubmit = this.promptToConfirmFormSubmit.bind(this);
        this.cancelPromptToConfirmFormSubmit = this.cancelPromptToConfirmFormSubmit.bind(this);

        this.setInitialStateFromProps(this.props);

        this.state = {...this.initialState};
    }

    componentDidUpdate(prevProps) {
        // eslint-disable-next-line max-len
        const prevPropsCompensationEmail = prevProps && prevProps.compensation.length && prevProps.compensation[0].email;
        // eslint-disable-next-line max-len
        const thisPropsCompensationEmail = this.props && this.props.compensation.length && this.props.compensation[0].email;
        if (prevPropsCompensationEmail !== thisPropsCompensationEmail) {
            this.setInitialStateFromProps(this.props);

            // This rule needs to be disabled so that we can set the form values in the state when a new employee is
            // selected.
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({...this.initialState});
        }
    }

    /**
     * Whenever the props change, the form values stored in the state need to be updated with values from the values
     * of the first compensation record (if it exists). This makes it so much easier for managing the records.
     *
     * @param {Object} props
     */
    setInitialStateFromProps(props) {
        // For the initial state, if there is at least one compensation record, then use the values from the first
        // compensation record. If not, then use some basic defaults
        const firstCompensationRecord = props.compensation.length > 0
            ? _.sortBy(props.compensation, 'created').reverse()[0]
            : null;

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        // Keep a copy of the initial state so that the form won't be submitted if the data hasn't changed
        this.initialState = !firstCompensationRecord
            ? {
                formValues: {
                    created: `${yyyy}-${mm}-${dd}`,
                    localCurrency: 'USD',
                    localCurrencyExchangeRate: '',
                    reason: '',
                    salary: '',
                    salaryExchangedForEquity: '',
                    grantCompensationAmount: '',
                    numberOfOptions: '',
                },
                promptToConfirmFormSubmit: false,
                formErrors: [],
            }
            : {
                formValues: {
                    created: `${yyyy}-${mm}-${dd}`,
                    localCurrency: firstCompensationRecord.localCurrency,

                    // These are all stored in the DB as cents (or removing two decimals)
                    localCurrencyExchangeRate: round(firstCompensationRecord.localCurrencyExchangeRate / 100) || '',

                    // These last values will always start from zero rather than the previous value. This is so that there is
                    // a good history for when they change.
                    numberOfOptions: '',
                    reason: '',
                    salary: '',
                    salaryExchangedForEquity: '',
                    grantCompensationAmount: '',
                },
                promptToConfirmFormSubmit: false,
                formErrors: [],
            };
    }

    /**
     * @param {String} name
     * @param {Mixed} value
     */
    updateFormValue(name, value) {
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                [name]: value,
            },
        }));
    }

    promptToConfirmFormSubmit() {
        this.setState({
            formErrors: [],
        }, () => {
            // This allows for a number with 11 digits before the decimal and up to 2 digits after the decimal
            const numberRegex = new RegExp(/^-?\d{0,11}(\.\d{0,2})?$/);
            const errors = [];

            // Validate all our input
            if (!numberRegex.test(this.state.formValues.localCurrencyExchangeRate)) {
                errors.push('Currency Exchange must be a number with at most two decimal places');
            }
            if (!numberRegex.test(cleanDollarAmount(this.state.formValues.salary))) {
                errors.push('Cash Salary must be a number with at most two decimal places');
            }
            if (!numberRegex.test(cleanDollarAmount(this.state.formValues.salaryExchangedForEquity))) {
                errors.push('Salary Exchanged for Equity must be a number with at most two decimal places');
            }
            if (!numberRegex.test(cleanDollarAmount(this.state.formValues.grantCompensationAmount))) {
                errors.push('Grant Compensation must be a number with at most two decimal places');
            }
            if (!numberRegex.test(this.state.formValues.numberOfOptions)) {
                errors.push('Options must be a number with at most two decimal places');
            }

            // If everything is valid, then have them confirm they want to submit the form
            this.setState({
                promptToConfirmFormSubmit: errors.length === 0,
                formErrors: errors,
            });
        });
    }

    cancelPromptToConfirmFormSubmit() {
        this.setState(prevState => ({
            promptToConfirmFormSubmit: false,
            formValues: {
                ...prevState.formValues,
                reason: '',
                salary: '',
                salaryExchangedForEquity: '',
                grantCompensationAmount: '',
                numberOfOptions: '',
            },
        }));
    }

    /**
     * @param {SyntheticEvent} e
     */
    submit(e) {
        e.preventDefault();

        // Nothing needs to be validated because it was validated when they clicked the first submit button
        createCompensation({
            effectiveDate: this.state.formValues.effectiveDate,
            email: this.props.email.trim(),
            localCurrency: this.state.formValues.localCurrency,
            numberOfOptions: Number(this.state.formValues.numberOfOptions),
            reason: this.state.formValues.reason,

            // Convert these values from a decimal to an integer because the DB stores them as integers
            // The rounding is necessary because with JS sometimes a value like 4.56 becomes 455.99999999 when
            // multiplied by 100
            localCurrencyExchangeRate: round(Number(cleanDollarAmount(this.state.formValues.localCurrencyExchangeRate)) * 100),
            salary: round(Number(cleanDollarAmount(this.state.formValues.salary)) * 100),
            salaryExchangedForEquity: round(Number(cleanDollarAmount(this.state.formValues.salaryExchangedForEquity)) * 100),
            grantCompensationAmount: round(Number(cleanDollarAmount(this.state.formValues.grantCompensationAmount)) * 100),
        });

        this.cancelPromptToConfirmFormSubmit();
    }

    render() {
        return (
            <form onSubmit={this.submit}>
                <div className="row">
                    {/* Row of labels */}
                    <div className="col-3">
                        <label htmlFor="created">Effective Date</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="localCurrency">Local Currency</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="localCurrencyExchangeRate">Currency Exchange</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="reason">Reason</label>
                    </div>
                </div>

                <div className="row">
                    {/* Row of form controls */}
                    <div className="col-3">
                        <input
                            type="date"
                            className="form-control"
                            id="effectiveDate"
                            required
                            value={this.state.formValues.effectiveDate}
                            onChange={e => this.updateFormValue('effectiveDate', e.target.value)}
                        />
                    </div>
                    <div className="col-3">
                        <CurrencySelector
                            id="localCurrency"
                            required
                            value={this.state.formValues.localCurrency}
                            onChange={e => this.updateFormValue('localCurrency', e.target.value)}
                        />
                    </div>
                    <div className="col-3">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="localCurrencyExchangeRate"
                                value={this.state.formValues.localCurrencyExchangeRate}
                                onChange={(e) => {
                                    this.updateFormValue('localCurrencyExchangeRate', e.target.value);
                                }}
                            />

                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-group mb-3">
                            <select
                                id="reason"
                                className="custom-select"
                                onChange={(e) => {
                                    this.updateFormValue('reason', e.target.value);
                                }}
                                value={this.state.formValues.reason}
                            >
                                <option value="">- Select -</option>
                                <option value="Comp Review">Comp Review</option>
                                <option value="Grant Vested">Grant Vested</option>
                                <option value="New Hire">New Hire</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    {/* Row of labels */}
                    <div className="col-3">
                        <label htmlFor="salary">Cash Salary</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="salaryExchangedForEquity">Salary Exchanged for Equity</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="grantCompensationAmount">Grant Compensation</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="numberOfOptions">Options</label>
                    </div>
                </div>

                <div className="row mb-4">
                    {/* Row of form controls */}
                    <div className="col-3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>

                            <input
                                type="text"
                                className="form-control"
                                id="salary"
                                value={this.state.formValues.salary}
                                onChange={e => this.updateFormValue('salary', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>

                            <input
                                type="text"
                                className="form-control"
                                id="salaryExchangedForEquity"
                                value={this.state.formValues.salaryExchangedForEquity}
                                onChange={e => this.updateFormValue('salaryExchangedForEquity', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>

                            <input
                                type="text"
                                className="form-control"
                                id="grantCompensationAmount"
                                value={this.state.formValues.grantCompensationAmount}
                                onChange={e => this.updateFormValue('grantCompensationAmount', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <input
                            type="text"
                            className="form-control"
                            id="numberOfOptions"
                            value={this.state.formValues.numberOfOptions}
                            onChange={e => this.updateFormValue('numberOfOptions', e.target.value)}
                        />
                    </div>
                </div>

                {this.state.formErrors.length > 0 && (
                    <div className="alert alert-danger mt-2">
                        <ul>
                            {_.map(this.state.formErrors, formError => <li key={formError}>{formError}</li>)}
                        </ul>
                    </div>
                )}

                {!this.state.promptToConfirmFormSubmit && (
                    <button
                        type="button"
                        className="btn btn-success"

                        // This button is disabled until the form values have changed
                        disabled={_.isEqual(this.initialState.formValues, this.state.formValues)}
                        onClick={this.promptToConfirmFormSubmit}
                    >
                        Add Compensation Record
                    </button>
                )}

                {this.state.promptToConfirmFormSubmit && (
                    <>
                        <p>
                            Are you sure you want to add this record?
                        </p>

                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                        >
                            Yes
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.cancelPromptToConfirmFormSubmit}
                        >
                            No
                        </button>
                    </>
                )}
            </form>
        );
    }
}

NewCompensationForm.propTypes = propTypes;
NewCompensationForm.defaultProps = defaultProps;

export default withOnyx({
    compensation: {
        key: ({email}) => `${ONYXKEYS.COLLECTION.COMPENSATION}${email}`,
    },
})(NewCompensationForm);
