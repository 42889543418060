import React, {useState} from 'react';
import {withOnyx} from 'react-native-onyx';
import PropTypes from 'prop-types';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {resetMatches, dismissResetSuccess, dismissResetError} from '../../libs/actions/ManageMatches';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';

const propTypes = {
    ...overviewPropTypes,

    /** From Onyx - data about resetting the matches */
    reset: PropTypes.shape({
        /** If the operation is currently in progress */
        loading: PropTypes.bool,

        /** A success message from the server */
        success: PropTypes.string,

        /** An error message from the server */
        error: PropTypes.string,
    }),
};
const defaultProps = {
    ...overviewDefaultProps,
    reset: {
        loading: false,
        success: null,
        error: null,
    },
};

const ManageMatchesReset = (props) => {
    const [shouldShowConfirm, showConfirm] = useState(false);

    return (
        <div className="card mt-4">
            <div className="card-header">
                Nuclear option
            </div>
            <div className="card-body">
                {shouldShowConfirm ? (
                    <>
                        <p>
                            <strong>Are you sure you want to start comp review period over and get rid of all the current matches?</strong>
                        </p>
                        <div className="alert alert-warning">
                            <strong>Warning:</strong>
                            {' This updates the '}
                            <code>period</code>
                            {' column of all matches to be '}
                            <code>{`${props.overview.period}_reset_<currentTimestamp>`}</code>
                            {' and effectively removes all matches for the current period while leaving all the data in the database.'}
                        </div>
                        <button
                            type="button"
                            className="btn btn-danger mr-4"
                            onClick={() => {
                                showConfirm(false);
                                resetMatches();
                            }}
                        >
                            Yes, by all means
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => showConfirm(false)}
                        >
                            No, cancel
                        </button>
                    </>
                ) : (
                    <>
                        Made too many mistakes and want to start over with a clean slate?
                        <br />
                        <br />
                        <button
                            type="button"
                            disabled={props.reset.loading || props.overview.totalMatches === 0}
                            className="btn btn-danger mr-4"
                            onClick={() => showConfirm(true)}
                        >
                            Start comp review over
                        </button>

                        <Spinner shouldShow={props.reset.loading} optionalText="This might take a while. Don't refresh the page." />
                    </>
                )}

                <Alert
                    shouldShow={Boolean(props.reset.error)}
                    onDismiss={dismissResetError}
                    type="danger"
                >
                    <strong>Error:</strong>
                    {` ${props.reset.error}`}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.reset.success)}
                    onDismiss={dismissResetSuccess}
                >
                    {props.reset.success}
                </Alert>
            </div>
        </div>
    );
};

ManageMatchesReset.propTypes = propTypes;
ManageMatchesReset.defaultProps = defaultProps;

export default compose(
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
        reset: {
            key: ONYXKEYS.MANAGEMATCHES.RESET,
        },
    }),
)(ManageMatchesReset);
