import _ from 'underscore';
import Onyx from 'react-native-onyx';
import ONYXKEYS from '../../ONYXKEYS';
import {
    Compensation_Create,
    Compensation_Get_History,
    Compensation_Get_Overview,
    Emails_Get,
    Compensation_Create_Multiple,
    Compensation_Download_CSV,
} from '../APIComp';

function sendDataToBrowserForCSVDownload(data, fileName) {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const arrayOfCSVRows = _.map(data, (row => row.join(',')));
    csvContent += arrayOfCSVRows.join('\r\n');

    // By adding a hidden link to the DOM and simulating a click, we can trigger a download and specify a filename
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * @param {String} email
 */
function getCompensationHistory(email) {
    Compensation_Get_History({email})
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.set(`${ONYXKEYS.COLLECTION.COMPENSATION}${email}`, response.data);
            }
        });
}

/**
 * @param {Object} parameters
 * @param {String} parameters.created
 * @param {String} parameters.email
 * @param {String} parameters.localCurrency
 * @param {Number} parameters.localCurrencyExchangeRate
 * @param {Number} parameters.salary
 * @param {Number} parameters.salaryExchangedForEquity
 * @param {Number} parameters.grantCompensationAmount
 * @param {Number} parameters.numberOfOptions
 * @param {String} parameters.reason
 */
function createCompensation(parameters) {
    // Add the data to Onyx in an optimistic fashion
    Onyx.merge(`${ONYXKEYS.COLLECTION.COMPENSATION}${parameters.email}`, [{
        ...parameters,
    }]);
    Compensation_Create(parameters)
        .then((response) => {
            if (response.jsonCode !== 200) {
                console.error(response.data.error);
            }
            getCompensationHistory(parameters.email);
        });
}

function getCompensationOverview() {
    Compensation_Get_Overview()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.COMPENSATION_OVERVIEW, response.data);
            }
        });
}

function downloadTemplateCSV() {
    const data = [];
    data.push(['Effective Date', 'Email', 'Local Currency', 'Currency Exchange', 'Reason', 'Cash Salary', 'Salary Exchanged for Equity', 'Grant Compensation Amount', 'Options']);
    data.push(['2023-02-28', 'test@expensify.com', 'USD', 0, 'Comp Review', 100, 0, 0, 0]);
    sendDataToBrowserForCSVDownload(data, 'template.csv');
}


function downloadOverviewCSV(effectiveDate) {
    Compensation_Download_CSV({effectiveDate})
        .then((response) => {
            if (response.jsonCode === 200) {
                sendDataToBrowserForCSVDownload(response.data, 'download.csv');
            }
        });
}

/**
 * @param {Object} arrayOfCompensations
 * @param {String} arrayOfCompensations[].created
 * @param {String} arrayOfCompensations[].email
 * @param {String} arrayOfCompensations[].localCurrency
 * @param {Number} arrayOfCompensations[].localCurrencyExchangeRate
 * @param {Number} arrayOfCompensations[].salary
 * @param {Number} arrayOfCompensations[].salaryExchangedForEquity
 * @param {Number} arrayOfCompensations[].grantCompensationAmount
 * @param {Number} arrayOfCompensations[].numberOfOptions
 * @param {String} arrayOfCompensations[].reason
 */
function createMultipleCompensations(arrayOfCompensations) {
    Compensation_Create_Multiple({multipleRecords: JSON.stringify(arrayOfCompensations)})
        .then((response) => {
            if (response.jsonCode !== 200) {
                console.error(response.data.error);
                return;
            }

            sendDataToBrowserForCSVDownload(response.data, 'import_confirmation.csv');
        });
}

function getAllEmails() {
    Emails_Get()
        .then((response) => {
            if (response.jsonCode === 200) {
                Onyx.set(ONYXKEYS.EMAILS, response.data);
            }
        });
}

function setDataToConfirmForUpload(compensationRecords) {
    Onyx.set(ONYXKEYS.COMPENSATION_TO_CONFIRM_FOR_UPLOAD, compensationRecords);
}

function clearDataToConfirmForUpload() {
    Onyx.set(ONYXKEYS.COMPENSATION_TO_CONFIRM_FOR_UPLOAD, []);
}

export {
    createCompensation,
    createMultipleCompensations,
    downloadTemplateCSV,
    downloadOverviewCSV,
    getCompensationHistory,
    getCompensationOverview,
    getAllEmails,
    setDataToConfirmForUpload,
    clearDataToConfirmForUpload,
};
