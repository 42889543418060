import PropTypes from 'prop-types';

export default PropTypes.shape({
    /** Is the app currently in a loading state? */
    loading: PropTypes.bool.isRequired,

    /** Similar to the loading state, but used to disable the entire UI (so it's still visible) */
    isUIDisabled: PropTypes.bool,

    /** The total number of matches needing to be done by the user */
    totalMatches: PropTypes.number,

    /** The total number of matches performed by the user */
    matchesPerformed: PropTypes.number,
});
