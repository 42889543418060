import _ from 'underscore';
import lodashGet from 'lodash/get';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Str from 'expensify-common/lib/str';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../../components/withWindowDimensions';

const propTypes = {
    /** All the metrics to in this comparison block */
    metrics: PropTypes.arrayOf(PropTypes.shape({
        /** Unique identifier for this metric */
        key: PropTypes.string,

        /** The name/label of the metric. Note that the display will change if the label includes the string 'total'. */
        label: PropTypes.string,

        /** The value of the metric for the user  */
        value: PropTypes.number,
    })).isRequired,

    /** The set of metrics for which this person out-performed the person they're being compared to  */
    winningMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** The set of metrics for which both people tied at zero. In this case, we hide the metric */
    metricsToExclude: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** Classes to be added to the labels */
    labelClasses: PropTypes.string.isRequired,

    /** Classes to be added to the values */
    valueClasses: PropTypes.string.isRequired,

    /** An array containing the min-height for each row. This is used to synchronize two IndividualDetails views on wide screens and line them up side-by-side */
    minRowHeights: PropTypes.arrayOf(PropTypes.number).isRequired,

    /** Function used to inform parent of measured row heights to synchronize two IndividualDetails views on wide screens and line them up side-by-side */
    updateRowHeights: PropTypes.func.isRequired,

    ...windowDimensionsPropTypes,

    /** Sets the key used to decide which metric ranking we should display, scrolls to the ranking table */
    selectMetric: PropTypes.func.isRequired,
};

class ComparisonBlock extends Component {
    constructor(props) {
        super(props);
        this.rowRefs = [];
    }

    componentDidMount() {
        this.updateRowHeights();
    }

    componentDidUpdate(prevProps) {
        if (this.props.windowWidth === prevProps.windowWidth) {
            return;
        }
        this.updateRowHeights();
    }

    updateRowHeights() {
        const rowHeights = _.map(this.rowRefs, container => container.clientHeight);
        this.props.updateRowHeights(rowHeights);
    }

    render() {
        const metricsToShow = _.filter(this.props.metrics, metric => !_.contains(this.props.metricsToExclude, metric.label));
        return (
            <>
                {_.map(metricsToShow, ({label, value, key}, index) => {
                    const isTotalRow = Str.caseInsensitiveContains(label, 'total');
                    const isWin = _.contains(this.props.winningMetrics, label);
                    return (
                        <div
                            onClick={() => this.props.selectMetric([key, label])}
                            role="button"
                            tabIndex={0}
                            className="row no-gutters mb-1 cursor-pointer"
                            key={label}
                            ref={node => this.rowRefs[index] = node}
                            style={{minHeight: lodashGet(this.props.minRowHeights, `[${index}]`, 0) || 0}}
                        >
                            <div className={cn(this.props.labelClasses, {'border-top text-secondary-color': isTotalRow})}>{label}</div>
                            <div
                                className={cn(this.props.valueClasses, {
                                    'font-weight-bold': isWin,
                                    'border-top': isTotalRow,
                                })}
                            >
                                {value}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }
}

ComparisonBlock.propTypes = propTypes;

export default withWindowDimensions(ComparisonBlock);
