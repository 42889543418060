import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {stackoverflowReputationProps} from './propTypesPair';

const propTypes = {
    /** The StackOverflow reputation nunber for the employee */
    reputation: stackoverflowReputationProps,

    /** Classes to be added to the title */
    titleClasses: PropTypes.string,

    /** Classes to be added to the value */
    valueClasses: PropTypes.string,

    /** The keys that need to be bold */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** The keys to show */
    keysToShow: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    reputation: {},
    titleClasses: '',
    valueClasses: '',
    winningKeys: [],
    keysToShow: [],
};

const Reputation = ({
    reputation, titleClasses, valueClasses, winningKeys, keysToShow,
}) => (
    <>
        {_.contains(keysToShow, 'stackoverflowReputation') && (
            <div className="row mb-1">
                <div className={titleClasses}>Stackoverflow</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'stackoverflowReputation') ? 'font-weight-bold' : ''}`}>
                    {reputation.stackoverflowReputation || 0}
                </div>
            </div>
        )}
    </>
);

Reputation.propTypes = propTypes;
Reputation.defaultProps = defaultProps;
Reputation.displayName = 'Reputation';

export default Reputation;
