import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {propTypesContributions} from './propTypesPair';

const propTypes = {
    /** The count of the person's GitHub contributions in the past 6 months */
    contributions: propTypesContributions,

    /** Classes to be added to the title */
    titleClasses: PropTypes.string,

    /** Classes to be added to the value */
    valueClasses: PropTypes.string,

    /** The keys that need to be bold */
    winningKeys: PropTypes.arrayOf(PropTypes.string),

    /** The keys to show */
    keysToShow: PropTypes.arrayOf(PropTypes.string),

};

const defaultProps = {
    contributions: {},
    titleClasses: '',
    valueClasses: '',
    winningKeys: [],
    keysToShow: [],
};

const Contributions = ({
    contributions, titleClasses, valueClasses, winningKeys, keysToShow,
}) => (
    <>
        {_.contains(keysToShow, 'openedIssues') && (
            <div className="row mb-1">
                <div className={titleClasses}>Opened GH Issues</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'openedIssues') ? 'font-weight-bold' : ''}`}>{contributions.openedIssues}</div>
            </div>
        )}
        {_.contains(keysToShow, 'closedIssues') && (
            <div className="row mb-1">
                <div className={titleClasses}>Closed, Assigned GH Issues</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'closedIssues') ? 'font-weight-bold' : ''}`}>{contributions.closedIssues}</div>
            </div>
        )}
        {_.contains(keysToShow, 'pullRequests') && (
            <div className="row mb-1">
                <div className={titleClasses}>Pull Requests</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'pullRequests') ? 'font-weight-bold' : ''}`}>{contributions.pullRequests}</div>
            </div>
        )}
        {_.contains(keysToShow, 'reviews') && (
            <div className="row mb-1">
                <div className={titleClasses}>Reviews</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'reviews') ? 'font-weight-bold' : ''}`}>{contributions.reviews}</div>
            </div>
        )}
        {_.contains(keysToShow, 'commits') && (
            <div className="row mb-1">
                <div className={titleClasses}>Commits</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'commits') ? 'font-weight-bold' : ''}`}>{contributions.commits}</div>
            </div>
        )}
        {_.contains(keysToShow, 'openSource') && (
            <div className="row mb-1">
                <div className={titleClasses}>#expensify-open-source</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'openSource') ? 'font-weight-bold' : ''}`}>{contributions.openSource}</div>
            </div>
        )}
        {_.contains(keysToShow, 'total') && (
            <div className="row mb-1">
                <div className={`${titleClasses} border-top text-secondary-color`}>Total</div>
                <div className={`${valueClasses} ${_.contains(winningKeys, 'total') ? 'font-weight-bold' : ''} border-top`}>{contributions.total}</div>
            </div>
        )}
    </>
);

Contributions.propTypes = propTypes;
Contributions.defaultProps = defaultProps;
Contributions.displayName = 'Contributions';

export default Contributions;
