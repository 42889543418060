import _ from 'underscore';
import Onyx from 'react-native-onyx';
import ONYXKEYS from '../ONYXKEYS';

let sleepTimer;
let lastTime;
let isOffline = false;

// Holds all of the callbacks that need to be triggered when the network reconnects
const reconnectionCallbacks = [];

/**
 * Loop over all reconnection callbacks and fire each one
 */
const triggerReconnectionCallbacks = _.throttle(() => {
    console.debug('[Reconnection]: Firing reconnection callbacks');
    _.each(reconnectionCallbacks, callback => callback());
}, 5000, {trailing: false});

/**
 * Called when the offline status of the app changes and if the network is "reconnecting" (going from offline to online)
 * then all of the reconnection callbacks are triggered
 *
 * @param {Boolean} isCurrentlyOffline
 */
function setOfflineStatus(isCurrentlyOffline) {
    Onyx.merge(ONYXKEYS.NETWORK, {isOffline: isCurrentlyOffline});

    // When reconnecting, ie, going from offline to online, all the reconnection callbacks
    // are triggered (this is usually Actions that need to re-download data from the server)
    if (isOffline && !isCurrentlyOffline) {
        triggerReconnectionCallbacks();
    }

    isOffline = isCurrentlyOffline;
}

/**
 * Sets the current mode to being online
 */
function setModeOnline() {
    console.debug('[NetInfo] isConnected:', true);
    setOfflineStatus(false);
}

/**
 * Sets the current mode to being offline
 */
function setModeOffline() {
    console.debug('[NetInfo] isConnected:', false);
    setOfflineStatus(true);
}

/**
 * Set up the event listener for NetInfo to tell whether the user has
 * internet connectivity or not. This is more reliable than the Pusher
 * `disconnected` event which takes about 10-15 seconds to emit.
 */
function listenForReconnect() {
    // Listen for when the window goes online
    window.addEventListener('ononline', setModeOnline);

    // Listen for when the window goes offline
    window.addEventListener('onoffline', setModeOffline);

    // When a device is put to sleep, NetInfo is not always able to detect
    // when connectivity has been lost. As a failsafe we will capture the time
    // every two seconds and if the last time recorded is greater than 20 seconds
    // we know that the computer has been asleep.
    lastTime = (new Date()).getTime();
    sleepTimer = setInterval(() => {
        const currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + 20000)) {
            triggerReconnectionCallbacks();
        }
        lastTime = currentTime;
    }, 2000);
}

/**
 * Tear down the event listeners when we are finished with them.
 */
function stopListeningForReconnect() {
    clearInterval(sleepTimer);
    window.removeEventListener('ononline', setModeOnline);
    window.removeEventListener('onoffline', setModeOffline);
}

/**
 * Register callback to fire when we reconnect
 *
 * @param {Function} callback
 */
function onReconnect(callback) {
    reconnectionCallbacks.push(callback);
}

export default {
    setOfflineStatus,
    listenForReconnect,
    stopListeningForReconnect,
    onReconnect,
    triggerReconnectionCallbacks,
};
