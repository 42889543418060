import React, {useEffect} from 'react';
import lodashGet from 'lodash/get';
import {withOnyx} from 'react-native-onyx';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import withMatchesAccessPermission, {permissionPropTypes} from '../../components/withMatchesAccessPermission';
import ManageMatchesNavbar from './ManageMatchesNavbar';
import {getManageMatchesOverview} from '../../libs/actions/ManageMatches';
import ManageMatchesReset from './ManageMatchesReset';
import {propTypes as overviewPropTypes, defaultProps as overviewDefaultProps} from './ManageMatchesOverviewProptypes';
import ManageMatchesAddToComp from './ManageMatchesAddToComp';
import ManageMatchesStartComp from './ManageMatchesStartComp';
import ManageMatchesDetailedProgress from './ManageMatchesDetailedProgress';
import ManageMatchesSetCurrentPeriod from './ManageMatchesSetCurrentPeriod';
import ManageMatchesToggleWorkDescriptions from './ManageMatchesToggleWorkDescriptions';
import ManageMatchesExclusions from './ManageMatchesExclusions';
import ManageMatchesRemoveFromComp from './ManageMatchesRemoveFromComp';
import ManageMatchesCollectMetrics from './ManageMatchesCollectMetrics';
import ManageMatchesModifyWhitelist from './ManageMatchesModifyWhitelist';
import ManageMatchesSetPeriodEndDate from './ManageMatchesSetPeriodEndDate';

const propTypes = {
    ...overviewPropTypes,

    /** Comes from  withMatchesAccessPermission */
    ...permissionPropTypes,
};
const defaultProps = {
    ...overviewDefaultProps,
};

const ManageMatchesOverviewPage = (props) => {
    // Load the overview data only when this is first rendered
    useEffect(getManageMatchesOverview, []);

    return (
        <>
            <ManageMatchesNavbar />

            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-4">
                        {`Current Period: ${props.overview.period}`}
                    </h1>

                    <ManageMatchesDetailedProgress />
                </div>
            </div>

            <div className="container">
                <h1>Before comp review begins</h1>

                <div className="card-deck">
                    <ManageMatchesSetCurrentPeriod />
                    <ManageMatchesCollectMetrics
                        previousPeriodStartDate={lodashGet(props, 'overview.periodDates.previousPeriodStartDate')}
                        currentPeriodStartDate={lodashGet(props, 'overview.periodDates.startDate')}
                    />
                </div>

                <div className="card-deck mt-4">
                    <ManageMatchesModifyWhitelist previousPeriodStartDate={lodashGet(props, 'overview.periodDates.previousPeriodStartDate')} />
                    <ManageMatchesToggleWorkDescriptions />
                </div>

                <ManageMatchesExclusions />

                <div className="card-deck">
                    <ManageMatchesStartComp />

                    <div className="card mt-4">
                        <div className="card-header">
                            Step 7: Make last minute changes
                        </div>
                        <div className="card-body">
                            <ManageMatchesAddToComp />

                            <ManageMatchesRemoveFromComp />
                        </div>
                    </div>
                </div>

                <ManageMatchesSetPeriodEndDate />

                <ManageMatchesReset />
            </div>

        </>
    );
};

ManageMatchesOverviewPage.propTypes = propTypes;
ManageMatchesOverviewPage.defaultProps = defaultProps;

export default compose(
    withMatchesAccessPermission,
    withOnyx({
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
        reset: {
            key: ONYXKEYS.MANAGEMATCHES.RESET,
        },
    }),
)(ManageMatchesOverviewPage);
