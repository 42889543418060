import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import MatchRows from '../../components/MatchRows';
import {getExclusions} from '../../libs/actions/ManageMatches';

const propTypes = {
    /** The title of the group */
    title: PropTypes.string.isRequired,

    /** The data containing the auto matches */
    autoMatches: PropTypes.arrayOf(
        PropTypes.shape({
            /** The person who was on the left of the match */
            A: PropTypes.shape({
                /** The email address of the person */
                email: PropTypes.string.isRequired,

                /** The name of the person */
                name: PropTypes.string.isRequired,
            }).isRequired,

            /** Same as A */
            B: PropTypes.shape({
                email: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired,

            /** The email address of the person which was ranked higher */
            higher: PropTypes.string.isRequired,
        }),
    ),

    /** The UI to display inside the group */
    children: PropTypes.node.isRequired,

    /** A method to call when we want to switch a pair */
    onSwitchPair: PropTypes.func.isRequired,

    /** A flag to disable the UI */
    disabled: PropTypes.bool,
};

const defaultProps = {
    autoMatches: [],
    disabled: false,
};

const AutoMatchesGroup = (props) => {
    if (!props.autoMatches.length) {
        return null;
    }

    // Fetch the exclusions when this component mounts
    useEffect(getExclusions, []);

    return (
        <div className="mt-5">
            <h2 className="text-center">{props.title}</h2>
            <p className="text-center my-3">
                {props.children}
            </p>

            <MatchRows
                onSwitch={props.onSwitchPair}
                matches={props.autoMatches}
                disabled={props.disabled}
            />
        </div>
    );
};

AutoMatchesGroup.propTypes = propTypes;
AutoMatchesGroup.defaultProps = defaultProps;

export default AutoMatchesGroup;
