import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    /** Whether or not the spinner should be shown */
    shouldShow: PropTypes.bool,

    /** Any other text that you want to display along with the spinner */
    optionalText: PropTypes.string,
};
const defaultProps = {
    shouldShow: false,
    optionalText: null,
};
const Spinner = (props) => {
    if (!props.shouldShow) {
        return null;
    }

    return (
        <>
            <span className="spinner" />
            {props.optionalText && (<small className="pl-2">{props.optionalText}</small>)}
        </>
    );
};
Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
