import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withOnyx} from 'react-native-onyx';
import lodashGet from 'lodash/get';
import ONYXKEYS from '../../ONYXKEYS';
import compose from '../../libs/compose';
import {
    dismissSetCurrentPeriodError,
    dismissSetCurrentPeriodSuccess,
    setCurrentPeriod,
    dismissSetPeriodStartDateError,
    dismissSetPeriodStartDateSuccess,
    setPeriodStartDate,
} from '../../libs/actions/ManageMatches';
import Alert from '../../components/Alert';
import Spinner from '../../components/Spinner';

const propTypes = {
    /** Information about setting the current period */
    setCurrentPeriod: PropTypes.shape({
        /** Whether or not the API request is pending */
        loading: PropTypes.bool,

        /** A success message from the server */
        success: PropTypes.string,

        /** An error message from the server */
        error: PropTypes.string,
    }),

    /** From Onyx - data about setting the start date for the period */
    setPeriodStartDate: PropTypes.shape({
        /** If the operation is currently in progress */
        loading: PropTypes.bool,

        /** A success message from the server */
        success: PropTypes.string,

        /** An error message from the server */
        error: PropTypes.string,
    }),

    /** The data that needs to be displayed on this page */
    overview: PropTypes.shape({
        /** The current period of comp review */
        period: PropTypes.string,
    }),
};
const defaultProps = {
    setCurrentPeriod: {
        loading: false,
        success: null,
        error: null,
    },
    setPeriodStartDate: {
        loading: false,
        success: null,
        error: null,
    },
    overview: {
        period: '',
    },
};

const ManageMatchesSetCurrentPeriod = (props) => {
    const [shouldShowForm, showForm] = useState(false);
    const currentCompPeriod = props.overview.period || '';
    const currentCompPeriodArray = currentCompPeriod.split('_');
    const currentCompPeriodYear = parseInt(currentCompPeriodArray[0], 10);
    const currentCompPeriodIteration = parseInt(currentCompPeriodArray[1], 10);
    const thisYear = (new Date()).getFullYear();

    const nextPeriod = thisYear === currentCompPeriodYear
        ? `${thisYear}_${currentCompPeriodIteration + 1}`
        : `${thisYear}_1`;

    const submit = () => {
        setCurrentPeriod(nextPeriod);
        showForm(false);
    };

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const todaysDate = `${yyyy}-${mm}-${dd}`;

    const [shouldShowConfirmStart, showConfirmStart] = useState(false);
    const [newStartDate, setStartDate] = useState(lodashGet(props, 'overview.periodDates.startDate') || todaysDate);

    return (
        <div className="card">
            <div className="card-header">
                Step 1: Increment the Comp Period and set the start date
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        {shouldShowForm ? (
                            <>
                                <p>
                                    <strong>
                                        Are you sure you want to start a new comp period for
                                        <code className="mx-2">{nextPeriod}</code>
                                        ?
                                    </strong>
                                </p>

                                <button
                                    type="button"
                                    className="btn btn-success mr-4"
                                    onClick={submit}
                                >
                                    Yes, for sure.
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => showForm(false)}
                                >
                                    No, cancel
                                </button>
                            </>
                        ) : (
                            <>
                                Do this
                                <strong> one month </strong>
                                before comp review begins.
                                <br />
                                <br />
                                <button
                                    type="button"
                                    className="btn btn-success mr-4"
                                    onClick={() => showForm(true)}
                                >
                                    Start a new comp period
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 pt-4">
                        <h3>
                            Set the date when voting begins (currently:
                            {' '}
                            <code>{lodashGet(props, 'overview.periodDates.startDate') || 'not set'}</code>
                            )
                        </h3>
                        {shouldShowConfirmStart ? (
                            <div className="my-4">
                                <p>
                                    <strong>
                                        Are you sure you want to record the start date as
                                        <code className="mx-2">{newStartDate}</code>
                                        ?
                                    </strong>
                                </p>

                                <button
                                    type="button"
                                    className="btn btn-success mr-4"
                                    onClick={() => {
                                        setPeriodStartDate(newStartDate);
                                        showConfirmStart(false);
                                    }}
                                >
                                    Yes, please!
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => showConfirmStart(false)}
                                >
                                    No, cancel
                                </button>
                            </div>
                        ) : (
                            <form className="form">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="effectiveDate"
                                    required
                                    value={newStartDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="btn btn-primary mt-4"
                                    onClick={() => showConfirmStart(true)}
                                >
                                    Set Start Date
                                </button>

                                <Spinner shouldShow={props.setPeriodStartDate.loading} optionalText="This might take a while. Don't refresh the page." />
                            </form>
                        )}
                    </div>
                </div>

                <Alert
                    shouldShow={Boolean(props.setCurrentPeriod.error)}
                    onDismiss={dismissSetCurrentPeriodError}
                    type="danger"
                >
                    <strong>Error:</strong>
                    {` ${props.setCurrentPeriod.error}`}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.setCurrentPeriod.success)}
                    onDismiss={dismissSetCurrentPeriodSuccess}
                >
                    {props.setCurrentPeriod.success}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.setPeriodStartDate.error)}
                    onDismiss={dismissSetPeriodStartDateError}
                    type="danger"
                >
                    <strong>Error:</strong>
                    {` ${props.setPeriodStartDate.error}`}
                </Alert>

                <Alert
                    shouldShow={Boolean(props.setPeriodStartDate.success)}
                    onDismiss={dismissSetPeriodStartDateSuccess}
                >
                    {props.setPeriodStartDate.success}
                </Alert>
            </div>
        </div>
    );
};

ManageMatchesSetCurrentPeriod.propTypes = propTypes;
ManageMatchesSetCurrentPeriod.defaultProps = defaultProps;

export default compose(
    withOnyx({
        setCurrentPeriod: {
            key: ONYXKEYS.MANAGEMATCHES.SETCURRENTPERIOD,
        },
        overview: {
            key: ONYXKEYS.MANAGEMATCHES.OVERVIEW,
        },
    }),
)(ManageMatchesSetCurrentPeriod);
